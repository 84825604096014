import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TitleService } from '@core/services';

@Component({
  selector: 'app-page-404',
  templateUrl: './page-404.component.html',
  styleUrls: ['./page-404.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Page404Component implements OnInit {
  constructor(private titleService: TitleService) {}

  ngOnInit() {
    this.titleService.setTranslatedTitle('PAGE_404.MESSAGE');
  }
}
