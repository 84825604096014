import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { MaterialParameterDto, MaterialParameterUpdateDto, ResultPart } from '@common/interfaces';
import { ApiPaths } from '@common/consts';
import { MaterialsParametersFilterParams } from '@common/models/filters';

@Injectable({ providedIn: 'root' })
export class MaterialParametersApiService {
  constructor(private http: HttpClient) {}

  /**
   * Возвращает заданное количество объектов, по умолчанию возвращает все.
   * @param filter - фильтр
   */
  public getList(filter: MaterialsParametersFilterParams): Observable<ResultPart<MaterialParameterDto>> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<ResultPart<MaterialParameterDto>>(`${ApiPaths.MaterialParameters}/filter`, params);
  }

  public getListWithoutPagination(
    filter: MaterialsParametersFilterParams,
  ): Observable<ResultPart<MaterialParameterDto>> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<ResultPart<MaterialParameterDto>>(
      `${ApiPaths.MaterialParameters}/GetMaterialParametersByFilter`,
      params,
    );
  }

  /**
   * Добавляет новый параметр материала
   * @param entity
   */
  create(entity: MaterialParameterUpdateDto): Observable<MaterialParameterDto> {
    return this.http.post<MaterialParameterDto>(ApiPaths.MaterialParameters, entity);
  }

  /**
   * Обновляет параметр материала
   * @param entity
   */
  update(entity: MaterialParameterUpdateDto): Observable<MaterialParameterDto> {
    return this.http.put<MaterialParameterDto>(`${ApiPaths.MaterialParameters}/${entity.id}`, entity);
  }

  /**
   * Удаляет параметр материала
   * @param entityId- id
   */
  remove(entityId: number): Observable<void> {
    return this.http.delete<void>(`${ApiPaths.MaterialParameters}/${entityId}`);
  }

  public getById(entityId: number): Observable<MaterialParameterDto> {
    return this.http.get<MaterialParameterDto>(`${ApiPaths.MaterialParameters}/${entityId}`);
  }

  public createGoogleSheet(filter: MaterialsParametersFilterParams): Observable<string> {
    const params = filter ? filter.toParams() : {};
    return this.http.post<string>(`${ApiPaths.MaterialParameters}/sheets`, params);
  }

  public importSheet(sheetId: string): Observable<void> {
    return this.http.put<void>(`${ApiPaths.MaterialParameters}/sheets/${sheetId}`, null);
  }

  public createExcelSheet(filter: MaterialsParametersFilterParams): Observable<Blob> {
    const params = filter ? filter.toParams() : {};
    return this.http.post(`${ApiPaths.MaterialParameters}/excel`, params, {
      responseType: 'blob',
    });
  }

  public importExcelSheet(file: File) {
    const data = new FormData();
    data.append('file', file);
    return this.http.put(`${ApiPaths.MaterialParameters}/excel`, data);
  }
}
