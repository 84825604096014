import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RoleType, RoleTypeValue } from '@common/enums';
import { ThemeColors } from '@common/consts';
import { PikTagModule } from '@pik-ui/ng-components';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-role-type-tag',
  template: ` <pik-tag [color]="tagColor">{{ RoleTypeValue[_type] | translate }}</pik-tag> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PikTagModule, TranslateModule],
})
export class RoleTypeTagComponent {
  @Input() set type(value: RoleType) {
    this._type = value;

    switch (value) {
      case RoleType.Admin:
        this.tagColor = this.color.Orange;
        break;
      case RoleType.Another:
        this.tagColor = this.color.Green;
        break;
      case RoleType.Default:
        this.tagColor = this.color.Aqua;
        break;
    }
  }

  get type() {
    return this._type;
  }

  RoleTypeValue = RoleTypeValue;

  public _type: RoleType;

  tagColor: string;

  color = ThemeColors;
}
