import { IAttributeFormItem } from '@shared/components/controls/attributes-control/attributes-control.model';
import { FilterAttributeData } from '@common/dto';
import { transformToIds } from '../transform-to-ids';

export function generateAttributeFilterData(attributes: IAttributeFormItem[]): FilterAttributeData[] {
  return (
    attributes
      ?.filter((a) => !a.removed)
      .map((item, index) => ({
        id: item.attributeId,
        attributeType: item.attributeType,
        valueStr: item.valueStr,
        options: transformToIds(item.options),
        attributeId: item.attributeId,
        sortNumber: index + 1,
      })) || []
  );
}
