import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PatchOperation, RoleDto } from '@common/dto';
import { ApiPaths } from '@common/consts';
import { ResultPart } from '@common/interfaces';
import { RolesFilterParams } from '@common/models/filters';
import { RoleCreateDto } from '@common/dto/Role/RoleCreateDto';

@Injectable({ providedIn: 'root' })
export class RolesApiService {
  constructor(private http: HttpClient) {}

  public getList(filter: RolesFilterParams): Observable<ResultPart<RoleDto>> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<ResultPart<RoleDto>>(`${ApiPaths.Roles}/filter`, params);
  }

  public create(role: RoleCreateDto): Observable<RoleDto> {
    return this.http.post<RoleDto>(ApiPaths.Roles, role);
  }

  public patch(id: number, patchData: PatchOperation[]): Observable<RoleDto> {
    return this.http.patch<RoleDto>(`${ApiPaths.Roles}/${id}`, patchData);
  }

  public delete(id: number): Observable<void> {
    return this.http.delete<void>(`${ApiPaths.Roles}/${id}`);
  }
}
