<div class="input-wrap">
  <input
    pikInput
    [formControl]="nameControl"
    [pikAutocomplete]="ftInput"
    placeholder="{{ 'COMPONENT_MATERIAL_CONTROL' | translate }}"
  />

  <pik-loader *ngIf="pending"></pik-loader>
</div>

<pik-autocomplete
  [standalone]="false"
  #ftInput="pikAutocomplete"
  (optionSelected)="pushItem($event)"
  [dataSource]="entities$ | async"
>
</pik-autocomplete>

<div class="tags" *ngIf="list?.length">
  <pik-tag large *ngFor="let item of list" removable (remove)="removeItem(item)">{{ item.name }}</pik-tag>
</div>
