<input
  type="text"
  pikInput
  [pikAutocomplete]="nomenclatureAutocomplete"
  [formControl]="entityName"
  placeholder="{{ 'COMPONENT_REQUEST_CONTROL' | translate }}"
/>

<pik-autocomplete
  #nomenclatureAutocomplete="pikAutocomplete"
  (optionSelected)="selectEntity($event)"
  [dataSource]="dataSource$ | async"
></pik-autocomplete>

<div class="tags" *ngIf="list?.length">
  <pik-tag large *ngFor="let item of list" removable (remove)="removeItem(item)">{{ item.name }}</pik-tag>
</div>
