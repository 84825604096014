<ng-container *ngIf="multi; else singleView">
  <ng-container
    *ngFor="
      let functionalType of functionalTypes
        | slice: 0:(isShowAllCount || !maxCount ? functionalTypes.length : maxCount);
      trackBy: trackByIndex
    "
  >
    <app-condition-link [access]="linked" [link]="['', routesParts.FUNCTIONAL_TYPES, functionalType?.id]">
      <pik-tag large [color]="ThemeColors.FunctionalType">
        {{ functionalType?.name }}
      </pik-tag>
    </app-condition-link>
  </ng-container>

  <pik-tag
    class="toggle-tag"
    *ngIf="maxCount !== undefined && functionalTypes.length > maxCount"
    [color]="'transparent'"
    [textColor]="ThemeColors.DarkBlue"
    (click)="toggle()"
    large
  >
    {{
      isShowAllCount
        ? ('COMMON.HIDE' | translate)
        : ('COMMON_PHRASE.AND_MORE' | translate: { number: functionalTypes.length - maxCount })
    }}
  </pik-tag>
</ng-container>

<ng-template #singleView>
  <app-condition-link [access]="linked" [link]="['', routesParts.FUNCTIONAL_TYPES, entity?.id]">
    <pik-tag large [color]="ThemeColors.FunctionalType">
      {{ entity?.name }}
    </pik-tag>
  </app-condition-link>
</ng-template>
