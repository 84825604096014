import { ITablePageFilter } from './table-page-filter.interface';

const LIMIT_MIN = 50;

export class TableParams {
  public readonly limitMin = LIMIT_MIN;
  public readonly pageSizesList = [LIMIT_MIN, 100];

  public skip: number;
  public limit: number;

  get pageIndex(): number {
    return this._pageIndex;
  }
  set pageIndex(index: number) {
    this._pageIndex = index;
    this.skip = (index - 1) * this.limit;
  }

  private _pageIndex = 1;

  constructor(params?: ITablePageFilter) {
    this.skip = 0;
    this.limit = LIMIT_MIN;

    this.setParams(params);
  }

  public setParams(params?: ITablePageFilter) {
    if (params) {
      if (params.skip) {
        this.skip = parseInt(`${params.skip}`, 0);
      }

      if (params.limit) {
        this.limit = parseInt(`${params.limit}`, 0);
      }

      if (params.pageIndex) {
        this.pageIndex = parseInt(`${params.pageIndex}`, 0);
      }
    }
  }

  public toObject(): ITablePageFilter {
    return {
      skip: this.skip,
      limit: this.limit,
      pageIndex: this.pageIndex,
    };
  }
}
