import { Injectable, NgZone } from '@angular/core';
import { AttachmentsCarouselService } from '@pik-ui/ng-components';

import { convertToBase64 } from '@common/utils';
import { AttachmentDto } from '@common/dto';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CarouselService {
  constructor(private carousel: AttachmentsCarouselService, private _zone: NgZone, private _sanitizer: DomSanitizer) {}

  public async openImageFile(file: Blob, fileName: string) {
    const extension = fileName.split('.').pop().toLowerCase();

    const imageFile = new Blob([file], {
      type: `image/${extension}`,
    });
    const base64 = (await convertToBase64(imageFile as File)) as string;

    this._zone.run(() => {
      this.carousel.open({
        files: [
          {
            url: base64,
            name: fileName,
            extension,
          },
        ],
      });
    });
  }

  openCarousel(attachmentList: AttachmentDto[], imageFileList$: Observable<Blob>[], fileId: number): void {
    forkJoin(imageFileList$).subscribe((urls) => {
      const urlCreator = window.URL || window.webkitURL;

      const currentIndex = attachmentList.findIndex((x) => x.id === fileId);
      const attachments = attachmentList.map((a, i) => ({
        ...a,
        url: this._sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(urls[i])) as string,
        file: urls[i],
      }));
      this._zone.run(() => {
        this.carousel.open({
          files: attachments.map((a) => {
            return {
              url: a.url,
              name: a.fileName,
              extension: a.fileName.split('.').pop(),
              file: a.file,
            };
          }),
          currentIndex,
        });
      });
    });
  }
}
