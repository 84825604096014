import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fm-dropdown-bottom-bar',
  templateUrl: './fm-dropdown-bottom-bar.component.html',
  styleUrls: ['./fm-dropdown-bottom-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FmDropdownBottomBarComponent {
  @Input() value: string;
  @Input() bordered: boolean;
  @Input() placeholder: string;
  @Input() buttonText = 'COMMON.ADD';
  @Input() uniqueValues: string[] = [];
  @Input() withForm = true;
  @Output() submit: EventEmitter<string> = new EventEmitter();
  showForm = false;

  addHandler() {
    if (this.withForm) {
      this.showForm = true;
    } else {
      this.submit.emit('');
    }
  }

  cancelHandler() {
    this.showForm = false;
  }
}
