<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="'entityCard'">
    <ng-container *ngIf="all || opened; else noCounts">
      <a [routerLink]="['', ROUTES_PARTS.REQUESTS, allFilter]">
        <pik-tag *ngIf="all" large [color]="colors.Request">
          {{ 'COMPONENT_REQUEST_TAG.ALL' | translate }} {{ all }}</pik-tag
        >
      </a>
      <a [routerLink]="['', ROUTES_PARTS.REQUESTS, openedFilter]">
        <pik-tag *ngIf="opened" large [color]="colors.Request">
          {{ 'COMPONENT_REQUEST_TAG.OPENED' | translate }} {{ opened }}</pik-tag
        >
      </a>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'userCard'">
    <ng-container *ngIf="isDependsOnMe; else noCounts">
      <a [routerLink]="['', ROUTES_PARTS.REQUESTS, userFilter]">
        <pik-tag large [color]="colors.Request">
          {{ 'COMPONENT_REQUEST_TAG.REQUIRE_ACTION' | translate }} {{ isDependsOnMe }}
        </pik-tag>
      </a>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #noCounts>
  {{ 'COMMON.NOT_AVAILABLE' | translate }}
</ng-template>
