import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { getValidBooleanValue } from './utils/get-valid-boolean-value';
import { TypedFormControl } from '@common/interfaces';
import { BooleanValues } from '../../enums';
import { ParameterValueControlService } from '@shared/components/controls/parameter-value-control/services';

@Component({
  selector: 'app-parameter-boolean-value-control',
  templateUrl: './parameter-boolean-value-control.component.html',
  styleUrls: ['./parameter-boolean-value-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ParameterBooleanValueControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ParameterBooleanValueControlComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParameterBooleanValueControlComponent implements OnInit, OnDestroy, ControlValueAccessor {
  control: TypedFormControl<BooleanValues> = new FormControl(null, Validators.required);
  BooleanValues = BooleanValues;

  private readonly _destroy$ = new Subject();
  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(
    private _changeDetector: ChangeDetectorRef,
    public parameterValueControlService: ParameterValueControlService,
  ) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this.subscribeToControl().subscribe();
  }

  validate() {
    return this.control.invalid ? { invalidValue: true } : null;
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.control.disable() : this.control.enable();
    this._changeDetector.markForCheck();
  }

  writeValue(value: string) {
    const controlValue: BooleanValues = getValidBooleanValue(value);
    this.control.setValue(controlValue, { emitEvent: false });
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  nextChangeValue(value: string): void {
    if (this._onChange) {
      this._onChange(value);
    }
  }

  private subscribeToControl() {
    return this.control.valueChanges.pipe(
      tap((value) => (value === null ? null : this.nextChangeValue(value))),
      takeUntil(this._destroy$),
    );
  }
}
