import { SortOrder } from '@common/interfaces/SortOrder';
import { ITablePageFilter } from '@common/models/filters/table-page-filter.interface';

export const DEFAULT_PAGE_PARAMS: ITablePageFilter = {
  sortBy: '',
  sortOrder: SortOrder.Ascending,
  limit: 50,
  skip: 0,
  pageIndex: 1,
  searchField: '',
  searchValue: '',
};
