import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { PikAutocompleteSelectedEvent } from '@pik-ui/ng-components';

import { IdNameDto, MaterialDto } from '@common/dto';
import { MaterialsApiService } from '@services/api';
import { MaterialsFilterParams } from '@common/models/filters';
import { TypedFormControl } from '@common/interfaces';

@Component({
  selector: 'app-material-control',
  templateUrl: './material-control.component.html',
  styleUrls: ['./material-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialControlComponent implements ControlValueAccessor, OnDestroy, OnInit {
  @Output() selectFull = new EventEmitter<MaterialDto>();
  @Input() materialsFilterParams = null;
  public readonly nameControl: TypedFormControl<string> = new FormControl(null);
  selectedMaterial: Partial<IdNameDto | MaterialDto>;
  entities$ = new Subject();
  isDisable: boolean;

  private _destroy$ = new Subject();

  private _onChange: (value) => void;
  public _onTouched: () => void;

  constructor(private _changeDetector: ChangeDetectorRef, private _materialsApi: MaterialsApiService) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this._subscribeToNameControl().subscribe();
  }

  writeValue(value: number) {
    if (value) {
      this._getEntity(value);
    }
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  selectEntity(event: PikAutocompleteSelectedEvent) {
    this.selectedMaterial = event.value;
    this.nextChangeValue();
  }

  nextChangeValue(): void {
    if (this._onChange) {
      const id = this.selectedMaterial?.id || null;
      if (id) {
        this._materialsApi
          .getById(id)
          .pipe(
            take(1),
            tap((material) => this.selectFull.emit(material)),
          )
          .subscribe();
      } else {
        this.selectFull.emit(null);
      }

      this._onChange(id);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisable = isDisabled;
    this._changeDetector.markForCheck();
  }

  private _subscribeToNameControl() {
    return this.nameControl.valueChanges.pipe(
      debounceTime(300),
      filter((f) => f !== this.selectedMaterial?.name),
      tap((name) => {
        this.selectedMaterial = null;
        this.nextChangeValue();
      }),
      switchMap((value) =>
        !value
          ? of([])
          : this.materialsFilterParams
          ? this._materialsApi
              .getList(new MaterialsFilterParams({ name: value }))
              .pipe(map(({ result }) => result.map((i) => ({ ...i, functionalTypes: [i.functionalType] }))))
          : this._materialsApi.smartSearch(value),
      ),
      map((result: IdNameDto[]) =>
        result.map((item) => ({
          label: item.name,
          value: item,
        })),
      ),
      tap((data) => this.entities$.next(data)),
      takeUntil(this._destroy$),
    );
  }

  private _getEntity(id: number) {
    if (this.selectedMaterial?.id !== id) {
      this._materialsApi
        .getList(new MaterialsFilterParams({ id, isShowDeleted: true }))
        .pipe(
          take(1),
          map((data) => data.result.map((entity) => ({ id: entity.id, name: entity.name }))[0]),
          tap((entity: IdNameDto) => {
            this.nameControl.setValue(entity.name, { emitEvent: false });
            this.selectedMaterial = entity;
            this._changeDetector.detectChanges();
          }),
        )
        .subscribe();
    }
  }
}
