import { BindingFilterOperators } from './BindingFilterOperators';

export enum BindingFilterTypes {
  FamilyName = 1,
  SymbolName = 2,
  FamilyParameterName = 4,
  FamilyParameterValue = 5,
  MaterialParameterName = 20,
  MaterialParameterValue = 21,
  NomenclatureName = 6,
  SpecificationName = 7,
  SpecificationValue = 8,
  Formula = 9,
}

export interface BindingFilterType {
  id: BindingFilterTypes;
  name: string;
}

export type BindingFilterOperatorsByType = {
  [key in BindingFilterTypes]: BindingFilterOperators[];
};

export const BINDING_FILTER_TYPES: BindingFilterType[] = [
  { name: 'BINDINGS_MODULE.BINDING_FILTER_TYPES.FAMILY_NAME', id: BindingFilterTypes.FamilyName },
  { name: 'BINDINGS_MODULE.BINDING_FILTER_TYPES.SYMBOL_NAME', id: BindingFilterTypes.SymbolName },
  { name: 'BINDINGS_MODULE.BINDING_FILTER_TYPES.FAMILY_PARAMETER_NAME', id: BindingFilterTypes.FamilyParameterName },
  { name: 'BINDINGS_MODULE.BINDING_FILTER_TYPES.FAMILY_PARAMETER_VALUE', id: BindingFilterTypes.FamilyParameterValue },
  {
    name: 'BINDINGS_MODULE.BINDING_FILTER_TYPES.MATERIAL_PARAMETER_NAME',
    id: BindingFilterTypes.MaterialParameterName,
  },
  {
    name: 'BINDINGS_MODULE.BINDING_FILTER_TYPES.MATERIAL_PARAMETER_VALUE',
    id: BindingFilterTypes.MaterialParameterValue,
  },
  { name: 'BINDINGS_MODULE.BINDING_FILTER_TYPES.NOMENCLATURE_NAME', id: BindingFilterTypes.NomenclatureName },
  { name: 'BINDINGS_MODULE.BINDING_FILTER_TYPES.SPECIFICATION_NAME', id: BindingFilterTypes.SpecificationName },
  { name: 'BINDINGS_MODULE.BINDING_FILTER_TYPES.SPECIFICATION_VALUE', id: BindingFilterTypes.SpecificationValue },
  { name: 'BINDINGS_MODULE.BINDING_FILTER_TYPES.FORMULA', id: BindingFilterTypes.Formula },
];

export const BINDING_FILTER_OPERATORS_BY_TYPE: BindingFilterOperatorsByType = {
  [BindingFilterTypes.FamilyName]: [BindingFilterOperators.Equal, BindingFilterOperators.NotEqual],
  [BindingFilterTypes.SymbolName]: [BindingFilterOperators.Equal, BindingFilterOperators.NotEqual],
  [BindingFilterTypes.FamilyParameterName]: [BindingFilterOperators.Equal],
  [BindingFilterTypes.FamilyParameterValue]: Object.values(BindingFilterOperators) as BindingFilterOperators[],
  [BindingFilterTypes.MaterialParameterName]: [BindingFilterOperators.Equal],
  [BindingFilterTypes.MaterialParameterValue]: Object.values(BindingFilterOperators) as BindingFilterOperators[],
  [BindingFilterTypes.NomenclatureName]: [BindingFilterOperators.Equal, BindingFilterOperators.NotEqual],
  [BindingFilterTypes.SpecificationName]: [BindingFilterOperators.Equal],
  [BindingFilterTypes.SpecificationValue]: Object.values(BindingFilterOperators) as BindingFilterOperators[],
  [BindingFilterTypes.Formula]: [BindingFilterOperators.Equal],
};
