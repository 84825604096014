<div class="familyTags">
  <div class="familyTags__itemTitle">
    <!--    <svg-icon *ngIf="icon" [src]="icon" [stretch]="true"></svg-icon>-->
    <fm-block-title size="4">{{ title }}</fm-block-title>
    <fm-info-tooltip *ngIf="tooltip" [text]="tooltip"></fm-info-tooltip>
    <fm-hint *ngIf="helpLink" [link]="helpLink" size="small"></fm-hint>
  </div>

  <div *ngIf="subtitle" class="familyTags__subtitle">{{ subtitle }}</div>

  <div class="familyTags__itemTag">
    <ng-content></ng-content>
  </div>
</div>
