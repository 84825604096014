import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  DashboardCounterDto,
  DashboardFamilyCounterDto,
  DashboardRequestCounterDto,
  DashboardTopFamilyCounterDto,
  DynamicsDto,
  EntityType,
  TopFamilySubType,
  TopUserSubType,
  VersionErrorDto,
} from '@common/dto';
import { ApiPaths } from '@common/consts';
import { Observable } from 'rxjs';
import { DashboardTopUserCounterDto } from '@common/dto/Dashboard/DashboardTopUserCounterDto';
import { DynamicsFilterParams, FamilyCountersFilterParams, RequestCountersFilterParams } from '@common/models/filters';
import { ElementCountDto } from '@common/dto/Dashboard/ElementCountDto';

@Injectable({ providedIn: 'root' })
export class DashboardApiService {
  constructor(private http: HttpClient) {}

  /**
   * Возвращает полный перечень каунтеров дашборда
   */
  public getCounters(): Observable<DashboardCounterDto[]> {
    return this.http.get<DashboardCounterDto[]>(`${ApiPaths.Dashboard}/counters`);
  }

  /**
   * Возвращает полный перечень каунтеров дашборда
   */
  public getErrors(): Observable<VersionErrorDto[]> {
    return this.http.get<VersionErrorDto[]>(`${ApiPaths.Dashboard}/errors/all`);
  }

  public getFamilyTops(type: TopFamilySubType): Observable<DashboardTopFamilyCounterDto> {
    return this.http.get<DashboardTopFamilyCounterDto>(`${ApiPaths.Dashboard}/familyTops`, {
      params: {
        type,
      },
    });
  }

  public getUserTops(type: TopUserSubType): Observable<DashboardTopUserCounterDto> {
    return this.http.get<DashboardTopUserCounterDto>(`${ApiPaths.Dashboard}/userTops`, {
      params: {
        type,
      },
    });
  }

  public getFamilyCounters(filter: FamilyCountersFilterParams): Observable<DashboardFamilyCounterDto[]> {
    const params = filter ? filter.toParams() : {};
    return this.http.post<DashboardFamilyCounterDto[]>(`${ApiPaths.Dashboard}/familyCounters/filter`, params);
  }

  public getRequestCounters(filter: RequestCountersFilterParams): Observable<DashboardRequestCounterDto[]> {
    const params = filter ? filter.toParams() : {};
    return this.http.post<DashboardRequestCounterDto[]>(`${ApiPaths.Dashboard}/requestCounters/filter`, params);
  }

  /** Возвращает количество новых элементов динамики за сегодня, за неделю, за месяц, всего */
  public getDynamicsNewElements(
    entityType: EntityType,
    onlyCompletedRequest: boolean = false,
  ): Observable<ElementCountDto> {
    const params = {
      entityType,
      onlyCompletedRequest,
    };

    return this.http.get<ElementCountDto>(`${ApiPaths.Dashboard}/dynamics/newElements`, {
      params,
    });
  }

  /** Получение динамики по фильтру */
  public getDynamicsFilter(filter: DynamicsFilterParams): Observable<DynamicsDto[]> {
    const params = filter ? filter.toParams() : {};
    return this.http.post<DynamicsDto[]>(`${ApiPaths.Dashboard}/dynamics/filter`, params);
  }
}
