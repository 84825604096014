<ng-container *ngIf="!isDisable; else parameterView">
  <input
    pikInput
    [formControl]="nameControl"
    [pikAutocomplete]="autocomplete"
    placeholder="{{ 'COMPONENT_UNIQUE_PARAMETER_CONTROL' | translate }}"
    #input
  />

  <pik-autocomplete
    [standalone]="false"
    #autocomplete="pikAutocomplete"
    (optionSelected)="selectEntity($event)"
    [dataSource]="entities$ | async"
  >
  </pik-autocomplete>
</ng-container>

<ng-template #parameterView>
  <ng-container *ngIf="selectedEntity$ | async as selectedEntity">
    <app-unique-parameters-link [entity]="selectedEntity"></app-unique-parameters-link>
  </ng-container>
</ng-template>
