import { FilterAttributeData, RangeDateDto } from '@common/dto';
import { IProjectsFilterParams } from './projects-filter.interface';
import { TablePageFilter } from '../table-page-filter-base';
import { formatRangeDate } from '@shared/utils';

const DEFAULT_PARAMS: IProjectsFilterParams = {
  defaultSortBy: 'mask',
};

export class ProjectsFilterParams extends TablePageFilter<IProjectsFilterParams> {
  id: number;
  mask: string;
  substring: string;
  description: string;
  withDisciplinesOnly: boolean;
  disciplineIds: number[];
  withAttributesOnly: boolean;
  attributes: FilterAttributeData[];
  authorIds: number[];
  lastEditorIds: number[];
  created: RangeDateDto;
  modified: RangeDateDto;

  constructor(filter: IProjectsFilterParams = {}) {
    super(filter, DEFAULT_PARAMS);
  }

  toObject(): IProjectsFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      mask: this.substring ? this.substring : this.mask,
      pathAsTemplate: !!(this.mask && !this.substring),
      withDisciplinesOnly: this.withDisciplinesOnly,
      disciplineIds: this.disciplineIds,
      description: this.description,
      withAttributesOnly: this.withAttributesOnly,
      attributes: this.attributes,
      authorIds: this.authorIds,
      lastEditorIds: this.lastEditorIds,
      created: formatRangeDate(this.created),
      modified: formatRangeDate(this.modified),
      substring: this.substring,
    };
  }
}
