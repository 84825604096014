import { ChangeDetectionStrategy, Component, ContentChild, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PikDrawerComponent } from '@pik-ui/ng-components';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { TablePageServiceBase } from '../models/table-page-service';
import { TableSettingsFilter } from '../models/filters-component';
import { TableColumnDef } from '../models/table-columns';
import { mapColumnsToStorage } from '../utils';
import { TableSettingsTabs } from '../types';
import { TypedFormControl } from '@common/interfaces';
import { AttributeDto } from '@common/dto';
import { Subject } from 'rxjs';
import { AttributesListControlComponent } from '@shared/components';

@Component({
  selector: 'app-table-settings',
  templateUrl: './table-settings.component.html',
  styleUrls: ['./table-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSettingsComponent implements OnInit, OnDestroy {
  @Input() showFilters: boolean;

  @ViewChild(PikDrawerComponent) readonly drawer: PikDrawerComponent;
  @ViewChild(AttributesListControlComponent) readonly attributeListControlComponent: AttributesListControlComponent;

  @ContentChild(TableSettingsFilter) tableFilterRef: TableSettingsFilter;

  TableSettingsTabs = TableSettingsTabs;
  selectedTabIndex: number;
  form: FormGroup;
  attributesControl: TypedFormControl<number[]> = new FormControl([]);

  readonly visibleColumns: TableColumnDef[];

  private destroy$ = new Subject<any>();

  constructor(public pageService: TablePageServiceBase) {
    this.showFilters = true;

    if (!this.pageService.tableColumns) {
      throw new Error('Speaker settings not set');
    }

    this.visibleColumns = this.pageService.tableColumns.filter((column) => column.isVisible);
    this.buildForm();
  }

  ngOnInit(): void {
    this.selectedTabIndex = this.showFilters ? TableSettingsTabs.Filter : TableSettingsTabs.Columns;
    this.getAttributesColumns();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  reorderColumn(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.pageService.tableColumns, event.previousIndex, event.currentIndex);

    this.pageService.tableColumns.map((col, idx) => {
      col['orderPosition'] = idx;
      return col;
    });

    this.buildForm(true);
  }

  resetFilter() {
    if (this.tableFilterRef) {
      this.tableFilterRef.reset();
      this.tableFilterRef.updateFiltersActiveState();
    }

    this.close();
  }

  cancel() {
    if (this.selectedTabIndex === TableSettingsTabs.Columns) {
      this.buildForm();
      this.close();
    }

    if (this.selectedTabIndex === TableSettingsTabs.Filter) {
      this.tableFilterRef.hasFilters() ? this.close() : this.resetFilter();
    }
  }

  apply() {
    if (this.selectedTabIndex === TableSettingsTabs.Columns) {
      this._applyColumnSettings();
      this.close();
    }

    if (this.selectedTabIndex === TableSettingsTabs.Filter) {
      const isValid = this.tableFilterRef.validate();

      if (isValid) {
        this.tableFilterRef.filter();
        this.tableFilterRef.updateFiltersActiveState();

        if (this.tableFilterRef.form) {
          this.tableFilterRef.form.markAsUntouched();
        }

        this.close();
      }
    }
  }

  setDefaultSettings() {
    this.pageService.setDefaultColumnsSettings();
    this.attributesControl.setValue([]);
    this.buildForm();
    this.close();
  }

  open() {
    this.drawer.open();
  }

  close() {
    this.drawer.close();
  }

  private _applyColumnSettings() {
    const visibleColumns = this.form.getRawValue();
    const columns: TableColumnDef[] = this.pageService.tableColumns.filter((f) => visibleColumns[f.name]);

    this.setAttributesColumns();

    const displayedColumns = columns.map(mapColumnsToStorage);
    this.pageService.setVisibleColumns(displayedColumns);
  }

  private setAttributesColumns() {
    const attributes: AttributeDto[] = this.attributesControl.value.map((key) =>
      this.attributeListControlComponent.attributes.get(key),
    );

    this.pageService.setAttributesColumns(this.attributesControl.value, attributes);
  }

  private buildForm(reOrder = false) {
    const controls = {};
    const oldFormValues = this.form?.value;

    this.pageService.tableColumns.forEach((c) => {
      const value = (reOrder ? oldFormValues[c.name] : c.isVisible) || c.required;
      controls[c.name] = new FormControl({ value, disabled: c.required });
    });

    this.form = new FormGroup(controls);
  }

  private getAttributesColumns() {
    if (this.pageService.attributesTableColumns) {
      this.attributesControl.setValue(this.pageService.attributesTableColumns);
    }
  }
}
