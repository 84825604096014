import { TablePageFilter } from '../table-page-filter-base';
import { IDataTypesFilterParams } from './data-types-filter.interface';

export class DataTypesFilterParams extends TablePageFilter<IDataTypesFilterParams> {
  id: number;
  name: string;
  code: string;

  constructor(filter?: IDataTypesFilterParams) {
    super(filter);
  }

  toObject(): IDataTypesFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      name: this.name,
      code: this.code,
    };
  }
}
