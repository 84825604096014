<pik-tag [color]="ThemeColors.Gray4" [textColor]="textColor" large>
  <ng-container [ngSwitch]="_value">
    <ng-container *ngSwitchCase="BooleanValues.Yes">{{ 'PARAMETER_BOOLEAN_VALUE.YES' | translate }}</ng-container>
    <ng-container *ngSwitchCase="BooleanValues.No">{{ 'PARAMETER_BOOLEAN_VALUE.NO' | translate }}</ng-container>
    <ng-container *ngSwitchCase="BooleanValues.YesOrNo">{{
      'PARAMETER_BOOLEAN_VALUE.YES_OR_NO' | translate
    }}</ng-container>
    <ng-container *ngSwitchDefault>—</ng-container>
  </ng-container>
</pik-tag>
