import { Directive } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'fm-table-page-header',
  // tslint:disable-next-line:no-host-metadata-property
  host: { class: 'fm-table-page-header' },
})
export class FmTablePageHeaderDirective {}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'fm-table-page-title, [fm-table-page-title]',
  // tslint:disable-next-line:no-host-metadata-property
  host: { class: 'fm-table-page-title' },
})
export class FmTablePageTitleDirective {}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'fm-table-page-toolbar',
  // tslint:disable-next-line:no-host-metadata-property
  host: { class: 'fm-table-page-toolbar' },
})
export class FmTablePageToolbarDirective {}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[fmTableFilter]',
})
export class FmTablePageFilterDirective {}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'fm-table-toolbar-action, [fm-table-toolbar-action]',
  // tslint:disable-next-line:no-host-metadata-property
  host: { class: 'fm-table-toolbar-action' },
})
export class FmTablePageToolbarActionDirective {}
