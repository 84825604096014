import { AppType, FamilyType } from '@common/enums';
import { SortOrder } from '@common/interfaces';
import { FilterAttributeData, RangeDateDto } from '@common/dto';

import { TablePageFilter } from '../table-page-filter-base';
import { IFamiliesFilterParams } from '../index';
import { formatRangeDate } from '@shared/utils';

const DEFAULT_PARAMS: IFamiliesFilterParams = {
  defaultSortBy: 'created',
  sortOrder: SortOrder.Descending,
};

export class FamiliesFilterParams extends TablePageFilter<IFamiliesFilterParams> {
  id: number;
  type: FamilyType;
  category: string;
  categoryIds: number[];
  name: string;
  author: string;
  authorIds: number[];
  lastEditor: string;
  lastEditorIds: number[];
  disciplineIds: number[];
  versionStatus: string;
  versionStatusIds: number[];
  created: RangeDateDto;
  modified: RangeDateDto;
  functionalType: string;
  functionalTypeIds: number[];
  parameterIds: number[];
  materialId: number;
  folderIds: number[];
  attributes: FilterAttributeData[];
  withAttributesOnly: boolean;
  valueStrFromFilterAsMask: boolean;
  withDisciplinesOnly: boolean;
  appType: AppType;
  searchString: string;
  description: string;
  versionDescription: string;

  constructor(filter: IFamiliesFilterParams = {}) {
    super(filter, DEFAULT_PARAMS);
  }

  public toObject(): IFamiliesFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      type: this.type,
      category: this.category,
      categoryIds: this.categoryIds,
      name: this.name,
      author: this.author,
      authorIds: this.authorIds,
      lastEditor: this.lastEditor,
      lastEditorIds: this.lastEditorIds,
      disciplineIds: this.disciplineIds,
      versionStatus: this.versionStatus,
      versionStatusIds: this.versionStatusIds,
      created: formatRangeDate(this.created),
      modified: formatRangeDate(this.modified),
      functionalType: this.functionalType,
      functionalTypeIds: this.functionalTypeIds,
      parameterIds: this.parameterIds,
      materialId: this.materialId,
      folderIds: this.folderIds,
      attributes: this.attributes,
      withAttributesOnly: this.withAttributesOnly,
      valueStrFromFilterAsMask: this.valueStrFromFilterAsMask,
      withDisciplinesOnly: this.withDisciplinesOnly,
      appType: this.appType,
      searchString: this.searchString,
      description: this.description,
      versionDescription: this.versionDescription,
    };
  }
}
