import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { TitleService } from '@core/services';

@Component({
  selector: 'app-read-only-page',
  templateUrl: './read-only-page.component.html',
  styleUrls: ['./read-only-page.component.scss'],
})
export class ReadOnlyPageComponent implements OnInit {
  environment = environment;

  constructor(private titleService: TitleService) {}

  ngOnInit() {
    this.titleService.setTitle('Family Manager');
  }
}
