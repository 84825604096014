import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BindingSourceType } from '@common/dto';
import { ApiPaths } from '@common/consts';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MatchingValuesApiService {
  constructor(private http: HttpClient) {}

  getSpecificationValues(
    specificationId: number,
    nomenclatureKindId: number,
    bindingSourceType: BindingSourceType,
  ): Observable<string[]> {
    return this.http.get<string[]>(`${ApiPaths.MatchingValues}/nomenclature-values`, {
      params: {
        specificationId: specificationId.toString(),
        nomenclatureKindId: nomenclatureKindId.toString(),
        bindingSourceType,
      },
    });
  }
}
