import { TablePageFilter } from '../table-page-filter-base';
import { ICategoriesFilterParams } from './categories-filter.interface';

const DEFAULT_PARAMS: ICategoriesFilterParams = {
  defaultSortBy: 'name',
};

export class CategoriesFilterParams extends TablePageFilter<ICategoriesFilterParams> {
  id: number;
  builtInCategoryId: number;
  name: string;
  builtInCategory: string;

  constructor(filter?: ICategoriesFilterParams) {
    super(filter, DEFAULT_PARAMS);
  }

  public toObject(): ICategoriesFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      builtInCategoryId: this.builtInCategoryId,
      name: this.name,
      builtInCategory: this.builtInCategory,
    };
  }
}
