import { RangeDateDto } from '@common/dto';
import { SortOrder } from '@common/interfaces';

import { TablePageFilter } from '../table-page-filter-base';
import { ILoadLogFilterParams } from './load-log-filter.interface';
import moment from 'moment/moment';
import { formatRangeDate } from '@shared/utils';

const DEFAULT_PARAMS: ILoadLogFilterParams = {
  defaultSortBy: 'created',
  sortOrder: SortOrder.Descending,
};

export class LoadLogFilterParams extends TablePageFilter<ILoadLogFilterParams> {
  id: number;
  familyIds: number[];
  familyVersionIds: number[];
  familySymbolIds: number[];
  functionalTypeIds: number[];
  familyVersionStatusIds: number[];
  userIds: number[];
  documentIds: number[];
  searchString: string;
  family: string;
  functionalType: string;
  familySymbol: string;
  familyVersionStatus: string;
  user: string;
  document: string;
  created: RangeDateDto;

  constructor(filter?: ILoadLogFilterParams) {
    super(filter, DEFAULT_PARAMS);
  }

  public toObject(): ILoadLogFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      familyIds: this.familyIds,
      familyVersionIds: this.familyVersionIds,
      familySymbolIds: this.familySymbolIds,
      familyVersionStatusIds: this.familyVersionStatusIds,
      functionalTypeIds: this.functionalTypeIds,
      userIds: this.userIds,
      documentIds: this.documentIds,
      searchString: this.searchString,
      family: this.family,
      functionalType: this.functionalType,
      familySymbol: this.familySymbol,
      familyVersionStatus: this.familyVersionStatus,
      user: this.user,
      document: this.document,
      created: formatRangeDate(this.created),
    };
  }
}
