<form [formGroup]="form">
  <div style="margin-bottom: 12px">
    <pik-checkbox formControlName="withAttributesOnly">
      <span class="capitalize-sentence">{{
        'ATTRIBUTES_CHECKBOX_FILTER' | translate: { value: entityCheckboxLabel | translate }
      }}</span>
    </pik-checkbox>
  </div>

  <app-attributes-control
    formControlName="attributes"
    [originalAttributesList]="dictionariesService.attributes$ | async"
  ></app-attributes-control>
</form>
