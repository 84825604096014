export enum FamilyRequestActionsEnum {
  Assign = 'Assign',
  Dismiss = 'Dismiss',
  Create = 'Create',
  Take = 'Take',
  ApplyToModeration = 'ApplyToModeration',
  ApplyToApprove = 'ApplyToApprove',
  Cancel = 'Cancel',
  Approve = 'Approve',
  Reject = 'Reject',
  Close = 'Close',
  GrantSolution = 'GrantSolution',
  ApproveSolution = 'ApproveSolution',
  RejectSolution = 'RejectSolution',
  TakeSolution = 'TakeSolution',
  SetSolutionDate = 'SetSolutionDate',
  ChangeSolutionDate = 'ChangeSolutionDate',
  OfferHimselfAs = 'OfferHimselfAs',
}
