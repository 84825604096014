import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-fm-back-button',
  templateUrl: './fm-back-button.component.html',
  styleUrls: ['./fm-back-button.component.scss'],
})
export class FmBackButtonComponent {
  @Input() label: string;
  @Input() link: string;
}
