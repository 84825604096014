import { Directive } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'fm-page-top-toolbar',
})
export class FmPageTopToolbarDirective {}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'fm-page-header',
  // tslint:disable-next-line:no-host-metadata-property
  host: { class: 'fm-page-header' },
})
export class FmPageHeaderDirective {}
