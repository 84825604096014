import { Component, Input } from '@angular/core';
import { ToastService } from '@services';

@Component({
  selector: 'fm-copy-button',
  templateUrl: './fm-copy-button.component.html',
  styleUrls: ['./fm-copy-button.component.scss'],
})
export class FmCopyButtonComponent {
  @Input() text: string | number;
  @Input() type: 'text' | 'link' = 'text';

  constructor(private toastService: ToastService) {}

  copy($event) {
    $event.stopPropagation();

    const temp = document.createElement('input');
    document.body.appendChild(temp);
    temp.value = this.text?.toString() || '';
    temp.select();
    document.execCommand('copy');
    document.body.removeChild(temp);
    this.toastService.copied(temp.value);
  }
}
