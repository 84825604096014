import { ChangeDetectorRef, Component, forwardRef, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { uniq } from 'lodash';
import { SelectComponent } from '../select/select.component';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class MultiSelectComponent extends SelectComponent {
  control = new UntypedFormControl([]);
  constructor(protected _cd: ChangeDetectorRef) {
    super(_cd);
  }

  selectAll() {
    setTimeout(() => {
      const foundValues = this.filteredOptions.map((o) => o.id);
      this.control.setValue(uniq([...this.control.value, ...foundValues]));
      this._cd.detectChanges();
      this.selectRef._detectChanges();
    });
  }

  reset() {
    this.searchControl.reset();

    setTimeout(() => {
      this.control.setValue([]);
      this._cd.detectChanges();
      this.selectRef._detectChanges();
    });
  }
}
