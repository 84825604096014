import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiPaths } from '@common/consts';

@Injectable({ providedIn: 'root' })
export class HeapFilesApiService {
  constructor(private http: HttpClient) {}

  /**
   * Возвращает объект по ID.
   * @param fileId - id файла
   */
  public getById(fileId: number) {
    return this.http.get(`${ApiPaths.HeapFiles}/?id=${fileId}`, { responseType: 'blob' });
  }

  /**
   * Загружает файл в хранилище и возвращает ID файла
   * @param file - файл
   */
  public upload(file: File): Observable<number> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<number>(`${ApiPaths.HeapFiles}`, formData);
  }

  /**
   * Загружает файл в хранилище и возвращает ссылку на файл
   * @param file - файл
   */
  public uploadWithLink(file: File): Observable<string> {
    return this.upload(file).pipe(map((id: number) => `${ApiPaths.HeapFiles}/?id=${id}`));
  }

  /**
   * Загружает файл в хранилище
   * @param fileId - ID файла
   */
  public remove(fileId: number): Observable<void> {
    return this.http.delete<void>(`${ApiPaths.HeapFiles}/${fileId}`);
  }
}
