import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fm-block-title',
  templateUrl: './fm-block-title.component.html',
  styleUrls: ['./fm-block-title.component.scss'],
})
export class FmBlockTitleComponent implements OnInit {
  @Input() size: number;
  sizeClass = '';
  ngOnInit(): void {
    this.sizeClass = this.size ? `size-${this.size}` : null;
  }
}
