import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { Permissions } from '@core/account/types';
import { PermissionService } from '@core/account/services';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ROUTES_PARTS } from '@common/consts';

@Injectable({
  providedIn: 'root',
})
export class VisibilityOfCatalogsGuard implements CanActivate {
  constructor(private permissionService: PermissionService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.permissionService.asyncAccess(Permissions.VisibilityOfCatalogs).pipe(
      tap((access) => {
        if (!access) {
          this.router.navigate([`${ROUTES_PARTS.NOT_FOUND}`], { skipLocationChange: true });
        }
      }),
    );
  }
}
