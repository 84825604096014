import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';
import { AccountService } from '@core/account/services/account.service';
import { Title } from '@angular/platform-browser';
import { APP_TITLE } from '@common/consts';
import { finalize, switchMap, take } from 'rxjs/operators';
import { DictionariesService } from '@services';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-root',
  template: `
    <ng-container *ngIf="!loading">
      <app-side-navigation></app-side-navigation>
      <app-content-layout>
        <router-outlet></router-outlet>
      </app-content-layout>
    </ng-container>
  `,
})
export class AppComponent implements OnInit {
  private title = environment.stand;

  loading = true;

  constructor(
    private oauthService: OAuthService,
    private accountService: AccountService,
    private dictionariesService: DictionariesService,
    private titleService: Title,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    this.setTitle();
    this.oauthService.loadDiscoveryDocument().then(() => this.loadUser().subscribe());
  }

  private loadUser() {
    return this.accountService.loadUser().pipe(
      take(1),
      switchMap((user) =>
        forkJoin([
          this.translate.use(user.cultureInfo ?? environment.locale),
          this.dictionariesService.loadAttributes(),
        ]),
      ),
      finalize(() => (this.loading = false)),
    );
  }

  private setTitle() {
    if (this.title && this.title !== 'prod') {
      this.titleService.setTitle(`${this.title} | ${APP_TITLE}`);
    } else {
      this.titleService.setTitle(APP_TITLE);
    }
  }
}
