import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { LogicalOperator, LogicalOperatorEnum } from '@common/enums';
import { IdNameDto } from '@common/dto';
import { transformToIds } from '@common/utils';

import { PermissionService } from '../services';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[hasDisciplines]',
})
export class HasDisciplinesDirective {
  private disciplineIds: number[];
  private logicalOp: LogicalOperator = LogicalOperatorEnum.Or;

  @Input()
  set hasDisciplines(disciplineIds: number[] | IdNameDto[]) {
    if (disciplineIds) {
      this.disciplineIds = transformToIds(disciplineIds);
      this.updateView();
    }
  }

  @Input()
  set hasDisciplinesOperation(op: LogicalOperator) {
    this.logicalOp = op;
    this.updateView();
  }

  @Input() set hasDisciplinesElse(template: TemplateRef<any>) {
    this._hasDisciplinesElse = template;
    this.updateView();
  }

  private _hasDisciplines: boolean;
  private _hasDisciplinesElse: TemplateRef<any>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionsService: PermissionService,
  ) {}

  private updateView() {
    const hasDisciplines = this.permissionsService.checkDisciplines(this.disciplineIds, this.logicalOp);
    if (
      hasDisciplines !== this._hasDisciplines ||
      (!this._hasDisciplines && this._hasDisciplinesElse && this.templateRef !== this._hasDisciplinesElse)
    ) {
      this.viewContainer.clear();

      if (hasDisciplines) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else if (this._hasDisciplinesElse) {
        this.viewContainer.createEmbeddedView(this._hasDisciplinesElse);
      }
    }

    this._hasDisciplines = hasDisciplines;
  }
}
