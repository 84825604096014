import { ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';

import { generateAttributeFilterData } from '@common/utils';

import { IAttributeFilterForm, TypedAttributeFilterFormGroup } from './attributes-filter-control.model';
import { DictionariesService } from '@services';
import { IAttributeFormItem } from '@shared/components/controls/attributes-control/attributes-control.model';

const DEFAULT_VALUES = {
  attributes: [],
  withAttributesOnly: false,
};

@Component({
  selector: 'app-attributes-filter-control',
  templateUrl: './attributes-filter-control.component.html',
  styleUrls: ['./attributes-filter-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AttributesFilterControlComponent),
      multi: true,
    },
  ],
})
export class AttributesFilterControlComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() entityCheckboxLabel = 'COMMON_LABELS.ELEMENTS';

  form: TypedAttributeFilterFormGroup = new UntypedFormGroup({
    attributes: new UntypedFormControl([]),
    withAttributesOnly: new UntypedFormControl(false),
  }) as TypedAttributeFilterFormGroup;

  private _destroy$ = new Subject();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(public dictionariesService: DictionariesService, private _changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    this._subscribeToFormChanges().subscribe();
  }

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  async writeValue(value: IAttributeFilterForm) {
    value ? this.form.setValue(value) : this.form.reset(DEFAULT_VALUES);
    this._changeDetector.detectChanges();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  nextChangeValue(): void {
    let value: IAttributeFilterForm = {
      ...this.form.getRawValue(),
      attributes: generateAttributeFilterData(this.form.controls.attributes.value) as IAttributeFormItem[],
    };

    value = value.attributes.length || value.withAttributesOnly ? value : null;

    if (this._onChange) {
      this._onChange(value);
    }
  }

  private _subscribeToFormChanges() {
    return this.form.valueChanges.pipe(
      distinctUntilChanged(),
      tap(() => this.nextChangeValue()),
      takeUntil(this._destroy$),
    );
  }
}
