/*export const VALUE_REGEX = {
  boolean: /^(да|нет|true|false)$/gim,
  stringWithSpecialSymbols: /[*,?]/gim,

};*/

export const VALUE_REGEX = {
  boolean: /^(да|нет|true|false|1|0|yes|no)$/gim,
  stringWithSpecialSymbols: /[*,?]/gim,
  interval:
    /(\[(?!\.)|\()(((-(?!0\.\.))?\d*([.,]\d*)?)?)(\.\.(?!\]))((-(?!(0\\|0\))))?\d*([.,]\d*)?)?(\]|\))((\.\.(?=\d))\d*([.,]\d*)?)?$/gm,
};
