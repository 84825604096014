import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-table-paginator',
  templateUrl: './table-paginator.component.html',
  styleUrls: ['./table-paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TablePaginatorComponent {
  @Input() fullCount: number;
  @Input() limitMin: number;
  @Input() limit: number;
  @Input() pageIndex: number;
  @Input() pageSizesList: number[];
  @Input() inverse = false;
  @Input() entitiesLabel = 'TABLE_PAGINATOR.ELEMENTS';

  @Output() pageNumberChange = new EventEmitter<number>();
  @Output() pageSizeChange = new EventEmitter<number>();
}
