export enum BindingFilterOperators {
  Equal = 1,
  NotEqual = 2,
  More = 5,
  Less = 6,
  MoreOrEqual = 7,
  LessOrEqual = 8,
}

export const NUMBER_COMPARISON_OPERATORS = [
  BindingFilterOperators.More,
  BindingFilterOperators.Less,
  BindingFilterOperators.LessOrEqual,
  BindingFilterOperators.MoreOrEqual,
];

export const BINDING_FILTER_OPERATORS: BindingFilterOperator[] = [
  { name: 'BINDINGS_MODULE.BINDING_FILTER_OPERATORS.EQUAL', id: BindingFilterOperators.Equal },
  { name: 'BINDINGS_MODULE.BINDING_FILTER_OPERATORS.NOT_EQUAL', id: BindingFilterOperators.NotEqual },
  { name: 'BINDINGS_MODULE.BINDING_FILTER_OPERATORS.MORE', id: BindingFilterOperators.More },
  { name: 'BINDINGS_MODULE.BINDING_FILTER_OPERATORS.LESS', id: BindingFilterOperators.Less },
  { name: 'BINDINGS_MODULE.BINDING_FILTER_OPERATORS.MORE_OR_EQUAL', id: BindingFilterOperators.MoreOrEqual },
  { name: 'BINDINGS_MODULE.BINDING_FILTER_OPERATORS.LESS_OR_EQUAL', id: BindingFilterOperators.LessOrEqual },
];

export interface BindingFilterOperator {
  id: BindingFilterOperators;
  name: string;
}
