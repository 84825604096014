<ng-container *ngIf="!isDisable; else materialView">
  <input
    pikInput
    [formControl]="nameControl"
    [pikAutocomplete]="autocomplete"
    placeholder="{{ 'COMPONENT_MATERIAL_CONTROL' | translate }}"
  />

  <pik-autocomplete
    [standalone]="false"
    #autocomplete="pikAutocomplete"
    (optionSelected)="selectEntity($event)"
    [dataSource]="entities$ | async"
  >
  </pik-autocomplete>
</ng-container>

<ng-template #materialView>
  <app-material-tag [entity]="selectedMaterial"></app-material-tag>
</ng-template>
