import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { DictionariesService } from '@services';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { DisciplineDto, IdNameDto } from '@common/dto';
import { transformToIds } from '@common/utils';

@Component({
  selector: 'app-disciplines-control',
  templateUrl: './disciplines-control.component.html',
  styleUrls: ['./disciplines-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DisciplinesControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisciplinesControlComponent implements OnChanges, OnDestroy, ControlValueAccessor, OnInit {
  @Input() set disciplines(data: number[] | IdNameDto[]) {
    if (Array.isArray(data)) {
      this._disciplines = transformToIds(data);
    }
  }

  disciplineControl = new UntypedFormControl([]);
  disciplinesList: DisciplineDto[] = [];
  allDisciplines: DisciplineDto[] = [];

  disciplineIdNames: IdNameDto[] = [];

  loadDisciplines$ = new BehaviorSubject(false);

  private _destroy$ = new Subject();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  private _disciplines: number[] = null;

  isDisabled: boolean;

  constructor(private _changeDetector: ChangeDetectorRef, private dictionaryService: DictionariesService) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disciplines && changes.disciplines.currentValue) {
      this._setDisciplines();
    }
  }

  ngOnInit() {
    this.getAllDisciplines();
  }

  async writeValue(value: number[]) {
    await this.loadDisciplines$
      .pipe(
        filter((f) => f),
        tap(() => {
          if (value) {
            this.disciplineIdNames = value.map((id) => this.disciplinesList.find((discipline) => id === discipline.id));
          }
        }),
        take(1),
      )
      .toPromise();

    this.disciplineControl.setValue(this.sortValue(value));
    this._changeDetector.detectChanges();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  private async getAllDisciplines() {
    await this.dictionaryService
      .loadDisciplines()
      .pipe(
        take(1),
        switchMap(() => this.dictionaryService.disciplines$),
        filter((disciplines) => !!disciplines.length),
        tap((disciplines) => (this.allDisciplines = disciplines)),
        tap(() => this._setDisciplines()),
        tap(() => this.loadDisciplines$.next(true)),
      )
      .toPromise();
  }

  private _setDisciplines() {
    this.disciplinesList = this._disciplines
      ? this.allDisciplines.filter((d) => this._disciplines?.includes(d.id))
      : this.allDisciplines;
    this._changeDetector.detectChanges();
  }

  nextChangeValue(value: number[]): void {
    if (this._onChange) {
      this._onChange(this.sortValue(value));
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    this._changeDetector.markForCheck();
  }

  sortValue(value: number[]): number[] {
    return value ? [...value].sort((a, b) => a - b) : value;
  }
}
