import { Permissions } from '@core/account/types';

export enum ColumnType {
  STRING = 'string',
  NUMBER = 'number',
  ATTRIBUTES = 'attributes',
}

export interface ITableColumn {
  name: string;
  label: string;
  type?: ColumnType;
  default?: boolean;
  required?: boolean;
  sorted?: boolean;
  sortField?: string;
  event?: boolean;
  description?: string;
  sticky?: boolean;
  stickyEnd?: boolean;
  orderPosition?: number;
  permission?: Permissions;
}

export class TableColumnDef implements ITableColumn {
  name: string;
  label: string;
  type: ColumnType;
  isVisible: boolean;
  default: boolean;
  required: boolean;
  sorted: boolean;
  sortField: string;
  orderPosition: number;
  event: boolean;
  description: string;
  sticky: boolean;
  stickyEnd: boolean;
  permission: Permissions;

  constructor(tableDef: ITableColumn) {
    if (!tableDef) {
      throw new Error('Column description not specified');
    }

    this.isVisible = true;
    this.default = true;
    this.required = false;
    this.sorted = false;
    this.event = false;
    this.sticky = false;
    this.stickyEnd = false;
    this.permission = null;

    Object.assign(this, tableDef);
  }
}

export function TableColumnsFactory(columns: TableColumnDef[]) {
  return columns.concat(
    new TableColumnDef({ name: 'tools', orderPosition: 99, label: '', required: true, stickyEnd: true }),
  );
}
