import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { ParameterValueControlService } from '../../services';

@Component({
  selector: 'app-parameter-range-values-control',
  templateUrl: './parameter-range-values-control.component.html',
  styleUrls: ['./parameter-range-values-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ParameterRangeValuesControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ParameterRangeValuesControlComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParameterRangeValuesControlComponent implements OnDestroy, ControlValueAccessor {
  @Input() isDisable: boolean;
  @Input() numberType: 'Z' | 'R';

  values: string[] = [];
  form = new FormGroup({});
  formControls = [];

  private _destroy$ = new Subject();
  private _subscription = new Subscription();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(
    public parameterValueControlService: ParameterValueControlService,
    private _changeDetector: ChangeDetectorRef,
  ) {}

  ngOnDestroy() {
    this._subscription.unsubscribe();
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  validate(): ValidationErrors | null {
    return this.form.invalid ? { invalidValue: true } : null;
  }

  writeValue(value: string) {
    this.values = value?.split('|') || [];
    this.parameterValueControlService.values$.next(this.values);

    this.buildForm();
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisable = isDisabled;
    isDisabled ? this.form.disable() : this.form.enable();
    this._changeDetector.markForCheck();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  nextChangeValue(): void {
    if (this._onChange) {
      const value = this.form.valid ? Object.values(this.form.value).join('|') : null;
      this._onChange(value);
    }
  }

  addValue() {
    this.values.push(this.parameterValueControlService.allowEmptyValue ? '' : null);
    this.parameterValueControlService.values$.next(this.values);

    this.buildForm();
    this.nextChangeValue();
  }

  removeValue(index: number) {
    this.values = this.values.filter((v, i) => i !== index);
    this.parameterValueControlService.values$.next(this.values);

    this.buildForm();
    this.nextChangeValue();
  }

  trackByValue(index: number, value: string) {
    return value;
  }

  private buildForm() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }

    const templateControls = [];

    const controls = [...this.values].map((v, i) => {
      templateControls.push(`${i}-${v}`);
      return [`control-${i}`, new FormControl(v, this.sameValueValidator(`control-${i}`))];
    });
    this.formControls = templateControls;

    this.form = new FormGroup(Object.fromEntries(controls));
    this.updateValidityFormControls();

    this._subscription = this.subscribeToFormChanges().subscribe();
    this._changeDetector.markForCheck();
  }

  private subscribeToFormChanges() {
    return this.form.valueChanges.pipe(
      tap((values) => {
        this.values = Object.values(values);
        this.parameterValueControlService.values$.next(this.values);
      }),
      delay(100),
      tap(() => {
        this.updateValidityFormControls();
        this.nextChangeValue();
      }),
    );
  }

  private updateValidityFormControls() {
    const controls = Object.keys(this.form.controls);
    controls.forEach((c) => this.form.controls[c].updateValueAndValidity({ emitEvent: false }));
    this._changeDetector.detectChanges();
  }

  sameValueValidator(controlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formValues = control.parent?.value;

      if (!formValues) {
        return null;
      }

      delete formValues[controlName];
      return Object.values(formValues).includes(control.value) ? { valueExists: true } : null;
    };
  }
}
