export enum BindingFilterGroupType {
  Element = 'Element',
  Nomenclature = 'Nomenclature',
  Mixed = 'Mixed',
}

export const GROUP_TYPE_DESCRIPTION = {
  [BindingFilterGroupType.Element]: 'BINDINGS_MODULE.GROUP_TYPE_DESCRIPTION.ELEMENT',
  [BindingFilterGroupType.Nomenclature]: 'BINDINGS_MODULE.GROUP_TYPE_DESCRIPTION.NOMENCLATURE',
  [BindingFilterGroupType.Mixed]: 'BINDINGS_MODULE.GROUP_TYPE_DESCRIPTION.MIXED',
};
