export enum ThemeColors {
  FunctionalType = '#0097ff',
  Folder = '#BDBDBD',
  Family = '#006D2E',
  FamilyVersion = '#27AE60',
  Symbol = '#6DC300',
  NomenclatureType = '#f12ab9',
  ParameterSet = '#A4BF00',
  Parameter = '#00B5CE',
  Discipline = '#3700D3',
  Material = '#9b51e0',
  Binding = '#efb508',
  Request = '#F12A66',
  Revit = '#f0d21e',
  Civil = '#F1882A',

  RevitSystem = '#00C2FF',
  RevitLoad = '#9B51E0',
  RevitGroup = '#cde051',
  Dwg = '#e05151',

  Green = '#27AE60',
  Gray1 = '#4f4f4f',
  Gray4 = '#F5F6FA',
  Gray5 = '#E0E0E0',
  DarkBlue = '#222D44',
  Orange = '#FF5B2D',
  Aqua = '#0097FF',
}
