import { Permissions } from '@core/account/types';

import { REQUEST_STATUS } from './request-status';
import { RequestActions } from './request-actions.enum';
import { RequestUserAction } from '../interfaces';

export const REQUEST_ACTIONS: RequestUserAction[] = [
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.SET_MODERATOR',
    action: RequestActions.SetModerator,
    statusIds: [REQUEST_STATUS.MODERATION.id],
    permissions: [Permissions.RequestModerate],
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.TO_APPROVE',
    action: RequestActions.SetApprover,
    statusIds: [REQUEST_STATUS.MODERATION.id],
    permissions: [Permissions.RequestModerate],
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.APPROVE',
    action: RequestActions.SelfApprove,
    statusIds: [REQUEST_STATUS.MODERATION.id],
    permissions: [Permissions.RequestModerate],
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.REJECT',
    action: RequestActions.Reject,
    statusIds: [REQUEST_STATUS.MODERATION.id],
    permissions: [Permissions.RequestModerate],
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.SET_DEVELOPER',
    action: RequestActions.SetDeveloper,
    statusIds: [REQUEST_STATUS.MODERATION.id, REQUEST_STATUS.SET_DEVELOPER.id, REQUEST_STATUS.APPROVING.id],
    permissions: [Permissions.RequestModerate],
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.CANCEL',
    action: RequestActions.CancelRequest,
    statusIds: [REQUEST_STATUS.MODERATION.id, REQUEST_STATUS.APPROVING.id, REQUEST_STATUS.SET_DEVELOPER.id],
    permissions: [Permissions.RequestCreate],
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.REMOVE',
    action: RequestActions.RemoveRequest,
    statusIds: [REQUEST_STATUS.MODERATION.id, REQUEST_STATUS.FILING.id],
    permissions: [Permissions.RequestCreate],
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.APPROVE_REQUEST',
    action: RequestActions.SelfApprove,
    statusIds: [REQUEST_STATUS.APPROVING.id],
    permissions: [Permissions.RequestApprove],
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.REJECT',
    action: RequestActions.Reject,
    statusIds: [REQUEST_STATUS.APPROVING.id],
    permissions: [Permissions.RequestApprove],
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.CHANGE_APPROVER',
    action: RequestActions.SetApprover,
    statusIds: [REQUEST_STATUS.APPROVING.id],
    permissions: [Permissions.RequestModerate, Permissions.RequestApprove],
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.SET_MYSELF_DEVELOPER',
    action: RequestActions.SelfDeveloper,
    statusIds: [REQUEST_STATUS.APPROVING.id, REQUEST_STATUS.MODERATION.id, REQUEST_STATUS.SET_DEVELOPER.id],
    permissions: [Permissions.RequestModerate],
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.SEND_SOLVE',
    description: {
      family: 'REQUEST_MODULE.REQUEST_ACTION_DESCRIPTIONS.SEND_SOLVE_FAMILY',
      material: 'REQUEST_MODULE.REQUEST_ACTION_DESCRIPTIONS.SEND_SOLVE_MATERIAL',
    },
    action: RequestActions.SelfResolve,
    statusIds: [
      REQUEST_STATUS.MODERATION.id,
      REQUEST_STATUS.APPROVING.id,
      REQUEST_STATUS.SET_DEVELOPER.id,
      REQUEST_STATUS.DEVELOPING.id,
    ],
    permissions: [Permissions.RequestModerate],
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.CHANGE_MODERATOR',
    action: RequestActions.SetModerator,
    statusIds: [
      REQUEST_STATUS.APPROVING.id,
      REQUEST_STATUS.SET_DEVELOPER.id,
      REQUEST_STATUS.DEVELOPING.id,
      REQUEST_STATUS.CHECKING_MODERATOR.id,
    ],
    permissions: [Permissions.RequestModerate],
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.CHANGE_PLAN_DATE',
    action: RequestActions.ChangePlanDate,
    statusIds: [
      REQUEST_STATUS.MODERATION.id,
      REQUEST_STATUS.APPROVING.id,
      REQUEST_STATUS.SET_DEVELOPER.id,
      REQUEST_STATUS.DEVELOPING.id,
      REQUEST_STATUS.CHECKING_MODERATOR.id,
      REQUEST_STATUS.CHECKING_INITIATOR.id,
    ],
    permissions: [Permissions.RequestModerate],
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.SUGGEST_MYSELF',
    action: RequestActions.OfferSelfDeveloper,
    statusIds: [REQUEST_STATUS.SET_DEVELOPER.id],
    permissions: [Permissions.RequestDevelop],
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.SEND_SOLVE',
    action: RequestActions.SendResolve,
    statusIds: [REQUEST_STATUS.DEVELOPING.id],
    permissions: [Permissions.RequestDevelop],
    currentUser: true,
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.CHANGE_DEVELOPER',
    action: RequestActions.SetDeveloper,
    statusIds: [REQUEST_STATUS.DEVELOPING.id, REQUEST_STATUS.CHECKING_MODERATOR.id],
    permissions: [Permissions.RequestModerate],
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.TAKE_OFF_DEVELOPER',
    action: RequestActions.RemoveDeveloper,
    statusIds: [REQUEST_STATUS.DEVELOPING.id],
    permissions: [Permissions.RequestModerate],
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.TO_APPROVE',
    action: RequestActions.ReturnToApprove,
    statusIds: [REQUEST_STATUS.SET_DEVELOPER.id],
    permissions: [Permissions.RequestModerate],
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.APPROVE_SOLVE',
    action: RequestActions.ApproveSolution,
    statusIds: [REQUEST_STATUS.CHECKING_MODERATOR.id],
    permissions: [Permissions.RequestModerate],
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.REJECT_SOLVE',
    action: RequestActions.RejectSolution,
    statusIds: [REQUEST_STATUS.CHECKING_MODERATOR.id],
    permissions: [Permissions.RequestModerate],
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.TAKE_OFF_DEVELOP',
    action: RequestActions.ReturnDevelop,
    statusIds: [REQUEST_STATUS.CHECKING_MODERATOR.id, REQUEST_STATUS.CHECKING_INITIATOR.id],
    permissions: [Permissions.RequestDevelop],
    currentUser: true,
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.RETURN_DEVELOP',
    action: RequestActions.ReturnDevelop,
    statusIds: [REQUEST_STATUS.CHECKING_INITIATOR.id],
    permissions: [Permissions.RequestModerate],
    currentUser: true,
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.TAKE_OFF_DEVELOP',
    action: RequestActions.ReturnFiling,
    statusIds: [REQUEST_STATUS.MODERATION.id],
    permissions: [Permissions.RequestCreate],
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.APPROVE_SOLVE',
    action: RequestActions.ApproveInitiatorSolution,
    statusIds: [REQUEST_STATUS.CHECKING_INITIATOR.id],
    permissions: [Permissions.RequestCreate],
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.REJECT_SOLVE',
    action: RequestActions.RejectSolution,
    statusIds: [REQUEST_STATUS.CHECKING_INITIATOR.id],
    permissions: [Permissions.RequestCreate],
  },
  {
    label: 'REQUEST_MODULE.REQUEST_ACTIONS.CHANGE_ATTRIBUTES',
    action: RequestActions.ModeratorAttributes,
    statusIds: [
      REQUEST_STATUS.SET_DEVELOPER.id,
      REQUEST_STATUS.DEVELOPING.id,
      REQUEST_STATUS.APPROVING.id,
      REQUEST_STATUS.CHECKING_INITIATOR.id,
      REQUEST_STATUS.CHECKING_MODERATOR.id,
      REQUEST_STATUS.MODERATION.id,
      REQUEST_STATUS.CANCELED.id,
      REQUEST_STATUS.RESOLVED.id,
      REQUEST_STATUS.FILING.id,
    ],
    permissions: [Permissions.RequestModerate],
  },
];
