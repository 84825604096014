import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { debounceTime, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PikAutocompleteSelectedEvent } from '@pik-ui/ng-components';

import { IdNameDto, RequestLightDto } from '@common/dto';
import { RequestsApiService } from '@services/api';
import { RequestsFilterParams } from '@common/models/filters';
import { transformToIds } from '@common/utils';
import { ResultPart } from '@common/interfaces';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-requests-control',
  templateUrl: './requests-control.component.html',
  styleUrls: ['./requests-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RequestsControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestsControlComponent implements OnDestroy, ControlValueAccessor, OnInit {
  list: IdNameDto[] = [];
  entityName = new UntypedFormControl(null);
  noNameLabel = this.translateService.instant('COMMON_LABELS.NO_NAME');
  dataSource$ = new Subject<PikAutocompleteSelectedEvent[]>();

  private _destroy$ = new Subject();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(
    private _changeDetector: ChangeDetectorRef,
    private _requestsApi: RequestsApiService,
    private translateService: TranslateService,
  ) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this._subscribeToNameControl().subscribe();
  }

  async writeValue(value: number[] | IdNameDto[]) {
    if (value) {
      const data = transformToIds(value);
      await this._getEntities(data).toPromise();
    } else {
      this.list = [];
    }
    this._changeDetector.detectChanges();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  removeItem(item: IdNameDto) {
    this.list = this.list.filter((ft) => ft.id !== item.id);
    this.nextChangeValue();
  }

  selectEntity(event: PikAutocompleteSelectedEvent) {
    setTimeout(() => this.entityName.setValue(''));

    const newItem: IdNameDto = event.value;

    if (!this.list.some((s) => s.id === newItem.id)) {
      this.list.push(event.value);
      this.nextChangeValue();
    }
  }

  nextChangeValue(): void {
    if (this._onChange) {
      this._onChange(this.list.map((i) => i.id));
    }
  }

  private _subscribeToNameControl() {
    return this.entityName.valueChanges.pipe(
      startWith(''),
      debounceTime(300),

      switchMap((value) => {
        return isNaN(+value)
          ? of([])
          : this._requestsApi
              .getList(new RequestsFilterParams({ id: value }))
              .pipe(map((data: ResultPart<RequestLightDto>) => data.result));
      }),
      map((data) =>
        data?.map((item) => ({
          label: `${item.id} (${item.requestTitle ?? `<${this.noNameLabel}>`})`,
          value: { id: item.id, name: item.requestTitle ?? `<${this.noNameLabel}>` },
        })),
      ),
      tap((data) => this.dataSource$.next(data)),
      takeUntil(this._destroy$),
    );
  }

  private _getEntities(ids: number[]) {
    this.list = [];
    return ids.length
      ? this._requestsApi.getIdNames(ids).pipe(
          map((data) =>
            data.map((r) => {
              const newR = { ...r };
              newR.name = newR.name ? newR.name : `<${this.noNameLabel}>`;
              return newR;
            }),
          ),
          tap((entities) => (this.list = [...entities])),
        )
      : of([]);
  }
}
