import { FilterAttributeData } from '@common/dto';
import { TablePageFilter } from '../table-page-filter-base';
import { IFunctionalTypesParametersFilterParams } from './functional-types-parameters-filter.interface';
import { ParameterErrorType } from '@common/enums';

const DEFAULT_PARAMS: IFunctionalTypesParametersFilterParams = {
  defaultSortBy: 'sortNumber',
  valueStrFromFilterAsMask: true,
};

export class FunctionalTypesParametersFilterParams extends TablePageFilter<IFunctionalTypesParametersFilterParams> {
  id: number;
  functionalTypeIds: number[];
  parameter: string;
  uniqueParameter: string;
  includeValues: boolean;
  isRequired: boolean;
  isAddress: boolean;
  isRequiredForRequest: boolean;
  disciplineIds: number[];
  attributes: FilterAttributeData[];
  withDisciplinesOnly: boolean;
  withAttributesOnly: boolean;
  description: string;
  valueDescription: string;
  dataTypeIds: number[];
  errorTypes: ParameterErrorType[];
  unitTypeIds: number[];
  valueStrFromFilterAsMask: boolean;
  searchString: string;

  skipEmptyArrayFields = ['errorTypes'];

  constructor(filter: IFunctionalTypesParametersFilterParams = {}) {
    super(filter, DEFAULT_PARAMS);
  }

  public toObject(): IFunctionalTypesParametersFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      functionalTypeIds: this.functionalTypeIds,
      parameter: this.parameter,
      uniqueParameter: this.uniqueParameter,
      includeValues: this.includeValues,
      isRequired: this.isRequired,
      isAddress: this.isAddress,
      disciplineIds: this.disciplineIds,
      attributes: this.attributes,
      errorTypes: this.errorTypes,
      withDisciplinesOnly: this.withDisciplinesOnly,
      withAttributesOnly: this.withAttributesOnly,
      isRequiredForRequest: this.isRequiredForRequest,
      description: this.description,
      searchString: this.searchString,
      valueDescription: this.valueDescription,
      dataTypeIds: this.dataTypeIds,
      unitTypeIds: this.unitTypeIds,
      valueStrFromFilterAsMask: this.valueStrFromFilterAsMask,
    };
  }
}
