import { Component, Input } from '@angular/core';

import { ROUTES_PARTS, ThemeColors } from '@common/consts';
import { IdNameDto } from '@common/dto';
import { normalizePageParams } from '@common/utils';
import { PermissionService } from '@core/account/services';

@Component({
  selector: 'app-category-tag',
  templateUrl: './category-tag.component.html',
  styleUrls: ['./category-tag.component.scss'],
})
export class CategoryTagComponent {
  @Input() set category(value: IdNameDto) {
    this._category = value;

    if (value) {
      this.params = normalizePageParams({ searchValue: value.id, searchField: 'id', id: value.id });
    }
  }
  _category: IdNameDto;
  params = {};
  ROUTES_PARTS = ROUTES_PARTS;
  colors = ThemeColors;

  constructor(public permissionService: PermissionService) {}
}
