import { TablePageFilter } from '../table-page-filter-base';
import { IParametersFilterParams } from './parameters-filter.interface';

const DEFAULT_PARAMS: IParametersFilterParams = {
  defaultSortBy: 'name',
};

export class ParametersFilterParams extends TablePageFilter<IParametersFilterParams> {
  id: number;
  name: string;
  dataTypes: number[];
  unitTypes: number[];
  uniqueParameter: string;
  showDeleted: boolean;
  uniqueParameterIds: number[];
  materialIds: number[];

  constructor(filter?: IParametersFilterParams) {
    super(filter, DEFAULT_PARAMS);
  }

  toObject(): IParametersFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      name: this.name,
      dataTypes: this.dataTypes,
      unitTypes: this.unitTypes,
      showDeleted: this.showDeleted,
      uniqueParameter: this.uniqueParameter,
      uniqueParameterIds: this.uniqueParameterIds,
      materialIds: this.materialIds,
    };
  }
}
