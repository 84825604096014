import { AttributeDto, AttributeValueDto } from '@common/dto';

export function generateAttributeValueFromAttributeDto(attribute: AttributeDto): AttributeValueDto {
  return {
    attributeId: attribute.id,
    attributeName: attribute.name,
    attributeType: attribute.attributeType,
    sortNumber: attribute.sortNumber,
    isRequiredForFamily: attribute.isRequiredForFamily,
    isRequiredForMaterial: attribute.isRequiredForMaterial,
    isRequiredForRequest: attribute.isRequiredForRequest,
    ownerId: null,
    valueStr: attribute.defaultValue,
    author: attribute.author?.name,
    options: attribute.options.map((o) => ({ id: o.id, name: o.name })),
    attributeDescription: attribute.description,
    displayInBim: attribute.displayInBim,
    isValueUpdated: false,
    id: attribute.id,
  };
}
