import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { MatchingPropertyDto, PatchOperation } from '@common/dto';
import { ApiPaths } from '@common/consts';

@Injectable({
  providedIn: 'root',
})
export class MatchingPropertiesApiService {
  constructor(private http: HttpClient) {}

  getById(id: number): Observable<MatchingPropertyDto> {
    return this.http.get<MatchingPropertyDto>(`${ApiPaths.MatchingProperties}/${id}`);
  }

  create(parameter): Observable<MatchingPropertyDto> {
    return this.http.post<MatchingPropertyDto>(`${ApiPaths.MatchingProperties}`, parameter);
  }

  patch(id, data: PatchOperation[]): Observable<MatchingPropertyDto> {
    return this.http.patch<MatchingPropertyDto>(`${ApiPaths.MatchingProperties}/${id}`, data);
  }

  remove(id: number): Observable<void> {
    return this.http.delete<void>(`${ApiPaths.MatchingProperties}/${id}`);
  }
}
