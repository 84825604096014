import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { TypedFormControl } from '@common/interfaces';
import { uniqueValueValidator } from '@shared/validators';

@Component({
  selector: 'app-string-list-control',
  templateUrl: './string-list-control.component.html',
  styleUrls: ['./string-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StringListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StringListControlComponent implements OnDestroy, ControlValueAccessor {
  valueList: string[] = [];
  validators = [uniqueValueValidator(this.valueList)];
  control: TypedFormControl<string> = new FormControl(null, this.validators);

  private _destroy$ = new Subject();
  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(private _changeDetector: ChangeDetectorRef) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  writeValue(valueList: string[]) {
    this.valueList = valueList ?? [];
    this.control = new FormControl<string>(null, this.validators);
    this._changeDetector.detectChanges();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  removeItem(item: string) {
    this.valueList = this.valueList.filter((v) => v !== item);
    const oldValue = this.control.value;
    this.control = new FormControl<string>(oldValue, this.validators);
    this.nextChangeValue();
  }

  editItem(item: string) {
    this.valueList = this.valueList.filter((v) => v !== item);
    this.control = new FormControl<string>(item, this.validators);
    this.nextChangeValue();
  }

  addItem() {
    const value = this.control.value;
    if (value && !this.valueList.find((f) => f === value)) {
      this.valueList = [...this.valueList, value];
      this.control = new FormControl<string>('', this.validators);
      this.nextChangeValue();
    }
  }

  nextChangeValue(): void {
    if (this._onChange) {
      this._onChange(this.valueList);
    }
  }
}
