import { Component, Input } from '@angular/core';
import { ROUTES } from '@common/consts';
import { PermissionService } from '@core/account/services';
import { normalizePageParams } from '@common/utils';
import { IMaterialsParametersFilterParams, MaterialsParametersFilterParams } from '@common/models/filters';

@Component({
  selector: 'app-material-parameters-link',
  templateUrl: './material-parameters-link.component.html',
})
export class MaterialParametersLinkComponent {
  @Input() set params(value: IMaterialsParametersFilterParams) {
    if (value) {
      this.link = [
        '',
        ROUTES.materialsParameters,
        normalizePageParams(new MaterialsParametersFilterParams(value).toParams()),
      ];
    }
  }
  @Input() name: string;

  link: any[];

  constructor(public permissionService: PermissionService) {}
}
