import { Injectable } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { AttachmentsCarouselService } from '@pik-ui/ng-components';

@Injectable({
  providedIn: 'root',
})
export class ImagesViewerService {
  private _subscriptions: Subscription;

  constructor(private carousel: AttachmentsCarouselService) {}

  public init(images: NodeListOf<HTMLImageElement>) {
    this._subscriptions = new Subscription();

    const _images = Array.from(images) as HTMLImageElement[];

    _images.forEach((image, index) => {
      this._subscriptions.add(fromEvent(image, 'click').subscribe(() => this._openImage(_images, index)));
    });
  }

  public destroy() {
    if (this._subscriptions) {
      this._subscriptions.unsubscribe();
      this._subscriptions = undefined;
    }
  }

  private _openImage(images: HTMLImageElement[], currentIndex: number) {
    const files = images.map((img) => ({
      url: img.getAttribute('src'),
      name: '',
      extension: 'jpg',
      externalDownloadLink: img.getAttribute('src'),
    }));

    this.carousel.open({ files, currentIndex });
  }
}
