import { Directive, TemplateRef } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[fmTableHeaderTemplate]',
})
export class FmTableHeaderTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[fmTableCellTemplate]',
})
export class FmTableCellTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}
