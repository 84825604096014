import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(date: any, withTime: boolean = false, keepLocalTime = true): any {
    const format = withTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY';
    return !!date ? moment.utc(date).local(keepLocalTime).format(format) : '';
  }
}
