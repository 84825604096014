<div class="wrap" *ngIf="parameterValueControlService.validValues$ | async">
  <div class="label">
    {{
      (parameterValueControlService.validValuesDescription$ | async) ?? 'PARAMETER_VALUES_COMPONENT.VALID_VALUES'
        | translate
    }}
  </div>
  <app-parameters-view
    [dataType]="parameterValueControlService.dataType$ | async"
    [value]="parameterValueControlService.validValues$ | async"
    maxCount="10"
  ></app-parameters-view>
</div>
