import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpParams, HttpRequest, HttpUrlEncodingCodec } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ParametersEncoder } from '@core/encoders';
import { environment } from '../../../environments/environment';

@Injectable()
export class HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const params = new HttpParams({ encoder: new ParametersEncoder(), fromString: req.params.toString() });
    const url = new HttpUrlEncodingCodec().encodeValue(req.url);
    const headers = req.url.includes(environment.fmEndpoint)
      ? req.headers.set('FmClientType', `fm-webapp ${environment.version}`)
      : req.headers;

    return next.handle(req.clone({ params, headers, url }));
  }
}
