import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';

@Component({
  selector: 'app-implicit-redirect',
  templateUrl: './implicit-redirect.component.html',
  styleUrls: ['./implicit-redirect.component.scss'],
})
export class ImplicitRedirectComponent implements OnInit {
  constructor(private oauthService: OAuthService, private router: Router) {}

  ngOnInit() {
    this.oauthService.loadDiscoveryDocument().then(() => {
      let navigateUrl = window.localStorage.getItem('initialUrl');
      if (navigateUrl[navigateUrl.length - 1] === '/') {
        navigateUrl = navigateUrl.slice(1, -1);
      }
      this.router.navigateByUrl(navigateUrl);
    });
  }
}
