<ng-container *ngIf="list.length; else negativeIcon">
  <div class="items">
    <pik-tag
      [color]="ThemeColors.Gray4"
      [textColor]="ThemeColors.DarkBlue"
      *ngFor="let item of list | slice: 0:(isShowAllCount || !maxCount ? list.length : maxCount); trackBy: trackByIndex"
      >{{ item }}</pik-tag
    >
  </div>

  <pik-tag
    class="toggle-tag"
    *ngIf="maxCount !== undefined && list.length > maxCount"
    [color]="'transparent'"
    [textColor]="ThemeColors.DarkBlue"
    (click)="toggle()"
    large
  >
    {{
      isShowAllCount
        ? ('COMMON.HIDE' | translate)
        : ('COMMON_PHRASE.AND_MORE' | translate: { number: list.length - maxCount })
    }}
  </pik-tag>
</ng-container>
<ng-template #negativeIcon>—</ng-template>
