import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { InputBoolean, PikAutocompleteSelectedEvent } from '@pik-ui/ng-components';

import { BindingSourceType, IdNameDto, NomenclatureTypeDto } from '@common/dto';
import { BindingsApiService, NomenclatureTypesApiService } from '@services/api';
import { NomenclatureTypesFilterParams } from '@common/models/filters';
import { ResultPart } from '@common/interfaces';

@Component({
  selector: 'app-nomenclature-kind-control',
  templateUrl: './nomenclature-kind-control.component.html',
  styleUrls: ['./nomenclature-kind-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NomenclatureKindControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NomenclatureKindControlComponent implements OnDestroy, ControlValueAccessor, OnInit {
  @Input() sourceType: BindingSourceType;

  @Input()
  @InputBoolean()
  full: boolean;

  nameControl = new UntypedFormControl(null);
  selectedEntity: IdNameDto;
  entities$ = new Subject<PikAutocompleteSelectedEvent[]>();

  private _destroy$ = new Subject();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(
    private _changeDetector: ChangeDetectorRef,
    private _bindingsApi: BindingsApiService,
    private _nomenclatureTypesApi: NomenclatureTypesApiService,
  ) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this._subscribeToNameControl().subscribe();
  }

  async writeValue(value: number) {
    if (value) {
      await this._getEntity(value).toPromise();
    } else {
      this.nameControl.setValue(null);
    }
    this._changeDetector.detectChanges();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  selectEntity(event: PikAutocompleteSelectedEvent) {
    setTimeout(() => this.nameControl.setValue(event.value.name));
    this.selectedEntity = { id: event.value.id, name: event.value.name };
    this.nextChangeValue();
  }

  nextChangeValue(): void {
    if (this._onChange) {
      this.full ? this._onChange(this.selectedEntity) : this._onChange(this.selectedEntity?.id);
    }
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.nameControl.disable() : this.nameControl.enable();
  }

  private _subscribeToNameControl() {
    return this.nameControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      filter((name) => name !== this.selectedEntity?.name),
      tap(() => (this.selectedEntity = null)),
      tap(() => this.nextChangeValue()),
      switchMap((value) =>
        !value
          ? of([])
          : this._nomenclatureTypesApi
              .getList(new NomenclatureTypesFilterParams({ name: value, sourceType: this.sourceType }))
              .pipe(
                map((data: ResultPart<NomenclatureTypeDto>) =>
                  data.result.map((n) => ({
                    value: { id: n.id, name: `${n.name} (${n.unitType?.name})` },
                    label: `${n.name} (${n.unitType?.name})`,
                  })),
                ),
              ),
      ),
      tap((data) => this.entities$.next(data)),
      takeUntil(this._destroy$),
    );
  }

  private _getEntity(id: number) {
    return this._nomenclatureTypesApi
      .getById(id)
      .pipe(take(1))
      .pipe(
        take(1),
        tap((entity) => {
          this.nameControl.setValue(entity.name, { emitEvent: false });
          this.selectedEntity = { id: entity.id, name: entity.name };
          this.nextChangeValue();
        }),
      );
  }
}
