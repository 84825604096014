import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { PikToastService } from '@pik-ui/ng-components';
import { environment } from 'environments/environment';
import { isJson } from '@common/utils';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements ErrorHandler {
  constructor(private _toast: PikToastService, private zone: NgZone, private translateService: TranslateService) {}

  async handleError(error: Error | HttpErrorResponse | any) {
    if (error?.promise && error?.rejection) {
      error = error.rejection;
    }

    if (environment.production) {
      console.error('%O', error);
    } else {
      this._toast.warning({
        title: 'Console error',
        message: `${error.toString().slice(0, 60)}...`,
        horizontalPosition: 'left',
        duration: 30000,
      });

      setTimeout(() => {
        throw error;
      });
    }

    /** Сбрасываем кеш если ошибка чанка */
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error?.message)) {
      window.location.reload();
    }

    if (error instanceof HttpErrorResponse) {
      const code = error.status;
      const codeError = await this.translateService.get('ERRORS.ERROR', { code }).pipe(take(1)).toPromise();
      let message = await this.translateService.get('ERRORS.SOMETHING_WRONG').pipe(take(1)).toPromise();
      const exception = isJson(error?.error) ? JSON.parse(error?.error) : error?.error;

      if (exception?.title) {
        message = exception.title;
      } else {
        if (code === 403) {
          message = await this.translateService.get('ERRORS.PERMISSIONS').pipe(take(1)).toPromise();
        }
      }

      if (code !== 401) {
        this.zone.run(() => {
          this._toast.show({
            type: 'warning',
            title: codeError,
            message,
            duration: 10000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
        });
      }
    }
  }
}
