import { PikToastService } from '@pik-ui/ng-components';
import { TranslateService } from '@ngx-translate/core';
import { take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { forkJoin, of } from 'rxjs';

export interface ToastData {
  value: string;
  parameters?: {};
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private pikToastService: PikToastService, private translateService: TranslateService) {}

  async success(value: string, translateParameters = {}) {
    const message = (await this.translateService.get(value, translateParameters).pipe(take(1)).toPromise()) as string;

    this.pikToastService.success({ message, horizontalPosition: 'left' });
  }

  async warning(message: ToastData, title?: ToastData) {
    return forkJoin({
      sTitle: title?.value ? this.translateService.get(title.value, title.value ?? {}) : of(''),
      sMessage: message?.value ? this.translateService.get(message.value, message.value ?? {}) : of(''),
    })
      .pipe(
        take(1),
        tap(({ sTitle, sMessage }) =>
          this.pikToastService.warning({
            message: sMessage,
            title: sTitle,
            horizontalPosition: 'left',
            duration: 30000,
          }),
        ),
      )
      .toPromise();
  }

  updated(many = false) {
    this.success(many ? 'OPERATION_MESSAGE.UPDATED_MANY' : 'OPERATION_MESSAGE.UPDATED');
  }

  removed() {
    this.success('OPERATION_MESSAGE.REMOVED');
  }

  added() {
    this.success('OPERATION_MESSAGE.ADDED');
  }

  archived() {
    this.success('OPERATION_MESSAGE.ARCHIVED');
  }

  restored() {
    this.success('OPERATION_MESSAGE.RESTORED');
  }

  async copied(message: string) {
    await this.translateService
      .get('COMMON.COPIED')
      .pipe(
        take(1),
        tap((copied) => this.pikToastService.success({ message, title: copied, horizontalPosition: 'left' })),
      )
      .toPromise();
  }
}
