import { Component, Input } from '@angular/core';

import { PermissionService } from '@core/account/services';
import { ConditionLinkModule } from '@shared/components/condition-link';
import { BindingSourceType } from '@common/dto';
import { BindingEntityType } from '@features/bindings/shared/types';
import { FmTabsService } from '@services';
import { Tab, TabType } from '@common/interfaces';
import { ROUTES_PARTS } from '@common/consts';

@Component({
  standalone: true,
  selector: 'app-bindings-link-card',
  imports: [ConditionLinkModule],
  templateUrl: './bindings-link-card.component.html',
  styleUrls: ['./bindings-link-card.component.scss'],
})
export class BindingsLinkCardComponent {
  @Input() bindingType: BindingEntityType;
  @Input() bindingSourceType: BindingSourceType;
  @Input() params?: { group?: number; binding?: number } = {};
  @Input() set entityId(value: number) {
    const bindingTabType = TabType.BINDINGS + '/' + this.bindingSourceType + '/' + this.bindingType;
    const tab: Tab = this.fmTabService.findTab({
      id: value?.toString(),
      type: this.getBindingTabType(bindingTabType).toLowerCase(),
    });

    const bindingParams = { ...this.params, ...(tab?.params ?? {}) };
    this.link = ['', ROUTES_PARTS.BINDINGS, this.bindingSourceType, this.bindingType, value, bindingParams];
  }

  link: any[];

  constructor(public permissionsService: PermissionService, public fmTabService: FmTabsService) {}

  private getBindingTabType(bindingTabTypeValue) {
    return bindingTabTypeValue;
  }
}
