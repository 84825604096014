import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'app-check-icon',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule],
  templateUrl: './check-icon.component.html',
})
export class CheckIconComponent {
  @Input() value;
}
