import { Injectable } from '@angular/core';

import { Permissions } from '@core/account/types';
import { LogicalOperator, LogicalOperatorEnum } from '@common/enums';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserDto } from '@common/dto';
import { filter, map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PermissionService {
  public access: Record<Permissions, boolean> = {} as Record<Permissions, boolean>;
  public disciplines: number[] = [];
  public hasAnyPermission = false;
  private userPermissions$: BehaviorSubject<Permissions[]> = new BehaviorSubject<Permissions[]>(null);

  constructor() {}

  public asyncAccess(permission?: Permissions): Observable<boolean> {
    return this.userPermissions$.pipe(
      filter((f) => !!f),
      take(1),
      map((userPermissions) =>
        permission ? userPermissions.some((item: Permissions) => item === permission) : !!userPermissions.length,
      ),
    );
  }

  public checkDisciplines(disciplineIds: number[] = [], op: LogicalOperator = LogicalOperatorEnum.Or): boolean {
    if (!disciplineIds.length) {
      return true;
    }

    if (op === LogicalOperatorEnum.And) {
      return disciplineIds.every((id) => this.checkSingleDiscipline(id));
    }

    if (op === LogicalOperatorEnum.Or) {
      return disciplineIds.some((id) => this.checkSingleDiscipline(id));
    }

    return false;
  }

  public checkSingleDiscipline(disciplineId: number): boolean {
    return this.disciplines.includes(disciplineId);
  }

  public setPermissions(user: UserDto) {
    this.access = {} as Record<Permissions, boolean>;
    user.permissions.forEach((p) => (this.access[p] = true));
    this.hasAnyPermission = !!user.permissions.length;
    this.disciplines = user.disciplines.map((d) => d.id);
    this.userPermissions$.next(user.permissions);
  }
}
