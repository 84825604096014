<div
  [ngClass]="{
    'fm-text-editor': true,
    disabled: disabled,
    inline: inlineMode
  }"
>
  <div [class.visually-hidden]="hideToolbar" class="fm-text-editor-toolbar">
    <div #toolbarRef class="fm-text-editor-toolbar__inner" [class.visually-hidden]="insertingLink">
      <ng-container *ngFor="let btn of toolbarButtons">
        <button
          type="button"
          class="fm-text-editor-toolbar__btn"
          [class]="btn.class"
          [attr.value]="btn.value"
          [pikTooltip]="btn.tooltip | translate"
          position="above"
        >
          <span style="background-image: url('{{ btn.icon }}');"></span>
        </button>
      </ng-container>
    </div>

    <app-embed-link
      *ngIf="insertingLink"
      [value]="previewLink"
      (save)="_inserLink($event)"
      (cancel)="insertingLink = false"
    ></app-embed-link>
  </div>

  <perfect-scrollbar
    id="editor-scrollbar-{{ scrollbarId }}"
    (click)="this.focus()"
    class="fm-text-editor-scroll"
    [class.focused]="!!_focused"
    [class.outlined]="!!outlined"
    [ngStyle]="{
      minHeight: minHeight,
      maxHeight: maxHeight,
      minWidth: minWidth,
      maxWidth: maxWidth
    }"
  >
    <div #editorRef></div>
  </perfect-scrollbar>
</div>

<ng-template #mentionDropdownRef>
  <ng-container *ngIf="useMention">
    <div class="pik-dropdown-content">
      <perfect-scrollbar
        #dropdownScrollbar
        style="max-width: 100%"
        [style.maxHeight]="mentionOptions.maxDropdownHeight + 'px'"
        [autoPropagation]="true"
      >
        <pik-option
          [attr.data-id]="user.id"
          *ngFor="let user of mentionService.users"
          (click)="mentionService.attachUser(user)"
        >
          {{ user.name }}
        </pik-option>
      </perfect-scrollbar>
    </div>
  </ng-container>
</ng-template>
