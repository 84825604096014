<input
  type="file"
  style="display: none"
  #fileInput
  accept="image/png"
  (change)="handleFileInput($event.target.files)"
  onclick="this.value = null"
  hidden
/>
<button
  *ngIf="!image && !isPending && isChangeAllowed"
  type="button"
  pikButton
  appearance="inverse"
  (click)="fileInput.nativeElement.click()"
>
  <svg-icon pikButtonIcon src="./assets/icons/plus.svg"></svg-icon>
  {{ 'COMMON.ADD' | translate }}
</button>

<div class="image-wrap" *ngIf="image; else default">
  <ng-container *ngIf="image?.file?.size; else plug">
    <div
      class="image {{ size }}"
      [ngClass]="{ 'not-open': image.file.type === 'image/svg+xml' || image.default }"
      (click)="showImage(image)"
      [style.background-image]="image?.styleUrl"
    ></div>
  </ng-container>

  <ng-template #plug>
    <div class="plug" [ngClass]="[entityType, size]"></div>
  </ng-template>

  <div class="image-wrap__descr" *ngIf="mode === 'editor'">
    <span class="image-wrap__message">{{ 'COMMON_PHRASE.IMAGE_UPLOADED' | translate }}</span>

    <button *ngIf="isChangeAllowed" type="button" pikButton appearance="inverse" (click)="reloadImage()">
      <svg-icon pikButtonIcon src="./assets/icons/swap.svg"></svg-icon>
      {{ 'COMMON.REPLACE' | translate }}
    </button>
  </div>

  <pik-loader class="image-wrap__loader" *ngIf="isPending"></pik-loader>
</div>

<ng-template #default>
  <svg-icon class="default-image" *ngIf="defaultImage" pikButtonIcon [src]="defaultImage"></svg-icon>
</ng-template>
