import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  PikAppFooterModule,
  PikAppHeaderModule,
  PikAttachmentsCarouselModule,
  PikAutocompleteModule,
  PikAvatarModule,
  PikButtonsModule,
  PikButtonToggleModule,
  PikCardModule,
  PikCheckboxModule,
  PikDatepickerModule,
  PikDrawerModule,
  PikDropdownModule,
  PikEllipsisTextModule,
  PikEmptyDataModule,
  PikFormControlModule,
  PikFormModule,
  PikInputModule,
  PikLoaderModule,
  PikMenuModule,
  PikModalModule,
  PikPaginatorModule,
  PikRadioButtonModule,
  PikSearchInputModule,
  PikSelectModule,
  PikSortHeaderModule,
  PikSwitchModule,
  PikTableModule,
  PikTabsModule,
  PikTagModule,
  PikToastModule,
  PikTooltipModule,
} from '@pik-ui/ng-components';

const pikComponents = [
  PikButtonsModule,
  PikAppHeaderModule,
  PikAvatarModule,
  PikAppFooterModule,
  PikLoaderModule,
  PikEmptyDataModule,
  PikCardModule,
  PikButtonToggleModule,
  PikSelectModule,
  PikPaginatorModule,
  PikTableModule,
  PikAttachmentsCarouselModule,
  PikDrawerModule,
  PikDropdownModule,
  PikDatepickerModule,
  PikTagModule,
  PikCheckboxModule,
  PikInputModule,
  PikFormControlModule,
  PikModalModule,
  PikTooltipModule,
  PikAutocompleteModule,
  PikSortHeaderModule,
  PikSwitchModule,
  PikTabsModule,
  PikToastModule,
  PikRadioButtonModule,
  PikMenuModule,
  PikEllipsisTextModule,
  PikFormModule,
  PikSearchInputModule,
  PikEmptyDataModule,
];

@NgModule({
  imports: [CommonModule],
  exports: [...pikComponents],
})
export class PikComponentsModule {}
