import { TablePageFilter } from '../table-page-filter-base';
import { IParametersSmartSearch } from '../index';

export class ParametersSmartSearchFilterParams extends TablePageFilter<IParametersSmartSearch> {
  searchString: string;

  constructor(filter?: IParametersSmartSearch) {
    super(filter);
  }

  toObject(): IParametersSmartSearch {
    return {
      ...super.toObjectBase(),
      searchString: this.searchString,
    };
  }
}
