import { getEnvironmentArrayField } from './utils';

export const environment = {
  stand: 'stage',
  lastBuildTime: '06-09-2024 07:09:33',
  version: '24.4.3',
  production: false,
  traceRoute: true,
  hrmImageEndpoint: window['env']['apiHrmImage'] || 'https://hrm.bimteam.ru/api/v2',
  fmEndpoint: window['env']['apiFm'] || 'https://fm1-api.bimteam.ru/v1',
  locale: 'ru-RU',
  metrikaWebvisor: window['env']['metrikaWebvisor'] === 'false' ? false : true,
  accessLink: window['env']['accessLink'] || 'https://jira.pik.ru/servicedesk/customer/portal/2/create/641',
  isGoogleIntegrationEnabled: window['env']['isGoogleIntegrationEnabled'] === 'false' ? false : true,
  supportLink: window['env']['supportLink'] || 'https://jira.pik.ru/servicedesk/customer/portal/2/create/129',

  auth: {
    silentRefreshRedirectUri:
      window.location.origin + (window['env']['silentRefreshRedirectUri'] || '/silent-refresh.html'),
    redirectUri: window.location.origin + (window['env']['redirectUri'] || '/implicit-redirect'),
    requireHttps: false,
    showDebugInformation: true,
    disableAtHashCheck: true,
    sendAccessToken: true,
    issuer: window['env']['issuer'] || 'https://test.auth.pik-digital.ru',
    clientId: window['env']['clientId'] || 'fm_spa',
    scope: window['env']['scope'] || 'fm_api allow_external',
    responseType: 'id_token token',
    allowedUrls: getEnvironmentArrayField(window['env']['allowedUrls'], ['https://fm1-api.bimteam.ru']),
  },
};
