<input
  pikInput
  [formControl]="nameControl"
  [pikAutocomplete]="ftInput"
  placeholder="{{ 'COMPONENT_NOMENCLATURE_TYPES_CONTROL' | translate }}"
/>

<pik-autocomplete
  [standalone]="false"
  #ftInput="pikAutocomplete"
  (optionSelected)="pushItem($event)"
  [dataSource]="entities$ | async"
>
</pik-autocomplete>

<div class="tags" *ngIf="list?.length">
  <pik-tag large *ngFor="let item of list" removable (remove)="removeItem(item)">{{ item.name }}</pik-tag>
</div>
