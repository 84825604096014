<pik-drawer #drawer panelClass="table-settings-drawer" maxWidth="738px">
  <pik-drawer-header class="header">
    <pik-tabs [selectedIndex]="selectedTabIndex" (selectedIndexChange)="selectedTabIndex = $event">
      <pik-tab header="{{ 'TABLE_SETTINGS.FILTERS' | translate }}" *ngIf="showFilters"></pik-tab>
      <pik-tab header="{{ 'TABLE_SETTINGS.VISIBILITY' | translate }}" [disabled]="!showFilters"></pik-tab>
    </pik-tabs>

    <div class="table-settings-drawer__content" [ngSwitch]="selectedTabIndex">
      <ng-container *ngSwitchCase="TableSettingsTabs.Filter">
        <button pikButton appearance="inverse" *ngIf="tableFilterRef?.hasFilters$ | async" (click)="resetFilter()">
          <svg-icon src="./assets/icons/close-2.svg" pikButtonIcon></svg-icon>
          {{ 'TABLE_SETTINGS.RESET' | translate }}
        </button>
      </ng-container>

      <ng-container *ngSwitchCase="TableSettingsTabs.Columns">
        <button pikButton appearance="inverse" (click)="setDefaultSettings()">
          <svg-icon src="./assets/icons/rollback.svg" pikButtonIcon></svg-icon>
          {{ 'TABLE_SETTINGS.RETURN' | translate }}
        </button>
      </ng-container>
    </div>
  </pik-drawer-header>

  <pik-drawer-content>
    <ng-container [ngSwitch]="selectedTabIndex">
      <ng-container *ngSwitchCase="TableSettingsTabs.Columns">
        <form
          class="draggable-checkbox-group"
          cdkDropList
          (cdkDropListDropped)="reorderColumn($event)"
          [formGroup]="form"
        >
          <ng-container *ngFor="let column of pageService.tableColumns">
            <div class="setting-item" cdkDrag>
              <ng-container [ngSwitch]="column.name">
                <ng-container *ngSwitchCase="'attributes'">
                  <pik-checkbox [formControlName]="column.name" class="draggable-settings-checkbox">
                    <svg-icon cdkDragHandle src="./assets/icons/drag-handle.svg"></svg-icon>
                    {{ 'COMMON_LABELS.ATTRIBUTES' | translate }}
                  </pik-checkbox>
                  <app-attributes-list-control [formControl]="attributesControl"></app-attributes-list-control>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  <pik-checkbox
                    *ngIf="column.name !== 'tools'"
                    [formControlName]="column.name"
                    class="draggable-settings-checkbox"
                  >
                    <svg-icon cdkDragHandle src="./assets/icons/drag-handle.svg"></svg-icon>
                    {{ column.label | translate }}
                  </pik-checkbox>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </form>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <ng-content></ng-content>
      </ng-container>
    </ng-container>
  </pik-drawer-content>

  <pik-drawer-footer class="footer">
    <button pikButton (click)="apply()">{{ 'COMMON.APPLY' | translate }}</button>
    <button pikButton appearance="link" (click)="cancel()">{{ 'COMMON.CANCEL' | translate }}</button>
  </pik-drawer-footer>
</pik-drawer>
