import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PikTagModule } from '@pik-ui/ng-components';
import { ThemeColors } from '@common/consts';
import { ShowMoreDirective } from '../show-more.directive';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-list-tags',
  standalone: true,
  imports: [CommonModule, PikTagModule, TranslateModule],
  templateUrl: './list-tags.component.html',
  styleUrls: ['./list-tags.component.scss'],
})
export class ListTagsComponent extends ShowMoreDirective {
  @Input() set data(value: string | string[]) {
    this.list = Array.isArray(value) ? value : value?.split(',') ?? [];
  }

  ThemeColors = ThemeColors;
  list: string[] = [];
}
