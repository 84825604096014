<pik-search-input
  inverse
  [formControl]="searchControl"
  placeholder="{{ 'COMMON.SEARCH' | translate }}"
></pik-search-input>

<perfect-scrollbar style="max-height: 256px; max-width: 100%">
  <pik-menu>
    <pik-menu-item *ngFor="let item of filteredAttributes$ | async" (click)="onAdd(item)">
      {{ item.name }}
    </pik-menu-item>
  </pik-menu>
</perfect-scrollbar>
