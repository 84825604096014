import { toParams } from '@common/utils';

export interface IRequestCountersFilterParams {
  functionalTypeIds?: number[];
  disciplineIds?: number[];
  withDisciplinesOnly?: boolean;
}

export class RequestCountersFilterParams {
  functionalTypeIds: number[];
  disciplineIds: number[];
  withDisciplinesOnly: boolean;

  constructor(filter?: IRequestCountersFilterParams) {
    Object.assign(this, filter);
  }

  public toObject(): IRequestCountersFilterParams {
    return {
      functionalTypeIds: this.functionalTypeIds,
      disciplineIds: this.disciplineIds,
      withDisciplinesOnly: this.withDisciplinesOnly,
    };
  }

  public toParams() {
    return toParams(this.toObject());
  }
}
