import { Component, Input } from '@angular/core';
import { ThemeColors } from '@common/consts';

@Component({
  selector: 'app-discipline-tag',
  templateUrl: './discipline-tag.component.html',
})
export class DisciplineTagComponent {
  @Input() name: string;
  @Input() large: boolean;
  ThemeColors = ThemeColors;
}
