<ng-container *ngIf="_disciplines.length; else negativeIcon">
  <div class="disciplines">
    <ng-container
      *ngFor="
        let discipline of _disciplines | slice: 0:(isShowAllCount || !maxCount ? _disciplines.length : maxCount);
        trackBy: trackByIndex
      "
    >
      <app-condition-link [access]="permissionService.access.VisibilityOfCatalogs" [link]="discipline.params">
        <app-discipline-tag large [name]="discipline.name"></app-discipline-tag>
      </app-condition-link>
    </ng-container>

    <pik-tag
      class="toggle-tag"
      *ngIf="maxCount !== undefined && _disciplines.length > maxCount"
      [color]="'transparent'"
      [textColor]="ThemeColors.DarkBlue"
      (click)="toggle()"
      large
    >
      {{
        isShowAllCount
          ? ('COMMON.HIDE' | translate)
          : ('COMMON_PHRASE.AND_MORE' | translate: { number: _disciplines.length - maxCount })
      }}
    </pik-tag>
  </div>
</ng-container>
<ng-template #negativeIcon>—</ng-template>
