<ng-container *ngIf="!isDisable; else ftView">
  <input
    pikInput
    [triggerValueOnEmpty]="emptyTrigger"
    [trigger]
    [formControl]="nameControl"
    [pikAutocomplete]="autocomplete"
    placeholder="{{ 'COMPONENT_FUNCTIONAL_TYPE_CONTROL' | translate }}"
  />

  <pik-autocomplete
    [standalone]="false"
    #autocomplete="pikAutocomplete"
    (optionSelected)="selectEntity($event)"
    [dataSource]="entities$ | async"
  >
  </pik-autocomplete>
</ng-container>

<ng-template #ftView>
  <app-functional-type-tag [entity]="selectedEntity"></app-functional-type-tag>
</ng-template>
