import { AttributeValueDto, IdNameDto } from '../index';
import { FunctionalTypeDto } from '../FunctionalType/FunctionalTypeDto';
import { FamilyCategoryDto } from './FamilyCategoryDto';
import { ParameterValueSetDto } from '../Parameters/ParameterValueSetDto';
import { ParameterDto } from '../Parameters/ParameterDto';
import { BaseId } from '../BaseId';
import { FunctionalTypeParameterDto } from '@common/dto';
import { AppType, FamilyType, ParameterValueOwner } from '@common/enums';

export enum AppVersionStatus {
  Major = 'Major',
  Minor = 'Minor',
}

interface IVisibleDto extends BaseId {
  name: string;
  isVisible: boolean;
}

export interface ITreeFolderPathDto extends IVisibleDto {
  path: IVisibleDto[];
  parentFolderId: number;
  discipline: IdNameDto;
  pathName: string;
}

export interface FamilyDto extends BaseId {
  functionalTypeId: number;
  functionalType: FunctionalTypeDto;
  functionalTypes: IdNameDto[];
  name: string;
  description: string;
  version: number;
  author: IdNameDto;
  lastEditor: IdNameDto;
  created: Date;
  modified: Date;
  attributes: AttributeValueDto[];
  fmId: string;
  requestCount: number;
  openedRequestCount: number;
  lastVersionId: number;
  bindingsCount: number;
  snapBindingsCount: number;
  appType: AppType;
  categoryId: number;
  category?: FamilyCategoryDto;
  sectionCodeId: number;
  sectionCodeName?: string;
  status: number;
  systemFileName: string;
  isDraft: boolean;
  isFavourite: boolean;
  imageUpdateRequired: boolean;
  familySymbols: FamilySymbolDto[];
  parameters: FamilyParameterDto[];
  requestId: number;
  isSystem: boolean;
  folders: ITreeFolderPathDto[];
  disciplineIds?: number[];
  loadLogsCount: number;
  parameterValueOwner: ParameterValueOwner;
  standards: IdNameDto[];
  type: FamilyType;
}

interface FamilyParameterDto extends BaseId {
  name: string;
  parameterId: number;
  parameter?: ParameterDto[];
  functionalTypeParameter?: FunctionalTypeParameterDto[];
  isInspect: boolean;
  categoryFamilyId: number;
  isType: boolean;
  uniqueParameterName: string;
}

interface FamilySymbolDto {
  id: number;
  name: string;
  parameterValueSets: ParameterValueSetDto[];
}
