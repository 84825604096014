import { RequestCreateItem } from '../views/request-create/types';

export const REQUEST_CREATE_NAVIGATION_FAMILY: RequestCreateItem[] = [
  {
    name: 'REQUEST_MODULE.REQUEST_CREATE_NAVIGATION.NAME_AND_PROPS',
    id: 1,
    valid: false,
    enable: false,
  },
  {
    name: 'COMMON_LABELS.ATTRIBUTES',
    id: 2,
    valid: false,
    enable: false,
  },
  {
    name: 'REQUEST_MODULE.REQUEST_CREATE_NAVIGATION.SYMBOLS_AND_PARAMETERS',
    id: 3,
    valid: false,
    enable: false,
  },
  {
    name: 'REQUEST_MODULE.REQUEST_CREATE_NAVIGATION.DESCRIPTION',
    id: 4,
    valid: false,
    enable: false,
  },
];

export const REQUEST_CREATE_NAVIGATION_MATERIAL: RequestCreateItem[] = [
  {
    name: 'REQUEST_MODULE.REQUEST_CREATE_NAVIGATION.NAME_AND_PROPS',
    id: 1,
    valid: false,
    enable: false,
  },
  {
    name: 'COMMON_LABELS.ATTRIBUTES',
    id: 2,
    valid: false,
    enable: false,
  },
  {
    name: 'REQUEST_MODULE.REQUEST_CREATE_NAVIGATION.PARAMETERS',
    id: 3,
    valid: false,
    enable: false,
  },
  {
    name: 'REQUEST_MODULE.REQUEST_CREATE_NAVIGATION.DESCRIPTION',
    id: 4,
    valid: false,
    enable: false,
  },
];

export const REQUEST_CREATE_NAVIGATION = {
  REQUEST_CREATE_NAVIGATION_FAMILY,
  REQUEST_CREATE_NAVIGATION_MATERIAL,
};
