<pik-select
  [formControl]="control"
  placeholder="{{ 'COMMON_PHRASE.SELECT_DISCIPLINE' | translate }}"
  appearance="outline"
  (change)="nextChangeValue()"
>
  <pik-option [value]="null">{{ 'COMMON_PHRASE.SELECT_DISCIPLINE' | translate }}</pik-option>
  <pik-option *ngFor="let disciplines of dictionariesService.disciplines$ | async" [value]="disciplines.id">{{
    disciplines.name
  }}</pik-option>
</pik-select>
