export enum BindingCheckErrorType {
  NoError = 0,
  CantCheck = 1,
  EmptyNomenclatures = 2,
  NoValid = 3,
  NoValidComparison = 4,
  ElementNoValid = 5,
}

export const BINDING_CHECK_ERROR_TYPE_LABEL: { [key in BindingCheckErrorType]: string } = {
  [BindingCheckErrorType.NoError]: 'BINDINGS_CHECK_MODULE.NO_ERROR',
  [BindingCheckErrorType.CantCheck]: 'BINDINGS_CHECK_MODULE.CANT_CHECK',
  [BindingCheckErrorType.EmptyNomenclatures]: 'BINDINGS_CHECK_MODULE.EMPTY_NOMENCLATURES',
  [BindingCheckErrorType.NoValid]: 'BINDINGS_CHECK_MODULE.NO_VALID',
  [BindingCheckErrorType.NoValidComparison]: 'BINDINGS_CHECK_MODULE.NO_VALID_COMPARISON',
  [BindingCheckErrorType.ElementNoValid]: 'BINDINGS_CHECK_MODULE.ELEMENT_NO_VALID',
};

export const BINDING_CHECK_ERROR_TYPE = [
  {
    id: BindingCheckErrorType.NoError,
    name: BINDING_CHECK_ERROR_TYPE_LABEL[BindingCheckErrorType.NoError],
  },
  {
    id: BindingCheckErrorType.CantCheck,
    name: BINDING_CHECK_ERROR_TYPE_LABEL[BindingCheckErrorType.CantCheck],
  },
  {
    id: BindingCheckErrorType.EmptyNomenclatures,
    name: BINDING_CHECK_ERROR_TYPE_LABEL[BindingCheckErrorType.EmptyNomenclatures],
  },
  {
    id: BindingCheckErrorType.NoValid,
    name: BINDING_CHECK_ERROR_TYPE_LABEL[BindingCheckErrorType.NoValid],
  },
  {
    id: BindingCheckErrorType.NoValidComparison,
    name: BINDING_CHECK_ERROR_TYPE_LABEL[BindingCheckErrorType.NoValidComparison],
  },
  {
    id: BindingCheckErrorType.ElementNoValid,
    name: BINDING_CHECK_ERROR_TYPE_LABEL[BindingCheckErrorType.ElementNoValid],
  },
];
