import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { orderBy } from 'lodash';
import { map, take, takeUntil, tap } from 'rxjs/operators';

import { FamilyVersionDto, IdNameDto } from '@common/dto';
import { FamiliesApiService, VersionsApiService } from '@services/api';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { TypedFormControl } from '@common/interfaces';
import { PikEllipsisTextModule, PikSelectModule } from '@pik-ui/ng-components';

@Component({
  selector: 'app-family-version-control',
  templateUrl: './family-version-control.component.html',
  styleUrls: ['./family-version-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FamilyVersionControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, ReactiveFormsModule, PikSelectModule, NgForOf, NgIf, PikEllipsisTextModule, TranslateModule],
})
export class FamilyVersionControlComponent implements OnDestroy, ControlValueAccessor, OnInit {
  @Input() set familyId(value: number) {
    this._familyId = value ?? null;

    if (value) {
      this._getEntities(value);
    } else {
      this.entities$.next([]);
      this.entityControl.disable({ emitEvent: false });
      this.entityControl.setValue(null, { emitEvent: false });
      this.selectFull.emit(null);
    }
  }

  @Output() selectFull: EventEmitter<FamilyVersionDto> = new EventEmitter<FamilyVersionDto>();

  public readonly entities$ = new Subject<IdNameDto[]>();
  public readonly entityControl: TypedFormControl<number> = new FormControl(null);
  public _familyId: number;

  private readonly _destroy$ = new Subject();
  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(
    private _changeDetector: ChangeDetectorRef,
    private _familiesApi: FamiliesApiService,
    private _versionsApi: VersionsApiService,
    private translate: TranslateService,
  ) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this.subscribeToVersionChange().subscribe();
  }

  writeValue(value: number) {
    this.entityControl.setValue(value, { emitEvent: false });
    this._changeDetector.detectChanges();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  nextChangeValue(id: number): void {
    if (this._onChange) {
      if (id) {
        this._versionsApi
          .getFamilyVersion(id)
          .pipe(
            take(1),
            tap((data) => this.selectFull.emit(data)),
          )
          .subscribe();
      } else {
        this.selectFull.emit(null);
      }

      this._onChange(id);
    }
  }

  private _getEntities(id: number) {
    this._familiesApi
      .getFamilyVersions(id)
      .pipe(
        take(1),
        map((versions) =>
          orderBy(versions, 'version').map((v) => ({
            id: v.id,
            name: `${this.translate.instant('COMMON_LABELS.VERSION')} ${v.version}`,
            isCurrentVersion: v.isCurrentVersion,
          })),
        ),
        tap((versions) => {
          this.entities$.next(versions);
          this.entityControl.enable();

          const value = versions.length ? versions.find((v) => v.isCurrentVersion).id : null;
          this.entityControl.setValue(value);
        }),
      )
      .subscribe();
  }

  private subscribeToVersionChange() {
    return this.entityControl.valueChanges.pipe(
      tap((value) => this.nextChangeValue(value)),
      takeUntil(this._destroy$),
    );
  }
}
