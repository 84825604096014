import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { from, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UnauthorizedInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;

  constructor(private oauthService: OAuthService, private router: Router) {}

  private getAuthRequest(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + this.oauthService.getAccessToken(),
      },
    });
  }

  private initImplicitFlow(): void {
    const encodedUri = encodeURIComponent(this.router.url);
    this.oauthService.initImplicitFlow(encodedUri);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((response: HttpResponse<any>) => {
        if (response && response.status === 401) {
          if (this.refreshTokenInProgress) {
            return this.oauthService.events.pipe(
              filter((result) => result.type === 'silently_refreshed'),
              take(1),
              switchMap(() => next.handle(this.getAuthRequest(req))),
            );
          } else {
            this.refreshTokenInProgress = true;
            return from(this.oauthService.silentRefresh()).pipe(
              take(1),
              switchMap(() => next.handle(this.getAuthRequest(req))),
              catchError((error) => {
                this.initImplicitFlow();
                return throwError(error);
              }),
              finalize(() => (this.refreshTokenInProgress = false)),
            );
          }
        }
        return throwError(response);
      }),
    );
  }
}
