import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { AttributeDto } from '@common/dto';
import { DictionariesService } from '@services';
import { TypedFormControl } from '@common/interfaces';

@Component({
  selector: 'app-attributes-list-control',
  templateUrl: './attributes-list-control.component.html',
  styleUrls: ['./attributes-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AttributesListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributesListControlComponent implements OnDestroy, ControlValueAccessor, OnInit {
  control: TypedFormControl<number[]> = new FormControl([]);
  attributesList: AttributeDto[] = [];
  attributes: Map<number, AttributeDto> = new Map<number, AttributeDto>();

  private _destroy$ = new Subject();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  isDisabled: boolean;

  constructor(private _changeDetector: ChangeDetectorRef, private dictionaryService: DictionariesService) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this.getAllAttributes().subscribe();
  }

  writeValue(value: number[]) {
    this.control.setValue(this.sortValue(value));
    this._changeDetector.detectChanges();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  private getAllAttributes() {
    return this.dictionaryService.loadAttributes().pipe(
      take(1),
      switchMap(() => this.dictionaryService.attributes$),
      map((d) => d ?? []),
      tap((d: AttributeDto[]) => {
        this.attributesList = d;
        this.attributes = new Map(d.map((a) => [a.id, a]));
      }),
    );
  }

  nextChangeValue(value: number[]): void {
    if (this._onChange) {
      this._onChange(this.sortValue(value));
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    this._changeDetector.markForCheck();
  }

  sortValue(value: number[]): number[] {
    return value ? [...value].sort((a, b) => a - b) : value;
  }
}
