import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { finalize, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { UpdateApiService } from '@services/api';
import { AppType } from '@common/enums';
import { Subject } from 'rxjs';
import { PermissionService } from '@core/account/services';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-download-app-buttons',
  templateUrl: './download-app-buttons.component.html',
  styleUrls: ['./download-app-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadAppButtonsComponent implements OnDestroy {
  pending = false;
  AppType = AppType;
  private destroy$ = new Subject();

  constructor(
    public permissionService: PermissionService,
    private updateApi: UpdateApiService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  download(appType: AppType) {
    this.pending = true;

    this.updateApi
      .getLastMsi(appType)
      .pipe(
        take(1),
        switchMap((lastVersion) =>
          this.updateApi.getPackage(lastVersion.id).pipe(tap((file) => saveAs(file, lastVersion.fileName))),
        ),
        finalize(() => {
          this.pending = false;
          this.cdr.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
