import { Component, Input } from '@angular/core';
import { BindingsFilterParams, IBindingsFilterParams } from '@common/models/filters';
import { ROUTES } from '@common/consts';
import { normalizePageParams } from '@common/utils';

import { PermissionService } from '@core/account/services';
import { ConditionLinkModule } from '@shared/components/condition-link';

@Component({
  standalone: true,
  selector: 'app-bindings-link',
  imports: [ConditionLinkModule],
  templateUrl: './bindings-link.component.html',
})
export class BindingsLinkComponent {
  @Input() set params(value: IBindingsFilterParams) {
    const filters = new BindingsFilterParams(value);
    this.link = ['', ROUTES.bindingsList, normalizePageParams(filters.toParams())];
  }

  link: any[];

  constructor(public permissionsService: PermissionService) {}
}
