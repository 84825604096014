export enum BindingStatus {
  Ready = 1,
  IdEdition = 2,
  Review = 3,
}

export const BINDING_STATUS_COLOR: { [key in BindingStatus]: string } = {
  [BindingStatus.Ready]: '#27AE60',
  [BindingStatus.IdEdition]: '#0097ff',
  [BindingStatus.Review]: '#F1882A',
};

export const BINDING_STATUS_LABEL: { [key in BindingStatus]: string } = {
  [BindingStatus.Ready]: 'COMMON.COMPLETE',
  [BindingStatus.IdEdition]: 'COMMON.REDACTION',
  [BindingStatus.Review]: 'COMMON.APPROVING',
};

export const BINDING_STATUSES = [
  {
    id: BindingStatus.Ready,
    name: BINDING_STATUS_LABEL[BindingStatus.Ready],
  },
  {
    id: BindingStatus.Review,
    name: BINDING_STATUS_LABEL[BindingStatus.Review],
  },
  {
    id: BindingStatus.IdEdition,
    name: BINDING_STATUS_LABEL[BindingStatus.IdEdition],
  },
];
