import { Component, Input } from '@angular/core';
import { ROUTES } from '@common/consts';
import { normalizePageParams } from '@common/utils';
import { ILoadLogFilterParams, LoadLogFilterParams } from '@common/models/filters';
import { ConditionLinkModule } from '@shared/components/condition-link';

@Component({
  standalone: true,
  selector: 'app-load-log-link',
  templateUrl: './load-log-link.component.html',
  imports: [ConditionLinkModule],
})
export class LoadLogLinkComponent {
  @Input() set params(value: ILoadLogFilterParams) {
    if (value) {
      this.link = ['', ROUTES.loadLogs, normalizePageParams(new LoadLogFilterParams(value).toParams())];
    }
  }
  @Input() name: string;

  link: any[];
}
