import { Component, Input } from '@angular/core';
import { ROUTES_PARTS, ThemeColors } from '@common/consts';
import { IRequestsFilterParams } from '@common/models/filters';
import { normalizePageParams } from '@common/utils';

@Component({
  selector: 'app-request-tag',
  templateUrl: './request-tag.component.html',
  styleUrls: ['./request-tag.component.scss'],
})
export class RequestTagComponent {
  @Input() mode: 'entityCard' | 'userCard';
  @Input() all: number;
  @Input() set filter(value: IRequestsFilterParams) {
    const normalizedParams = normalizePageParams({ ...value });
    Object.entries(normalizedParams).forEach(([key, value]) => {
      if (typeof value !== 'string') return;
      if (value?.includes('true')) {
        normalizedParams[key] = true;
      }
      if (value?.includes('false')) {
        normalizedParams[key] = false;
      }
    });
    this.openedFilter = { ...normalizedParams, ignoreFinished: true };
    this.allFilter = { ...normalizedParams, ignoreFinished: false };
  }

  @Input() opened: number;
  @Input() isDependsOnMe: number;
  colors = ThemeColors;
  ROUTES_PARTS = ROUTES_PARTS;

  openedFilter = {};
  userFilter = normalizePageParams({ isDependsOnMe: true });
  allFilter = {};
}
