<pik-select
  class="checkbox-multiselect"
  multiple
  [headerTemplate]="headerTemplate"
  [placeholder]="placeholder"
  [formControl]="control"
>
  <pik-option
    #option="pikOption"
    class="checkbox-multiselect__option"
    *ngFor="let item of filteredOptions; trackBy: trackById"
    [value]="item.id"
  >
    <pik-checkbox disabled [checked]="option.isActive">{{ item.name }}</pik-checkbox>
  </pik-option>
</pik-select>

<ng-template #headerTemplate>
  <div class="checkbox-multiselect__search" *ngIf="canSearch" [class.focused]="focused">
    <pik-search-input
      [formControl]="searchControl"
      (focused)="focused = true"
      (blurred)="focused = false"
      placeholder="{{ 'COMMON.SEARCH' | translate }}"
    >
    </pik-search-input>
  </div>

  <div class="checkbox-multiselect__tools">
    <button (click)="selectAll()">{{ 'COMPONENT_MULTI_SELECT.SELECT_ALL' | translate }}</button>
    <button (click)="reset()">{{ 'COMPONENT_MULTI_SELECT.RESET' | translate }}</button>
  </div>
</ng-template>
