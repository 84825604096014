import { Component } from '@angular/core';
import { Language, LANGUAGE_LABEL } from '@common/dto';
import { take, tap } from 'rxjs/operators';
import { UsersApiService } from '@services/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
})
export class LanguageSwitcherComponent {
  languages = Object.values(Language);
  languageLabel = LANGUAGE_LABEL;

  constructor(public translate: TranslateService, private usersApi: UsersApiService) {}

  changeLanguage(language: Language) {
    this.usersApi
      .updateCulture(language)
      .pipe(
        take(1),
        tap(() => this.translate.use(language)),
      )
      .subscribe();
  }
}
