export enum RoleType {
  Default = 'Default',
  Admin = 'Admin',
  Another = 'Another',
}

export const RoleTypeValue = {
  [RoleType.Default]: 'ROLE_ENUM.DEFAULT',
  [RoleType.Admin]: 'ROLE_ENUM.ADMIN',
  [RoleType.Another]: 'ROLE_ENUM.ANOTHER',
};
