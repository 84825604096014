import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IdNameDto } from '@common/dto';
import { ROUTES_PARTS, ThemeColors } from '@common/consts';
import { ConditionLinkModule } from '@shared/components/condition-link';
import { NgIf, NgStyle } from '@angular/common';
import { normalizePageParams } from '@common/utils';
import { PermissionService } from '@core/account/services';

@Component({
  standalone: true,
  selector: 'app-unit-type-link',
  templateUrl: './unit-type-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ConditionLinkModule, NgIf, NgStyle],
})
export class UnitTypeLinkComponent {
  @Input() set entity(value: IdNameDto) {
    this._entity = value;
    this.link = value
      ? ['', ROUTES_PARTS.UNIT_TYPES, normalizePageParams({ id: value.id, searchValue: value.id, searchField: 'id' })]
      : null;
  }

  @Input() set alert(value: boolean) {
    this.textColor = value ? ThemeColors.Orange : 'inherit';
  }

  public textColor: ThemeColors | 'inherit';

  _entity: IdNameDto;
  link: any[];

  constructor(public permissionService: PermissionService) {}
}
