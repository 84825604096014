import { NgModule } from '@angular/core';
import { ParameterValueControlComponent } from './parameter-value-control.component';
import { SharedModule } from '@shared/shared.module';
import {
  ParameterBooleanValueControlComponent,
  ParameterRangeValueControlComponent,
  ParameterRangeValuesControlComponent,
  ParameterStringValueControlComponent,
  ParameterValidValuesComponent,
} from './components';
import { ParameterValueControlService } from './services';

@NgModule({
  declarations: [
    ParameterValueControlComponent,
    ParameterRangeValueControlComponent,
    ParameterBooleanValueControlComponent,
    ParameterStringValueControlComponent,
    ParameterRangeValuesControlComponent,
    ParameterValidValuesComponent,
  ],
  imports: [SharedModule],
  exports: [ParameterValueControlComponent, ParameterRangeValuesControlComponent, ParameterRangeValuesControlComponent],
})
export class ParameterValueControlModule {}
