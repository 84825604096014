import { Component, Input } from '@angular/core';
import { ThemeColors } from '@common/consts';
import { BooleanValues } from '@shared/components/controls/parameter-value-control/enums';
import { getValidBooleanValue } from '@shared/components/controls/parameter-value-control/components/parameter-boolean-value-control/utils/get-valid-boolean-value';

@Component({
  selector: 'app-parameters-boolean-view',
  templateUrl: './parameters-boolean-view.component.html',
  styleUrls: ['./parameters-boolean-view.component.scss'],
})
export class ParametersBooleanViewComponent {
  @Input() set value(value: BooleanValues) {
    this._value = getValidBooleanValue(value);
  }

  @Input() public readonly textColor: ThemeColors;

  public readonly ThemeColors = ThemeColors;
  public readonly BooleanValues = BooleanValues;
  public _value: string;
}
