<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="'version'">
    <app-condition-link
      [access]="true"
      [link]="['', routesParts.FAMILIES, solution.familyId, { version: solution.version }]"
    >
      <pik-tag large [color]="colors.Family">
        {{
          solution.familyName +
            (solution.version ? ' - ' + ('COMMON_LABELS.VERSION' | translate) + ' ' + solution.version : '')
        }}
      </pik-tag>
    </app-condition-link>
  </ng-container>

  <ng-container *ngSwitchCase="'solution'">
    <pik-tag large [color]="colors.Family" [removable]="removable" (remove)="remove.emit(solution)">
      {{
        solution.familyName +
          (solution.version ? ' - ' + ('COMMON_LABELS.VERSION' | translate) + ' ' + solution.version : '')
      }}
    </pik-tag>
  </ng-container>

  <ng-container *ngSwitchCase="'single'">
    <app-condition-link [access]="true" [link]="['', routesParts.FAMILIES, entity.id]">
      <pik-tag large [color]="colors.Family" [removable]="removable" (remove)="remove.emit(solution)">
        {{ entity.name }}
      </pik-tag>
    </app-condition-link>
  </ng-container>
</ng-container>
