import { Directive, HostListener, Input } from '@angular/core';
import { FmTabsService } from '@services';
import { OpenTabData } from '@common/interfaces';

@Directive({
  selector: '[appOpenTab]',
})
export class OpenTabDirective {
  @Input() appOpenTab: OpenTabData;

  constructor(private _tabService: FmTabsService) {}

  @HostListener('click', ['$event']) onClick($event) {
    $event.preventDefault();
    this._tabService.openTab(
      this.appOpenTab.type,
      this.appOpenTab.entity.id,
      this.appOpenTab.entity.name,
      !$event.ctrlKey,
    );
  }
}
