import { Component, Input } from '@angular/core';

import { ROUTES_PARTS, ThemeColors } from '@common/consts';
import { IdNameDto } from '@common/dto';

@Component({
  selector: 'app-material-tag',
  templateUrl: './material-tag.component.html',
  styleUrls: ['./material-tag.component.scss'],
})
export class MaterialTagComponent {
  @Input() entity: IdNameDto;

  colors = ThemeColors;
  routesParts = ROUTES_PARTS;
}
