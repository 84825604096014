<pik-select
  [formControl]="control"
  inverse
  [placeholder]="placeholder ?? ('COMMON.SELECT' | translate)"
  [headerTemplate]="headerTemplate"
>
  <pik-option *ngFor="let item of filteredOptions; trackBy: trackById" [value]="item[fieldName]">{{
    item.name | translate
  }}</pik-option>
</pik-select>

<ng-template #headerTemplate>
  <div class="checkbox-multiselect__search" *ngIf="canSearch" [class.focused]="focused">
    <pik-search-input
      [formControl]="searchControl"
      (focused)="focused = true"
      (blurred)="focused = false"
      placeholder="{{ 'COMMON.SEARCH' | translate }}"
    >
    </pik-search-input>
  </div>

  <div class="checkbox-multiselect__tools" *ngIf="showReset">
    <button (click)="reset()">{{ 'COMPONENT_MULTI_SELECT.RESET' | translate }}</button>
  </div>
</ng-template>
