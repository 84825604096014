import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AuthConfigService } from './authconfig.service';
import { authConfig, resourceServerConfig } from './auth.config';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { CommonModule } from '@angular/common';

export function init_app(authConfigService: AuthConfigService) {
  return () => authConfigService.initAuth();
}

export function storageFactory(): OAuthStorage {
  return localStorage;
}

export function authConfigFactory(): OAuthModuleConfig {
  return {
    resourceServer: resourceServerConfig,
  };
}

@NgModule({
  imports: [HttpClientModule, OAuthModule.forRoot(), CommonModule],
  providers: [
    AuthConfigService,
    { provide: AuthConfig, useValue: authConfig },
    { provide: OAuthModuleConfig, useFactory: authConfigFactory },
    { provide: OAuthStorage, useFactory: storageFactory },
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AuthConfigService],
      multi: true,
    },
  ],
})
export class AuthConfigModule {}
