import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { IdNameDto, UserDto } from '@common/dto';
import { UsersApiService } from '@services/api';
import { startMetrika } from '@core/metrika';
import { PermissionService } from '@core/account/services/permission.service';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class AccountService {
  user$: BehaviorSubject<UserDto> = new BehaviorSubject<UserDto>(null);
  userStandards$: BehaviorSubject<IdNameDto[]> = new BehaviorSubject<IdNameDto[]>([]);

  constructor(private _usersApi: UsersApiService, private permissionService: PermissionService) {}

  public loadUser(): Observable<UserDto> {
    return this._usersApi.getMe().pipe(
      take(1),
      tap((user: UserDto) => {
        this.setUser(user);
        if (environment.metrikaWebvisor) {
          startMetrika(user.login, user.name);
        }
      }),
    );
  }

  public changeStandard(data: any) {
    return this._usersApi.changeStandard(data).pipe(
      take(1),
      tap(() => window.location.reload()),
    );
  }

  public setUser(user: UserDto) {
    this.permissionService.setPermissions(user);
    this.user$.next(user);
    this.userStandards$.next(user.standards);
  }
}
