import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { orderBy } from 'lodash';
import { map, take, takeUntil, tap } from 'rxjs/operators';

import { FamilySymbolSetDto, IdNameDto, ValueSetDto } from '@common/dto';
import { VersionsApiService } from '@services/api';
import { TypedFormControl } from '@common/interfaces';
import { PikEllipsisTextModule, PikSelectModule } from '@pik-ui/ng-components';
import { AsyncPipe, NgForOf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-family-symbol-control',
  templateUrl: './family-symbol-control.component.html',
  styleUrls: ['./family-symbol-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FamilySymbolControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PikSelectModule, ReactiveFormsModule, AsyncPipe, PikEllipsisTextModule, TranslateModule, NgForOf],
})
export class FamilySymbolControlComponent implements OnDestroy, ControlValueAccessor, OnInit {
  @Input() set versionId(value: number) {
    this._versionId = value ?? null;

    if (value) {
      this._getEntities(this._versionId);
    } else {
      this.entities$.next([]);
      this.entityControl.setValue(null);
      this.entityControl.disable({ emitEvent: false });
      this.symbols = [];
      this.activeSets$.next([]);
    }
  }

  @Output() selectFull = new EventEmitter<FamilySymbolSetDto>();

  public symbols: FamilySymbolSetDto[] = [];
  public readonly activeSets$: BehaviorSubject<ValueSetDto[]> = new BehaviorSubject([]);
  public readonly entities$ = new Subject<IdNameDto[]>();
  public readonly entityControl: TypedFormControl<number> = new FormControl(null);

  private _destroy$ = new Subject();
  private _versionId: number;
  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(private _changeDetector: ChangeDetectorRef, private _versionsApi: VersionsApiService) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this.subscribeToValueChange().subscribe();
  }

  writeValue(value: number) {
    this.entityControl.setValue(value, { emitEvent: false });
    this._changeDetector.detectChanges();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  nextChangeValue($event): void {
    if (this._onChange) {
      this._onChange($event);
    }
  }

  private subscribeToValueChange() {
    return this.entityControl.valueChanges.pipe(
      tap((id) => {
        this.nextChangeValue(id);
        const symbol = this.symbols.find((s) => s.id === id);
        const sets = id ? symbol.parameterValueSets : [];
        this.activeSets$.next(sets);
        this.selectFull.emit(symbol);
      }),
      takeUntil(this._destroy$),
    );
  }

  private _getEntities(id: number) {
    this._versionsApi
      .getSymbolsWithSets(id)
      .pipe(
        take(1),
        map((symbols) => orderBy(symbols, 'name')),
        tap((symbols) => {
          this.symbols = symbols;
          this.entities$.next(symbols);
          this.entityControl.enable({ emitEvent: false });

          const value = symbols.length ? symbols[0].id : null;
          this.entityControl.setValue(value);
        }),
      )
      .subscribe();
  }
}
