import { AttributeValueDto, FilterAttributeData } from '@common/dto';
import { transformToIds } from '../transform-to-ids';

export function generateAttributeFilterDataFromValues(attribute: AttributeValueDto): FilterAttributeData {
  return {
    id: attribute.attributeId,
    attributeType: attribute.attributeType,
    valueStr: attribute.valueStr,
    options: transformToIds(attribute.options),
  };
}
