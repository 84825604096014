import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MaterialLightDto, ParameterValueSetLightDto, PatchOperation, ValueSetDto } from '../../../common/dto';
import { ApiPaths } from '@common/consts/api-paths';
import { ResultPart } from '@common/interfaces';

@Injectable({ providedIn: 'root' })
export class ParameterValueSetsApiService {
  constructor(private http: HttpClient) {}

  /**
   * Возвращает материалы по идентификатору набора параметров
   * @param setId - id
   */
  public getMaterials(setId: number): Observable<ResultPart<MaterialLightDto>> {
    return this.http.get<ResultPart<MaterialLightDto>>(`${ApiPaths.ParameterValueSets}/${setId}/materials`);
  }

  public remove(setId: number): Observable<void> {
    return this.http.delete<void>(`${ApiPaths.ParameterValueSets}/${setId}`);
  }

  public create(data: ParameterValueSetLightDto): Observable<ValueSetDto> {
    return this.http.post<ValueSetDto>(ApiPaths.ParameterValueSets, data);
  }

  public update(data: ValueSetDto): Observable<ValueSetDto> {
    return this.http.put<ValueSetDto>(`${ApiPaths.ParameterValueSets}/${data.id}`, data);
  }

  public patch(setId: number, data: PatchOperation[]): Observable<ValueSetDto> {
    return this.http.patch<ValueSetDto>(`${ApiPaths.ParameterValueSets}/${setId}`, data);
  }
}
