import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { debounceTime, finalize, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PikAutocompleteSelectedEvent } from '@pik-ui/ng-components';

import { IdNameDto } from '@common/dto';
import { MaterialsApiService } from '@services/api';
import { transformToIds } from '@common/utils';

@Component({
  selector: 'app-materials-control',
  templateUrl: './materials-control.component.html',
  styleUrls: ['./materials-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialsControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialsControlComponent implements OnDestroy, ControlValueAccessor, OnInit {
  @Input() ignored: number[] = [];

  list: IdNameDto[] = [];
  pending = false;
  nameControl = new UntypedFormControl(null);
  entities$ = new Subject<PikAutocompleteSelectedEvent[]>();

  private _destroy$ = new Subject();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(private _changeDetector: ChangeDetectorRef, private _materialsApi: MaterialsApiService) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this._subscribeToNameControl().subscribe();
  }

  async writeValue(value: number[] | IdNameDto[]) {
    if (value) {
      const data = transformToIds(value);
      await this._getEntities(data).toPromise();
    } else {
      this.list = [];
    }
    this._changeDetector.detectChanges();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  removeItem(item: IdNameDto) {
    this.list = this.list.filter((ft) => ft.id !== item.id);
    this.nextChangeValue();
  }

  pushItem(event: PikAutocompleteSelectedEvent) {
    setTimeout(() => this.nameControl.setValue(''));

    const newItem: IdNameDto = event.value;

    if (!this.list.some((s) => s.id === newItem.id)) {
      this.list.push(event.value);
      this.nextChangeValue();
    }
  }

  nextChangeValue(): void {
    if (this._onChange) {
      this._onChange(this.list.map((i) => i.id));
    }
  }

  private _subscribeToNameControl() {
    return this.nameControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      tap(() => (this.pending = true)),
      switchMap((value) =>
        (value === '' ? of([]) : this._materialsApi.smartSearch(value)).pipe(finalize(() => (this.pending = false))),
      ),
      map((data) => data.filter((i) => !this.list.some((l) => l.id === i.id) && !this.ignored.includes(i.id))),
      map((result: IdNameDto[]) =>
        result.map((item) => ({
          label: item.name,
          value: item,
        })),
      ),
      tap((data) => this.entities$.next(data)),
      takeUntil(this._destroy$),
    );
  }

  private _getEntities(ids: number[]) {
    this.list = [];
    this.pending = true;

    return (
      ids.length ? this._materialsApi.getIdNames(ids).pipe(tap((entities) => (this.list = [...entities]))) : of([])
    ).pipe(finalize(() => (this.pending = false)));
  }
}
