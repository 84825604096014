import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';

import { environment } from '../../../environments/environment';

@Injectable()
export class AuthConfigService {
  constructor(private readonly oauthService: OAuthService, private readonly authConfig: AuthConfig) {}

  async initAuth(): Promise<any> {
    return new Promise<void>((resolveFn, rejectFn) => {
      const wl = window.location;

      if (!wl.pathname.includes('implicit-redirect')) {
        localStorage.setItem('initialUrl', wl.pathname + wl.search);
      }

      this.oauthService.configure(this.authConfig);
      this.oauthService.tokenValidationHandler = new JwksValidationHandler();

      this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          this.oauthService.setupAutomaticSilentRefresh();
          resolveFn();
        } else {
          switch (environment.auth.responseType) {
            case 'id_token token':
              this.oauthService.initImplicitFlow();
              break;
            case 'code':
              this.oauthService.initCodeFlow();
              break;
          }

          rejectFn();
        }
      });
    });
  }
}
