import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { identity, pickBy } from 'lodash';

import { environment } from '../../environments/environment';
import { ChatParams, MessageDto } from '@common/dto';
import { ResultPart } from '@common/interfaces';

@Injectable({
  providedIn: 'root',
})
export class FamilyChatService {
  params: any = {};
  chatParams$: ReplaySubject<ChatParams> = new ReplaySubject<ChatParams>(1);
  chatParams: ChatParams;

  private readonly headers: HttpHeaders;
  private messagesEndpoint = environment.fmEndpoint + '/messages';
  private _familyChatList$: Subject<MessageDto[]> = new Subject<MessageDto[]>();

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  }

  get familyChatList(): Observable<MessageDto[]> {
    return this._familyChatList$.asObservable();
  }

  getFamilyChatList() {
    return this.subscribeToChatParams().pipe(
      filter((f) => !!f),
      tap((chatParams: ChatParams) => {
        this.chatParams = pickBy(chatParams, identity);
      }),
      switchMap(() => this.getMessages()),
      map((data: ResultPart<MessageDto>) => {
        data.result = Object.values(data.result).sort((a, b) => {
          return +new Date(a.created) - +new Date(b.created);
        });
        return data;
      }),
      tap((data: ResultPart<MessageDto>) => this._familyChatList$.next(data.result)),
    );
  }

  private getMessages(): Observable<ResultPart<MessageDto>> {
    const params = {};
    Object.keys(this.chatParams).map((k) => {
      params[k] = this.chatParams[k]?.toString();
    });
    return this.http.get<ResultPart<MessageDto>>(`${this.messagesEndpoint}`, {
      params: { ...params },
    });
  }

  subscribeToChatParams() {
    return this.chatParams$.asObservable();
  }

  public sendMessage(message: MessageDto): Observable<MessageDto> {
    return this.http.post<MessageDto>(`${this.messagesEndpoint}/`, message, { headers: this.headers });
  }
}
