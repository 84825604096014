import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ROUTES } from '@common/consts';
import { IUniqueParametersFilterParams, UniqueParametersFilterParams } from '@common/models/filters';
import { normalizePageParams } from '@common/utils';
import { PermissionService } from '@core/account/services';
import { ConditionLinkModule } from '@shared/components/condition-link';
import { IdNameDto } from '@common/dto';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-unique-parameters-link',
  templateUrl: './unique-parameter-link.component.html',
  imports: [ConditionLinkModule, NgIf, NgSwitch, NgSwitchCase],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniqueParameterLinkComponent {
  @Input() set entity(uniqueParameter: IdNameDto) {
    this.type = 'entity';
    if (uniqueParameter?.id) {
      this._entity = uniqueParameter;
      this.link = [
        '',
        ROUTES.uniqueParameters,
        normalizePageParams(
          new UniqueParametersFilterParams({
            id: uniqueParameter?.id,
            searchValue: uniqueParameter?.id.toString(),
            searchField: 'id',
          }).toParams(),
        ),
      ];
    } else {
      this._entity = null;
    }
  }

  @Input() set params(value: IUniqueParametersFilterParams) {
    this.type = 'link';
    if (value) {
      this.link = [
        '',
        ROUTES.uniqueParameters,
        normalizePageParams(new UniqueParametersFilterParams(value).toParams()),
      ];
    }
  }

  type: 'entity' | 'link';
  _entity: IdNameDto;
  link: any[];

  constructor(public permissionService: PermissionService) {}
}
