import { TablePageFilter } from '../table-page-filter-base';
import { IFunctionalTypesFilterParams } from '@common/models/filters';
import { FilterAttributeData, RangeDateDto } from '@common/dto';
import { formatRangeDate } from '@shared/utils';

const DEFAULT_PARAMS: IFunctionalTypesFilterParams = {
  isShowDeleted: false,
};

export class FunctionalTypesFilterParams extends TablePageFilter<IFunctionalTypesFilterParams> {
  id: number;
  name: string;
  code: string;
  description: string;
  disciplineIds: number[];
  uniqueParameterIds: number[];
  isShowDeleted: boolean;
  isMaterial: boolean;
  searchString: string;
  familyIds: number[];
  materialIds: number[];
  bindingIds: number[];
  parameterIds: number[];
  requestIds: number[];
  standardIds: number[];
  withAttributesOnly: boolean;
  attributes: FilterAttributeData[];
  valueStrFromFilterAsMask: boolean;
  withAllDisciplines: boolean;
  authorIds: number[];
  lastEditorIds: number[];
  created: RangeDateDto;
  modified: RangeDateDto;

  constructor(filter?: IFunctionalTypesFilterParams) {
    super(filter, DEFAULT_PARAMS);
  }

  toObject(): IFunctionalTypesFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      name: this.name,
      code: this.code,
      description: this.description,
      disciplineIds: this.disciplineIds,
      uniqueParameterIds: this.uniqueParameterIds,
      isShowDeleted: this.isShowDeleted,
      isMaterial: this.isMaterial,
      searchString: this.searchString,
      familyIds: this.familyIds,
      materialIds: this.materialIds,
      bindingIds: this.bindingIds,
      parameterIds: this.parameterIds,
      requestIds: this.requestIds,
      withAttributesOnly: this.withAttributesOnly,
      attributes: this.attributes,
      standardIds: this.standardIds,
      valueStrFromFilterAsMask: this.valueStrFromFilterAsMask,
      withAllDisciplines: this.withAllDisciplines,
      authorIds: this.authorIds,
      lastEditorIds: this.lastEditorIds,
      created: formatRangeDate(this.created),
      modified: formatRangeDate(this.modified),
    };
  }
}
