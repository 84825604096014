import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-condition-link',
  templateUrl: './condition-link.component.html',
  styleUrls: ['./condition-link.component.scss'],
})
export class ConditionLinkComponent {
  @Input() access: boolean;
  __link: any;
  @Input() set link(value: any) {
    value.forEach((item) => {
      if (typeof item === 'object' && item !== null) {
        Object.entries(item).forEach(([key, value]) => {
          if (typeof value !== 'string') return;
          if (value?.includes('true')) {
            item[key] = true;
          }
          if (value?.includes('false')) {
            item[key] = false;
          }
        });
      }
    });

    this.__link = value;
  }
  get link() {
    return this.__link;
  }
  onClick(event) {
    if (event.ctrlKey) {
      event.stopPropagation();
      event.preventDefault();
    }
  }
}
