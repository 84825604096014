import { NgModule } from '@angular/core';
import { AttributesListControlComponent } from './attributes-list-control.component';
import { MultiSelectModule } from '@shared/components/controls/multi-select/multi-select.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { PikSelectModule } from '@pik-ui/ng-components';

@NgModule({
  declarations: [AttributesListControlComponent],
  imports: [MultiSelectModule, ReactiveFormsModule, TranslateModule, CommonModule, PikSelectModule],
  exports: [AttributesListControlComponent],
})
export class AttributesListControlModule {}
