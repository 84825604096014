import { NavigationCategory } from '@common/interfaces/Navigation';
import { NavigationCategories } from '@common/enums';

import { NAVIGATION_ITEMS } from './navigatoin-items';
import { Permissions } from '@core/account/types';

export const NAVIGATION_LIST: NavigationCategory[] = [
  {
    type: NavigationCategories.Access,
    title: 'ACCESS',
    icon: 'settings',
    pages: NAVIGATION_ITEMS.filter((item) => item.type === NavigationCategories.Access),
  },
  {
    type: NavigationCategories.Dictionaries,
    title: 'CATALOGS',
    icon: 'folder',
    pages: NAVIGATION_ITEMS.filter((item) => item.type === NavigationCategories.Dictionaries),
    permission: Permissions.VisibilityOfCatalogs,
  },
  {
    type: NavigationCategories.Parameters,
    title: 'PARAMETERS',
    icon: 'parameters',
    pages: NAVIGATION_ITEMS.filter((item) => item.type === NavigationCategories.Parameters),
  },
  {
    type: NavigationCategories.Elements,
    title: 'ELEMENTS',
    icon: 'elements',
    pages: NAVIGATION_ITEMS.filter((item) => item.type === NavigationCategories.Elements),
  },
  {
    type: NavigationCategories.Services,
    title: 'SERVICES',
    icon: 'square',
    pages: NAVIGATION_ITEMS.filter((item) => item.type === NavigationCategories.Services),
  },
];

export const PINNED_NAVIGATION_LIST: NavigationCategory = {
  type: NavigationCategories.Pinned,
  title: 'PINNED',
  icon: 'pin',
  pages: [],
};
