import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { BindingSourceType, UnitTypeDto } from '@common/dto';
import { DictionariesService } from '@services';
import { Store } from '@ngxs/store';
import { AppState } from '../../../../store';
import { TypedFormControl } from '@common/interfaces';

@Component({
  selector: 'app-unit-type-control',
  templateUrl: './unit-type-control.component.html',
  styleUrls: ['./unit-type-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UnitTypeControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitTypeControlComponent implements OnDestroy, ControlValueAccessor, OnInit {
  @Input() bindingSourceType: BindingSourceType;

  public readonly entityControl: TypedFormControl<number> = new FormControl();
  public selectedEntity: UnitTypeDto;
  public isDisable: boolean;
  private _destroy$ = new Subject();
  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(
    public readonly dictionariesService: DictionariesService,
    private readonly _changeDetector: ChangeDetectorRef,
    private readonly store: Store,
  ) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this._subscribeToEntityControl().subscribe();
    this.dictionariesService.loadUnitTypes(this.bindingSourceType);
  }

  writeValue(value: number) {
    this.dictionariesService.unitTypes$
      .pipe(
        filter((f) => !!f?.length),
        take(1),
        tap((unitTypes) => {
          this.selectedEntity = unitTypes.find((item) => value === item.id);

          if (!value) {
            value = null;
            this.selectedEntity = null;
          }

          this.entityControl.setValue(value, { emitEvent: false });
          this._changeDetector.detectChanges();
        }),
      )
      .subscribe();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  nextChangeValue(value: number): void {
    if (this._onChange) {
      this._onChange(value);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisable = isDisabled;
    this._changeDetector.markForCheck();
  }

  private _subscribeToEntityControl() {
    return this.entityControl.valueChanges.pipe(
      tap((value) => {
        const unitTypes = this.store.selectSnapshot(AppState.unitTypes);
        this.selectedEntity = unitTypes.find((f) => f.id === value);
        this.nextChangeValue(value);
      }),
      takeUntil(this._destroy$),
    );
  }
}
