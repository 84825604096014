import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { take, tap } from 'rxjs/operators';

import { FamilyChatService } from '@services';
import { ChatParams, MessageDto, UserDto } from '@common/dto';

@Component({
  selector: 'app-chat-message-form',
  templateUrl: './chat-message-form.component.html',
  styleUrls: ['./chat-message-form.component.scss'],
})
export class ChatMessageFormComponent {
  @Input() chatParams: ChatParams;
  @Output() sended = new EventEmitter<MessageDto>();

  form: UntypedFormGroup = new UntypedFormGroup({
    message: new UntypedFormControl(null, Validators.required),
    additionalReceiverIds: new UntypedFormControl([]),
  });

  constructor(private familyChatService: FamilyChatService) {}

  onChangeReceivers(receivers: UserDto[]) {
    const ids = receivers.map((item) => item.id);
    this.form.controls.additionalReceiverIds.setValue(ids);
  }

  public formSubmit() {
    const message = {
      message: this.form.value.message.trim(),
      additionalReceiverIds: this.form.value.additionalReceiverIds,
    } as MessageDto;
    if (this.chatParams.chatMessageType) {
      message.chatMessageType = this.chatParams.chatMessageType;
    }
    if (this.chatParams.messageObjectId) {
      message.messageObjectId = this.chatParams.messageObjectId;
    }
    if (this.form.valid) {
      message['chatMessageTypeId'] = message.chatMessageType;
      this.familyChatService
        .sendMessage(message)
        .pipe(
          take(1),
          tap((m) => this.sended.emit(m)),
        )
        .subscribe();
      this.form.reset();
    }
  }
}
