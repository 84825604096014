import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SheetExportEnum } from './types';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PikModalComponent } from '@pik-ui/ng-components';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-sheet-buttons',
  templateUrl: './sheet-buttons.component.html',
  styleUrls: ['./sheet-buttons.component.scss'],
})
export class SheetButtonsComponent {
  @Input() isImportShow: boolean;
  @Input() isExportShow = true;
  @Input() exportedType: SheetExportEnum;
  @Input() pending: boolean;
  @Input() showHelpButton = true;
  @Output() importGoogle: EventEmitter<any> = new EventEmitter<any>();
  @Output() importGoogleLink: EventEmitter<any> = new EventEmitter<any>();
  @Output() importExcel: EventEmitter<File> = new EventEmitter<File>();
  @Output() exportSheet: EventEmitter<SheetExportEnum> = new EventEmitter<SheetExportEnum>();
  @ViewChild('modal', { static: false }) modal: PikModalComponent;
  isGoogleIntegrationEnabled = environment.isGoogleIntegrationEnabled;
  SheetExportEnum = SheetExportEnum;

  form: UntypedFormGroup = this._formBuilder.group({
    link: ['', Validators.required],
  });

  constructor(private _formBuilder: UntypedFormBuilder) {}

  selectFile(fileList: FileList) {
    const file = fileList?.item(0) || null;
    if (file) {
      this.importExcel.emit(file);
    }
  }

  onSubmit() {
    if (this.form.value.link) {
      this.form.markAllAsTouched();
      if (this.form.value.link.includes('spreadsheets/d/') && this.form.value.link.includes('/edit')) {
        const id = this.form.value.link.split('spreadsheets/d/')[1].split('/edit')[0];
        this.importGoogleLink.emit(id);
      } else if (this.form.value.link.includes('spreadsheets/d/') && this.form.value.link.includes('/view')) {
        const id = this.form.value.link.split('spreadsheets/d/')[1].split('/view')[0];
        this.importGoogleLink.emit(id);
      } else {
        this.importGoogleLink.emit(this.form.value.link);
      }
      this.modal.close();
    }
  }
}
