import { PIK_MODAL_DATA, PikModalRef } from '@pik-ui/ng-components';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'fm-modal-google-sheet',
  templateUrl: './fm-modal-google-sheet.component.html',
  styleUrls: ['./fm-modal-google-sheet.component.scss'],
})
export class FmModalGoogleSheetComponent {
  constructor(
    public dialogRef: PikModalRef<FmModalGoogleSheetComponent>,
    @Inject(PIK_MODAL_DATA) public data: { googleId },
  ) {}
}
