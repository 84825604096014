import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import * as _ from 'lodash';

/**
 * Проверяет состояние формы на изменения
 * @param form - форма
 * @param initialValue - начальное состояние формы с чем сравнивать при изменении
 * @return Observable
 */
export function checkDirtyForm(form: UntypedFormGroup, initialValue?: any): Observable<boolean> {
  const initial = { ...form.getRawValue() };

  return form.valueChanges.pipe(
    startWith(null),
    distinctUntilChanged(),
    map(() => {
      return _.isEqual(_.omitBy(initial, _.isNil), _.omitBy(form.getRawValue(), _.isNil));
    }),
  );
}
