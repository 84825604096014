import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import {
  AttachmentDto,
  FamilySymbolSetDto,
  FamilyVersionDto,
  FullVersionParameterDto,
  IdNameDto,
  LiteVersionParameterDto,
  MaterialLightDto,
  PatchOperation,
  VersionSymbolDto,
} from '@common/dto';
import { ApiPaths } from '@common/consts';
import { FamilyVersionFTParametersFilterParams, FamilyVersionParameterFilterParams } from '@common/models/filters';

@Injectable({ providedIn: 'root' })
export class VersionsApiService {
  constructor(private http: HttpClient, private _sanitized: DomSanitizer) {}

  public getFamilyVersion(versionId: number): Observable<FamilyVersionDto> {
    return this.http.get<FamilyVersionDto>(`${ApiPaths.Versions}/${versionId}`);
  }

  public getFamilyVersionParameters(
    id: number,
    filter?: FamilyVersionParameterFilterParams,
  ): Observable<LiteVersionParameterDto[]> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<LiteVersionParameterDto[]>(`${ApiPaths.Versions}/sets/familyVersionParameters`, {
      ...params,
      parameterValueSetId: id,
    });
  }

  public getFamilyVersionFunctionalTypeParameters(
    setId: number,
    functionalTypeId,
    filter?: FamilyVersionFTParametersFilterParams,
  ): Observable<FullVersionParameterDto[]> {
    const params = filter ? filter.toApiParams() : {};
    let httpParams = new HttpParams().set('functionalTypeId', functionalTypeId);
    return this.http.post<FullVersionParameterDto[]>(
      `${ApiPaths.Versions}/sets/${setId}/functionalTypeVersionParameters`,
      { ...params },
      { params: httpParams },
    );
  }

  public batchDeleteVersions(versionIds: number[]): Observable<void> {
    return this.http.post<void>(`${ApiPaths.Versions}/batchDelete`, versionIds);
  }

  public getVersionSymbols(versionId: number): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.Versions}/${versionId}/symbols`);
  }

  public getVersionSymbolsWithParameters(
    versionId: number,
    includeMissingParameters: boolean = false,
    functionalTypeId?: number,
  ): Observable<VersionSymbolDto[]> {
    const params = {
      includeMissingParameters,
    };

    if (functionalTypeId) {
      params['functionalTypeId'] = functionalTypeId.toString();
    }

    return this.http.get<VersionSymbolDto[]>(`${ApiPaths.Versions}/${versionId}/symbolsWithParameters`, {
      params,
    });
  }

  public getVersionAttachments(versionId: number): Observable<AttachmentDto[]> {
    return this.http.get<AttachmentDto[]>(`${ApiPaths.Versions}/${versionId}/attachments`);
  }

  public async downloadAllVersionAttachments(versionId: number) {
    const file = await this.getAllVersionAttachments(versionId).toPromise();
    saveAs(file, `Files ${versionId}`);
  }

  public getVersionAttachment(versionId: number, attachmentId: number) {
    return this.http.get(this.getAttachmentUrl(versionId, attachmentId), {
      responseType: 'blob',
    });
  }

  public getAttachmentUrl(versionId: number, attachmentId: number): string {
    return `${ApiPaths.Versions}/${versionId}/attachments/${attachmentId}`;
  }

  public getAllVersionAttachments(versionId: number) {
    return this.http.get(`${ApiPaths.Versions}/${versionId}/attachments/batch`, {
      responseType: 'blob',
    });
  }

  public downloadFamilyVersion(versionId: number) {
    return this.http.get(`${ApiPaths.Versions}/${versionId}/file`, { responseType: 'blob' });
  }

  public removeVersionAttachment(versionId: number, attachmentId: number) {
    return this.http.delete<{ id: number; name: string }[]>(
      `${ApiPaths.Versions}/${versionId}/attachments/${attachmentId}`,
    );
  }

  public loadVersionAttachments(versionId: number, files: File[]): Observable<AttachmentDto[]> {
    const formData = new FormData();
    files.forEach((file) => formData.append('files', file));

    return this.http.post<AttachmentDto[]>(`${ApiPaths.Versions}/${versionId}/attachments`, formData);
  }

  public getFamilyVersionPreview(versionId: number) {
    return this.getFamilyVersionPreviewAsync(versionId).pipe(
      take(1),
      map((data) =>
        data
          ? {
              file: data,
              styleUrl: this._sanitized.bypassSecurityTrustStyle('url(' + URL.createObjectURL(data) + ')'),
              url: this._sanitized.bypassSecurityTrustUrl(URL.createObjectURL(data)),
              resourceUrl: this._sanitized.bypassSecurityTrustResourceUrl(URL.createObjectURL(data)),
            }
          : null,
      ),
      catchError(() => of(null)),
    );
  }

  getFamilyVersionPreviewAsync(versionId: number) {
    return this.http.get(`${ApiPaths.Versions}/${versionId}/preview`, { responseType: 'blob' });
  }

  public uploadFamilyVersionPreview(versionId: number, preview: File): Observable<void> {
    const formData = new FormData();
    formData.append('preview', preview);
    formData.append('family', preview);

    return this.http.post<void>(`${ApiPaths.Versions}/${versionId}/file`, formData);
  }

  public patchFamilyVersion(versionId: number, patchData: PatchOperation[]): Observable<FamilyVersionDto> {
    return this.http.patch<FamilyVersionDto>(`${ApiPaths.Versions}/${versionId}`, patchData);
  }

  public createGoogleTable(versionId: number, functionalTypeId: number) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.post(`${ApiPaths.Versions}/${versionId}/functionalTypes/${functionalTypeId}/sheets`, null, {
      headers,
      responseType: 'text',
    });
  }

  public createExcelTable(versionId: number, functionalTypeId: number): Observable<Blob> {
    return this.http.post(`${ApiPaths.Versions}/${versionId}/functionalTypes/${functionalTypeId}/excel`, null, {
      responseType: 'blob',
    });
  }

  public loadFromGoogleTables(versionId: number, functionalTypeId: number, sheetId: string): Observable<string> {
    return this.http.put<string>(
      `${ApiPaths.Versions}/${versionId}/functionalTypes/${functionalTypeId}/sheets/${sheetId}`,
      null,
    );
  }

  public importExcelSheet(versionId: number, functionalTypeId: number, file: File) {
    const data = new FormData();
    data.append('file', file);
    return this.http.put(`${ApiPaths.Versions}/${versionId}/functionalTypes/${functionalTypeId}/excel`, data);
  }

  public getSymbolsWithSets(versionId: number): Observable<FamilySymbolSetDto[]> {
    return this.http.get<FamilySymbolSetDto[]>(`${ApiPaths.Versions}/${versionId}/symbolsWithSets`);
  }

  public getVersionMaterials(versionId: number): Observable<MaterialLightDto[]> {
    return this.http.get<MaterialLightDto[]>(`${ApiPaths.Versions}/${versionId}/materials`);
  }
}
