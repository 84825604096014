<div class="sheet-wrap">
  <pik-button-group>
    <pik-loader *ngIf="pending"></pik-loader>
    <button pikButton appearance="inverse" [pikDropdownTrigger]="dropdownMenu" [disabled]="pending">
      <svg-icon pikButtonIcon position="before" src="./assets/icons/upload-cloud.svg"></svg-icon>
      {{ 'COMMON_LABELS.OPEN_IN_SHEET' | translate }}
    </button>

    <ng-container [ngSwitch]="exportedType" *ngIf="isImportShow && isExportShow">
      <ng-container *ngIf="isGoogleIntegrationEnabled">
        <button pikButton appearance="inverse" [pikDropdownTrigger]="dropdownMenuGoogle" [disabled]="pending">
          <svg-icon pikButtonIcon position="before" src="./assets/icons/download-cloud.svg"></svg-icon>
          {{ 'COMMON_LABELS.LOAD_FROM_GOOGLE_SHEET' | translate }}
        </button>
      </ng-container>

      <ng-container *ngIf="isExportShow">
        <input
          #inputRef
          type="file"
          class="cdk-visually-hidden"
          accept=".xlsx"
          onclick="this.value = null"
          (change)="selectFile($event.target.files)"
        />

        <button pikButton type="file" appearance="inverse" [disabled]="pending" (click)="inputRef.click()">
          <svg-icon pikButtonIcon position="before" src="./assets/icons/download-cloud.svg"></svg-icon>
          {{ 'COMMON_LABELS.LOAD_FROM_EXCEL_SHEET' | translate }}
        </button>
      </ng-container>
    </ng-container>
  </pik-button-group>

  <fm-hint
    *ngIf="showHelpButton"
    link="https://fm-help.bimteam.app/{{ 'LOCALIZATION' | translate }}/web/bulk"
  ></fm-hint>
</div>

<pik-dropdown #dropdownMenu="pikDropdown">
  <pik-menu>
    <pik-menu-item *ngIf="isGoogleIntegrationEnabled" (click)="exportSheet.emit(SheetExportEnum.Google)"
      >Google</pik-menu-item
    >
    <pik-menu-item (click)="exportSheet.emit(SheetExportEnum.Excel)">Excel</pik-menu-item>
  </pik-menu>
</pik-dropdown>

<pik-dropdown #dropdownMenuGoogle="pikDropdown">
  <pik-menu>
    <ng-container [ngSwitch]="exportedType">
      <ng-container *ngSwitchCase="SheetExportEnum.Google">
        <pik-menu-item (click)="importGoogle.emit()">{{ 'COMMON_LABELS.CURRENT_TABLE' | translate }}</pik-menu-item>
      </ng-container>
    </ng-container>
    <pik-menu-item (click)="modal.open()">{{ 'COMMON_LABELS.PROVIDE_LINK' | translate }}</pik-menu-item>
  </pik-menu>
</pik-dropdown>

<pik-modal #modal maxWidth="400px">
  <h3 pikModalTitle>{{ 'COMMON_PHRASE.PROVIDE_A_LINK' | translate }}</h3>
  <form pikForm [formGroup]="form" (ngSubmit)="onSubmit()">
    <fieldset>
      <pik-form-control>
        <input pikInput formControlName="link" type="text" class="modal-input" />
        <pik-error *ngIf="form.controls.link.touched && form.controls.link.errors?.required">{{
          'COMMON_LABELS.REQUIRED_FIELD' | translate
        }}</pik-error>
      </pik-form-control>
    </fieldset>

    <button size="medium" pikButton>{{ 'COMMON.SEND' | translate }}</button>
  </form>
</pik-modal>
