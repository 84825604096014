import { TablePageFilter } from '../table-page-filter-base';
import { IUniqueParametersFilterParams } from '../index';

const DEFAULT_PARAMS: IUniqueParametersFilterParams = {
  defaultSortBy: 'name',
};

export class UniqueParametersFilterParams extends TablePageFilter<IUniqueParametersFilterParams> {
  id: number;
  name: string;
  dataTypeIds: number[];
  unitTypeIds: number[];
  functionalTypeIds: number[];
  materialIds: number[];
  parameterIds: number[];
  isAddress: boolean;
  isShowDeleted: boolean;
  description: string;
  value: string;
  source: string;
  searchString: string;

  constructor(filter?: IUniqueParametersFilterParams) {
    super(filter, DEFAULT_PARAMS);
  }

  toObject(): IUniqueParametersFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      name: this.name,
      dataTypeIds: this.dataTypeIds,
      unitTypeIds: this.unitTypeIds,
      functionalTypeIds: this.functionalTypeIds,
      materialIds: this.materialIds,
      parameterIds: this.parameterIds,
      isAddress: this.isAddress,
      isShowDeleted: this.isShowDeleted,
      description: this.description,
      value: this.value,
      source: this.source,
      searchString: this.searchString,
    };
  }
}
