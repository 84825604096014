import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { debounceTime, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PikAutocompleteSelectedEvent } from '@pik-ui/ng-components';

import { BindingSourceType, IdNameDto } from '@common/dto';
import { NomenclatureTypesApiService } from '@services/api';
import { INomenclaturesFilterParams, NomenclatureTypesFilterParams } from '@common/models/filters';
import { TypedFormControl } from '@common/interfaces';

@Component({
  selector: 'app-nomenclature-types-control',
  templateUrl: './nomenclature-types-control.component.html',
  styleUrls: ['./nomenclature-types-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NomenclatureTypesControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NomenclatureTypesControlComponent implements OnDestroy, ControlValueAccessor, OnInit {
  @Input() sourceType: BindingSourceType;
  @Input() filter: INomenclaturesFilterParams = {};

  list: IdNameDto[] = [];
  nameControl: TypedFormControl<string> = new FormControl<string>(null);
  entities$ = new Subject<PikAutocompleteSelectedEvent[]>();

  private _destroy$ = new Subject();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(private _changeDetector: ChangeDetectorRef, private nomenclatureTypesApi: NomenclatureTypesApiService) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this._subscribeToNameControl().subscribe();
  }

  writeValue(value: number[]) {
    if (value) {
      this._getEntities(value)
        .pipe(tap(() => this._changeDetector.markForCheck()))
        .subscribe();
    } else {
      this.list = [];
    }
    this.nameControl.setValue(null, { emitEvent: false });
    this._changeDetector.detectChanges();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  removeItem(item: IdNameDto) {
    this.list = this.list.filter((ft) => ft.id !== item.id);
    this.nextChangeValue();
  }

  pushItem(event: PikAutocompleteSelectedEvent) {
    setTimeout(() => this.nameControl.setValue(''));

    const newItem: IdNameDto = event.value;

    if (!this.list.some((s) => s.id === newItem.id)) {
      this.list.push(event.value);
      this.nextChangeValue();
    }
  }

  nextChangeValue(): void {
    if (this._onChange) {
      this._onChange(this.list.map((i) => i.id));
    }
  }

  private _subscribeToNameControl() {
    return this.nameControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap((value) => (value === '' ? of([]) : this._searchQuery(value))),
      map((result: IdNameDto[]) =>
        result.map((item) => ({
          label: item.name,
          value: item,
        })),
      ),
      tap((data) => this.entities$.next(data)),
      takeUntil(this._destroy$),
    );
  }

  private _getEntities(ids: number[]) {
    this.list = [];
    return ids.length
      ? this.nomenclatureTypesApi.getIdNames(ids).pipe(tap((entities) => (this.list = [...entities])))
      : of([]);
  }

  private _searchQuery(name) {
    return this.nomenclatureTypesApi
      .getList(new NomenclatureTypesFilterParams({ ...this.filter, sourceType: this.sourceType, name }))
      .pipe(map((data) => data.result.map((f) => ({ id: f.id, name: f.name }))));
  }
}
