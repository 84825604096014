import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { IdNameDto } from '@common/dto';
import { StandardsApiService } from '@services/api/standards';
import { StandardsFilterParams } from '@common/models/filters';
import { TypedFormControl } from '@common/interfaces';

@Component({
  selector: 'app-standard-control',
  templateUrl: './standard-control.component.html',
  styleUrls: ['./standard-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StandardControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandardControlComponent implements OnDestroy, ControlValueAccessor, OnInit {
  public isListStandards = false;
  public readonly entityControl: TypedFormControl<number> = new FormControl();
  public isDisable: boolean;

  @Input() set standards(value: IdNameDto[]) {
    if (value) {
      this.isListStandards = true;
      this.entityList$.next(value);
    }
  }

  public entityList$ = new BehaviorSubject<IdNameDto[]>([]);

  private _destroy$ = new Subject();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(private _changeDetector: ChangeDetectorRef, private standardsApi: StandardsApiService) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this.loadStandards();
    this._subscribeToEntityControl().subscribe();
  }

  writeValue(value: number) {
    this.entityList$.pipe(
      filter((f) => !!f?.length),
      take(1),
    );

    if (!value) {
      value = null;
    }

    this.entityControl.setValue(value);
    this._changeDetector.detectChanges();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  nextChangeValue(value: number): void {
    if (this._onChange) {
      this._onChange(value);
    }
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.entityControl.disable() : this.entityControl.enable();
  }

  private _subscribeToEntityControl() {
    return this.entityControl.valueChanges.pipe(
      tap((value) => this.nextChangeValue(value)),
      takeUntil(this._destroy$),
    );
  }

  private loadStandards() {
    this.standardsApi
      .getList(new StandardsFilterParams({ limit: -1 }))
      .pipe(
        map((data) => data.result.map((s) => ({ id: s.id, name: s.name }))),
        tap((standards) => this.entityList$.next(standards)),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }
}
