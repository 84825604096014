import { Component, Input } from '@angular/core';

import { ThemeColors } from '@common/consts';
import { FamilyType } from '@common/enums';

export enum FamilyTypeLabel {
  RevitSystem = 'COMMON_LABELS.SYSTEMIC',
  RevitLoad = 'COMMON_LABELS.DOWNLOADABLE',
  RevitGroup = 'COMMON_LABELS.REVIT_GROUP',
  Dwg = 'COMMON_LABELS.DWG_FILE',
}

@Component({
  selector: 'app-family-type-tag',
  templateUrl: './family-type-tag.component.html',
  styleUrls: ['./family-type-tag.component.scss'],
})
export class FamilyTypeTagComponent {
  colors = ThemeColors;
  FamilyTypeLabel = FamilyTypeLabel;

  @Input() type: FamilyType;
}
