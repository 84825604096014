<div class="download-apps">
  <div class="download-apps__loading" *ngIf="pending">
    <span>{{ 'COMMON.LOADING' | translate }}</span> <pik-loader></pik-loader>
  </div>
  <pik-button-group>
    <button
      pikButton
      appearance="inverse"
      *ngIf="permissionService.access.AppVersionDownload"
      (click)="download(AppType.Revit)"
      [disabled]="pending"
    >
      <svg-icon svgClass="blue" position="before" pikButtonIcon src="./assets/icons/table-settings.svg"></svg-icon>
      {{ 'DOWNLOAD_BUTTON.FOR_REVIT' | translate }}
    </button>
    <button
      pikButton
      appearance="inverse"
      *ngIf="permissionService.access.AppVersionDownload"
      (click)="download(AppType.Civil)"
      [disabled]="pending"
    >
      <svg-icon svgClass="blue" position="before" pikButtonIcon src="./assets/icons/table-settings.svg"></svg-icon>
      {{ 'DOWNLOAD_BUTTON.FOR_CIVIL' | translate }}
    </button>
  </pik-button-group>
</div>
