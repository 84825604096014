import { IParameterRangeValueControlModel } from '../parameter-range-value-control.model';

export function generateRangeValue(data: IParameterRangeValueControlModel): string {
  return (
    (data.fromInclude ? '[' : '(') +
    (data.fromValue || '') +
    '..' +
    (data.toValue || '') +
    (data.toInclude ? ']' : ')') +
    (data.hasStep ? `..${data.step || 0}` : '')
  );
}
