import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ROUTES_PARTS } from '@common/consts';
import { ConditionLinkModule } from '@shared/components/condition-link';
import { PikTooltipModule } from '@pik-ui/ng-components';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-material-link',
  standalone: true,
  imports: [CommonModule, ConditionLinkModule, PikTooltipModule, TranslateModule],
  templateUrl: './material-link.component.html',
})
export class MaterialLinkComponent {
  @Input() set id(value: number) {
    this.link = ['', ROUTES_PARTS.MATERIALS, value];
  }

  public link: any;
}
