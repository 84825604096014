import { NgModule } from '@angular/core';
import { ConditionLinkComponent } from './condition-link.component';
import { RouterLinkWithHref } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LinkTabDirective } from '@shared/directives';

@NgModule({
  declarations: [ConditionLinkComponent],
  imports: [RouterLinkWithHref, CommonModule, LinkTabDirective],
  exports: [ConditionLinkComponent],
})
export class ConditionLinkModule {}
