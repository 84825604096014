import { IRolesFilterParams } from '@common/models/filters';
import { RoleType } from '@common/enums';
import { TablePageFilter } from '../table-page-filter-base';

const DEFAULT_PARAMS: IRolesFilterParams = {
  defaultSortBy: 'name',
};

export class RolesFilterParams extends TablePageFilter<IRolesFilterParams> {
  id: number;
  name: string;
  description: string;
  permissions: number[];
  userIds: number[];
  types: RoleType[];
  searchString: string;

  constructor(filter?: IRolesFilterParams) {
    super(filter, DEFAULT_PARAMS);
  }

  toObject(): IRolesFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      name: this.name,
      description: this.description,
      permissions: this.permissions,
      userIds: this.userIds,
      types: this.types,
      searchString: this.searchString,
    };
  }
}
