<ng-container *ngIf="!isDisable; else familyView">
  <input
    pikInput
    [formControl]="nameControl"
    [pikAutocomplete]="ftInput"
    placeholder="{{ 'COMPONENT_FAMILIES_CONTROL' | translate }}"
  />

  <pik-autocomplete
    [standalone]="false"
    #ftInput="pikAutocomplete"
    (optionSelected)="selectEntity($event)"
    [dataSource]="families$ | async"
  >
  </pik-autocomplete>
</ng-container>

<ng-template #familyView>
  <app-family-tag mode="single" [entity]="{ id: selectedFamily?.id, name: selectedFamily?.name }"></app-family-tag>
</ng-template>
