<form class="chatForm" [formGroup]="form" (ngSubmit)="formSubmit()">
  <markdown-editor
    placeholder="{{ 'COMPONENT_CHAT.WRITE_MESSAGE' | translate }}"
    formControlName="message"
    useMention
    useImages
    (mentionChange)="onChangeReceivers($event)"
  ></markdown-editor>
  <button pikButton appearance="link" type="submit" [attr.disabled]="form.invalid ? true : null">
    <svg-icon pikButtonIcon position="before" class="fmLogo" src="./assets/icons/send.svg"></svg-icon>
    {{ 'COMMON.SEND' | translate }}
  </button>
</form>
