import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class YandexMetrikaService {
  public action(id: string, params?) {
    if (environment.metrikaWebvisor) {
      (<any>window).ym(55939810, 'reachGoal', id, params);
    }
  }
}
