import { toParams } from '@common/utils';

export interface IFamilyCountersFilterParams {
  functionalTypeIds?: number[];
  disciplineIds?: number[];
  withDisciplinesOnly?: boolean;
}

export class FamilyCountersFilterParams {
  functionalTypeIds: number[];
  disciplineIds: number[];
  withDisciplinesOnly: boolean;

  constructor(filter?: IFamilyCountersFilterParams) {
    Object.assign(this, filter);
  }

  public toObject(): IFamilyCountersFilterParams {
    return {
      functionalTypeIds: this.functionalTypeIds,
      disciplineIds: this.disciplineIds,
      withDisciplinesOnly: this.withDisciplinesOnly,
    };
  }

  public toParams() {
    return toParams(this.toObject());
  }
}
