import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { SortOrder } from '@common/interfaces/SortOrder';

import { TableParams } from './table-params.model';
import { ITablePageFilter } from './table-page-filter.interface';
import { DEFAULT_PAGE_PARAMS } from '@common/consts';
import { omit } from 'lodash';

export abstract class TablePageFilter<T = ITablePageFilter> {
  public hasFilter$: Observable<boolean>;
  public sortBy: string;
  public defaultSortBy: string;
  public tableParams: TableParams;
  public searchField: string;
  public searchValue: string;
  public skipEmptyArrayFields = [];
  private defaultParams: T;

  set sortOrder(direction: any) {
    if (direction) {
      this._sortOrder = direction === 'asc' || direction === 'ascending' ? SortOrder.Ascending : SortOrder.Descending;
    } else {
      this.sortBy = this.defaultSortBy ? this.defaultSortBy : '';
      this._sortOrder = this.defaultSortBy ? SortOrder.Ascending : '';
    }
  }

  get sortOrder(): any {
    return this._sortOrder;
  }

  private _sortOrder: any;
  private _hasFilterSubject = new BehaviorSubject<boolean>(false);

  constructor(filter?: T, defaultParams?: T) {
    this.hasFilter$ = this._hasFilterSubject.asObservable();
    this.defaultParams = defaultParams ?? ({} as T);
    this.init({ ...DEFAULT_PAGE_PARAMS, ...defaultParams, ...filter });
  }

  init(initialValue?: T) {
    this.initTableParams();
    this.setFilter(initialValue);
  }

  public clear(): void {
    this.init();
  }

  public filterChanges() {
    this._hasFilterSubject.next(this.hasFilters());
  }

  public initTableParams() {
    this.tableParams = new TableParams();
  }

  public setFilter(filter: any) {
    if (filter) {
      Object.assign(this, filter);

      if (!filter.sortBy && filter.defaultSortBy) {
        this.sortBy = filter.defaultSortBy;
      }

      this.tableParams.setParams({
        limit: filter.limit,
        skip: filter.skip,
        pageIndex: filter.pageIndex,
      });

      this.filterChanges();
    }
  }

  public hasFilters(): boolean {
    const fieldsToIgnore = ['limit', 'pageIndex', 'skip', 'sortOrder', 'sortBy', 'searchField'];

    const notEmptyFilters = Object.keys(this.toObject())
      .filter((key) => !fieldsToIgnore.includes(key))
      .filter((key) => !this.isDefaultFilterValue(key, this[key]))
      .filter((key) => this[key] !== null && this[key] !== undefined && this[key] !== '')
      .filter((key) =>
        Array.isArray(this[key]) ? !!this[key].length || this.skipEmptyArrayFields.includes(key) : true,
      );

    return notEmptyFilters.length > 0;
  }

  public toQueryParams(): HttpParams {
    const params = this.toApiParams();
    return new HttpParams({ fromObject: params });
  }

  public toApiParams() {
    return omit(this.toParams() ?? {}, ['pageIndex', 'searchField', 'searchValue']);
  }

  public toParams(ignoreFalseFields?: string[]) {
    const objParams = this.toObject();

    return Object.keys(objParams)
      .filter((key) => {
        if (ignoreFalseFields && ignoreFalseFields.includes(key)) {
          return true;
        }

        return objParams[key] !== null && objParams[key] !== undefined && objParams[key] !== '';
      })
      .filter((key) =>
        Array.isArray(objParams[key]) ? !!objParams[key].length || this.skipEmptyArrayFields.includes(key) : true,
      )
      .reduce((obj, key) => {
        obj[key] = objParams[key];

        return obj;
      }, {});
  }

  public toObjectBase(): ITablePageFilter {
    return {
      limit: this.tableParams.limit,
      skip: this.tableParams.skip,
      pageIndex: this.tableParams.pageIndex,
      sortBy: this.sortBy,
      sortOrder: this.sortOrder,
      searchField: this.searchField,
      searchValue: this.searchValue,
    };
  }

  abstract toObject(): T;

  isDefaultFilterValue(key: string, value: any): boolean {
    return this.defaultParams === null ? false : this.defaultParams[key] === value;
  }
}
