import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';

import { DictionariesService } from '@services';
import { TypedFormControl } from '@common/interfaces';

@Component({
  selector: 'app-data-types-control',
  templateUrl: './data-types-control.component.html',
  styleUrls: ['./data-types-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DataTypesControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTypesControlComponent implements OnDestroy, ControlValueAccessor, OnInit {
  public readonly entityControl: TypedFormControl<number[]> = new FormControl([]);

  private _destroy$ = new Subject();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(public dictionariesService: DictionariesService, private _changeDetector: ChangeDetectorRef) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this.dictionariesService.loadDataTypes();
    this._subscribeToEntityControl().subscribe();
  }

  writeValue(value: number[]) {
    this.dictionariesService.dataTypes$
      .pipe(
        filter((f) => !!f?.length),
        take(1),
        tap(() => {
          if (!value) {
            value = [];
          }

          this.entityControl.setValue(value, { emitEvent: false });
          this._changeDetector.detectChanges();
        }),
      )
      .subscribe();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  nextChangeValue(value: number[]): void {
    if (this._onChange) {
      this._onChange(value);
    }
  }

  private _subscribeToEntityControl() {
    return this.entityControl.valueChanges.pipe(
      tap((data) => this.nextChangeValue(data)),

      takeUntil(this._destroy$),
    );
  }
}
