import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';

import { ValueSetDto } from '@common/dto';
import { VersionsApiService } from '@services/api';
import { TypedFormControl } from '@common/interfaces';
import { PikEllipsisTextModule, PikSelectModule } from '@pik-ui/ng-components';
import { AsyncPipe, NgForOf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-family-set-control',
  templateUrl: './family-set-control.component.html',
  styleUrls: ['./family-set-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FamilySetControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PikSelectModule, ReactiveFormsModule, AsyncPipe, PikEllipsisTextModule, TranslateModule, NgForOf],
})
export class FamilySetControlComponent implements OnDestroy, ControlValueAccessor, OnInit {
  @Input() set sets(value: ValueSetDto[]) {
    this.valueSets = value ?? [];

    if (!this.valueSets.length) {
      this.entityControl.setValue(null);
      this.entityControl.disable({ emitEvent: false });
    } else {
      const controlValue = this.valueSets.length ? value[0].id : null;

      this.entityControl.setValue(controlValue);
      this.entityControl.enable({ emitEvent: false });
    }
  }

  @Output() selectFull = new EventEmitter<ValueSetDto>();

  valueSets: ValueSetDto[] = [];
  entityControl: TypedFormControl<number> = new FormControl(null);

  private _destroy$ = new Subject();
  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(private _changeDetector: ChangeDetectorRef, private _versionsApi: VersionsApiService) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this.subscribeToEntityControl().subscribe();
  }

  writeValue(value: number) {
    this.entityControl.setValue(value, { emitEvent: false });
    this._changeDetector.detectChanges();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  nextChangeValue(id: number): void {
    if (this._onChange) {
      this._onChange(id);
      this.selectFull.emit(this.valueSets.find((s) => s.id === id));
    }
  }

  private subscribeToEntityControl() {
    return this.entityControl.valueChanges.pipe(
      tap((d) => this.nextChangeValue(d)),
      takeUntil(this._destroy$),
    );
  }
}
