import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import _ from 'lodash';

import { AttributeValueDto } from '@common/dto';
import { ROUTES, ThemeColors } from '@common/consts';
import { normalizePageParams } from '@common/utils';
import { PermissionService } from '@core/account/services';
import { AttributesFilterParams } from '@common/models/filters';

interface AttributeValueWithParams extends AttributeValueDto {
  params: any[];
}

@Component({
  selector: 'app-attributes-view',
  templateUrl: './attributes-view.component.html',
  styleUrls: ['./attributes-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributesViewComponent {
  @Input()
  set attributes(data: AttributeValueDto[]) {
    data = data?.length ? data : [];
    this._attributes = data.map((a) => {
      return {
        ...a,
        options: _.orderBy(a.options, 'name', 'asc'),
        params: [
          '',
          ROUTES.attributes,
          normalizePageParams(
            new AttributesFilterParams({
              id: a.attributeId,
              searchValue: a.attributeId.toString(),
              searchField: 'id',
            }).toParams(),
          ),
        ],
      };
    });
  }

  colors = ThemeColors;

  _attributes: AttributeValueWithParams[];

  constructor(public permissionService: PermissionService) {}
}
