/**
 * Преобразует значение в зависимости от полученного типа данных
 * @param recievedValue полученное значение может быть строкой или массивом
 * @param initialArray изначльный массив значений, с которым нужно слить полученные значения
 * @returns итоговый массив значений
 */
export function getEnvironmentArrayField(recievedValue: string | string[], initialArray: string[]): string[] {
  if (recievedValue) {
    if (Array.isArray(recievedValue)) {
      return recievedValue;
    } else {
      return [recievedValue];
    }
  } else {
    return initialArray;
  }
}
