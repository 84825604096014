<div class="dropDownBar">
  <div class="dropDownBar__bottomBarWrap" [ngClass]="{ bordered: bordered }">
    <button pikButton *ngIf="!showForm" (click)="addHandler()">{{ buttonText }}</button>
    <fm-edit-row-form
      *ngIf="showForm"
      (submit)="submit.emit($event)"
      (cancel)="cancelHandler()"
      [placeholder]="placeholder"
      [buttonText]="buttonText"
      [value]="null"
      [uniqueValues]="uniqueValues"
    ></fm-edit-row-form>
  </div>
</div>
