<app-multi-select
  *ngIf="!isDisabled; else disciplinesView"
  class="disciplines-select"
  [formControl]="disciplineControl"
  [options]="disciplinesList"
  (change)="nextChangeValue($event)"
  placeholder="{{ 'COMPONENT_DISCIPLINES_CONTROL' | translate }}"
>
</app-multi-select>

<ng-template #disciplinesView>
  <app-disciplines-tags [disciplines]="disciplineIdNames"></app-disciplines-tags>
</ng-template>
