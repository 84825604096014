import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { map, startWith } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { AttributeDto } from '@common/dto';
import { PikSearchInputComponent } from '@pik-ui/ng-components';

@Component({
  selector: 'app-attributes-list',
  templateUrl: './attributes-list.component.html',
  styleUrls: ['./attributes-list.component.scss'],
})
export class AttributesListComponent {
  @Input()
  set attributes(attributes: AttributeDto[]) {
    if (attributes && Array.isArray(attributes) && attributes.length > 0) {
      this._attributes = attributes;
      this._initSearch();
    }
  }

  @Output()
  add = new EventEmitter<AttributeDto>();

  @ViewChild(PikSearchInputComponent)
  searchRef: PikSearchInputComponent;

  filteredAttributes$: Observable<AttributeDto[]>;
  searchControl = new UntypedFormControl();

  private _attributes: AttributeDto[] = [];

  onAdd(item: AttributeDto) {
    this.add.emit(item);
    this.searchRef.reset();
  }

  private _initSearch() {
    this.filteredAttributes$ = this.searchControl.valueChanges.pipe(
      startWith(''),
      map((term: string) =>
        term ? this._attributes.filter((x) => x.name.toLowerCase().includes(term.toLowerCase())) : this._attributes,
      ),
    );
  }
}
