import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FunctionalTypesParametersFilterParams } from '@common/models/filters';
import { FunctionalTypeParameterDto, FunctionalTypeParameterUpdateDto, IdNameDto, PatchOperation } from '@common/dto';
import { CrudService, ResultPart } from '@common/interfaces';
import { ApiPaths } from '@common/consts';

@Injectable({ providedIn: 'root' })
export class FunctionalTypeParametersApiService
  implements
    CrudService<FunctionalTypeParameterDto, FunctionalTypeParameterUpdateDto, FunctionalTypeParameterUpdateDto>
{
  constructor(private http: HttpClient) {}

  public getList(filter: FunctionalTypesParametersFilterParams): Observable<ResultPart<FunctionalTypeParameterDto>> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<ResultPart<FunctionalTypeParameterDto>>(
      `${ApiPaths.FunctionalTypeParameters}/filter`,
      params,
    );
  }

  public getById(entityId: number): Observable<FunctionalTypeParameterDto> {
    return this.http.get<FunctionalTypeParameterDto>(`${ApiPaths.FunctionalTypeParameters}/${entityId}`);
  }

  public getParameters(functionalTypeId: number): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.FunctionalTypeParameters}/${functionalTypeId}/uniqueParameters`);
  }

  public create(entity: FunctionalTypeParameterUpdateDto): Observable<FunctionalTypeParameterDto> {
    return this.http.post<FunctionalTypeParameterDto>(`${ApiPaths.FunctionalTypeParameters}`, entity);
  }

  public update(entity: FunctionalTypeParameterUpdateDto): Observable<FunctionalTypeParameterDto> {
    return this.http.put<FunctionalTypeParameterDto>(`${ApiPaths.FunctionalTypeParameters}/${entity.id}`, entity);
  }

  public patch(id: number, data: PatchOperation[]): Observable<FunctionalTypeParameterDto> {
    return this.http.patch<FunctionalTypeParameterDto>(`${ApiPaths.FunctionalTypeParameters}/${id}`, data);
  }

  public remove(paramId: number): Observable<void> {
    return this.http.delete<void>(`${ApiPaths.FunctionalTypeParameters}/${paramId}`);
  }

  public createGoogleSheet(filter: FunctionalTypesParametersFilterParams): Observable<string> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<string>(`${ApiPaths.FunctionalTypeParameters}/sheets`, params);
  }

  public importSheet(sheetId: string) {
    return this.http.put(`${ApiPaths.FunctionalTypeParameters}/sheets/${sheetId}`, {});
  }

  public createExcelSheet(filter: FunctionalTypesParametersFilterParams): Observable<Blob> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post(`${ApiPaths.FunctionalTypeParameters}/excel`, params, { responseType: 'blob' });
  }

  public importExcelSheet(file: File) {
    const data = new FormData();
    data.append('file', file);
    return this.http.put(`${ApiPaths.FunctionalTypeParameters}/excel`, data);
  }

  public getFunctionalTypeUsage(functionalTypeId: number) {
    return this.http.get<any[]>(`${ApiPaths.FunctionalTypeParameters}/${functionalTypeId}/parameterRulesUsage`);
  }
}
