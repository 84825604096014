import { TablePageFilter } from '../table-page-filter-base';
import { IDisciplinesFilterParams } from './disciplines-filter.interface';

const DEFAULT_PARAMS: IDisciplinesFilterParams = {
  defaultSortBy: 'name',
};

export class DisciplinesFilterParams extends TablePageFilter<IDisciplinesFilterParams> {
  id: number;
  name: string;
  fullName: string;
  description: string;
  searchString: string;
  showDeleted: boolean;
  functionalTypeIds: number[];
  materialIds: number[];
  familyIds: number[];
  requestIds: number[];
  openedRequestsIds: number[];
  standardIds: number[];
  userIds: number[];

  constructor(filter?: IDisciplinesFilterParams) {
    super(filter, DEFAULT_PARAMS);
  }

  public toObject(): IDisciplinesFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      name: this.name,
      fullName: this.fullName,
      description: this.description,
      standardIds: this.standardIds,
      searchString: this.searchString,
      functionalTypeIds: this.functionalTypeIds,
      materialIds: this.materialIds,
      familyIds: this.familyIds,
      requestIds: this.requestIds,
      openedRequestsIds: this.openedRequestsIds,
      showDeleted: this.showDeleted,
      userIds: this.userIds,
    };
  }
}
