import { Component, Input } from '@angular/core';
import { ROUTES } from '@common/consts';
import { PermissionService } from '@core/account/services';
import { normalizePageParams } from '@common/utils';
import { IMaterialsFilterParams, MaterialsFilterParams } from '@common/models/filters';

@Component({
  selector: 'app-materials-link',
  templateUrl: './materials-link.component.html',
})
export class MaterialsLinkComponent {
  @Input() set params(value: IMaterialsFilterParams) {
    if (value) {
      this.link = ['', ROUTES.materialsList, normalizePageParams(new MaterialsFilterParams(value).toParams())];
    }
  }
  @Input() name: string;

  link: any[];

  constructor(public permissionService: PermissionService) {}
}
