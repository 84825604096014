import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { merge, Subject } from 'rxjs';

import {
  IParameterRangeValueControlModel,
  ParameterRangeValueControlModel,
} from './parameter-range-value-control.model';
import { takeUntil, tap } from 'rxjs/operators';
import { getValues } from './utils/get-values';
import { generateRangeValue } from './utils/generate-range-value';
import { ParameterValueControlService } from '@shared/components/controls/parameter-value-control/services';

const ZNumberPattern = /^-?[0-9]*$/;
const ZPositiveNumberPattern = /^[0-9]*$/;

const RNumberPattern = /^-?[0-9]*[.,]?[0-9]+$/;
const RPositiveNumberPattern = /^[0-9]*[.,]?[0-9]+$/;

@Component({
  selector: 'app-parameter-range-value-control',
  templateUrl: './parameter-range-value-control.component.html',
  styleUrls: ['./parameter-range-value-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ParameterRangeValueControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ParameterRangeValueControlComponent,
    },
  ],
})
export class ParameterRangeValueControlComponent implements OnDestroy, ControlValueAccessor, OnInit {
  @Input() set numberType(value: 'Z' | 'R') {
    this._numberType = value;
    this.setValidators();
  }
  @Input() index: number;
  @Input() validValues: string;

  @Output() remove = new EventEmitter<number>();

  _values: string[] = [];
  isRange = false;
  isDisable = false;
  rangeSwitchControl = new FormControl();
  numberValueControl = new FormControl(null);
  _numberType: 'Z' | 'R';

  form: ParameterRangeValueControlModel = new FormGroup(
    {
      fromInclude: new FormControl({ value: null, disabled: true }),
      fromValue: new FormControl(''),
      toInclude: new FormControl({ value: null, disabled: true }),
      toValue: new FormControl(''),
      hasStep: new FormControl(),
      step: new FormControl({ value: null, disabled: true }),
    },
    [this.sameRangeValueValidator(), this.rangeValueValidator()],
  );

  private _destroy$ = new Subject();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(
    private _changeDetector: ChangeDetectorRef,
    public parameterValueControlService: ParameterValueControlService,
  ) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this.subscribeToFromValue().subscribe();
    this.subscribeToToValue().subscribe();
    this.subscribeToValues().subscribe();
    this.subscribeToRangeSwitcher().subscribe();
    this.subscribeToHasStepControl().subscribe();
    this.subscribeToParameterValues().subscribe();
  }

  writeValue(value: string) {
    this.parseValue(value);
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisable = isDisabled;
    if (isDisabled) {
      this.form.disable();
      this.rangeSwitchControl.disable();
      this.numberValueControl.disable();
    } else {
      this.form.enable();
      this.rangeSwitchControl.enable();
      this.numberValueControl.enable();
    }
  }

  validate(): ValidationErrors | null {
    if (this.isRange) {
      return this.form.invalid ? { invalidValue: true } : null;
    }
    return this.numberValueControl.invalid ? { invalidValue: true } : null;
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  nextChangeValue(): void {
    if (this._onChange) {
      let value;

      if (this.rangeSwitchControl.value) {
        value = this.form.valid ? generateRangeValue(this.form.getRawValue()) : null;
      } else {
        value = this.numberValueControl.valid ? this.numberValueControl.value : null;
      }

      this._onChange(value);
    }
  }

  private parseValue(value: string) {
    value = value || '';
    if (value.includes('[') || value.includes('(')) {
      this.isRange = true;
      setTimeout(() => this.rangeSwitchControl.setValue(true));

      const values = getValues(value);
      const formValue: IParameterRangeValueControlModel = {
        fromInclude: value.includes('['),
        fromValue: values[0],
        toInclude: value.includes(']'),
        toValue: values[1],
        hasStep: value.split('..').length === 3,
        step: values[2] ?? '',
      };

      this.form.setValue(formValue, { emitEvent: false });

      setTimeout(() => {
        if (formValue.toValue) {
          this.form.controls.toInclude.enable();
        }

        if (formValue.fromValue) {
          this.form.controls.fromValue.enable();
        }

        if (formValue.hasStep) {
          this.form.controls.step.enable();
        }
      });

      return;
    }

    this.numberValueControl.setValue(value, { emitEvent: false });
  }

  private subscribeToHasStepControl() {
    return this.form.controls.hasStep.valueChanges.pipe(
      tap((value) => (value ? this.form.controls.step.enable() : this.form.controls.step.disable())),
      takeUntil(this._destroy$),
    );
  }

  private subscribeToFromValue() {
    return this.form.controls.fromValue.valueChanges.pipe(
      tap((value) => {
        if (value) {
          this.form.controls.fromInclude.enable();
          return;
        }
        this.form.controls.fromInclude.setValue(false);
        this.form.controls.fromInclude.disable();
      }),
      takeUntil(this._destroy$),
    );
  }

  private subscribeToToValue() {
    return this.form.controls.toValue.valueChanges.pipe(
      tap((value) => {
        if (value) {
          this.form.controls.toInclude.enable();
          return;
        }
        this.form.controls.toInclude.setValue(false);
        this.form.controls.toInclude.disable();
      }),
      takeUntil(this._destroy$),
    );
  }

  private subscribeToValues() {
    return merge(this.subscribeToRangeValueChanges(), this.subscribeToNumberValueChanges()).pipe(
      tap(() => this.nextChangeValue()),
      takeUntil(this._destroy$),
    );
  }

  private subscribeToRangeValueChanges() {
    return this.form.valueChanges.pipe(tap(() => this.form.updateValueAndValidity({ emitEvent: false })));
  }

  private subscribeToNumberValueChanges() {
    return this.numberValueControl.valueChanges.pipe(
      tap(() => this.numberValueControl.updateValueAndValidity({ emitEvent: false })),
    );
  }

  private subscribeToRangeSwitcher() {
    return this.rangeSwitchControl.valueChanges.pipe(
      tap((v) => {
        this.isRange = v;
        this.nextChangeValue();
      }),
      takeUntil(this._destroy$),
    );
  }

  sameValueValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this._values.filter((f) => control.value === f).length > 1 ? { sameValueError: true } : null;
    };
  }

  sameRangeValueValidator(): ValidatorFn {
    return (form: FormGroup): ValidationErrors | null => {
      return this._values.filter((f) => generateRangeValue(form.getRawValue()) === f).length > 1
        ? { sameValueError: true }
        : null;
    };
  }

  rangeValueValidator(): ValidatorFn {
    return (form: FormGroup): ValidationErrors | null => {
      const values: IParameterRangeValueControlModel = form.getRawValue();
      let error = null;

      const fromValue = values.fromValue !== '' ? +values.fromValue : Number.NEGATIVE_INFINITY;
      const toValue = values.toValue !== '' ? +values.toValue : Number.POSITIVE_INFINITY;

      if (fromValue >= toValue) {
        error = { rangeValueError: true };
      }

      return error;
    };
  }

  private getPattern(positive = false) {
    if (this._numberType === 'Z') {
      return positive ? ZPositiveNumberPattern : ZNumberPattern;
    } else {
      return positive ? RPositiveNumberPattern : RNumberPattern;
    }
  }

  private setValidators() {
    const numberControlValidators = [Validators.pattern(this.getPattern()), this.sameValueValidator()];

    if (!this.parameterValueControlService.allowEmptyValue) {
      numberControlValidators.push(Validators.required);
    }

    this.numberValueControl.setValidators(numberControlValidators);
    this.form.controls.step.setValidators(Validators.pattern(this.getPattern(true)));
    this.form.controls.fromValue.setValidators(Validators.pattern(this.getPattern()));
    this.form.controls.toValue.setValidators(Validators.pattern(this.getPattern()));
  }

  private subscribeToParameterValues() {
    return this.parameterValueControlService.values$.pipe(
      tap((values) => {
        this._values = values;

        if (this.numberValueControl) {
          this.numberValueControl.updateValueAndValidity({ emitEvent: false });
          return;
        }

        if (this.form) {
          this.form.updateValueAndValidity({ emitEvent: false });
        }
      }),
      takeUntil(this._destroy$),
    );
  }
}
