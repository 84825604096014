import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  ValidatorFn,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { IdValue } from '@common/dto';

@Component({
  selector: 'app-id-value-list-control',
  templateUrl: './id-value-list-control.component.html',
  styleUrls: ['./id-value-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IdValueListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdValueListControlComponent implements OnDestroy, ControlValueAccessor {
  valueList: Pick<IdValue, 'valueStr'>[] = [];
  control = new UntypedFormControl(null, [this.uniqueValueValidator()]);
  private _destroy$ = new Subject();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(private _changeDetector: ChangeDetectorRef) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  writeValue(valueList: IdValue[]) {
    this.valueList = valueList?.length ? valueList.map((v) => ({ valueStr: v.valueStr })) : [];
    this.control = new UntypedFormControl(null, this.uniqueValueValidator());
    this._changeDetector.detectChanges();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  removeItem(item: IdValue) {
    this.valueList = this.valueList.filter((v) => v.valueStr !== item.valueStr);
    const oldValue = this.control.value;
    this.control = new UntypedFormControl(oldValue, [this.uniqueValueValidator()]);
    this.nextChangeValue();
  }

  editItem(item: IdValue) {
    this.valueList = this.valueList.filter((v) => v.valueStr !== item.valueStr);
    this.control = new UntypedFormControl(item.valueStr, [this.uniqueValueValidator()]);
    this.nextChangeValue();
  }

  addItem() {
    const value = this.control.value;
    if (!this.valueList.find((f) => f.valueStr === value)) {
      this.valueList = [...this.valueList, { valueStr: this.control.value ? this.control.value : '' }];
      this.control = new UntypedFormControl('', [this.uniqueValueValidator()]);
      this.nextChangeValue();
    }
  }

  nextChangeValue(): void {
    if (this._onChange) {
      this._onChange(this.valueList);
    }
  }

  uniqueValueValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const forbidden = !!this.valueList.find((v) => v.valueStr === control.value);
      return forbidden ? { notUnique: true } : null;
    };
  }
}
