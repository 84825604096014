import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Status, STATUS_COLORS, STATUS_NAMES } from '@common/consts';

@Component({
  selector: 'fm-status-tag',
  templateUrl: './fm-status-tag.component.html',
  styleUrls: ['./fm-status-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FmStatusTagComponent implements OnChanges {
  @Input() statusId: Status;

  statusName: string;
  color: string;

  ngOnChanges(changes: SimpleChanges): void {
    this.setData();
  }

  private setData(): void {
    this.statusName = STATUS_NAMES[this.statusId];
    this.color = STATUS_COLORS[this.statusId];
  }
}
