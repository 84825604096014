import { NgModule } from '@angular/core';
import { SideNavigationComponent } from './side-navigation.component';
import { SharedModule } from '@shared/shared.module';
import { ExpandableListComponent } from './components/expandable-list';

@NgModule({
  imports: [SharedModule],
  declarations: [SideNavigationComponent, ExpandableListComponent],
  exports: [SideNavigationComponent],
})
export class SideNavigationModule {}
