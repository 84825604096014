import { TablePageFilter } from '../table-page-filter-base';
import { IAttributesFilterParams } from './attributes-filter.interface';

const DEFAULT_PARAMS: IAttributesFilterParams = {
  showDeletedAttributes: false,
  showDeletedValues: false,
  defaultSortBy: 'sortNumber',
};

export class AttributesFilterParams extends TablePageFilter<IAttributesFilterParams> {
  id: number;
  name: string;
  attributeValue: string;
  showDeletedAttributes: boolean;
  showDeletedValues: boolean;
  searchString: null;
  attributeType: boolean;
  isRequiredForRequest: boolean;
  isRequiredForFamily: boolean;
  isRequiredForMaterial: boolean;
  displayInBim: boolean;
  functionalTypeIds: number[];
  materialIds: number[];
  standardIds: number[];
  description: string;

  constructor(filter: IAttributesFilterParams = {}) {
    super(filter, DEFAULT_PARAMS);
  }

  toObject(): IAttributesFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      name: this.name,
      attributeValue: this.attributeValue,
      showDeletedAttributes: this.showDeletedAttributes,
      showDeletedValues: this.showDeletedValues,
      searchString: this.searchString,
      attributeType: this.attributeType,
      isRequiredForRequest: this.isRequiredForRequest,
      isRequiredForFamily: this.isRequiredForFamily,
      isRequiredForMaterial: this.isRequiredForMaterial,
      displayInBim: this.displayInBim,
      functionalTypeIds: this.functionalTypeIds,
      materialIds: this.materialIds,
      description: this.description,
      standardIds: this.standardIds,
    };
  }
}
