import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fm-page',
  templateUrl: './page-component.component.html',
  styleUrls: ['./page-component.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line:no-host-metadata-property
  host: { class: 'fm-page' },
})
export class FmPageComponent {}
