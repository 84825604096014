import { ERquestTypes, ERequestTypesMode, ERequestCreateUpdate } from '../consts';

export function getRequestTypesMode(id): ERequestTypesMode {
  switch (id) {
    case ERquestTypes.createFamily:
    case ERquestTypes.updateFamily:
      return ERequestTypesMode.family;
    case ERquestTypes.createMaterial:
    case ERquestTypes.updateMaterial:
      return ERequestTypesMode.material;
    default:
      return ERequestTypesMode.family;
  }
}

export function getRequestCreateUpdateMode(id): ERequestCreateUpdate {
  switch (id) {
    case ERquestTypes.createFamily:
    case ERquestTypes.createMaterial:
      return ERequestCreateUpdate.create;
    case ERquestTypes.updateMaterial:
    case ERquestTypes.updateFamily:
      return ERequestCreateUpdate.update;
    default:
      return ERequestCreateUpdate.create;
  }
}
