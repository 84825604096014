import { Component, Input } from '@angular/core';

import { ROUTES_PARTS, ThemeColors } from '@common/consts';
import { IdNameDto } from '@common/dto';

@Component({
  selector: 'app-functional-type-tag',
  templateUrl: './functional-type-tag.component.html',
  styleUrls: ['./functional-type-tag.component.scss'],
})
export class FunctionalTypeTagComponent {
  @Input() entity: IdNameDto;
  @Input() functionalTypes: IdNameDto[];
  @Input() linked = true;
  @Input() multi = false;
  @Input() maxCount: number;
  ThemeColors = ThemeColors;
  routesParts = ROUTES_PARTS;
  isShowAllCount: boolean;

  trackByIndex(index): number {
    return index;
  }

  toggle() {
    this.isShowAllCount = !this.isShowAllCount;
  }
}
