import { TablePageFilter } from '../table-page-filter-base';
import { IBindingsFilterParams } from '../index';
import { BindingSourceType, RangeDateDto } from '@common/dto';
import { SortOrder } from '@common/interfaces';
import { formatRangeDate } from '@shared/utils';

const DEFAULT_PARAMS: IBindingsFilterParams = {
  sourceType: BindingSourceType.Internal,
  defaultSortBy: 'created',
  sortOrder: SortOrder.Descending,
};

export class BindingsFilterParams extends TablePageFilter<IBindingsFilterParams> {
  id: number;
  nsiName: string;
  formula: string;
  searchString: string;
  description: string;
  discipline: string;
  linkStatusId: number;
  authorIds: number[];
  lastEditorIds: number[];
  groupNumber: number;
  bindingId: number;
  isChanged: boolean;
  sourceType: BindingSourceType;
  nomenclatureTypeIds: number[];
  functionalTypeIds: number[];
  familyIds: number[];
  disciplineIds: number[];
  materialIds: number[];
  created: RangeDateDto;
  modified: RangeDateDto;
  isCheckError: boolean;
  checkError: string;
  checkErrorType: number;

  constructor(filter?: IBindingsFilterParams) {
    super(filter, DEFAULT_PARAMS);
  }

  toObject(): IBindingsFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      nsiName: this.nsiName,
      formula: this.formula,
      searchString: this.searchString,
      description: this.description,
      discipline: this.discipline,
      materialIds: this.materialIds,
      nomenclatureTypeIds: this.nomenclatureTypeIds,
      functionalTypeIds: this.functionalTypeIds,
      linkStatusId: this.linkStatusId,
      sourceType: this.sourceType,
      authorIds: this.authorIds,
      lastEditorIds: this.lastEditorIds,
      familyIds: this.familyIds,
      disciplineIds: this.disciplineIds,
      groupNumber: this.groupNumber,
      bindingId: this.bindingId,
      isChanged: this.isChanged,
      created: formatRangeDate(this.created),
      modified: formatRangeDate(this.modified),
      isCheckError: this.isCheckError,
      checkError: this.checkError,
      checkErrorType: this.checkErrorType,
    };
  }
}
