<div class="language">
  <div
    class="language__item"
    *ngFor="let language of languages"
    (click)="changeLanguage(language)"
    [ngClass]="{ active: translate.currentLang === language }"
  >
    {{ languageLabel[language] }}
  </div>
</div>
