import { ChangeDetectorRef, Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { tap } from 'rxjs/operators';
import { ParameterValueControlService } from '../../services';

@Component({
  selector: 'app-parameter-string-value-control',
  templateUrl: './parameter-string-value-control.component.html',
  styleUrls: ['./parameter-string-value-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ParameterStringValueControlComponent),
      multi: true,
    },
  ],
})
export class ParameterStringValueControlComponent implements OnDestroy, ControlValueAccessor {
  values: string[] = [];
  form = new FormGroup({});
  @Input() isDisable = false;

  private _destroy$ = new Subject();
  private _subscription = new Subscription();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(
    private _changeDetector: ChangeDetectorRef,
    public parameterValueControlService: ParameterValueControlService,
  ) {}

  ngOnDestroy() {
    this._subscription.unsubscribe();
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  writeValue(value: string) {
    this.values = value?.split('|') || [];
    this.buildForm();
  }

  setDisabledState(isDisable: boolean) {
    this.isDisable = isDisable;
    isDisable ? this.form.disable() : this.form.enable();
    this._changeDetector.markForCheck();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  nextChangeValue(): void {
    if (this._onChange) {
      const value = this.form.valid ? Object.values(this.form.value).join('|') : null;
      this._onChange(value);
    }
  }

  addValue() {
    this.values.push('');
    this.parameterValueControlService.values$.next(this.values);
    this.buildForm();
    this.nextChangeValue();
  }

  removeValue(index: number) {
    this.values = this.values.filter((v, i) => i !== index);
    this.parameterValueControlService.values$.next(this.values);
    this.buildForm();
    this.nextChangeValue();
  }

  trackByValue(index: number, value: string) {
    return index;
  }

  private buildForm() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }

    const controls = [...this.values].map((v, i) => [`control-${i}`, new FormControl(v, this.sameValueValidator())]);
    this.form = new FormGroup(Object.fromEntries(controls));

    this._subscription = this.subscribeToFormChanges().subscribe();
    this._changeDetector.markForCheck();
  }

  private subscribeToFormChanges() {
    return this.form.valueChanges.pipe(
      tap((t) => {
        this.values = Object.values(this.form.value);

        const controls = Object.keys(this.form.controls);
        controls.forEach((c) => this.form.controls[c].updateValueAndValidity({ emitEvent: false }));

        this.nextChangeValue();
      }),
    );
  }

  sameValueValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.values.filter((f) => control.value === f).length > 1 ? { sameValueError: true } : null;
    };
  }
}
