import { TablePageFilter } from '../table-page-filter-base';
import { IMaterialsParametersFilterParams } from './materials-parameters-filter.interface';
import { ParameterErrorType } from '@common/enums';

export class MaterialsParametersFilterParams extends TablePageFilter<IMaterialsParametersFilterParams> {
  id: number;
  materialIds: number[];
  parameterName: string;
  uniqueParameterName: string;
  valueStr: string;
  description: string;
  isFunctionalTypeParameters: boolean;
  searchString: string;
  dataTypeIds: number[];
  unitTypeIds: number[];
  errorTypes: ParameterErrorType[];
  isRequired: boolean;
  isAddress?: boolean;
  disciplineIds?: number[];
  withDisciplinesOnly?: boolean;

  skipEmptyArrayFields = ['errorTypes'];

  DEFAULT_PARAMS: IMaterialsParametersFilterParams = {
    defaultSortBy: 'parameterName',
  };

  constructor(filter: IMaterialsParametersFilterParams = {}) {
    super(filter);
  }

  public toObject(): IMaterialsParametersFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      materialIds: this.materialIds,
      parameterName: this.parameterName,
      uniqueParameterName: this.uniqueParameterName,
      valueStr: this.valueStr,
      isFunctionalTypeParameters: this.isFunctionalTypeParameters,
      description: this.description,
      searchString: this.searchString,
      dataTypeIds: this.dataTypeIds,
      unitTypeIds: this.unitTypeIds,
      errorTypes: this.errorTypes,
      isRequired: this.isRequired,
      isAddress: this.isAddress,
      disciplineIds: this.disciplineIds,
      withDisciplinesOnly: this.withDisciplinesOnly,
    };
  }
}
