<svg-icon pikModalConfirmIcon src="./assets/icons/question-2.svg" [stretch]="true"></svg-icon>
<h3 *ngIf="!data.html">{{ data.title }}</h3>
<h3 *ngIf="data.html" [innerHTML]="data.title"></h3>

<pik-button-group pikModalActions>
  <button pikButton size="medium" (click)="confirm()">
    {{ data?.successLabel || 'COMMON.OK' | translate }}
  </button>
  <button pikButton size="medium" appearance="outlined" (click)="cancel()">
    {{ data?.failureLabel || 'COMMON.CANCEL' | translate }}
  </button>
</pik-button-group>
