import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { TypedFormControl } from '@common/interfaces';
import { IdNameDto } from '@common/dto';
import { ROUTES_PARTS } from '@common/consts';

@Component({
  selector: 'app-family-ft-control',
  templateUrl: './family-ft-control.component.html',
  styleUrls: ['./family-ft-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FamilyFtControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FamilyFtControlComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() set functionalTypeList(value: IdNameDto[]) {
    this.functionalTypeList$.next(value ?? []);
  }

  @Input() size: 'small' | 'standard' = 'standard';

  public activeFunctionalType?: IdNameDto;
  public readonly functionalTypesControl: TypedFormControl<number> = new FormControl(null);
  public readonly functionalTypeList$ = new BehaviorSubject<IdNameDto[]>([]);
  public readonly ROUTES_PARTS = ROUTES_PARTS;

  private readonly _destroy$ = new Subject();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(private _changeDetector: ChangeDetectorRef) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this._subscribeToEntityControl().subscribe();
  }

  writeValue(value: number) {
    if (value) {
      this.functionalTypeList$
        .pipe(
          map((list) => list.find((s) => s.id === value)),
          filter((f) => !!f),
          take(1),
          tap((functionalType) => {
            this.activeFunctionalType = functionalType;
            this.functionalTypesControl.setValue(functionalType.id, { emitEvent: false });

            this._changeDetector.detectChanges();
          }),
        )
        .subscribe();
    } else {
      this.functionalTypesControl.setValue(null, { emitEvent: false });
    }
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  nextChangeValue(value: number): void {
    if (this._onChange) {
      this._onChange(value);
    }
  }

  private _subscribeToEntityControl() {
    return this.functionalTypesControl.valueChanges.pipe(
      tap((value) => {
        this.activeFunctionalType = this.functionalTypeList$.getValue().find((f) => f.id === value);
        this.nextChangeValue(value);
      }),
      takeUntil(this._destroy$),
    );
  }
}
