import { Component, Input } from '@angular/core';

@Component({
  selector: 'fm-hint',
  templateUrl: './fm-hint.component.html',
  styleUrls: ['./fm-hint.component.scss'],
})
export class FmHintComponent {
  @Input() link: any;
  @Input() size: 'small' | 'medium' = 'medium';
}
