import { AttributeDto, AttributeUpdateDto, AttributeValueDto, IdNameDto, RequestAttributeDto } from '@common/dto';
import _ from 'lodash';
import { sortBySortNumber } from '@common/utils';

export function getAttributeName(attr: AttributeValueDto, originalAttributes: AttributeDto[]): string {
  if (attr) {
    if (attr.attributeName) {
      return attr.attributeName;
    }

    const attribute = originalAttributes.find((item) => item.id === (attr.attributeId || attr.id));
    return attribute?.name;
  }
}

export function getDisplayOptionsForAttribute(
  attr: AttributeValueDto,
  originalAttributes: AttributeDto[],
): IdNameDto[] {
  let displayOptions = attr ? attr.options : [];

  if (attr) {
    const exist = originalAttributes.find((item) => item.id === (attr.attributeId || attr.id));

    if (exist) {
      displayOptions = exist.options.map((x) => ({
        id: x.id,
        name: x.name,
      }));
    }
  }

  return _.orderBy(displayOptions, [(option) => option.name?.toLowerCase()], ['asc']);
}

export function getFilteredOriginalAttributes(
  originalAttributes: AttributeDto[],
  entityAttributes: AttributeValueDto[] = [],
): AttributeDto[] {
  return originalAttributes
    .filter((a) => {
      const exist = entityAttributes.find((x) => x.attributeId === a.id);
      return exist !== undefined;
    })
    .map((a) => {
      const exist = entityAttributes.find((x) => x.attributeId === a.id);
      const options = a.options.filter((x) => {
        const existOption = exist.options.find((b) => b.id === x.id);
        return existOption !== undefined;
      });

      return { ...a, options };
    });
}

export function getRequestAttributes(attributes: AttributeValueDto[], ownerId: number): RequestAttributeDto[] {
  return attributes?.length
    ? _.orderBy(attributes, 'attributeId').map((a: any | AttributeValueDto) => {
        const attr = {
          attributeId: a.attributeId,
          ownerId,
          valueStr: a.valueStr,
          options: a.options.map((o) => (o?.id ? o.id : o)),
          isDeleted: false,
          isValueUpdated: false,
        };

        if (a.id !== null) {
          return {
            ...attr,
            id: a.id,
          };
        }

        return attr;
      })
    : [];
}

export function transformAttributeValueToAttribute(attributes: AttributeValueDto[]): AttributeDto[] {
  return sortBySortNumber(
    attributes.map((a) => {
      return {
        id: a.attributeId,
        name: a.attributeName,
        deleted: false,
        isDeleted: false,
        isRequiredForFamily: a.isRequiredForFamily,
        isRequiredForMaterial: a.isRequiredForMaterial,
        isRequiredForRequest: a.isRequiredForRequest,
        attributeType: a.attributeType,
        defaultValue: a.valueStr,
        options: a.options.map((x) => ({
          id: x.id,
          name: x.name,
          isDeleted: null,
        })),
        sortNumber: a.sortNumber,
        displayInBim: a.displayInBim,
        description: a.attributeDescription,
      };
    }),
  );
}

export function transformAttributeToAttributeValue(
  attributes: AttributeDto[],
  ownerId: number = null,
): AttributeValueDto[] {
  return sortBySortNumber(
    attributes.map((a) => {
      return {
        attributeId: a.id,
        attributeName: a.name,
        attributeType: a.attributeType,
        sortNumber: a.sortNumber,
        isRequiredForFamily: a.isRequiredForFamily,
        isRequiredForMaterial: a.isRequiredForMaterial,
        isRequiredForRequest: a.isRequiredForRequest,
        ownerId,
        valueStr: a.defaultValue,
        author: null,
        options: a.options,
        attributeDescription: a.description,
        displayInBim: a.displayInBim,
        isValueUpdated: false,
      };
    }),
  );
}

export function transformAttributeToResponse(attributes: AttributeUpdateDto[]): any[] {
  return attributes.map((attr) => ({
    id: attr.id,
    attributeId: attr.attributeId,
    ownerId: attr.ownerId,
    valueStr: attr.valueStr,
    options: attr.options.map((o) => o.id),
  }));
}
