import { Component, Input } from '@angular/core';
import { ROUTES_PARTS } from '@common/consts';
import { ConditionLinkModule } from '@shared/components/condition-link';
import { FamilyCardLinkInterface } from '@shared/components/links/family-card-link/family-card-link.interface';
import { omit } from 'lodash';
@Component({
  standalone: true,
  selector: 'app-family-card-link',
  templateUrl: './family-card-link.component.html',
  imports: [ConditionLinkModule],
  styleUrls: ['./family-card-link.component.scss'],
})
export class FamilyCardLinkComponent {
  @Input() set params(value: FamilyCardLinkInterface) {
    if (value) {
      const filterParams = omit(value, 'id');
      this.link = ['', ROUTES_PARTS.FAMILIES, value.id, filterParams];
    }
  }
  link: any[];
}
