<div class="fmFileItem">
  <div class="fmFileItem__download" (click)="open.emit()">
    <div class="fmFileItem__icon">
      <svg-icon src="./assets/icons/file.svg"></svg-icon>
    </div>
    <div class="fmFileItem__name">{{ name }}</div>
  </div>

  <div class="fmFileItem__remove" (click)="remove.emit()" *ngIf="removable">
    <svg-icon src="./assets/icons/delete.svg"></svg-icon>
  </div>
</div>
