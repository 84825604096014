import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BindingGroupsFilterParams } from '@common/models/filters';
import { ResultPart } from '@common/interfaces';
import { ApiPaths } from '@common/consts';
import { BindingGroupCreateDto, BindingGroupDto, BindingGroupUpdateDto } from '@common/dto/Bindings/BindingGroups';

@Injectable({ providedIn: 'root' })
export class BindingsGroupsApiService {
  constructor(private http: HttpClient) {}

  public getList(filter?: BindingGroupsFilterParams) {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<ResultPart<BindingGroupDto>>(`${ApiPaths.BindingGroups}/filter`, params);
  }

  public getById(id: number) {
    return this.http.get<BindingGroupDto>(`${ApiPaths.BindingGroups}/${id}`);
  }

  public updateSnap(data: BindingGroupUpdateDto) {
    return this.http.put<BindingGroupDto>(`${ApiPaths.BindingGroups}/snap`, data);
  }

  public updateInternal(data: BindingGroupUpdateDto) {
    return this.http.put<BindingGroupDto>(`${ApiPaths.BindingGroups}`, data);
  }

  public createSnap(data: BindingGroupCreateDto) {
    return this.http.post<BindingGroupDto>(`${ApiPaths.BindingGroups}/snap`, data);
  }

  public createInternal(data: BindingGroupCreateDto) {
    return this.http.post<BindingGroupDto>(`${ApiPaths.BindingGroups}`, data);
  }

  public removeInternal(id: number) {
    return this.http.delete(`${ApiPaths.BindingGroups}/${id}`);
  }

  public removeSnap(id: number) {
    return this.http.delete(`${ApiPaths.BindingGroups}/snap/${id}`);
  }
}
