import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataTypeCodeEnum } from '@common/enums';

@Injectable()
export class ParameterValueControlService {
  public readonly maxValuesCount$ = new BehaviorSubject<number>(Infinity);
  public readonly validValues$ = new BehaviorSubject<string>(null);
  public readonly dataType$ = new BehaviorSubject<DataTypeCodeEnum>(null);
  public readonly validValuesDescription$ = new BehaviorSubject<string>(null);
  public values$ = new BehaviorSubject<string[]>([]);
  public allowEmptyValue: boolean;
}
