import { ISmartSearchFilter } from '@common/models/filters';

export class SmartSearchFilter {
  filter: ISmartSearchFilter;

  constructor(filter: ISmartSearchFilter) {
    this.init(filter);
  }

  init(filter: ISmartSearchFilter) {
    this.filter = filter;
  }

  public toParams(ignoreFalseFields?: string[]) {
    const objParams = this.filter;

    return Object.keys(objParams)
      .filter((key) => objParams[key] !== null && objParams[key] !== undefined && objParams[key] !== '')
      .reduce((obj, key) => {
        obj[key] = objParams[key];
        return obj;
      }, {});
  }
}
