<div class="editableOption">
  <svg-icon
    *ngIf="invalid"
    class="warning"
    src="./assets/icons/invalid.svg"
    pikTooltip="{{ invalidTooltip | translate }}"
  ></svg-icon>

  <ng-container *ngIf="showForm; else editView">
    <fm-edit-row-form
      (submit)="editValueHandler($event)"
      (cancel)="cancelHandler()"
      [placeholder]="placeholder"
      buttonText="{{ 'COMMON.SAVE' | translate }}"
      [value]="value"
      [uniqueValues]="uniqueValues"
    ></fm-edit-row-form>
  </ng-container>

  <ng-template #editView>
    <div class="editableOption__name">
      <span>{{ label || value }}</span>
      <fm-copy-button *ngIf="true" [text]="label || value"></fm-copy-button>
    </div>
    <span class="editableOption__tools" *ngIf="!showForm">
      <button class="editableOption__tool" (click)="editHandler($event)" *ngIf="editable">
        <svg-icon src="./assets/icons/edit.svg" [stretch]="true"></svg-icon>
      </button>
      <button class="editableOption__tool" (click)="removeHandler($event)" *ngIf="removable">
        <svg-icon src="./assets/icons/trash-2.svg" [stretch]="true"></svg-icon>
      </button>
    </span>
    <span class="editableOption__validation" *ngIf="validation !== undefined">
      <ng-container *ngIf="validation; else invalid">
        <svg-icon src="./assets/icons/valid.svg" [stretch]="true"></svg-icon>
      </ng-container>
      <ng-template #invalid>
        <svg-icon src="./assets/icons/invalid.svg" [stretch]="true"></svg-icon>
      </ng-template>
    </span>
  </ng-template>
</div>
