import { NgModule } from '@angular/core';
import { MarkdownViewerModule } from './viewer';
import { MarkdownEditorModule } from './editor';

const modules = [MarkdownViewerModule, MarkdownEditorModule];

@NgModule({
  imports: modules,
  exports: modules,
})
export class MarkdownModule {}
