<app-select
  *ngIf="!isDisable; else unitTypeView"
  [formControl]="entityControl"
  placeholder="{{ 'COMPONENT_UNIT_CONTROL.ONE_PLACEHOLDER' | translate }}"
  [options]="dictionariesService.unitTypes$ | async"
  [canSearch]="true"
></app-select>

<ng-template #unitTypeView>
  <app-unit-type-link [entity]="selectedEntity"></app-unit-type-link>
</ng-template>
