import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

import { FmTabsService } from '@services';
import { PermissionService } from '@core/account/services';
import { Tab, TabNavLink } from '@common/interfaces';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
})
export class ContentLayoutComponent implements OnInit, OnDestroy {
  firstLinks: TabNavLink[];
  otherLinks: TabNavLink[];
  toggleTabs = false;

  @ViewChild('otherTabs', { static: false }) otherTabs: ElementRef;

  private readonly navLinksBase: TabNavLink[] = [];
  navLinks: TabNavLink[] = [...this.navLinksBase];

  private _destroy$ = new Subject();

  constructor(
    private fmTabsService: FmTabsService,
    private _permissionService: PermissionService,
    private _router: Router,
  ) {}

  ngOnInit() {
    this._subscribeToTabs().subscribe();
  }

  closeAllTabs() {
    this.fmTabsService.closeAllTabs();
  }

  ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  onCloseTab(event, link) {
    if (event.button === 0 || event.button === 1) {
      event.stopPropagation();
      event.preventDefault();
      this.fmTabsService.closeTab(link.type, link.id);
    }
  }

  private _subscribeToTabs() {
    return this.fmTabsService.tabs$.asObservable().pipe(
      tap((tabs: Tab[]) => this.updateLinks(tabs)),
      takeUntil(this._destroy$),
    );
  }

  private updateLinks(tabs: Tab[]) {
    this.navLinks = [...this.navLinksBase];

    tabs.forEach((tab: Tab) => {
      const type = tab.type.split('/')[0];
      const tabNavLink: TabNavLink = {
        closed: true,
        path: this.fmTabsService.getRouterLink(tab),
        params: this.fmTabsService.getQueryParams(tab),
        label: tab.name,
        class: type,
        id: tab.id,
        type: tab.type,
      };

      this.navLinks.push(tabNavLink);
    });

    if (this.navLinks.length > 10) {
      this.firstLinks = this.navLinks.slice(0, 10);
      this.otherLinks = this.navLinks.slice(10);
    } else {
      this.firstLinks = this.navLinks;
      this.otherLinks = [];
    }
  }

  toggleOtherTabs() {
    this.toggleTabs = !this.toggleTabs;
  }

  disableOtherTabs(event: Event) {
    this.toggleTabs = false;
    event.target.dispatchEvent(new Event('customClose', { bubbles: true }));
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.otherTabs && !this.otherTabs.nativeElement.contains(event.target)) {
      this.toggleTabs = false;
    }
  }
}
