<pik-card>
  <app-language-switcher></app-language-switcher>

  <div class="read-only">
    <div class="family-manager">
      <img src="./assets/logo.svg" alt="" />
      <div class="family-manager__title">Family Manager</div>
    </div>
    <div class="read-only__message">
      <span [innerHTML]="'READ_ONLY_PAGE' | translate: { link: environment.accessLink }"></span>
    </div>
  </div>
</pik-card>
