import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ParameterValueOwner } from '@common/enums';

@Component({
  selector: 'app-parameter-value-owner-control',
  templateUrl: './parameter-value-owner-control.component.html',
  styleUrls: ['./parameter-value-owner-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ParameterValueOwnerControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParameterValueOwnerControlComponent implements OnDestroy, ControlValueAccessor, OnInit {
  ParameterValueOwner = ParameterValueOwner;
  formControl = new UntypedFormControl();

  private _destroy$ = new Subject();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(private _changeDetector: ChangeDetectorRef) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this._subscribeToFormControl().subscribe();
  }

  setDisabledState(value: boolean) {
    value ? this.formControl.disable() : this.formControl.enable();
  }

  writeValue(value: ParameterValueOwner) {
    this.formControl.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  nextChangeValue(): void {
    if (this._onChange) {
      this._onChange(this.formControl.value);
    }
  }

  private _subscribeToFormControl() {
    return this.formControl.valueChanges.pipe(
      tap(() => this.nextChangeValue()),
      takeUntil(this._destroy$),
    );
  }
}
