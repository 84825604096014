import { Component, Input } from '@angular/core';
import { ROUTES } from '@common/consts';
import { normalizePageParams } from '@common/utils';
import { FamiliesFilterParams, IFamiliesFilterParams } from '@common/models/filters';
import { ConditionLinkModule } from '@shared/components/condition-link';
import { PikTagModule } from '@pik-ui/ng-components';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'app-families-link',
  templateUrl: './families-link.component.html',
  imports: [ConditionLinkModule, PikTagModule, NgIf, TranslateModule],
})
export class FamiliesLinkComponent {
  @Input() set params(value: IFamiliesFilterParams) {
    if (value) {
      this.link = ['', ROUTES.familiesList, normalizePageParams(new FamiliesFilterParams(value).toParams())];
    }
  }
  @Input() name: string;

  link: any[];
}
