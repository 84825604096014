import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { PikSelectComponent } from '@pik-ui/ng-components';
import { Subscription } from 'rxjs';

import { IdNameDto } from '@common/dto';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SelectComponent implements OnInit, OnDestroy {
  @ViewChild(PikSelectComponent)
  selectRef: PikSelectComponent;

  @Input()
  set options(options: IdNameDto<any>[]) {
    if (options) {
      this._options = options;
      this.filteredOptions = options;
    }
  }
  get options() {
    return this._options;
  }

  @Input() placeholder;
  @Input() canSearch = false;
  @Input() fieldName = 'id';
  @Input() showReset = true;
  @Output() change = new EventEmitter();

  filteredOptions: IdNameDto[];
  searchControl = new UntypedFormControl('');
  control = new UntypedFormControl(null);
  focused: boolean;

  private _onChange: (value) => void;
  private _onTouched: () => void;
  private _subscription: Subscription;
  private _options: IdNameDto[];

  constructor(protected _cd: ChangeDetectorRef) {
    this.options = [];
    this.filteredOptions = [];
    this._subscription = new Subscription();
  }

  ngOnInit(): void {
    this._subscription.add(
      this.control.valueChanges.subscribe((value: number[]) => {
        this.change.emit(value);

        if (this._onChange) {
          this._onChange(value);
        }
      }),
    );

    this._subscription.add(
      this.searchControl.valueChanges.subscribe((term: string) => {
        this.filteredOptions = term
          ? this.options.filter((x) => x.name.toLowerCase().includes(term.toLowerCase()))
          : this.options;

        this._cd.detectChanges();
        this.selectRef._detectChanges();
      }),
    );
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  trackById(index: number, item: IdNameDto) {
    return item.id;
  }

  writeValue(value: number) {
    this.control.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  reset() {
    this.searchControl.reset();

    setTimeout(() => {
      this.close();
      this.control.setValue(null);
      this._cd.detectChanges();
      this.selectRef._detectChanges();
    });
  }

  close() {
    this.selectRef.close();
  }
}
