import { TablePageFilter } from '../table-page-filter-base';
import { IFunctionalTypeAttributesFilterParams } from './functional-type-attributes-filter.interface';

export class FunctionalTypeAttributesFilter extends TablePageFilter<IFunctionalTypeAttributesFilterParams> {
  functionalTypesIds: number[];

  constructor(filter?: IFunctionalTypeAttributesFilterParams) {
    super(filter);
  }

  toObject(): IFunctionalTypeAttributesFilterParams {
    return {
      ...super.toObjectBase(),
      functionalTypesIds: this.functionalTypesIds,
    };
  }
}
