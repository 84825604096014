import { Directive, Input } from '@angular/core';

@Directive()
export abstract class ShowMoreDirective {
  @Input() maxCount: number;

  isShowAllCount: boolean;

  trackByIndex(index): number {
    return index;
  }

  toggle() {
    this.isShowAllCount = !this.isShowAllCount;
  }
}
