import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { CarouselService } from '@services';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-image-loader-control',
  templateUrl: './image-loader-control.component.html',
  styleUrls: ['./image-loader-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageLoaderControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageLoaderControlComponent implements OnDestroy, ControlValueAccessor {
  @Input() pending;
  @Input() canOpen = true;
  @Input() removable: boolean;

  @ViewChild('fileInput')
  fileInput: ElementRef<HTMLInputElement>;

  image;
  file: File;

  private _destroy$ = new Subject();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  isDisabled: boolean;

  constructor(
    private _changeDetector: ChangeDetectorRef,
    private _carouselService: CarouselService,
    private _sanitized: DomSanitizer,
  ) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  onDeleteImage() {
    this.file = null;
    this.image = null;
    this.nextChangeValue();
  }

  writeValue(file: File) {
    this.file = file;
    this.createImage(file);

    this._changeDetector.detectChanges();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  nextChangeValue(): void {
    if (this._onChange) {
      this._onChange(this.file ? this.file : null);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  reloadImage() {
    this.fileInput.nativeElement.click();
  }

  showImage(image) {
    if (!this.canOpen) return;
    const fileName = 'image.png';
    this._carouselService.openImageFile(image.file, fileName);
  }

  public handleFileInput(files: FileList) {
    const file = files[0];

    this.createImage(file);

    this.file = file;
    this.nextChangeValue();
  }

  private createImage(file: File) {
    this.image = file
      ? {
          styleUrl: this._sanitized.bypassSecurityTrustStyle('url(' + URL.createObjectURL(file) + ')'),
          file,
        }
      : null;
  }
}
