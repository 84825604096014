import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ParameterValueAddDto, ParameterValueDto, ParameterValueRemoveDto, ParameterValueUpdateDto } from '@common/dto';
import { ApiPaths } from '@common/consts';

@Injectable({ providedIn: 'root' })
export class ParameterValuesApiService {
  constructor(private http: HttpClient) {}

  /**
   * Обновляет объект
   * @param parameter - изменения
   */
  public update(parameter: ParameterValueUpdateDto): Observable<ParameterValueDto> {
    return this.http.put<ParameterValueDto>(`${ApiPaths.ParameterValues}/${parameter.id}`, parameter);
  }

  public add(parameter: ParameterValueAddDto): Observable<ParameterValueDto> {
    return this.http.post<ParameterValueDto>(`${ApiPaths.ParameterValues}`, parameter);
  }

  public remove(parameter: ParameterValueRemoveDto): Observable<ParameterValueDto> {
    return this.http.delete<ParameterValueDto>(`${ApiPaths.ParameterValues}/${parameter.id}`);
  }
}
