import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { InputBoolean, PikAutocompleteSelectedEvent } from '@pik-ui/ng-components';
import { debounceTime, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { IdNameDto, FunctionalTypeParameterDto } from '@common/dto';
import { FunctionalTypeParametersApiService } from '@services/api';
import { FunctionalTypesParametersFilterParams } from '@common/models/filters';
import { FunctionalTypeParameterControlItem, TypedFormControl } from '@common/interfaces';

@Component({
  selector: 'app-functional-type-parameter-control',
  templateUrl: './functional-type-parameter-control.component.html',
  styleUrls: ['./functional-type-parameter-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FunctionalTypeParameterControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunctionalTypeParameterControlComponent implements OnDestroy, ControlValueAccessor, OnInit {
  @Input() excludeIds: number[] = [];
  @Input() entities;
  @Input() set initialValueName(value) {
    if (!value) return;
    this.nameControl.setValue(value);
  }
  public entities$ = new Subject();
  public _selectedEntity: IdNameDto;
  public isDisable: boolean;
  @Input() set filter(value: FunctionalTypesParametersFilterParams) {
    this._filter = value;
  }
  @Input()
  @InputBoolean()
  emptyTrigger = false;
  private _filter: FunctionalTypesParametersFilterParams;
  public readonly nameControl: TypedFormControl<string> = new FormControl(null);

  public readonly selectedEntity$ = new Subject<FunctionalTypeParameterDto>();
  private readonly _destroy$ = new Subject();

  private _onChange: (value) => void;
  public _onTouched: () => void;

  constructor(
    private _changeDetector: ChangeDetectorRef,
    private _functionalParametersApi: FunctionalTypeParametersApiService,
  ) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this._subscribeToNameControl().subscribe();
  }

  writeValue(value: number) {
    if (value) {
      this._getEntity(value)
        .pipe(
          take(1),
          tap((parameter) => this.nameControl.setValue(parameter.name, { emitEvent: false })),
        )
        .subscribe();
    } else {
      this._selectedEntity = undefined;
      this.selectedEntity$.next(undefined);
      this.nameControl.setValue(undefined, { emitEvent: false });
      this._changeDetector.detectChanges();
    }
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  selectEntity(event: PikAutocompleteSelectedEvent) {
    this.nextChangeValue(event.value.id);
    this._getEntity(event.value.id).subscribe();
  }

  nextChangeValue(value: number): void {
    if (this._onChange) {
      this._onChange(value);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisable = isDisabled;
    this._changeDetector.markForCheck();
  }

  private _subscribeToNameControl() {
    return this.nameControl.valueChanges.pipe(
      debounceTime(300),
      filter((name) => name !== this._selectedEntity?.name),
      tap(() => {
        this._selectedEntity = null;
        this.selectedEntity$.next(null);
        this.nextChangeValue(null);
      }),
      switchMap((value) => {
        return this.entities
          ? of(
              this.entities
                .map((fTparameter) => ({
                  ...fTparameter.parameter,
                  id: fTparameter.id,
                  parameterId: fTparameter.parameterId,
                }))
                .filter((parameter) => (parameter.name as string).toLowerCase().includes(value.toLowerCase())),
            )
          : this._functionalParametersApi
              .getList(
                new FunctionalTypesParametersFilterParams({
                  ...this._filter?.toObject(),
                  parameter: value,
                }),
              )
              .pipe(map(({ result }) => result.map((i) => ({ ...i.parameter, id: i.id, parameterId: i.parameterId }))));
      }),
      map((items) => {
        return items.reduce((acc: FunctionalTypeParameterControlItem[], item: FunctionalTypeParameterDto) => {
          if (!this.excludeIds?.includes(item.parameterId)) {
            acc.push({
              label: item.name,
              value: item,
            });
          }
          return acc;
        }, []);
      }),
      tap((data) => this.entities$.next(data)),
      takeUntil(this._destroy$),
    );
  }

  private _getEntity(id: number) {
    return this._functionalParametersApi.getById(id).pipe(
      take(1),
      tap((entity: any) => {
        this.selectedEntity$.next(entity);
        this._selectedEntity = { name: entity.parameter?.name || entity.name, id };
        this._changeDetector.detectChanges();
      }),
    );
  }
}
