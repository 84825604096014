import { BehaviorSubject, Subject } from 'rxjs';
import { ColumnStorage, ColumnType, PageAttributesColumnsStorage, TableColumnDef } from '../../table-builder';
import { TABLE_COLUMNS, TABLE_PAGE_NAME } from '../../table-builder/tokens';
import { TableBuilderLocatorService } from '../../table-builder/table-builder-locator';
import { columnShouldBeVisible, mapColumnsToStorage } from '../utils';
import { PageColumnsStorage } from '../consts';
import { PermissionService } from '@core/account/services';
import { Markdown } from '@shared/components/markdown/utils/markdown';
import { AttributeDto } from '@common/dto';
import { DictionariesService } from '@services';
import { take, tap } from 'rxjs/operators';

export abstract class TablePageServiceBase {
  abstract result$;
  abstract fullCount$;
  abstract pending$;

  pageName: string;
  filter: any;
  tableColumns: TableColumnDef[];
  defaultColumns: TableColumnDef[];
  attributesTableColumns: number[];
  columns$: BehaviorSubject<TableColumnDef[]>;
  attributesColumns$: BehaviorSubject<TableColumnDef[]> = new BehaviorSubject([]);
  permissionService: PermissionService;
  dictionariesService: DictionariesService;
  setParametersAction$ = new Subject();

  protected constructor() {
    this.pageName = TableBuilderLocatorService.injector && TableBuilderLocatorService.injector.get(TABLE_PAGE_NAME);
    this.tableColumns = TableBuilderLocatorService.injector && TableBuilderLocatorService.injector.get(TABLE_COLUMNS);
    this.defaultColumns = this.tableColumns;

    this.columns$ = new BehaviorSubject<TableColumnDef[]>([]);
    this.permissionService =
      TableBuilderLocatorService.injector && TableBuilderLocatorService.injector.get(PermissionService);
    this.dictionariesService =
      TableBuilderLocatorService.injector && TableBuilderLocatorService.injector.get(DictionariesService);

    this._getAttributesColumnsFromStorage();

    if (this.pageName && this.tableColumns) {
      this._initVisibleColumns();

      const hasRequiredColumns = this.tableColumns.find((c) => c.required);

      if (!hasRequiredColumns) {
        const nameColumn = this.tableColumns.find((col) => col.name === 'name');

        if (!nameColumn || nameColumn.required === false) {
          throw new Error('The required column name was not set or the required flag was not set to true');
        }
      }
    }
  }

  public setDefaultColumnsSettings() {
    this.tableColumns = this.defaultColumns;
    this.tableColumns = this.tableColumns.map((col, index) => ({ ...col, orderPosition: index }));
    this.attributesTableColumns = [];
    this.attributesColumns$.next([]);

    const defaultColumns = this.tableColumns.filter(columnShouldBeVisible).map(mapColumnsToStorage);
    this.setVisibleColumns(defaultColumns);
  }

  public setVisibleColumns(columnNames: ColumnStorage[]) {
    const visibleColumns = this.tableColumns.map((col) => {
      const found = columnNames.find((c) => c.name === col.name);
      col.isVisible = !!found || col.name === 'tools';
      return col;
    });

    this._setColumnsToStorage(PageColumnsStorage, visibleColumns.map(mapColumnsToStorage));
    this.columns$.next(visibleColumns.filter((col) => col.isVisible));
  }

  public setAttributesColumns(attributesIds: number[], attributes: AttributeDto[]) {
    this._setColumnsToStorage(PageAttributesColumnsStorage, attributesIds);

    this.attributesColumns$.next(
      attributes.map(
        (a) =>
          new TableColumnDef({
            name: a.name,
            label: a.name,
            orderPosition: 0,
            type: ColumnType.ATTRIBUTES,
            description: a.description ? Markdown.removeTags(a.description.toString()) : null,
          }),
      ),
    );
  }

  public normalizePageParams(params: any): any {
    return Object.keys(params).reduce((obj, key) => {
      obj[key] = JSON.stringify(params[key]);

      return obj;
    }, {});
  }

  public normalizePageFilterParams(params: any): any {
    return { filter: JSON.stringify(params) };
  }

  public applyFilter(filter: any) {
    filter.tableParams.skip = 0;
    filter.tableParams.pageIndex = 1;
    this.filter = filter;
  }

  _initVisibleColumns() {
    const storageColumns: ColumnStorage[] = this._transformColumnsIfOld(this._getColumnsFromStorage());

    // Reorder columns
    this.tableColumns = this.tableColumns
      .filter((f) => (f.permission ? this.permissionService.access[f.permission] : true))
      .map((col) => {
        const found = storageColumns.find((c) => c.name === col.name);

        if (found) {
          if (found.hasOwnProperty('orderPosition') && !col.hasOwnProperty('orderPosition')) {
            col.orderPosition = found.orderPosition;
          }
        }

        return col;
      })
      .sort((a, b) => a.orderPosition - b.orderPosition);

    // Visible setting of columns
    const visibleColumns = this.tableColumns.map((col) => {
      const found = storageColumns.find((c) => c.name === col.name);
      col.isVisible = found ? found.isVisible : columnShouldBeVisible(col);
      return col;
    });

    this.setVisibleColumns(visibleColumns.filter((c) => c.isVisible).map(mapColumnsToStorage));
  }

  private _getAttributesColumnsFromStorage() {
    const storageData = localStorage.getItem(PageAttributesColumnsStorage);

    if (storageData) {
      const attributesColumnsSettings = JSON.parse(storageData);

      if (attributesColumnsSettings && attributesColumnsSettings[this.pageName]) {
        this.attributesTableColumns = attributesColumnsSettings[this.pageName];
        this.dictionariesService.attributes$
          .pipe(
            take(1),
            tap((attributes) => {
              this.setAttributesColumns(
                this.attributesTableColumns,
                attributes.filter((f) => this.attributesTableColumns.includes(f.id)),
              );
            }),
          )
          .subscribe();
      }
    }
  }

  private _getColumnsFromStorage(): ColumnStorage[] {
    const getDefaultColumns = (): ColumnStorage[] =>
      this.tableColumns.filter(columnShouldBeVisible).map(mapColumnsToStorage);

    try {
      const columnsSettings = JSON.parse(localStorage.getItem(PageColumnsStorage));

      if (columnsSettings && columnsSettings[this.pageName]) {
        return columnsSettings[this.pageName] as ColumnStorage[];
      } else {
        return getDefaultColumns();
      }
    } catch (error) {
      return getDefaultColumns();
    }
  }

  private _setColumnsToStorage(storageToken: string, columnsData: any[]) {
    if (!localStorage.getItem(storageToken)) {
      localStorage.setItem(
        storageToken,
        JSON.stringify({
          [this.pageName]: columnsData,
        }),
      );
    } else {
      const columnsStorage = JSON.parse(localStorage.getItem(storageToken));
      columnsStorage[this.pageName] = columnsData;
      localStorage.setItem(storageToken, JSON.stringify(columnsStorage));
    }
  }

  private _transformColumnsIfOld(cols: string[] | ColumnStorage[]): ColumnStorage[] {
    if (Array.isArray(cols) && Array.length >= 1 && typeof cols[0] === 'string') {
      return this.tableColumns
        .map((c) => {
          const hasColNameInOldStorage = (cols as string[]).includes(c.name);
          c.isVisible = hasColNameInOldStorage ? true : columnShouldBeVisible(c);
          return c;
        })
        .map(mapColumnsToStorage);
    }

    return cols as ColumnStorage[];
  }

  abstract reloadAndSetParams();
  abstract loadAll();
  abstract resetFilter();
}
