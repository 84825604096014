import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { defer, of, Subject } from 'rxjs';
import { PikAutocompleteSelectedEvent } from '@pik-ui/ng-components';
import { debounceTime, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { IdNameDto, ParameterDto } from '@common/dto';
import { ParametersApiService } from '@services/api';
import { ParametersFilterParams, ParametersSmartSearchFilterParams } from '@common/models/filters';
import { TypedFormControl } from '@common/interfaces';

@Component({
  selector: 'app-parameter-control',
  templateUrl: './parameter-control.component.html',
  styleUrls: ['./parameter-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ParameterControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParameterControlComponent implements OnDestroy, ControlValueAccessor, OnInit {
  public entities$ = new Subject();
  public _selectedEntity: IdNameDto;
  public isDisable: boolean;
  @Input() set filter(value: ParametersFilterParams) {
    this._filter = value;
  }
  private _filter: ParametersFilterParams;
  public readonly nameControl: TypedFormControl<string> = new FormControl(null);

  public readonly selectedEntity$ = new Subject<ParameterDto>();
  private readonly _destroy$ = new Subject();

  private _onChange: (value) => void;
  public _onTouched: () => void;

  constructor(private _changeDetector: ChangeDetectorRef, private _parametersApi: ParametersApiService) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this._subscribeToNameControl().subscribe();
  }

  writeValue(value: number) {
    if (value) {
      this._getEntity(value)
        .pipe(
          take(1),
          tap((parameter) => this.nameControl.setValue(parameter.name, { emitEvent: false })),
        )
        .subscribe();
    } else {
      this._selectedEntity = undefined;
      this.selectedEntity$.next(undefined);
      this.nameControl.setValue(undefined, { emitEvent: false });
      this._changeDetector.detectChanges();
    }
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  selectEntity(event: PikAutocompleteSelectedEvent) {
    this.nextChangeValue(event.value.id);
    this._getEntity(event.value.id).subscribe();
  }

  nextChangeValue(value: number): void {
    if (this._onChange) {
      this._onChange(value);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisable = isDisabled;
    this._changeDetector.markForCheck();
  }

  private _subscribeToNameControl() {
    return this.nameControl.valueChanges.pipe(
      debounceTime(300),
      filter((name) => name !== this._selectedEntity?.name),
      tap(() => {
        this._selectedEntity = null;
        this.selectedEntity$.next(null);
        this.nextChangeValue(null);
      }),
      switchMap((value) =>
        defer(() =>
          Boolean(value === '' || value === null)
            ? of([])
            : defer(() =>
                Boolean(this._filter)
                  ? this._parametersApi
                      .getList(new ParametersFilterParams({ ...this._filter.toObject(), name: value }))
                      .pipe(map((data) => data.result))
                  : value === '' || value === null
                  ? of([])
                  : this._parametersApi.smartSearch(new ParametersSmartSearchFilterParams({ searchString: value })),
              ),
        ),
      ),
      map((result: IdNameDto[]) =>
        result.map((item) => ({
          label: item.name,
          value: item,
        })),
      ),
      tap((data) => this.entities$.next(data)),
      takeUntil(this._destroy$),
    );
  }

  private _getEntity(id: number) {
    return this._parametersApi.getById(id).pipe(
      take(1),
      tap((entity: ParameterDto) => {
        this.selectedEntity$.next(entity);
        this._selectedEntity = { name: entity.name, id };
        this._changeDetector.detectChanges();
      }),
    );
  }
}
