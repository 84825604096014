<span [pikTooltip]="tooltip" tooltipClass="fm-info-tooltip" position="above">
  <svg-icon src="./assets/icons/info.svg" svgClass="info-icon" [svgStyle]="{ stroke: color }"></svg-icon>
</span>

<ng-template #tooltip>
  <ng-container *ngIf="isText; else template">
    <span class="template-tooltip" [innerHTML]="_text ?? '' | translate"></span>
  </ng-container>

  <ng-template #template>
    <span class="template-tooltip">
      <ng-container *ngTemplateOutlet="templateTooltip"> </ng-container>
    </span>
  </ng-template>
</ng-template>
