import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PikTooltipModule } from '@pik-ui/ng-components';

import { FmInfoTooltipComponent } from './fm-info-tooltip.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [FmInfoTooltipComponent],
  imports: [CommonModule, PikTooltipModule, AngularSvgIconModule, TranslateModule],
  exports: [FmInfoTooltipComponent],
})
export class FmInfoTooltipModule {}
