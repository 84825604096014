import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-check-empty',
  standalone: true,
  templateUrl: './check-empty.component.html',
  imports: [NgIf],
})
export class CheckEmptyComponent {
  @Input() value: any;
}
