import { Component, Input } from '@angular/core';
import { DataTypeDto } from '@common/dto';
import { DATA_TYPE_LABEL } from '@common/enums';

@Component({
  selector: 'app-data-type-link',
  templateUrl: './data-type-link.component.html',
})
export class DataTypeLinkComponent {
  @Input() set entity(data: DataTypeDto) {
    if (data) {
      this._entity = { ...data, name: DATA_TYPE_LABEL[data.code] };
      // this.link = ['', ROUTES.dataTypes, normalizePageParams({ id: data.id, searchValue: data.id, searchField: 'id' })];
    }
  }

  link: any[];
  _entity: DataTypeDto;

  // constructor(public permissionService: PermissionService) {}
}
