import { TablePageFilter } from '../table-page-filter-base';
import { IFoldersFilterParams } from './folders-filter.interface';

export class FoldersFilterParams extends TablePageFilter<IFoldersFilterParams> {
  disciplineIds: number[];
  searchString: string;

  constructor(filter?: IFoldersFilterParams) {
    super(filter);
  }

  public toObject(): IFoldersFilterParams {
    return {
      ...super.toObjectBase(),
      disciplineIds: this.disciplineIds,
      searchString: this.searchString,
    };
  }
}
