import { Component, Input } from '@angular/core';
import { ROUTES_PARTS, ThemeColors } from '@common/consts';
import { TabType } from '@common/interfaces';
import { FmTabsService } from '@services';
import { IdNameDto } from '@common/dto';

@Component({
  selector: 'app-family-symbol-tag',
  templateUrl: './family-symbol-tag.component.html',
  styleUrls: ['./family-symbol-tag.component.scss'],
})
export class FamilySymbolTagComponent {
  @Input() symbol: IdNameDto;
  @Input() symbols: IdNameDto[];
  @Input() family;
  @Input() version: number;
  @Input() linked = true;
  @Input() multi = false;
  @Input() maxCount: number;

  routesParts = ROUTES_PARTS;
  isShowAllCount: boolean;
  TabType = TabType;
  colors = ThemeColors;

  constructor(public tabService: FmTabsService) {}

  trackByIndex(index): number {
    return index;
  }

  toggle() {
    this.isShowAllCount = !this.isShowAllCount;
  }
}
