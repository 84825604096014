import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeclensionOfWordsModule, PikPaginatorModule, PikSelectModule } from '@pik-ui/ng-components';
import { TablePaginatorComponent } from './table-paginator.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [TablePaginatorComponent],
  imports: [CommonModule, PikPaginatorModule, PikSelectModule, DeclensionOfWordsModule, TranslateModule],
  exports: [TablePaginatorComponent],
})
export class TablePaginatorModule {}
