import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ROUTES, ThemeColors } from '@common/consts';
import { IdNameDto } from '@common/dto';
import _ from 'lodash';
import { normalizePageParams } from '@common/utils';
import { ConditionLinkModule } from '@shared/components/condition-link';
import { PikTagModule } from '@pik-ui/ng-components';
import { NgForOf } from '@angular/common';

export interface IRoleTag extends IdNameDto {
  link: any[];
}

@Component({
  selector: 'app-roles-tags',
  standalone: true,
  templateUrl: './roles-tags.component.html',
  styleUrls: ['./roles-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ConditionLinkModule, PikTagModule, NgForOf],
})
export class RolesTagsComponent {
  @Input() set roles(data: IdNameDto[]) {
    this._roles = _.orderBy(data, 'name', 'asc').map((item) => ({
      ...item,
      link: ['', ROUTES.rolesList, normalizePageParams({ id: item.id, searchValue: item.id, searchField: 'id' })],
    }));
  }
  public readonly ThemeColors = ThemeColors;
  public _roles: IRoleTag[];
  trackByIndex(index): number {
    return index;
  }
}
