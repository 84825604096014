export const ToolbarButtons = [
  {
    class: 'ql-header',
    tooltip: 'COMPONENT_MARKDOWN.HEADER_1',
    icon: 'h1',
    value: 1,
  },
  {
    class: 'ql-header',
    tooltip: 'COMPONENT_MARKDOWN.HEADER_2',
    icon: 'h2',
    value: 2,
  },
  {
    class: 'ql-header',
    tooltip: 'COMPONENT_MARKDOWN.HEADER_3',
    icon: 'h3',
    value: 3,
  },
  {
    class: 'ql-bold',
    tooltip: 'COMPONENT_MARKDOWN.BOLD',
    icon: 'bold',
  },
  {
    class: 'ql-italic',
    tooltip: 'COMPONENT_MARKDOWN.ITALIC',
    icon: 'italic',
  },
  {
    class: 'ql-list',
    tooltip: 'COMPONENT_MARKDOWN.MARK_LIST',
    icon: 'u-list',
    value: 'bullet',
  },
  {
    class: 'ql-list',
    tooltip: 'COMPONENT_MARKDOWN.NUMBER_LIST',
    icon: 'o-list',
    value: 'ordered',
  },
  {
    class: 'ql-link',
    tooltip: 'COMPONENT_MARKDOWN.LINK',
    icon: 'link',
  },
  {
    class: 'ql-image',
    tooltip: 'COMMON_LABELS.IMAGE',
    icon: 'image',
  },
  {
    class: 'ql-mention',
    tooltip: 'COMPONENT_MARKDOWN.MENTION',
    icon: 'at-sign',
  },
].map((btn) => {
  btn.icon = `/assets/icons/text-editor-icons/${btn.icon}.svg`;
  return btn;
});
