import { AppVersionStatus } from '@common/dto';
import { TablePageFilter } from '../table-page-filter-base';
import { IAppsFilterParams } from './apps-filter.interface';
import { AppType } from '@common/enums';

export class AppsFilterParams extends TablePageFilter<IAppsFilterParams> {
  id?: number;
  status?: AppVersionStatus;
  isPublished?: boolean;
  appType?: AppType;
  fileName?: string;
  version?: number;

  constructor(filter?: IAppsFilterParams) {
    super(filter);
  }

  public toObject(): IAppsFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      status: this.status,
      isPublished: this.isPublished,
      appType: this.appType,
      fileName: this.fileName,
      version: this.version,
    };
  }
}
