<div class="pik-table-footer">
  <div class="pik-table-footer__total">
    {{ 'TABLE_PAGINATOR.SHOWED' | translate }}
    <span>{{ fullCount | number }}</span>
  </div>

  <ng-container *ngIf="fullCount > limitMin">
    <pik-paginator
      listenKeyboard
      [pageIndex]="pageIndex"
      [pageSize]="limit"
      [totalSize]="fullCount"
      (pageChange)="pageNumberChange.emit($event)"
    ></pik-paginator>

    <div class="pik-table-footer__size">
      <span>{{ 'TABLE_PAGINATOR.ELEMENTS_SIZE' | translate }}</span>

      <pik-select [inverse]="inverse" [value]="limit" (change)="pageSizeChange.emit($event)">
        <pik-option *ngFor="let item of pageSizesList" [value]="item">
          {{ item }}
        </pik-option>
      </pik-select>
    </div>
  </ng-container>
</div>
