<app-select
  *ngIf="!isDisable; else dataTypeView"
  [formControl]="entityControl"
  placeholder="{{ 'COMPONENT_DATA_TYPE_CONTROL.ONE_PLACEHOLDER' | translate }}"
  [options]="dictionariesService.dataTypes$ | async"
></app-select>

<ng-template #dataTypeView>
  <app-data-type-link [entity]="selectedEntity"></app-data-type-link>
</ng-template>
