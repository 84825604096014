import { TablePageFilter } from '../table-page-filter-base';
import { INomenclatureTypesFilterParams } from './nomenclature-types-filter.interface';
import { BindingSourceType, RangeDateDto } from '@common/dto';
import { SortOrder } from '@common/interfaces';

const DEFAULT_PARAMS: INomenclatureTypesFilterParams = {
  sourceType: BindingSourceType.Internal,
  defaultSortBy: 'created',
  sortOrder: SortOrder.Descending,
  unitTypeIds: [],
};

export class NomenclatureTypesFilterParams extends TablePageFilter<INomenclatureTypesFilterParams> {
  id: number;
  name: string;
  searchString: string;
  showDeleted: boolean;
  unitTypeIds: number[];
  created: RangeDateDto;
  sourceType: BindingSourceType;

  constructor(filter?: INomenclatureTypesFilterParams) {
    super(filter, DEFAULT_PARAMS);
  }

  toObject(): INomenclatureTypesFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      name: this.name,
      searchString: this.searchString,
      showDeleted: this.showDeleted,
      unitTypeIds: this.unitTypeIds,
      sourceType: this.sourceType,
    };
  }
}
