export function parseInterval(interval: string): string[] {
  const firstInequality = interval.includes('[') ? ' ≤' : ' <';
  const lastInequality = interval.includes(']') ? '≤ ' : '< ';
  const step = interval.split('..')[2];

  const intervalPoints = interval
    .match(/([\[(]).*([\])])/gm)[0]
    .replace(/[\[\(\)\]]/gm, '')
    .split('..');

  let resultString = '';

  if (intervalPoints[0]) {
    resultString += intervalPoints[0] + firstInequality;
  }

  resultString += ' X ';

  if (intervalPoints[1]) {
    resultString += lastInequality + intervalPoints[1];
  }

  return [resultString, step || null];
}
