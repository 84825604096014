import { UntypedFormGroup } from '@angular/forms';
import { Directive, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { cloneDeep, filter, isEqual } from 'lodash';

export const provideFilterComponent = (component) => ({
  provide: TableSettingsFilter,
  useExisting: forwardRef(() => component),
});

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class TableSettingsFilter<T = any> {
  @Input() set updatedFilter(updatedFilter: T) {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.controls[key].setValue(updatedFilter[key] === undefined ? this._initialValue[key] : updatedFilter[key]);
    });
    this.updateFiltersActiveState();
  }

  @Output()
  readonly apply: EventEmitter<T> = new EventEmitter();

  hasFilters$: Observable<boolean>;
  skipEmptyArrayFields = [];

  private _initialValue: {};
  private _hasFilters: BehaviorSubject<boolean>;

  protected constructor(public form: UntypedFormGroup) {
    this._initialValue = cloneDeep(form.getRawValue());
    this._hasFilters = new BehaviorSubject(false);
    this.hasFilters$ = this._hasFilters.asObservable();
  }

  hasFilters(): boolean {
    return this._hasFilters.value;
  }

  updateFiltersActiveState(): void {
    const filterFunction = (obj: {}) =>
      filter(
        obj,
        (f: any, key) =>
          f !== null && f !== undefined && (this.skipEmptyArrayFields.includes(key) ? true : f?.length !== 0),
      );
    this._hasFilters.next(!isEqual(filterFunction(this._initialValue), filterFunction(this.form.value)));
  }

  validate(): boolean {
    this.form.markAllAsTouched();
    return this.form.valid;
  }

  abstract reset(): void;
  abstract filter(): void;
}
