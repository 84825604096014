import { Component, Input } from '@angular/core';
import { isHrmImageUrlDisabled } from '@features/users/views/users-main/utils/user-functions';
import { UsersApiService } from '@services/api';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, tap } from 'rxjs';

@Component({
  selector: 'app-user-photo',
  templateUrl: './user-photo.component.html',
  styleUrls: ['./user-photo.component.scss'],
})
export class UserPhotoComponent {
  defaultImage = './assets/images/user.png';
  loading = false;
  @Input() set value(value: string) {
    if (this.loading || !value || isHrmImageUrlDisabled()) return;
    this.loading = true;
    this.loadUserPhoto(value).subscribe();
  }
  @Input() isBackground = false;

  photo$ = new BehaviorSubject(this.defaultImage);
  backgroundPhoto$ = new BehaviorSubject(`url(${this.defaultImage})`);
  constructor(private readonly _usersApi: UsersApiService) {}

  loadUserPhoto(login: string, index: 1 | 2 = 1): Observable<string> {
    return this._usersApi.getAvatarLink(login, index).pipe(
      tap((link) => {
        this.photo$.next(link);
        this.backgroundPhoto$.next(`url(${link})`);
        this.loading = false;
      }),
    );
  }
}
