import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

import { ApiPaths } from '@common/consts';
import { CreateFolderDto, FolderDto, FolderLightDto, IdNameDto, PatchOperation } from '@common/dto';
import { FoldersFilterParams } from '@common/models/filters';
import { ImageDataInterface, ResultPart } from '@common/interfaces';

@Injectable({ providedIn: 'root' })
export class FoldersApiService {
  constructor(private http: HttpClient, private _sanitized: DomSanitizer) {}

  /** Возвращает папку по id */
  public getById(id: number): Observable<FolderDto> {
    return this.http.get<FolderDto>(`${ApiPaths.Folders}/${id}`);
  }

  /**
   * Возвращает заданное количество объектов, по умолчанию возвращает все.
   * @param filter - фильтр
   */
  public getList(filter?: FoldersFilterParams) {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<ResultPart<FolderDto>>(`${ApiPaths.Folders}/filter`, params);
  }

  /** Создает новую папку */
  public create(data: CreateFolderDto): Observable<FolderDto> {
    return this.http.post<FolderDto>(ApiPaths.Folders, data);
  }

  /** Обновляет отдельные поля папки */
  public update(data: FolderLightDto): Observable<FolderDto> {
    return this.http.put<FolderDto>(ApiPaths.Folders + `/${data.id}`, data);
  }

  /** Удаляет папку */
  public delete(id: number) {
    return this.http.delete(`${ApiPaths.Folders}/${id}`);
  }

  /** Удаляет несколько папок */
  public batchDelete(ids: number[]) {
    return this.http.post(`${ApiPaths.Folders}/batchDelete`, ids);
  }

  /** Пакетно редактирует папки */
  public batchModify(data: FolderLightDto[]) {
    return this.http.post(`${ApiPaths.Folders}/batchModify`, data);
  }

  /** Обновляет отдельные поля папки */
  public patch(entityId: number, patchData: PatchOperation[]): Observable<FolderDto> {
    return this.http.patch<FolderDto>(`${ApiPaths.Folders}/${entityId}`, patchData);
  }

  smartSearch(query: string): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.Folders}/smartSearch`, {
      params: {
        searchString: query,
      },
    });
  }

  /**
   * Возвращает папки по id[]
   * @param ids - айди папок
   */
  public getIdNames(ids: number[]): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.Folders}/idnames`, {
      params: {
        ids: ids.map((id) => id.toString()),
      },
    });
  }

  /**
   * Возвращает иконку предпросмотра по ID папки
   * @param folderId - ID папки
   */
  public getFolderPreview(folderId: number): Observable<ImageDataInterface> {
    return this.getFolderImageAsync(folderId).pipe(
      take(1),
      map((data) =>
        data
          ? {
              file: data,
              styleUrl: this._sanitized.bypassSecurityTrustStyle('url(' + URL.createObjectURL(data) + ')'),
              url: this._sanitized.bypassSecurityTrustUrl(URL.createObjectURL(data)),
              resourceUrl: this._sanitized.bypassSecurityTrustResourceUrl(URL.createObjectURL(data)),
            }
          : null,
      ),
      catchError(() => of(null)),
    );
  }

  getFolderImageAsync(folderId: number): Observable<Blob> {
    return this.http.get(`${ApiPaths.Folders}/${folderId}/image`, { responseType: 'blob' });
  }

  updateFolderImage(id: number, image: File | Blob): Observable<void> {
    const formData = new FormData();
    formData.append('file', image);

    return this.http.post<void>(`${ApiPaths.Folders}/${id}/image?forceUpdate=true`, formData);
  }

  removeFolderImage(folderId: number): Observable<void> {
    return this.http.delete<void>(`${ApiPaths.Folders}/${folderId}/image`);
  }
}
