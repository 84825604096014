import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ROUTES_PARTS } from '@common/consts';
import { ConditionLinkModule } from '@shared/components/condition-link';
import { PikTooltipModule } from '@pik-ui/ng-components';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-family-link',
  standalone: true,
  imports: [CommonModule, ConditionLinkModule, PikTooltipModule, TranslateModule],
  templateUrl: './family-link.component.html',
})
export class FamilyLinkComponent {
  @Input() set data(value: { id: number; versionNumber?: number; functionalTypeId?: number }) {
    const params = {};

    if (value.versionNumber) {
      params['version'] = value.versionNumber;
    }

    if (value.functionalTypeId) {
      params['functionalTypeId'] = value.functionalTypeId;
    }

    this.link = ['', ROUTES_PARTS.FAMILIES, value.id, params];
  }

  public link: any;
}
