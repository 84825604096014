import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import {
  PikButtonsModule,
  PikDropdownModule,
  PikInputModule,
  PikOptionModule,
  PikTooltipModule,
} from '@pik-ui/ng-components';

import { EmbedLinkComponent } from './embed-link/embed-link.component';
import { MarkdownEditorComponent } from './editor.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [MarkdownEditorComponent, EmbedLinkComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    PikButtonsModule,
    PikInputModule,
    PikTooltipModule,
    OverlayModule,
    PikOptionModule,
    PikDropdownModule,
    TranslateModule,
  ],
  exports: [MarkdownEditorComponent],
})
export class MarkdownEditorModule {}
