import { Component, Input } from '@angular/core';

import { ROUTES_PARTS, ThemeColors } from '@common/consts';
import { ProjectsFilterParams } from '@common/models/filters';
import { normalizePageParams } from '@common/utils';

@Component({
  selector: 'app-projects-tag',
  templateUrl: './projects-tag.component.html',
  styleUrls: ['./projects-tag.component.scss'],
})
export class ProjectsTagComponent {
  @Input() set filter(value: ProjectsFilterParams) {
    if (value) {
      this.params = normalizePageParams(value.toParams());
    }
  }
  params;

  colors = ThemeColors;
  routesParts = ROUTES_PARTS;
}
