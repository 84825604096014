import { TableColumnDef } from './models';
import { ColumnStorage } from './types';

export function mapColumnsToStorage(col: TableColumnDef): ColumnStorage {
  return {
    name: col.name,
    default: col.default,
    required: col.required,
    isVisible: col.isVisible,
    orderPosition: col.orderPosition,
  };
}

export function columnShouldBeVisible(col: TableColumnDef) {
  return col.default || col.required || col.name === 'tools';
}
