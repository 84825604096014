import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';

import { AttributeDto, DataTypeDto, DisciplineDto, IdNameDto, PermissionDto, RoleDto, UnitTypeDto } from '@common/dto';
import { DisciplinesApiService, RolesApiService } from './api';

import {
  AppState,
  LoadAttributes,
  LoadDataTypes,
  LoadDisciplines,
  LoadPermissions,
  LoadRequestStatuses,
  LoadRoles,
  LoadUnitTypes,
} from '../store';

@Injectable({
  providedIn: 'root',
})
export class DictionariesService {
  @Select(AppState.attributes) public attributes$: Observable<AttributeDto[]>;
  @Select(AppState.disciplines) public disciplines$: Observable<DisciplineDto[]>;
  @Select(AppState.requestStatuses) public requestStatuses$: Observable<IdNameDto[]>;
  @Select(AppState.roles) public roles$: Observable<RoleDto[]>;
  @Select(AppState.dataTypes) public dataTypes$: Observable<DataTypeDto[]>;
  @Select(AppState.unitTypes) public unitTypes$: Observable<UnitTypeDto[]>;
  @Select(AppState.permissions) public permissions$: Observable<PermissionDto[]>;

  constructor(private rolesApi: RolesApiService, private disciplinesApi: DisciplinesApiService, private store: Store) {}

  public loadAttributes() {
    return this.store.dispatch(new LoadAttributes());
  }

  public loadDisciplines() {
    return this.store.dispatch(new LoadDisciplines());
  }

  public loadRoles() {
    this.store.dispatch(new LoadRoles());
  }

  public loadPermissions() {
    this.store.dispatch(new LoadPermissions());
  }

  public loadDataTypes() {
    this.store.dispatch(new LoadDataTypes());
  }

  public loadUnitTypes(bindingSourceType?, force?) {
    return this.store.dispatch(new LoadUnitTypes(force ? force : false, bindingSourceType));
  }

  public loadRequestStatuses() {
    this.store.dispatch(new LoadRequestStatuses());
  }
}
