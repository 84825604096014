import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MaterialAttributeDto, ResultPart } from '@common/interfaces';
import { ApiPaths } from '@common/consts';
import { AttributeValueDto } from '@common/dto';
import { MaterialsFilterParams } from '@common/models/filters';

@Injectable({ providedIn: 'root' })
export class MaterialAttributesApiService {
  constructor(private http: HttpClient) {}

  /**
   * Возвращает заданное количество объектов, по умолчанию возвращает все.
   * @param filter - фильтр
   */
  public getList(filter: MaterialsFilterParams): Observable<ResultPart<AttributeValueDto>> {
    const params = filter.toQueryParams();
    return this.http.get<ResultPart<AttributeValueDto>>(`${ApiPaths.MaterialAttributes}`, { params });
  }

  /**
   * Создает новый атрибут материала
   * @param materialAttribute
   */
  public create(materialAttribute: Omit<MaterialAttributeDto, 'id'>): Observable<AttributeValueDto> {
    return this.http.post<AttributeValueDto>(ApiPaths.MaterialAttributes, materialAttribute);
  }

  /**
   * Возвращает атрибут материала по Id
   * @param materialAttribute
   */
  public update(materialAttribute: MaterialAttributeDto): Observable<AttributeValueDto> {
    return this.http.put<AttributeValueDto>(
      `${ApiPaths.MaterialAttributes}/${materialAttribute.id}`,
      materialAttribute,
    );
  }

  /**
   * Удаляет атрибут материала
   * @param materialAttributeId - id
   */
  public remove(materialAttributeId: number): Observable<void> {
    return this.http.delete<void>(`${ApiPaths.MaterialAttributes}/${materialAttributeId}`);
  }
}
