import { Component, Input } from '@angular/core';
import { AttributeValueDto } from '@common/dto';
import { AttributeType } from '@common/enums';
import { ROUTES, ThemeColors } from '@common/consts';
import { normalizePageParams } from '@common/utils';
import { PermissionService } from '@core/account/services';

@Component({
  selector: 'app-table-attributes',
  templateUrl: './table-attributes.component.html',
  styleUrls: ['./table-attributes.component.scss'],
})
export class TableAttributesComponent {
  @Input() attributeName: string;

  @Input() set data(value: { attributes: AttributeValueDto[] }) {
    const attribute = value.attributes.find((a) => a.attributeName === this.attributeName);
    if (!value.attributes.length) {
      this.attributeValues = [];
    }
    if (attribute) {
      this.attributeValues = (
        attribute.attributeType === AttributeType.String ? [{ id: null, name: attribute.valueStr }] : attribute.options
      ).map((d) => ({
        ...d,
        params: [
          '',
          ROUTES.attributes,
          normalizePageParams({ id: attribute.attributeId, searchValue: attribute.attributeId, searchField: 'id' }),
        ],
      }));
    }
  }

  ThemeColors = ThemeColors;
  attributeValues: { id: number; name: string; params: any }[] = [];
  maxCount = 5;
  isShowAllCount: boolean;

  constructor(public permissionService: PermissionService) {}

  toggle() {
    this.isShowAllCount = !this.isShowAllCount;
  }

  trackByIndex(index): number {
    return index;
  }
}
