import { NgModule } from '@angular/core';
import { SelectComponent } from './select.component';
import { PikSearchInputModule, PikSelectModule } from '@pik-ui/ng-components';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [SelectComponent],
  exports: [SelectComponent],
  imports: [PikSelectModule, ReactiveFormsModule, TranslateModule, PikSearchInputModule, CommonModule],
})
export class SelectModule {}
