import { PIK_MODAL_DATA, PikModalRef } from '@pik-ui/ng-components';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'fm-modal-message',
  templateUrl: './fm-modal-message.component.html',
  styleUrls: ['./fm-modal-message.component.scss'],
})
export class FmModalMessageComponent {
  constructor(
    public dialogRef: PikModalRef<FmModalMessageComponent>,
    @Inject(PIK_MODAL_DATA) public data: { title: string; message: string },
  ) {
    this.dialogRef.config.center = true;
  }

  close() {
    this.dialogRef.close();
  }
}
