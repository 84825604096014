export const REQUEST_STATUS = {
  FILING: {
    name: 'REQUEST_MODULE.REQUEST_STATUS.FILING',
    id: 1,
    order: 1,
  },
  MODERATION: {
    name: 'REQUEST_MODULE.REQUEST_STATUS.MODERATION',
    id: 2,
    order: 2,
  },
  APPROVING: {
    name: 'REQUEST_MODULE.REQUEST_STATUS.APPROVING',
    id: 3,
    order: 3,
  },
  SET_DEVELOPER: {
    name: 'REQUEST_MODULE.REQUEST_STATUS.SET_DEVELOPER',
    id: 4,
    order: 4,
  },
  DEVELOPING: {
    name: 'REQUEST_MODULE.REQUEST_STATUS.DEVELOPING',
    id: 5,
    order: 5,
  },
  CHECKING_MODERATOR: {
    name: 'REQUEST_MODULE.REQUEST_STATUS.CHECKING_MODERATOR',
    id: 6,
    order: 6,
  },
  CHECKING_INITIATOR: {
    name: 'REQUEST_MODULE.REQUEST_STATUS.CHECKING_INITIATOR',
    id: 7,
    order: 7,
  },
  RESOLVED: {
    name: 'REQUEST_MODULE.REQUEST_STATUS.RESOLVED',
    id: 8,
    order: 0,
  },
  CANCELED: {
    name: 'REQUEST_MODULE.REQUEST_STATUS.CANCELED',
    id: 9,
    order: 0,
  },
};
