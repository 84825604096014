export enum BindingPriority {
  Normal = 0,
  Elevated = 1,
}

export const BINDING_PRIORITY_LABEL: { [key in BindingPriority]: string } = {
  [BindingPriority.Normal]: 'COMMON.NORMAL',
  [BindingPriority.Elevated]: 'COMMON.HIGH',
};

export const BINDING_PRIORITY = [
  {
    id: BindingPriority.Normal,
    name: BINDING_PRIORITY_LABEL[BindingPriority.Normal],
  },
  {
    id: BindingPriority.Elevated,
    name: BINDING_PRIORITY_LABEL[BindingPriority.Elevated],
  },
];
