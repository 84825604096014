import { IdNameDto } from '@common/dto';

export enum Status {
  Allowed = 1,
  Review = 2,
  Forbidden = 3,
  Outdated = 4,
}

export const STATUS_NAMES = {
  [Status.Allowed]: 'STATUS.ALLOWED',
  [Status.Review]: 'STATUS.REVIEW',
  [Status.Forbidden]: 'STATUS.FORBIDDEN',
  [Status.Outdated]: 'STATUS.OUTDATED',
};

export const STATUS_COLORS = {
  [Status.Allowed]: '#27AE60',
  [Status.Review]: '#EFB508',
  [Status.Forbidden]: '#F12A66',
  [Status.Outdated]: '#BDBDBD',
};

export const STATUSES: IdNameDto[] = [
  { id: Status.Allowed, name: STATUS_NAMES[Status.Allowed] },
  { id: Status.Review, name: STATUS_NAMES[Status.Review] },
  { id: Status.Forbidden, name: STATUS_NAMES[Status.Forbidden] },
  { id: Status.Outdated, name: STATUS_NAMES[Status.Outdated] },
];
