import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarkdownViewerComponent } from './viewer.component';
import { PikAttachmentsCarouselModule, PikButtonsModule, PikEllipsisTextModule } from '@pik-ui/ng-components';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [MarkdownViewerComponent],
  imports: [CommonModule, PikAttachmentsCarouselModule, TranslateModule, PikButtonsModule, PikEllipsisTextModule],
  exports: [MarkdownViewerComponent],
})
export class MarkdownViewerModule {}
