<div class="modal-wrap">
  <h3 pikModalTitle>{{ 'COMPONENT_MODAL_GOOGLE.TITLE' | translate }}</h3>
  <p>
    {{ 'COMPONENT_MODAL_GOOGLE.MESSAGE' | translate }}
    <a target="_blank" href="https://docs.google.com/spreadsheets/d/{{ data.googleId }}"
      >https://docs.google.com/spreadsheets/d/{{ data.googleId }}</a
    >
  </p>
  <pik-button-group pikModalActions>
    <button pikButton size="medium" (click)="dialogRef.close()">{{ 'COMMON.OK' | translate }}</button>
  </pik-button-group>
</div>
