import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { Tab, TabType } from '@common/interfaces';
import {
  BindingsApiService,
  FamiliesApiService,
  FunctionalTypesApiService,
  MaterialsApiService,
  NomenclatureTypesApiService,
  ProjectsApiService,
  UsersApiService,
} from '@services/api';
import { map, take, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class FmTabsService {
  private static tabsIsEqual(tab1: Tab, tab2: Tab): boolean {
    return tab1.type === tab2.type && tab1.id === tab2.id;
  }
  tabs$: BehaviorSubject<Tab[]> = new BehaviorSubject<Tab[]>([]);
  readonly PARAM_PART_SPLITTER = /[?;]/;

  private readonly LOCALSTORAGE_TOKEN = 'FM2Tabs';
  private readonly DEFAULT_REDIRECT_PATH = '';
  private tabs: Tab[] = [];

  constructor(
    private router: Router,
    private zone: NgZone,
    private familiesApi: FamiliesApiService,
    private functionalTypeApi: FunctionalTypesApiService,
    private nomenclatureTypesApi: NomenclatureTypesApiService,
    private materialsApi: MaterialsApiService,
    private usersApi: UsersApiService,
    private bindingsApi: BindingsApiService,
    private projectsApi: ProjectsApiService,
    private translateService: TranslateService,
  ) {
    this.loadTabs();
  }

  openTabById(id: number, tabType: TabType, params?: any) {
    let request: Observable<string> = of(null);
    switch (tabType) {
      case TabType.BINDINGS_INTERNAL_FAMILY:
      case TabType.BINDINGS_SNAP_FAMILY:
      case TabType.FAMILY:
        request = this.familiesApi.getIdNames([id]).pipe(map((data) => data[0].name));
        break;
      case TabType.REQUESTS:
        request = of(`${this.translateService.instant('COMMON_LABELS.REQUEST')} №${id}`);
        break;
      case TabType.BINDINGS_INTERNAL_FUNCTIONAL_TYPE:
      case TabType.BINDINGS_SNAP_FUNCTIONAL_TYPE:
      case TabType.FUNCTIONAL_TYPE:
        request = this.functionalTypeApi.getIdNames([id]).pipe(map((data) => data[0].name));
        break;
      case TabType.NOMENCLATURE_TYPE:
        request = this.nomenclatureTypesApi.getIdNames([id]).pipe(map((data) => data[0].name));
        break;
      case TabType.BINDINGS_INTERNAL_MATERIAL:
      case TabType.BINDINGS_SNAP_MATERIAL:
      case TabType.MATERIAL:
        request = this.materialsApi.getIdNames([id]).pipe(map((data) => data[0].name));
        break;
      case TabType.USERS:
        request = this.usersApi.getIdNames([id]).pipe(map((data) => data[0].name));
        break;
    }
    request
      .pipe(
        take(1),
        tap((name: string) => this.openTab(tabType, id, name, false, params)),
      )
      .subscribe();
  }

  public openTab(type, id: string | number, name: string, withNavigate = true, params?: any) {
    if (isNaN(+id)) {
      return;
    }

    const tab: Tab = { id: id?.toString(), type, name, params };

    if (!this.findTab(tab)) {
      this.tabs.push(tab);
    } else {
      this.tabs = this.tabs.map((t) => (t.id === tab.id && t.type === tab.type ? tab : t));
    }
    this.saveTabs();
    if (withNavigate) {
      this.navigateTab(tab);
    }
  }

  closeAllTabs() {
    this.tabs = this.tabs.filter((t) => this.isActiveTab(t));
    if (!this.checkUrl()) {
      this.navigateLast();
    }
    this.saveTabs();
  }

  closeTab(type: TabType, id: number | string) {
    const tab: Tab = { type, id: id?.toString() || null };
    const tabToClose = this.findTab(tab);
    this.tabs = this.tabs.filter((tabItem: Tab) =>
      tabToClose ? tabToClose !== tabItem : tabItem.name !== tab.name && tabItem.type !== tab.type && !tab.id,
    );

    this.isActiveTab(tab) && this.navigateLast();
    this.saveTabs();
  }

  generateLink(tab: Tab): string {
    let urlSrt = `${tab.type}/${tab.id}`;
    if (tab.params) {
      urlSrt =
        urlSrt +
        ';' +
        Object.entries(tab.params)
          .map(([key, val]) => `${key}=${val}`)
          .join(';');
    }
    return urlSrt;
  }

  getRouterLink(tab: Tab): string {
    return `${tab.type}/${tab.id}`;
  }

  getQueryParams(tab: Tab): object {
    return tab.params;
  }

  private isActiveTab(tab: Tab): boolean {
    const pathPart = this.router.url.split(this.PARAM_PART_SPLITTER)[0];
    return pathPart === `/${tab.type}/${tab.id}`;
  }

  private async navigateTab(tab: Tab) {
    const link = this.generateLink(tab);
    await this._navigate(link);
  }

  private loadTabs() {
    this.tabs = JSON.parse(localStorage.getItem(this.LOCALSTORAGE_TOKEN)) || [];
    this.tabs$.next(this.tabs);
  }

  private saveTabs() {
    localStorage.setItem(this.LOCALSTORAGE_TOKEN, JSON.stringify(this.tabs));
    this.tabs$.next(this.tabs);
  }

  public findTab(tab: Tab): Tab | undefined {
    return this.tabs.find((tabItem: Tab) => FmTabsService.tabsIsEqual(tab, tabItem));
  }

  private navigateLast() {
    if (this.tabs.length) {
      const link = this.generateLink(this.tabs[this.tabs.length - 1]);
      this._navigate(link);
    } else {
      this._navigate(this.DEFAULT_REDIRECT_PATH);
    }
  }

  private _navigate(link) {
    this.zone.run(() => this.router.navigateByUrl(link));
  }

  private checkUrl() {
    let url = this.router.url;
    url = url.split(';').length > 1 ? url.split(';')[0] : url;
    const urlSegments = url.split('/');
    return isNaN(+urlSegments[urlSegments.length - 1]);
  }
}
