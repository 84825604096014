import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tabs-router',
  templateUrl: './tabs-router.component.html',
  styleUrls: ['./tabs-router.component.scss'],
  standalone: true,
})
export class TabsRouterComponent {
  @Input() fullCount: number;
  @Input() limitMin: number;
  @Input() limit: number;
  @Input() pageIndex: number;
  @Input() pageSizesList: number[];
  @Input() inverse = false;
  @Input() entitiesLabel = 'TABLE_PAGINATOR.ELEMENTS';

  @Output() pageNumberChange = new EventEmitter<number>();
  @Output() pageSizeChange = new EventEmitter<number>();
}
