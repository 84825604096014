import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadAppButtonsComponent } from './download-app-buttons.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { PikButtonsModule, PikLoaderModule } from '@pik-ui/ng-components';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DownloadAppButtonsComponent],
  imports: [CommonModule, AngularSvgIconModule, PikButtonsModule, PikLoaderModule, TranslateModule],
  exports: [DownloadAppButtonsComponent],
})
export class DownloadAppButtonsModule {}
