import { Component, Input } from '@angular/core';
import { ROUTES } from '@common/consts';
import { normalizePageParams } from '@common/utils';
import { FunctionalTypesParametersFilterParams, IFunctionalTypesParametersFilterParams } from '@common/models/filters';
import { ConditionLinkModule } from '@shared/components/condition-link';
import { PermissionService } from '@core/account/services';

@Component({
  standalone: true,
  selector: 'app-functional-types-parameters-link',
  templateUrl: './functional-types-parameters-link.component.html',
  imports: [ConditionLinkModule],
})
export class FunctionalTypesParametersLinkComponent {
  @Input() set params(value: IFunctionalTypesParametersFilterParams) {
    if (value) {
      this.link = [
        '',
        ROUTES.functionalTypesParameters,
        normalizePageParams(new FunctionalTypesParametersFilterParams(value).toParams()),
      ];
    }
  }
  @Input() name: string;

  link: any[];

  constructor(public permissionsService: PermissionService) {}
}
