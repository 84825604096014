import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[yandexTarget]',
})
export class YandexTargetDirective {
  @Input('yandexTarget') params: any;

  @HostListener('click', ['$event']) onClick($event) {
    if (this.params) {
      (<any>window)?.ym && (<any>window)?.ym(55939810, 'reachGoal', this.params);
    }
  }
}
