<ng-container *ngIf="viewInit">
  <div
    class="expand-container"
    [ngClass]="{ expand, expandable: content.offsetHeight > maxHeight }"
    [ngStyle]="{ 'max-height': maxHeight + 'px' }"
  >
    <div class="expand-content" #content>
      <ng-content></ng-content>
    </div>
  </div>

  <div class="expand-button" *ngIf="content.offsetHeight > maxHeight">
    <button (click)="toggleExpand()" [ngClass]="{expand}">
      <svg-icon
        [pikTooltip]="(expand ? 'COMMON.COLLAPSE' : 'COMMON.EXPAND') | translate"
        svgClass="expand-icon"
        src="./assets/icons/tree-angle-active.svg"
      ></svg-icon>
    </button>
  </div>
</ng-container>
