import { Component, Input } from '@angular/core';
import { ROUTES_PARTS } from '@common/consts';
import { ConditionLinkModule } from '@shared/components/condition-link';

@Component({
  standalone: true,
  selector: 'app-request-card-link',
  imports: [ConditionLinkModule],
  templateUrl: './request-card-link.component.html',
})
export class RequestCardLinkComponent {
  @Input() set id(value: number) {
    this.link = ['', ROUTES_PARTS.REQUESTS, value];
  }
  link: any[];
}
