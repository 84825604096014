import { ParameterValueType, VALUE_REGEX } from '../types';
import { parseInterval } from './parse-interval';
import { DataTypeCodeEnum } from '@common/enums';

export function parseValue(
  value: string,
  dataType?: DataTypeCodeEnum,
): { value?: string; type: ParameterValueType; intervalValue?: string[] } {
  switch (dataType) {
    case DataTypeCodeEnum.String:
      return value === '*'
        ? {
            value,
            type: ParameterValueType.AnyString,
          }
        : !!value.match(VALUE_REGEX.stringWithSpecialSymbols)
        ? {
            value,
            type: ParameterValueType.StringWithSpecSymbols,
          }
        : {
            value,
            type: ParameterValueType.Default,
          };
    case DataTypeCodeEnum.Integer:
    case DataTypeCodeEnum.Double:
      return value === '(..)'
        ? {
            value,
            type: ParameterValueType.AnyNumber,
          }
        : !!value.match(VALUE_REGEX.interval)
        ? {
            intervalValue: parseInterval(value),
            type: ParameterValueType.Interval,
          }
        : {
            value,
            type: ParameterValueType.Default,
          };
    case DataTypeCodeEnum.Boolean:
      return {
        value,
        type: ParameterValueType.Boolean,
      };
    default:
      return {
        value,
        type: ParameterValueType.Default,
      };
  }
}
