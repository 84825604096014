import { Component, Input } from '@angular/core';
import { ROUTES_PARTS, ThemeColors } from '@common/consts';
import { IdNameDto } from '@common/dto';

@Component({
  selector: 'app-user-link',
  templateUrl: './user-link.component.html',
})
export class UserLinkComponent {
  @Input() user: IdNameDto;
  ROUTES_PARTS = ROUTES_PARTS;
  protected readonly ThemeColors = ThemeColors;
}
