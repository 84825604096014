import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash';

import { ApiPaths, ROUTES } from '@common/consts';
import { IdNameDto, UserStandardDto } from '@common/dto';
import { UsersApiService } from '@services/api';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-standard-tags',
  templateUrl: './standard-tags.component.html',
  styleUrls: ['./standard-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandardTagsComponent {
  @Input() set standards(data: UserStandardDto[]) {
    this._standards = _.orderBy(data, 'name', 'asc');
  }
  @Input() active: number;
  @Input() short: boolean;
  @Input() switchable: boolean;

  ApiPaths = ApiPaths;
  _standards: UserStandardDto[];

  constructor(private _router: Router, private usersApi: UsersApiService) {}

  trackByIndex(index): number {
    return index;
  }

  navigateToRole(role: IdNameDto) {
    const id = JSON.stringify(role.id.toString());
    this._router.navigate([ROUTES.standards, { id, searchValue: id, searchField: JSON.stringify('id') }]);
  }

  changeStandard(standard: UserStandardDto) {
    if (this.switchable) {
      return this.usersApi
        .changeStandard(standard.id)
        .pipe(
          take(1),
          tap(() => window.location.reload()),
        )
        .subscribe();
    }
  }
}
