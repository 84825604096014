import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { DataTypeCodeEnum } from '@common/enums';
import { takeUntil, tap } from 'rxjs/operators';
import { ParameterValueControlService } from './services';

@Component({
  selector: 'app-parameter-value-control',
  templateUrl: './parameter-value-control.component.html',
  styleUrls: ['./parameter-value-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ParameterValueControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ParameterValueControlComponent,
    },
    { provide: ParameterValueControlService },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParameterValueControlComponent implements OnDestroy, OnInit, ControlValueAccessor {
  @Input() set dataTypeCode(value: DataTypeCodeEnum) {
    this.parameterValueControlService.dataType$.next(value ?? DataTypeCodeEnum.String);

    if (this.valueControl) {
      setTimeout(() => this.valueControl.updateValueAndValidity());
    }
  }

  @Input() set validValues(value: string) {
    this.parameterValueControlService.validValues$.next(value);
  }
  @Input() set validValuesDescription(value: string) {
    this.parameterValueControlService.validValuesDescription$.next(value);
  }

  @Input() set maxValuesCount(value: number) {
    this.parameterValueControlService.maxValuesCount$.next(value);
  }

  @Input() set allowEmptyValue(value: boolean) {
    this.parameterValueControlService.allowEmptyValue = value;
  }

  @Input() isDisable: boolean;

  @Output() isSomeValueAdded = new EventEmitter<boolean>(false);

  DataTypeCodeEnum = DataTypeCodeEnum;
  valueControl = new FormControl();

  private _destroy$ = new Subject();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(
    public parameterValueControlService: ParameterValueControlService,
    private _changeDetector: ChangeDetectorRef,
  ) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  validate() {
    return this.valueControl.invalid ? { invalidValue: true } : null;
  }

  ngOnInit() {
    this.subscribeToControl().subscribe();
  }

  writeValue(value: string) {
    this.valueControl.setValue(value, { emitEvent: false });
    this.isSomeValueAdded.emit(!!value);
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.valueControl.disable() : this.valueControl.enable();
    this._changeDetector.markForCheck();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  nextChangeValue(value: string): void {
    this.isSomeValueAdded.emit(!!this.parameterValueControlService.values$.getValue().length);

    if (this._onChange) {
      this._onChange(value);
    }
  }

  private subscribeToControl() {
    return this.valueControl.valueChanges.pipe(
      tap((d) => this.nextChangeValue(d)),
      takeUntil(this._destroy$),
    );
  }
}
