import { Component, Input } from '@angular/core';
import { ConditionLinkModule } from '@shared/components/condition-link';
import { CardLinkInterface } from '@shared/components/links/card-link/card-link.interface';
import { FmTabsService } from '@services';
import { Tab } from '@common/interfaces';

@Component({
  standalone: true,
  selector: 'app-card-link',
  imports: [ConditionLinkModule],
  templateUrl: './card-link.component.html',
  styleUrls: ['./card-link.component.scss'],
})
export class CardLinkComponent {
  link: any[];
  isAccess = true;
  @Input() set params(value: CardLinkInterface) {
    const tab: Tab = { type: value.type, id: value.id.toString() };
    const params = Object.values(value.params ?? {}).length
      ? value.params
      : this.fmTabService.findTab(tab)?.params ?? {};
    this.link = ['', value.type, value.id, params];
    if (value.isAccess === false) {
      this.isAccess = value.isAccess;
    } else {
      this.isAccess = true;
    }
  }
  constructor(public fmTabService: FmTabsService) {}
}
