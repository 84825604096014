import { Injector, ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DragDropModule } from '@angular/cdk/drag-drop';

import {
  PikButtonsModule,
  PikCardModule,
  PikCheckboxModule,
  PikDrawerModule,
  PikSearchInputModule,
  PikSelectModule,
  PikTabsModule,
} from '@pik-ui/ng-components';

import {
  FmTablePageFilterDirective,
  FmTablePageHeaderDirective,
  FmTablePageTitleDirective,
  FmTablePageToolbarActionDirective,
  FmTablePageToolbarDirective,
} from './layout';

import { TableSettingsComponent } from './table-settings/table-settings.component';
import { FmTablePageComponent } from './table-page-component/table-page-component.component';
import { TableBuilderComponent } from './table-builder/table-builder.component';
import { TABLE_COLUMNS, TABLE_PAGE_NAME, TABLE_SEARCH_TYPES } from './tokens';
import { ITablePageDef } from './types';
import { TablePageServiceBase } from './models';
import { FmTableModule } from '@shared/modules/fm-table';
import { FmInfoTooltipModule } from '@shared/components/fm-ui/fm-info-tooltip/fm-info-tooltip.module';
import { TableBuilderLocatorService } from './table-builder-locator';
import { TranslateModule } from '@ngx-translate/core';
import { MultiSelectModule } from '@shared/components/controls/multi-select/multi-select.module';
import { AttributesListControlModule } from '@shared/components/controls/attributes-list-control';

const layout = [
  FmTablePageComponent,
  FmTablePageHeaderDirective,
  FmTablePageTitleDirective,
  FmTablePageToolbarDirective,
  FmTablePageFilterDirective,
  FmTablePageToolbarActionDirective,
];

@NgModule({
  declarations: [TableSettingsComponent, ...layout, TableBuilderComponent],
  imports: [
    CommonModule,
    PikDrawerModule,
    PikTabsModule,
    PikButtonsModule,
    PikCheckboxModule,
    PikCardModule,
    ReactiveFormsModule,
    FmTableModule,
    AngularSvgIconModule,
    PikSelectModule,
    PikSearchInputModule,
    FmInfoTooltipModule,
    DragDropModule,
    TranslateModule,
    MultiSelectModule,
    AttributesListControlModule,
  ],
  providers: [TableBuilderLocatorService],
  exports: [TableSettingsComponent, ...layout, TableBuilderComponent, FmTableModule],
})
export class TableBuilderModule {
  static forFeature(pageDef: ITablePageDef): ModuleWithProviders<TableBuilderModule> {
    const providers: Provider[] = [
      { provide: TABLE_COLUMNS, useValue: pageDef.columns, name: pageDef.name },
      { provide: TABLE_PAGE_NAME, useValue: pageDef.name },
      { provide: TABLE_SEARCH_TYPES, useValue: pageDef.searchTypes },
    ];

    if (pageDef?.service) {
      providers.push({ provide: TablePageServiceBase, useClass: pageDef.service });
    }

    return {
      ngModule: TableBuilderModule,
      providers,
    };
  }

  constructor(private injector: Injector) {
    TableBuilderLocatorService.injector = this.injector;
  }
}
