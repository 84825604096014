import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AttributeValueDto, FamilyAttributeCreateDto, FamilyAttributeUpdateDto } from '@common/dto';
import { ApiPaths } from '@common/consts';

@Injectable({ providedIn: 'root' })
export class FamilyAttributesApiService {
  constructor(private http: HttpClient) {}

  public getById(entityId: number): Observable<AttributeValueDto> {
    return this.http.get<AttributeValueDto>(`${ApiPaths.FamilyAttributes}/${entityId}`);
  }

  public create(entity: FamilyAttributeCreateDto): Observable<AttributeValueDto> {
    return this.http.post<AttributeValueDto>(ApiPaths.FamilyAttributes, entity);
  }

  public update(entity: FamilyAttributeUpdateDto): Observable<AttributeValueDto> {
    return this.http.put<AttributeValueDto>(`${ApiPaths.FamilyAttributes}/${entity.id}`, entity);
  }

  public remove(entityId: number): Observable<void> {
    return this.http.delete<void>(`${ApiPaths.FamilyAttributes}/${entityId}`);
  }
}
