<div class="bindings-tags">
  <ng-container *ngIf="internalCount || snapCount; else noCounts">
    <app-bindings-link-card
      [bindingSourceType]="BindingSourceType.Internal"
      [bindingType]="bindingType"
      [entityId]="entityId"
    >
      <pik-tag large [color]="colors.Binding"> {{ 'COMMON_LABELS.INTERNAL' | translate }} {{ internalCount }} </pik-tag>
    </app-bindings-link-card>

    <app-bindings-link-card
      [bindingSourceType]="BindingSourceType.Snap"
      [bindingType]="bindingType"
      [entityId]="entityId"
    >
      <pik-tag large [color]="colors.Binding"> {{ 'COMMON_LABELS.EXTERNAL' | translate }} {{ snapCount }} </pik-tag>
    </app-bindings-link-card>
  </ng-container>
  <ng-template #noCounts>
    {{ 'COMMON.NOT_AVAILABLE' | translate }}
  </ng-template>
</div>
