export enum RequestActions {
  Reject = 'Reject',
  SetModerator = 'SetModerator',
  SetApprover = 'SetApprover',
  SetDeveloper = 'SetDeveloper',
  SelfApprove = 'SelfApprove',
  SelfDeveloper = 'SelfDeveloper',
  SelfResolve = 'SelfResolve',
  SendResolve = 'SendResolve',
  CancelRequest = 'CancelRequest',
  RemoveRequest = 'RemoveRequest',
  ApproveRequest = 'ApproveRequest',
  ChangePlanDate = 'ChangePlanDate',
  OfferSelfDeveloper = 'OfferSelfDeveloper',
  RemoveDeveloper = 'RemoveDeveloper',
  ReturnToApprove = 'ReturnToApprove',
  ApproveSolution = 'ApproveSolution',
  RejectSolution = 'RejectSolution',
  ApproveInitiatorSolution = 'ApproveInitiatorSolution',
  ReturnDevelop = 'ReturnDevelop',
  ReturnFiling = 'ReturnFiling',
  ModeratorAttributes = 'ModeratorAttributes',
}
