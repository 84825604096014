export enum RequestsSubType {
  RequestPerInitiator,
  RequestNew = 'RequestNew',
  Moderation = 'Moderation',
  Approving = 'Approving',
  DeveloperSetting = 'DeveloperSetting',
  Development = 'Development',
  Verification = 'Verification',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
}
