import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplateRef, ViewContainerRef } from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { DOWN_ARROW, ENTER, ESCAPE, TAB, UP_ARROW } from '@angular/cdk/keycodes';
import { filter } from 'rxjs/operators';
import { mentionDropdownPositions } from './dropdown-positions';

export class MentionDropdown {
  onSelectOption = new Subject();

  private _opened: boolean;
  private _overlayRef: OverlayRef | null;
  private _subscription: Subscription;
  private _keyboardSubs: Subscription;

  constructor(
    private editor: any,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private dropdownTemplateRef: TemplateRef<any>,
  ) {}

  public open() {
    if (!this._opened) {
      const { x, y } = this._getDropdownPosition();

      const positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo({ x, y })
        .withPositions(mentionDropdownPositions)
        .withTransformOriginOn('.fm-text-editor');

      const overlayConfig = new OverlayConfig({
        positionStrategy,
        scrollStrategy: this.overlay.scrollStrategies.close(),
        panelClass: 'mention-dropdown-panel',
        hasBackdrop: true,
        backdropClass: '',
      });

      this._overlayRef = this.overlay.create(overlayConfig);
      this._overlayRef.attach(new TemplatePortal(this.dropdownTemplateRef, this.viewContainerRef));

      this._subscription = this._overlayRef.backdropClick().subscribe(() => this.close());
      this._keyboardSubs = this._subscribeToKeyboard();

      this._opened = true;
    }
  }

  public close() {
    if (this._opened) {
      if (this._overlayRef) {
        this._overlayRef.dispose();
        this._overlayRef = null;
      }

      if (this._keyboardSubs) {
        this._keyboardSubs.unsubscribe();
      }

      if (this._subscription) {
        this._subscription.unsubscribe();
      }

      this._opened = false;
    }
  }

  destroy() {
    if (this.onSelectOption) {
      this.onSelectOption.complete();
    }
  }

  private _subscribeToKeyboard() {
    const activeClass = 'pik-option--active';

    return fromEvent(document, 'keydown')
      .pipe(
        filter((event: KeyboardEvent) => {
          const keys = [TAB, ESCAPE, ENTER, DOWN_ARROW, UP_ARROW];
          const { keyCode } = event;
          return keys.includes(keyCode);
        }),
      )
      .subscribe((event: KeyboardEvent) => {
        const { keyCode } = event;
        const isTabKey = keyCode === TAB;
        const isEscKey = keyCode === ESCAPE;
        const isArrowKey = keyCode === DOWN_ARROW || keyCode === UP_ARROW;
        const isEnter = keyCode === ENTER;

        if (isTabKey || isEscKey) {
          this.close();
        }

        const options = Array.from(document.querySelectorAll('.mention-dropdown-panel .pik-option')) as HTMLElement[];

        if (isArrowKey && options.length > 0) {
          event.preventDefault();

          const currentActiveOption = options.find((item) => item.classList.contains(activeClass));
          const currentActiveOptionIndex = options.findIndex((item) => item.classList.contains(activeClass));

          if (currentActiveOption) {
            currentActiveOption.classList.remove(activeClass);
          }

          let nextOptionToActivate: HTMLElement;

          if (keyCode === DOWN_ARROW) {
            const isOptionLast = options[options.length - 1] === currentActiveOption;
            nextOptionToActivate = !isOptionLast ? options[currentActiveOptionIndex + 1] : options[0];
          }

          if (keyCode === UP_ARROW) {
            const isOptionFirst = options[0] === currentActiveOption;

            if (isOptionFirst || !currentActiveOption) {
              nextOptionToActivate = options[options.length - 1];
            } else {
              nextOptionToActivate = options[currentActiveOptionIndex - 1];
            }
          }

          nextOptionToActivate.classList.add(activeClass);
          this.scrollToOption(nextOptionToActivate);
        }

        if (isEnter) {
          event.preventDefault();
          event.stopPropagation();

          const currentActiveOption = options.find((item) => item.classList.contains(activeClass));

          if (currentActiveOption) {
            this.onSelectOption.next(parseInt(currentActiveOption.dataset.id, 0));
          }
        }
      });
  }

  public scrollToOption(element: HTMLElement) {
    const scrollElement = document.querySelector('.pik-dropdown-content .ps') as HTMLElement;

    const _options = {
      offsetTop: 0,
      offsetBottom: 0,
      paddingTop: 4,
      paddingBottom: 4,
    };

    const dropdownHeight = scrollElement.offsetHeight;
    const dropdownScrollTop = scrollElement.scrollTop;
    const optionHeight = element.offsetHeight;
    const optionOffset = element.offsetTop;

    let scrollPosition = dropdownScrollTop;

    if (optionOffset < scrollElement.scrollTop) {
      scrollPosition = optionOffset - _options.paddingTop;
    }

    if (optionOffset + optionHeight > dropdownScrollTop + dropdownHeight) {
      scrollPosition = Math.max(0, optionOffset - dropdownHeight + optionHeight) + _options.paddingBottom;
    }

    scrollElement.scrollTop = scrollPosition;
  }

  private _getDropdownPosition() {
    const mention = this.editor.getModule('mention');

    const containerPos = this.editor.container.getBoundingClientRect();
    const mentionCharPos = mention.quill.getBounds(mention.mentionCharPos);
    const containerHeight = mention.mentionContainer.offsetHeight;

    let topPos = mention.options.offsetTop;
    let leftPos = mention.options.offsetLeft;

    // handle horizontal positioning
    if (mention.options.fixMentionsToQuill) {
      const rightPos = 0;
      mention.mentionContainer.style.right = `${rightPos}px`;
    } else {
      leftPos += mentionCharPos.left;
    }

    if (mention.containerRightIsNotVisible(leftPos, containerPos)) {
      const containerWidth = mention.mentionContainer.offsetWidth + mention.options.offsetLeft;
      const quillWidth = containerPos.width;
      leftPos = quillWidth - containerWidth;
    }

    // handle vertical positioning
    if (mention.options.defaultMenuOrientation === 'top') {
      // Attempt to align the mention container with the top of the quill editor
      if (mention.options.fixMentionsToQuill) {
        topPos = -1 * (containerHeight + mention.options.offsetTop);
      } else {
        topPos = mentionCharPos.top - (containerHeight + mention.options.offsetTop);
      }

      // default to bottom if the top is not visible
      if (topPos + containerPos.top <= 0) {
        let overMentionCharPos = mention.options.offsetTop;

        if (mention.options.fixMentionsToQuill) {
          overMentionCharPos += containerPos.height;
        } else {
          overMentionCharPos += mentionCharPos.bottom;
        }

        topPos = overMentionCharPos;
      }
    } else {
      // Attempt to align the mention container with the bottom of the quill editor
      if (mention.options.fixMentionsToQuill) {
        topPos += containerPos.height;
      } else {
        topPos += mentionCharPos.bottom;
      }

      // default to the top if the bottom is not visible
      if (mention.containerBottomIsNotVisible(topPos, containerPos)) {
        let overMentionCharPos = mention.options.offsetTop * -1;

        if (!mention.options.fixMentionsToQuill) {
          overMentionCharPos += mentionCharPos.top;
        }

        topPos = overMentionCharPos - containerHeight;
      }
    }

    return {
      x: leftPos + containerPos.x,
      y: topPos + containerPos.y,
    };
  }
}
