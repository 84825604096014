import { TablePageFilter } from '../table-page-filter-base';
import { IMaterialsFilterParams } from '@common/models/filters';
import { AppType } from '@common/enums';
import { FilterAttributeData, RangeDateDto } from '@common/dto';
import { formatRangeDate } from '@shared/utils';

const DEFAULT_PARAMS: IMaterialsFilterParams = {
  defaultSortBy: 'code',
};

export class MaterialsFilterParams extends TablePageFilter<IMaterialsFilterParams> {
  name: string;
  description: string;
  code: string;
  id: number;
  statusIds: number[];
  hasFile: boolean;
  appType: AppType;
  parameterValueSetId: number;
  isShownDesktopAttributes: boolean;
  searchString: string;
  disciplineIds: number[];
  functionalTypeIds?: number[];
  withDisciplinesOnly: boolean;
  attributes: FilterAttributeData[];
  withAttributesOnly?: boolean;
  parameterIds: number[];
  isShowDeleted: boolean;
  uniqueParameterIds: number[];
  nomenclatureKindIds: number[];
  authorIds: number[];
  lastEditorIds: number[];
  created: RangeDateDto;
  modified: RangeDateDto;

  constructor(filter?: IMaterialsFilterParams) {
    super(filter, DEFAULT_PARAMS);
  }

  public toObject(): IMaterialsFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      name: this.name,
      code: this.code,
      statusIds: this.statusIds,
      hasFile: this.hasFile,
      description: this.description,
      appType: this.appType,
      parameterValueSetId: this.parameterValueSetId,
      isShownDesktopAttributes: this.isShownDesktopAttributes,
      uniqueParameterIds: this.uniqueParameterIds,
      searchString: this.searchString,
      disciplineIds: this.disciplineIds,
      functionalTypeIds: this.functionalTypeIds,
      withDisciplinesOnly: this.withDisciplinesOnly,
      attributes: this.attributes,
      withAttributesOnly: this.withAttributesOnly,
      parameterIds: this.parameterIds,
      nomenclatureKindIds: this.nomenclatureKindIds,
      isShowDeleted: this.isShowDeleted,
      authorIds: this.authorIds,
      lastEditorIds: this.lastEditorIds,
      created: formatRangeDate(this.created),
      modified: formatRangeDate(this.modified),
    };
  }
}
