import { TablePageFilter } from '../table-page-filter-base';
import { ISpecificationsFilterParams } from '../index';
import { BindingSourceType } from '@common/dto';

const DEFAULT_PARAMS: ISpecificationsFilterParams = {
  sourceType: BindingSourceType.Internal,
  defaultSortBy: 'name',
};

export class SpecificationsFilterParams extends TablePageFilter<ISpecificationsFilterParams> {
  id: number;
  name: string;
  showDeleted: boolean;
  searchString: string;
  dataTypeIds: number[];
  nomenclatureTypeIds: number[];
  sourceType: BindingSourceType;

  constructor(filter?: ISpecificationsFilterParams) {
    super(filter, DEFAULT_PARAMS);
  }

  toObject(): ISpecificationsFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      name: this.name,
      showDeleted: this.showDeleted,
      dataTypeIds: this.dataTypeIds,
      nomenclatureTypeIds: this.nomenclatureTypeIds,
      searchString: this.searchString,
      sourceType: this.sourceType,
    };
  }
}
