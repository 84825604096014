import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private readonly stand = environment.stand;
  constructor(private title: Title, public translate: TranslateService) {}

  public setTitle(value: string) {
    const title = this.stand === 'prod' ? value : `${this.stand} | ${value}`;
    this.title.setTitle(title);
  }

  setTranslatedTitle(value: string) {
    this.setTitle(this.translate.instant(value));
  }
}
