import { Component, Input } from '@angular/core';
import { ROUTES } from '@common/consts';
import { normalizePageParams } from '@common/utils';
import { AttributesFilterParams, IAttributesFilterParams } from '@common/models/filters';
import { ConditionLinkModule } from '@shared/components/condition-link';

@Component({
  standalone: true,
  selector: 'app-attributes-link',
  templateUrl: 'attributes-link.component.html',
  imports: [ConditionLinkModule],
})
export class AttributesLinkComponent {
  @Input() set params(value: IAttributesFilterParams) {
    if (value) {
      this.link = ['', ROUTES.loadLogs, normalizePageParams(new AttributesFilterParams(value).toParams())];
    }
  }
  @Input() name: string;

  link: any[];
}
