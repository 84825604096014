export enum DataTypeCodeEnum {
  Boolean = 'Boolean',
  Material = 'Material',
  String = 'String',
  Integer = 'Integer',
  Double = 'Double',
  Element = 'Element',
}

export const DATA_TYPE_LABEL = {
  [DataTypeCodeEnum.String]: 'DATA_TYPE.STRING',
  [DataTypeCodeEnum.Double]: 'DATA_TYPE.DOUBLE',
  [DataTypeCodeEnum.Integer]: 'DATA_TYPE.INTEGER',
  [DataTypeCodeEnum.Boolean]: 'DATA_TYPE.BOOLEAN',
  [DataTypeCodeEnum.Element]: 'DATA_TYPE.ELEMENT',
  [DataTypeCodeEnum.Material]: 'DATA_TYPE.MATERIAL',
};
