<div class="standards">
  <div
    class="standards__item"
    *ngFor="let standard of _standards; trackBy: trackByIndex"
    [ngClass]="{ active: standard.isActive, switchable: switchable }"
    (click)="changeStandard(standard)"
  >
    <div
      class="standards__image"
      [pikTooltip]="standard.name"
      style="background-image: url('{{ ApiPaths.Standards + '/' + standard.id + '/image' }}')"
    ></div>
  </div>
</div>
