import { TablePageFilter } from '../table-page-filter-base';
import { IStandardsFilterParams } from './standards-filter.interface';
import { RangeDateDto } from '@common/dto';
import { formatRangeDate } from '@shared/utils';

export class StandardsFilterParams extends TablePageFilter<IStandardsFilterParams> {
  id: number;
  name: string;
  fullName: string;
  searchString: string;
  description: string;
  showDeleted: boolean;
  created: RangeDateDto;
  modified: RangeDateDto;

  constructor(filter?: IStandardsFilterParams) {
    super(filter);
  }

  toObject(): IStandardsFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      name: this.name,
      fullName: this.fullName,
      description: this.description,
      searchString: this.searchString,
      showDeleted: this.showDeleted,
      created: formatRangeDate(this.created),
      modified: formatRangeDate(this.modified),
    };
  }
}
