import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ROUTES_PARTS, ThemeColors } from '@common/consts';
import { FamilySolutionDto, IdNameDto } from '@common/dto';

@Component({
  selector: 'app-family-tag',
  templateUrl: './family-tag.component.html',
  styleUrls: ['family-tag.component.scss'],
})
export class FamilyTagComponent {
  @Input() mode: 'version' | 'solution' | 'single' = 'single';
  @Input() solution: FamilySolutionDto;
  @Input() removable: boolean;
  @Output() remove: EventEmitter<any> = new EventEmitter<any>();
  @Input() entity: IdNameDto;
  colors = ThemeColors;
  routesParts = ROUTES_PARTS;
}
