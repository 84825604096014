import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MaterialParameterValueSetCreateDto, MaterialParameterValueSetDto } from '@common/dto';
import { ApiPaths } from '@common/consts';

@Injectable({ providedIn: 'root' })
export class MaterialParameterValueSetsApiService {
  constructor(private http: HttpClient) {}

  /**
   * Добавляет новый параметр материала
   * @param entity - Связь материала с версией семейства
   */
  public create(entity: MaterialParameterValueSetCreateDto): Observable<MaterialParameterValueSetDto> {
    return this.http.post<MaterialParameterValueSetDto>(ApiPaths.MaterialParameterValueSets, entity);
  }

  /**
   * Добавляет несколько объектов.
   * @param data - Список объектов.
   */
  public batch(data: MaterialParameterValueSetCreateDto[]): Observable<MaterialParameterValueSetDto[]> {
    return this.http.post<MaterialParameterValueSetDto[]>(`${ApiPaths.MaterialParameterValueSets}/batch`, data);
  }

  /**
   * Удаляет параметр материала
   * @param entityId - id
   */
  public remove(entityId: number) {
    return this.http.delete(`${ApiPaths.MaterialParameterValueSets}/${entityId}`);
  }
}
