import removeMd from 'remove-markdown';
import showdown from 'showdown';

// Extension for `showdown` package to open links in a new tab
const targetBlank = () => [
  {
    type: 'output',
    regex: '<a(.*?)>',
    replace: (match, content) => '<a target="_blank"' + content + '>',
  },
];

export namespace Markdown {
  export function removeTags(mdString: string): string {
    return mdString ? removeMd(mdString + '') : mdString;
  }

  export function toHTML(mdString: string): string {
    return new showdown.Converter({ extensions: [targetBlank] }).makeHtml(mdString);
  }
}
