import { Component, Input, TemplateRef } from '@angular/core';
import { ThemeColors } from '@common/consts';

@Component({
  selector: 'fm-info-tooltip',
  templateUrl: './fm-info-tooltip.component.html',
  styleUrls: ['./fm-info-tooltip.component.scss'],
})
export class FmInfoTooltipComponent {
  @Input() set text(value: any) {
    this.isText = typeof value === 'string';
    if (this.isText) {
      this._text = value;
    } else {
      this.templateTooltip = value;
    }
  }
  @Input() color: ThemeColors = ThemeColors.Gray5;

  _text;
  isText: boolean;
  templateTooltip: TemplateRef<Element>;
}
