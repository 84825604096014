import { NgModule } from '@angular/core';
import { MultiSelectComponent } from './multi-select.component';
import { PikCheckboxModule, PikSearchInputModule, PikSelectModule } from '@pik-ui/ng-components';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [MultiSelectComponent],
  exports: [MultiSelectComponent],
  imports: [
    CommonModule,
    PikSelectModule,
    ReactiveFormsModule,
    PikCheckboxModule,
    PikSearchInputModule,
    TranslateModule,
  ],
})
export class MultiSelectModule {}
