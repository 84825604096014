import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

import { RoleDto } from '@common/dto';
import { DictionariesService } from '@services';

@Component({
  selector: 'app-roles-control',
  templateUrl: './roles-control.component.html',
  styleUrls: ['./roles-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RolesControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RolesControlComponent implements OnDestroy, ControlValueAccessor, OnInit {
  rolesControl = new UntypedFormControl([]);

  selectedEntities: RoleDto[] = [];

  isDisabled: boolean;

  private _destroy$ = new Subject();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(private _changeDetector: ChangeDetectorRef, public dictionariesService: DictionariesService) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this.dictionariesService.loadRoles();
  }

  async writeValue(value: number[]) {
    await this.dictionariesService.roles$
      .pipe(
        filter((f) => !!f?.length),
        tap((roles) => {
          if (value) {
            this.selectedEntities = value.map((id) => roles.find((role) => id === role.id));
          }
        }),
        take(1),
      )
      .toPromise();

    if (value) {
      this.rolesControl.setValue(value);
      this._changeDetector.detectChanges();
    }
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  nextChangeValue(value: number[]): void {
    if (this._onChange) {
      this._onChange(value);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    this._changeDetector.markForCheck();
  }
}
