import { Component } from '@angular/core';
import { ParameterValueControlService } from '../../services';

@Component({
  selector: 'app-parameter-valid-values',
  templateUrl: './parameter-valid-values.component.html',
  styleUrls: ['./parameter-valid-values.component.scss'],
})
export class ParameterValidValuesComponent {
  constructor(public parameterValueControlService: ParameterValueControlService) {}
}
