export enum EntityType {
  DownloadLog = 'DownloadLog',
  LoadLog = 'LoadLog',
  Category = 'Category',
  FunctionalType = 'FunctionalType',
  Material = 'Material',
  UniqueParameter = 'UniqueParameter',
  Request = 'Request',
  FamilyVersion = 'FamilyVersion',
  User = 'User',
  Family = 'Family',
}
