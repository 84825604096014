import { TablePageFilter } from '../table-page-filter-base';
import { IUnitTypesFilterParams } from './unit-types-filter.interface';
import { BindingSourceType } from '@common/dto';

export class UnitTypesFilterParams extends TablePageFilter<IUnitTypesFilterParams> {
  id: number;
  name: string;
  code: string;
  bindingSourceType: BindingSourceType;

  constructor(filter?: IUnitTypesFilterParams) {
    super(filter);
  }

  toObject(): IUnitTypesFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      name: this.name,
      code: this.code,
      bindingSourceType: this.bindingSourceType,
    };
  }
}
