import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { NavigationCategory, NavigationPage } from '@common/interfaces/Navigation';
import { PermissionService } from '@core/account/services';
import { NavigationCategories } from '@common/enums';

@Component({
  selector: 'expandable-list',
  templateUrl: './expandable-list.component.html',
  styleUrls: ['./expandable-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandableListComponent {
  @Input() isPinnedList = false;
  @Output() pinAction = new EventEmitter<NavigationPage>();
  @Input() set menuItemsList(value: NavigationCategory) {
    this._menuItemsList = {
      ...value,
      pages: value.pages.filter((p) => (p.permission ? this.permissionService.access[p.permission] : true)),
    };
    const expandedMenuList = JSON.parse(localStorage.getItem('expandedMenuList')) || [];
    this.isExpanded = expandedMenuList.includes(value.type);
  }

  get menuItemsList() {
    return this._menuItemsList;
  }

  @Input() readonly isActiveMenu: boolean;
  @Input() readonly noSelected: boolean;

  public isExpanded = false;
  private _menuItemsList: NavigationCategory;

  constructor(public permissionService: PermissionService, private _router: Router) {}

  togglePinItem(item: NavigationPage, event: Event) {
    event.stopPropagation();
    event.preventDefault();
    item.pinned ? this.unsetPinnedPage(item.name) : this.setPinnedPage(item.name);

    this.pinAction.next(item);
  }

  setPinnedPage(pageName: string) {
    const pinnedPages: string[] = JSON.parse(localStorage.getItem('pinnedMenuListPages')) || [];
    localStorage.setItem('pinnedMenuListPages', JSON.stringify([...pinnedPages, pageName]));
  }

  unsetPinnedPage(pageName: string) {
    const pinnedPages: string[] = JSON.parse(localStorage.getItem('pinnedMenuListPages')) || [];
    localStorage.setItem('pinnedMenuListPages', JSON.stringify(pinnedPages.filter((f) => f !== pageName)));
  }

  toggleExpandableList() {
    if (this.menuItemsList.pages?.length) {
      this.isExpanded = !this.isExpanded;

      let expandedMenuList: NavigationCategories[] = JSON.parse(localStorage.getItem('expandedMenuList')) || [];

      (expandedMenuList = this.isExpanded
        ? [...expandedMenuList, this.menuItemsList.type]
        : expandedMenuList.filter((f) => f !== this.menuItemsList.type)),
        localStorage.setItem('expandedMenuList', JSON.stringify(expandedMenuList));
    }
  }

  get isListSelected(): boolean {
    return this.checkSelectedItem();
  }

  checkSelectedItem(): boolean {
    return this.menuItemsList.pages.some((item) => new RegExp(`\/${item.link}([\/|;]|$)`, 'g').test(this._router.url));
  }
}
