import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subject, switchMap } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';

import { DictionariesService } from '@services';
import { DataTypeDto } from '@common/dto';
import { TypedFormControl } from '@common/interfaces';

@Component({
  selector: 'app-data-type-control',
  templateUrl: './data-type-control.component.html',
  styleUrls: ['./data-type-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DataTypeControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTypeControlComponent implements OnDestroy, ControlValueAccessor, OnInit {
  @Output() valueChanged = new EventEmitter<boolean>();
  public readonly entityControl: TypedFormControl<number> = new FormControl();
  public selectedEntity: DataTypeDto;
  public isDisable: boolean;

  private _destroy$ = new Subject();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(public dictionariesService: DictionariesService, private _changeDetector: ChangeDetectorRef) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this.dictionariesService.loadDataTypes();
    this._subscribeToEntityControl().subscribe();
  }

  writeValue(value: number) {
    this.dictionariesService.dataTypes$
      .pipe(
        filter((f) => !!f?.length),
        take(1),
        tap((values) => {
          this.selectedEntity = values.find((item) => value === item.id);

          if (!value) {
            value = null;
            this.selectedEntity = null;
            this.isDisable = false;
          }
          this.entityControl.setValue(value, { emitEvent: false });
          this._changeDetector.detectChanges();
        }),
      )
      .subscribe();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  nextChangeValue(value: number): void {
    if (this._onChange) {
      this._onChange(value);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisable = isDisabled;
    this._changeDetector.markForCheck();
  }

  private _subscribeToEntityControl() {
    return this.entityControl.valueChanges.pipe(
      switchMap((value: number) =>
        this.findElement(value).pipe(
          tap((element) => {
            this.valueChanged.emit(true);
            this.selectedEntity = element ?? null;
            this.nextChangeValue(element?.id || null);
          }),
        ),
      ),

      takeUntil(this._destroy$),
    );
  }

  private findElement(id: number): Observable<DataTypeDto> {
    return this.dictionariesService.dataTypes$.pipe(
      filter((f) => !!f?.length),
      map((values: DataTypeDto[]) => values.find((v) => v.id === id)),
    );
  }
}
