import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'fm-editable-option',
  templateUrl: './fm-editable-option.component.html',
  styleUrls: ['fm-editable-option.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FmEditableOptionComponent {
  @Input() value: any;
  @Input() label: string;
  @Input() editable: boolean;
  @Input() removable = true;
  @Input() validation: boolean;
  @Input() invalid: boolean;
  @Input() invalidTooltip: string;
  @Input() placeholder: string;
  @Input() uniqueValues: string[] = [];
  @Output() cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() open: EventEmitter<string> = new EventEmitter<string>();
  @Output() changeValue: EventEmitter<string> = new EventEmitter<string>();
  @Output() removeItem: EventEmitter<any> = new EventEmitter<any>();
  @Input() copied: boolean;
  showForm = false;

  constructor() {}

  editHandler($event) {
    $event.stopPropagation();
    this.open.emit(this.value);
    this.showForm = true;
  }

  public editValueHandler($event) {
    this.changeValue.emit($event);
    this.showForm = false;
  }

  public cancelHandler() {
    this.showForm = false;
  }

  removeHandler($event) {
    $event.stopPropagation();
    this.removeItem.emit(this.value);
  }
}
