import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { debounceTime, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { PikAutocompleteSelectedEvent } from '@pik-ui/ng-components';

import { IdNameDto } from '@common/dto';
import { UsersApiService } from '@services/api';
import { transformToIds } from '@common/utils';
import { AutocompleteDataSource } from '@pik-ui/ng-components/autocomplete/autocomplete.component';

@Component({
  selector: 'app-users-control',
  templateUrl: './users-control.component.html',
  styleUrls: ['./users-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UsersControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersControlComponent implements OnDestroy, ControlValueAccessor, OnInit {
  list: IdNameDto[] = [];
  nameControl = new UntypedFormControl(null);
  users$: Subject<AutocompleteDataSource[]> = new Subject<PikAutocompleteSelectedEvent[]>();

  private _destroy$ = new Subject();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(private _changeDetector: ChangeDetectorRef, private usersApi: UsersApiService) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this._subscribeToNameControl().subscribe();
  }

  async writeValue(value: number[] | IdNameDto[]) {
    if (value) {
      this._getEntities(transformToIds(value))
        .pipe(
          take(1),
          tap(() => this._changeDetector.detectChanges()),
        )
        .subscribe();
    } else {
      this.list = [];
      this._changeDetector.detectChanges();
    }
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  removeItem(item: IdNameDto) {
    this.list = this.list.filter((ft) => ft.id !== item.id);
    this.nextChangeValue();
  }

  pushItem(event: PikAutocompleteSelectedEvent) {
    setTimeout(() => this.nameControl.setValue(''));

    const newItem: IdNameDto = event.value;

    if (!this.list.some((s) => s.id === newItem.id)) {
      this.list.push(event.value);
      this.nextChangeValue();
    }
  }

  nextChangeValue(): void {
    if (this._onChange) {
      this._onChange(this.list.map((i) => i.id));
    }
  }

  private _subscribeToNameControl() {
    return this.nameControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap((value) => (value === '' ? of([]) : this.usersApi.smartSearch(value))),
      map((result: IdNameDto[]) =>
        result.map((item) => ({
          label: item.name,
          value: item,
        })),
      ),
      tap((data) => this.users$.next(data)),
      takeUntil(this._destroy$),
    );
  }

  private _getEntities(ids: number[]) {
    this.list = [];
    return ids.length ? this.usersApi.getIdNames(ids).pipe(tap((entities) => (this.list = [...entities]))) : of([]);
  }
}
