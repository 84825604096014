import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ExpandableBlockService } from './services';
import { ExpandEntityType } from './types';

@Component({
  selector: 'app-expandable-block',
  templateUrl: './expandable-block.component.html',
  styleUrls: ['./expandable-block.component.scss'],
})
export class ExpandableBlockComponent implements AfterViewInit {
  @Input() set entityType(value: 'description' | 'attributes') {
    this._entityType = value;
    this.expand = this.expandableBlockService.getExpandableBlock(value);
  }

  @Input() maxHeight = 200;

  viewInit = false;
  expand = false;

  private _entityType: ExpandEntityType;

  constructor(private expandableBlockService: ExpandableBlockService, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.viewInit = true;
      this.cdr.markForCheck();
    });
  }

  toggleExpand() {
    this.expand = !this.expand;
    this.expandableBlockService.setExpandableBlock(this.expand, this._entityType);
  }
}
