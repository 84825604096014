import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { IdNameDto } from '@common/dto';
import { transformToIds } from '@common/utils';
import { STATUSES } from '@common/consts';

@Component({
  selector: 'app-statuses-control',
  templateUrl: './statuses-control.component.html',
  styleUrls: ['./statuses-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StatusesControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusesControlComponent implements OnDestroy, ControlValueAccessor, OnInit {
  statusControl = new UntypedFormControl([]);
  statuses = STATUSES;
  private _destroy$ = new Subject();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(private _changeDetector: ChangeDetectorRef) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this._subscribeToStatusControl().subscribe();
  }

  writeValue(value: number[] | IdNameDto[]) {
    const data = transformToIds(value);
    setTimeout(() => {
      this.statusControl.setValue(data);
      this._changeDetector.detectChanges();
    });
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  nextChangeValue(): void {
    if (this._onChange) {
      this._onChange(this.statusControl.value);
    }
  }

  private _subscribeToStatusControl() {
    return this.statusControl.valueChanges.pipe(
      tap(() => this.nextChangeValue()),
      takeUntil(this._destroy$),
    );
  }
}
