<pik-drawer #drawer maxWidth="440px" [panelClass]="'chatDrawer'">
  <pik-drawer-header>
    <h2>{{ title }}</h2>
  </pik-drawer-header>
  <pik-drawer-content>
    <ng-container *ngIf="showMessages.length">
      <div class="messageList">
        <app-chat-message
          *ngFor="let message of showMessages; let last = last; trackBy: trackByIndex"
          [message]="message"
          [ngClass]="{ last: last }"
        ></app-chat-message>
      </div>
    </ng-container>
  </pik-drawer-content>
  <pik-drawer-footer>
    <ng-container *ngIf="permissionService.access.ChatMessageCreate">
      <div class="bottomBar">
        <div class="bottomBar__tools">
          <pik-switch (change)="systemMessageHandler($event)">{{ 'COMPONENT_CHAT.SYSTEM' | translate }}</pik-switch>
        </div>
        <div class="bottomBar__form">
          <app-chat-message-form [chatParams]="chatParams" (sended)="sended($event)"></app-chat-message-form>
        </div>
      </div>
    </ng-container>
  </pik-drawer-footer>
</pik-drawer>
