export interface Tab {
  type: TabsType;
  id: string;
  name?: string;
  params?: object;
}

/**
 * @important Enum item value must be equal with his part of path in routing
 */
export enum TabType {
  FAMILY = 'families',
  REQUESTS = 'requests',
  FUNCTIONAL_TYPE = 'functional-types',
  NOMENCLATURE_TYPE = 'nomenclature-types',
  PROJECTS = 'projects',
  MATERIAL = 'materials',
  BINDINGS = 'bindings',
  USERS = 'users',
  BINDINGS_INTERNAL_MATERIAL = 'bindings/internal/material',
  BINDINGS_SNAP_MATERIAL = 'bindings/snap/material',
  BINDINGS_INTERNAL_FUNCTIONAL_TYPE = 'bindings/internal/functional-type',
  BINDINGS_SNAP_FUNCTIONAL_TYPE = 'bindings/snap/functional-type',
  BINDINGS_INTERNAL_FAMILY = 'bindings/internal/family',
  BINDINGS_SNAP_FAMILY = 'bindings/snap/family',
}

export type TabsType =
  | 'families'
  | 'requests'
  | 'functional-types'
  | 'nomenclature-types'
  | 'projects'
  | 'materials'
  | 'bindings'
  | 'users'
  | 'bindings/internal/material'
  | 'bindings/internal/functional-type'
  | 'bindings/internal/family'
  | 'bindings/snap/material'
  | 'bindings/snap/family'
  | 'bindings/snap/functional-type';
