import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ThemeColors } from '@common/consts';
import { AppType } from '@common/enums';

@Component({
  selector: 'fm-app-tag',
  template: '<pik-tag [color]="color">{{ _appType }}</pik-tag>',
  styleUrls: ['./fm-app-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FmAppTagComponent {
  @Input() set appType(value: AppType) {
    this.color = value === AppType.Civil ? ThemeColors.Civil : ThemeColors.Revit;
    this._appType = value;
  }
  color = '#ccc';
  _appType: AppType;
}
