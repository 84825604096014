import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'tags-item',
  templateUrl: './tags-item.component.html',
  styleUrls: ['./tags-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsItemComponent {
  @Input() icon: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() tooltip: any;
  @Input() helpLink: string;
}
