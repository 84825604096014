import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiPaths } from '@common/consts';
import { ResultPart } from '@common/interfaces';
import { UnitTypesFilterParams } from '@common/models/filters';
import { BindingSourceType, UnitTypeDto } from '@common/dto';

@Injectable({ providedIn: 'root' })
export class UnitTypesApiService {
  constructor(private http: HttpClient) {}

  public getList(filter: UnitTypesFilterParams): Observable<ResultPart<UnitTypeDto>> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<ResultPart<UnitTypeDto>>(`${ApiPaths.UnitTypes}/filter`, {
      ...params,
      bindingSourceType: filter.bindingSourceType ? filter.bindingSourceType : BindingSourceType.Internal,
    });
  }

  public getAll(bindingSourceType): Observable<ResultPart<UnitTypeDto>> {
    return this.getList(
      new UnitTypesFilterParams({
        bindingSourceType,
        limit: -1,
      }),
    );
  }

  public getById(id: number): Observable<UnitTypeDto> {
    return this.http.get<UnitTypeDto>(`${ApiPaths.UnitTypes}/${id}`);
  }

  public update(data: UnitTypeDto): Observable<UnitTypeDto> {
    return this.http.put<UnitTypeDto>(`${ApiPaths.UnitTypes}/${data.id}`, data);
  }

  public create(data: Omit<UnitTypeDto, 'id'>): Observable<UnitTypeDto> {
    return this.http.post<UnitTypeDto>(ApiPaths.UnitTypes, data);
  }

  public delete(id: number): Observable<null> {
    return this.http.delete<null>(`${ApiPaths.UnitTypes}/${id}`);
  }
}
