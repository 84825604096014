<app-multi-select
  *ngIf="!isDisabled; else rolesView"
  placeholder="{{ 'COMPONENT_ROLES_CONTROL' | translate }}"
  [options]="dictionariesService?.roles$ | async"
  (change)="nextChangeValue($event)"
  [formControl]="rolesControl"
  [canSearch]="true"
>
</app-multi-select>

<ng-template #rolesView>
  <app-roles-tags [roles]="selectedEntities"></app-roles-tags>
</ng-template>
