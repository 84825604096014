import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiPaths } from '@common/consts';
import { AttributeUpdateDto, AttributeValueDto } from '@common/dto';
import { CrudService, ResultPart } from '@common/interfaces';
import { FunctionalTypeAttributesFilter } from '@common/models/filters';

@Injectable({ providedIn: 'root' })
export class FunctionalTypeAttributesApiService
  implements CrudService<AttributeValueDto, AttributeUpdateDto, AttributeUpdateDto>
{
  constructor(private http: HttpClient) {}

  public getById(entityId: number): Observable<AttributeValueDto> {
    return this.http.get<AttributeValueDto>(`${ApiPaths.FunctionalTypeAttributes}/${entityId}`);
  }

  public getList(filter: FunctionalTypeAttributesFilter): Observable<ResultPart<AttributeValueDto>> {
    return this.http.get<ResultPart<AttributeValueDto>>(`${ApiPaths.FunctionalTypeAttributes}`, {
      params: filter.toQueryParams(),
    });
  }

  public create(entity: Omit<AttributeUpdateDto, 'id'>): Observable<AttributeValueDto> {
    return this.http.post<AttributeValueDto>(ApiPaths.FunctionalTypeAttributes, entity);
  }

  public update(entity: AttributeUpdateDto): Observable<AttributeValueDto> {
    return this.http.put<AttributeValueDto>(`${ApiPaths.FunctionalTypeAttributes}/${entity.id}`, entity);
  }

  public remove(entityId: number): Observable<void> {
    return this.http.delete<void>(`${ApiPaths.FunctionalTypeAttributes}/${entityId}`);
  }
}
