import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiPaths } from '@common/consts';
import { IdNameDto, SpecificationDto } from '@common/dto';
import { ResultPart } from '@common/interfaces';
import { SpecificationsFilterParams } from '@common/models/filters';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SpecificationsApiService {
  constructor(private http: HttpClient) {}

  /**
   * Возвращает характеристики по фильтру.
   * @param filter - фильтр
   */
  public getList(filter?: SpecificationsFilterParams) {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<ResultPart<SpecificationDto>>(`${ApiPaths.Specifications}/filter`, params);
  }

  /**
   * Возвращает объект по ID.
   * @param entityId - id
   */
  public getById(entityId: number) {
    return this.http.get<SpecificationDto>(`${ApiPaths.Specifications}/${entityId}`);
  }

  /**
   * Добавляет новый объект
   * @param entity - новый объект
   */
  public create(entity: Pick<SpecificationDto, 'name' | 'dataType' | 'description'>) {
    return this.http.post<SpecificationDto>(ApiPaths.Specifications, entity);
  }

  /**
   * Обновляет объект
   * @param entity - изменённые данные
   */
  public update(entity: Pick<SpecificationDto, 'id' | 'name' | 'dataType' | 'description' | 'isDeleted'>) {
    return this.http.put<SpecificationDto>(`${ApiPaths.Specifications}/${entity.id}`, entity);
  }

  /**
   * Удаляет объект
   * @param entityId - id
   */
  public remove(entityId: number) {
    return this.http.delete<void>(`${ApiPaths.Specifications}/${entityId}`);
  }

  /**
   * Возвращает характеристики по id[]
   * @param ids - айди характеристик
   */
  public getIdNames(ids: number[]): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.Specifications}/idnames`, {
      params: {
        ids: ids.map((id) => id.toString()),
      },
    });
  }

  public createGoogleSheet(filter: SpecificationsFilterParams): Observable<string> {
    const params = filter ? filter.toParams() : {};
    return this.http.post<string>(`${ApiPaths.Specifications}/sheets`, params);
  }

  public createExcelSheet(filter: SpecificationsFilterParams): Observable<Blob> {
    const params = filter ? filter.toParams() : {};
    return this.http.post(`${ApiPaths.Specifications}/excel`, params, {
      responseType: 'blob',
    });
  }
}
