<div class="attributes">
  <div class="attributes__row" *ngFor="let attribute of _attributes | attributesSort">
    <div class="attributes__label" [pikTooltip]="attribute.attributeDescription ? tooltip : null" position="above">
      {{ attribute.attributeName }}
    </div>
    <div class="attributes__value">
      <app-condition-link [access]="permissionService.access.VisibilityOfCatalogs" [link]="attribute.params">
        <ng-container *ngIf="attribute.options?.length">
          <app-options-list
            [color]="attribute.isValueUpdated ? colors.Orange : colors.DarkBlue"
            [bgColor]="colors.Gray4"
            [options]="attribute.options"
            [maxCount]="20"
          ></app-options-list>
        </ng-container>

        <ng-container *ngIf="!attribute.options?.length">
          <pik-tag
            [color]="colors.Gray4"
            [textColor]="attribute.isValueUpdated ? colors.Orange : colors.DarkBlue"
            large
          >
            <span appLinkTransform>{{ attribute.valueStr }}</span>
          </pik-tag>
        </ng-container>
      </app-condition-link>
    </div>

    <ng-template #tooltip>
      <markdown-viewer [removeMargin]="true" [data]="attribute.attributeDescription"></markdown-viewer>
    </ng-template>
  </div>
</div>
