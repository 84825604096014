<div class="wrap">
  <pik-switch [formControl]="rangeSwitchControl">{{ 'PARAMETER_VALUES_COMPONENT.RANGE' | translate }}</pik-switch>
  <div class="field">
    <ng-container *ngIf="rangeSwitchControl.value; else numberValue">
      <form [formGroup]="form">
        <pik-form-control>
          <div class="range-value">
            <div class="range-value__label">{{ 'PARAMETER_VALUES_COMPONENT.FROM' | translate }}</div>
            <div class="range-value__col">
              <input pikInput formControlName="fromValue" placeholder="ꝏ" size="small" />
              <pik-checkbox formControlName="fromInclude">{{
                'PARAMETER_VALUES_COMPONENT.INCLUDE' | translate
              }}</pik-checkbox>
            </div>
            <div class="range-value__label">{{ 'PARAMETER_VALUES_COMPONENT.TO' | translate }}</div>
            <div class="range-value__col">
              <input pikInput formControlName="toValue" placeholder="ꝏ" size="small" />
              <pik-checkbox formControlName="toInclude">{{
                'PARAMETER_VALUES_COMPONENT.INCLUDE' | translate
              }}</pik-checkbox>
            </div>
            <div class="range-value__step">
              <pik-checkbox formControlName="hasStep">{{ 'PARAMETER_VALUES_COMPONENT.STEP' | translate }}</pik-checkbox>
              <input pikInput formControlName="step" size="small" />
            </div>
          </div>
          <pik-error *ngIf="form.controls.toValue.errors?.pattern || form.controls.fromValue.errors?.pattern">{{
            'PARAMETER_VALUES_COMPONENT.' + _numberType + '_ERROR_RANGE' | translate
          }}</pik-error>
          <pik-error *ngIf="form.controls.step.errors?.pattern">{{
            'PARAMETER_VALUES_COMPONENT.' + _numberType + '_ERROR_STEP' | translate
          }}</pik-error>
          <pik-error *ngIf="form.hasError('sameValueError')">{{
            'PARAMETER_VALUES_COMPONENT.ERROR_SAME_VALUE' | translate
          }}</pik-error>
          <pik-error *ngIf="form.hasError('rangeValueError')">{{
            'PARAMETER_VALUES_COMPONENT.ERROR_RANGE_VALUE' | translate
          }}</pik-error>
        </pik-form-control>
      </form>
    </ng-container>

    <ng-template #numberValue>
      <pik-form-control class="string-value">
        <input pikInput [formControl]="numberValueControl" size="small" />
        <pik-error *ngIf="numberValueControl.errors?.required">{{
          'PARAMETER_VALUES_COMPONENT.ERROR_EMPTY' | translate
        }}</pik-error>
        <pik-error *ngIf="numberValueControl.errors?.pattern">{{
          'PARAMETER_VALUES_COMPONENT.' + _numberType + '_ERROR_NUMBER_VALUE' | translate
        }}</pik-error>
        <pik-error *ngIf="numberValueControl.hasError('sameValueError')">{{
          'PARAMETER_VALUES_COMPONENT.ERROR_SAME_VALUE' | translate
        }}</pik-error>
      </pik-form-control>
    </ng-template>
  </div>

  <icon-button
    *ngIf="!isDisable"
    icon="./assets/icons/delete.svg"
    tooltip="{{ 'COMMON.REMOVE' | translate }}"
    tooltipPosition="before"
    (click)="remove.emit(index)"
  ></icon-button>
</div>
