<div class="sidenav" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave($event)" [ngClass]="{ active: activeMenu }">
  <div class="sidenav__wrap">
    <div class="sidenavUser__wrap" *ngIf="userService.user$ | async as user">
      <div class="sidenav__topRow">
        <a routerLink="/" class="sidenav__logo">
          <svg-icon class="fmLogo" src="./assets/logo.svg" [stretch]="true"></svg-icon>
          <span>Family Manager</span>
        </a>
        <fm-hint link="https://fm-help.bimteam.app/{{ 'LOCALIZATION' | translate }}/web/menu"></fm-hint>
      </div>
      <app-condition-link class="sidenav__link" [access]="true" [link]="['', routesParts.USERS, user.id]">
        <div class="sidenavUser">
          <div class="sidenavUser__pic">
            <div *ngIf="user?.requestCount" class="sidenavUser__open-applications">
              {{ user.requestCount }}
            </div>
            <app-user-photo [value]="user.login"></app-user-photo>
          </div>
          <div class="sidenavUser__name">{{ user.name }}</div>
        </div>
      </app-condition-link>
      <div class="sidenav-standard">
        <div class="sidenav-standard__logo">
          <img src="{{ ApiPaths.Standards + '/' + user.currentStandard.id + '/image' }}" alt="" />
        </div>

        <div class="sidenav-standard__select">
          <span>{{ 'MENU.STANDARD' | translate }}:</span>

          <pik-select
            #standardSelect
            *ngIf="(userService.userStandards$ | async).length > 1; else activeStandard"
            inverse
            [formControl]="standardControl"
            [customTemplate]="customTemplate"
            backdropClass="sidenav-backdrop"
          >
            <pik-option *ngFor="let standard of userService.userStandards$ | async" [value]="standard.id">
              <pik-ellipsis-text>{{ standard.name }}</pik-ellipsis-text>
            </pik-option>
          </pik-select>

          <ng-template #customTemplate let-option>
            <pik-ellipsis-text>{{ option?.text }}</pik-ellipsis-text>
          </ng-template>

          <ng-template #activeStandard>
            <pik-ellipsis-text>{{ user.currentStandard.name }}</pik-ellipsis-text>
          </ng-template>
        </div>
      </div>
    </div>

    <perfect-scrollbar class="sidenav__scroll" [config]="scrollConfig" #scroll>
      <ng-container *ngIf="permissionService.hasAnyPermission">
        <div class="sidenavMenu" *ngIf="pinnedCategory.pages.length">
          <expandable-list
            [menuItemsList]="pinnedCategory"
            [isActiveMenu]="activeMenu"
            [noSelected]="true"
            (pinAction)="setCategories()"
          ></expandable-list>
        </div>

        <div class="sidenavMenu" *ngFor="let list of menuLists">
          <expandable-list
            [menuItemsList]="list"
            [isActiveMenu]="activeMenu"
            (pinAction)="setCategories()"
          ></expandable-list>
        </div>
      </ng-container>
    </perfect-scrollbar>
    <div class="sidenavMenu bottom">
      <ul class="sidenavMenu__list">
        <li class="sidenavMenu__item" [routerLinkActive]="'active'" *ngIf="permissionService.hasAnyPermission">
          <a routerLink="apps">
            <span class="sidenavMenu__icon">
              <svg-icon src="./assets/icons/download.svg" [stretch]="true"></svg-icon>
            </span>
            <span class="sidenavMenu__label">{{ 'MENU.DOWNLOAD' | translate }}</span>
          </a>
        </li>
        <li class="sidenavMenu__item">
          <a href="https://t.me/fm_bimteam" target="_blank">
            <span class="sidenavMenu__icon">
              <svg-icon src="./assets/icons/telegram.svg" [stretch]="true"></svg-icon>
            </span>
            <span class="sidenavMenu__label">{{ 'MENU.TELEGRAM' | translate }}</span>
          </a>
        </li>
        <li class="sidenavMenu__item">
          <a
            href="https://fm-help.bimteam.app/{{ 'LOCALIZATION' | translate }}/web/general-information"
            target="_blank"
          >
            <span class="sidenavMenu__icon">
              <svg-icon src="./assets/icons/question.svg" [stretch]="true"></svg-icon>
            </span>
            <span class="sidenavMenu__label">{{ 'MENU.FAQ' | translate }}</span>
          </a>
        </li>
        <li class="sidenavMenu__item">
          <a [href]="supportLink" [class.disabled-href]="!supportLink" target="_blank">
            <span class="sidenavMenu__icon">
              <svg-icon src="./assets/icons/cloud.svg" [stretch]="true"></svg-icon>
            </span>
            <span class="sidenavMenu__label">{{ 'MENU.SUPPORT' | translate }}</span>
          </a>
        </li>
        <li class="sidenavMenu__item">
          <a (click)="logout()">
            <span class="sidenavMenu__icon">
              <svg-icon src="./assets/icons/exit.svg" [stretch]="true"></svg-icon>
            </span>
            <span class="sidenavMenu__label">{{ 'MENU.LOGOUT' | translate }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
