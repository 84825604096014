<button (click)="copy($event)" *ngIf="text">
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'link'">
      <svg-icon src="./assets/icons/link-grey.svg"></svg-icon>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <svg-icon src="./assets/icons/copy.svg"></svg-icon>
    </ng-container>
  </ng-container>
</button>
