import { createSelector } from '@ngxs/store';

export class TablePageState {
  static result<T>() {
    return createSelector([this], (state: { result: T[] }) => {
      return state.result;
    });
  }

  static fullCount() {
    return createSelector([this], (state: { fullCount: number }) => {
      return state.fullCount;
    });
  }

  static pending() {
    return createSelector([this], (state: { pending: boolean }) => {
      return state.pending;
    });
  }
}
