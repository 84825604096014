<form [formGroup]="form">
  <ng-container *ngFor="let value of formControls; index as i; trackBy: trackByValue">
    <app-parameter-range-value-control
      [index]="i"
      (remove)="removeValue($event)"
      [numberType]="numberType"
      formControlName="control-{{ i }}"
    ></app-parameter-range-value-control>
  </ng-container>
</form>
<div class="params-footer">
  <app-parameter-valid-values></app-parameter-valid-values>

  <button
    class="add-value"
    pikButton
    appearance="inverse"
    (click)="addValue()"
    *ngIf="!isDisable && values.length < (parameterValueControlService.maxValuesCount$ | async)"
  >
    <svg-icon pikButtonIcon src="./assets/icons/plus.svg"></svg-icon>
    {{ 'PARAMETER_VALUES_COMPONENT.ADD_VALUE' | translate }}
  </button>
</div>
