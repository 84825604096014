import { Component, Input } from '@angular/core';
import { ROUTES } from '@common/consts';
import { PermissionService } from '@core/account/services';
import { normalizePageParams } from '@common/utils';
import { FunctionalTypesFilterParams, IFunctionalTypesFilterParams } from '@common/models/filters';

@Component({
  selector: 'app-functional-types-link',
  templateUrl: './functional-types-link.component.html',
})
export class FunctionalTypesLinkComponent {
  @Input() set params(value: IFunctionalTypesFilterParams) {
    if (value) {
      this.link = [
        '',
        ROUTES.functionalTypesList,
        normalizePageParams(new FunctionalTypesFilterParams(value).toParams()),
      ];
    }
  }
  @Input() name: string;

  link: any[];

  constructor(public permissionService: PermissionService) {}
}
