import { Injectable } from '@angular/core';
import { PikModalService } from '@pik-ui/ng-components';
import { switchMap, take, tap } from 'rxjs/operators';

import { FmModalDialogComponent } from '@shared/components/fm-ui/fm-modal-dialog';
import { FmModalGoogleSheetComponent } from '@shared/components/fm-ui/fm-modal-google-sheet';
import { FmModalMessageComponent } from '@shared/components/fm-ui/fm-modal-message';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private _modal: PikModalService, private translate: TranslateService) {}

  dialogModal(title: string, successLabel?: string, failureLabel?: string, html?: boolean) {
    return this._modal.open(FmModalDialogComponent, {
      data: { title, successLabel, failureLabel, html },
    });
  }

  infoModal(title: string) {
    return this.translate.get(title).pipe(
      take(1),
      tap((t) =>
        this._modal.open(FmModalMessageComponent, {
          data: { title: t },
        }),
      ),
    );
  }

  confirm(title: string, successLabel = '', failureLabel = '', html = false): Observable<boolean> {
    return forkJoin([
      this.translate.get(title),
      successLabel ? this.translate.get(successLabel) : of(''),
      failureLabel ? this.translate.get(failureLabel) : of(''),
    ]).pipe(
      switchMap(([sTitle, sLabel, fLabel]) =>
        this.dialogModal(sTitle, sLabel, fLabel, html).afterClosed.asObservable(),
      ),
    );
  }

  googleWindow(googleId: string) {
    const windowTable = window.open(`https://docs.google.com/spreadsheets/d/${googleId}`, '_blank');
    if (!windowTable || windowTable.closed || typeof windowTable.closed === 'undefined') {
      return this._modal.open(FmModalGoogleSheetComponent, {
        data: { googleId },
      });
    }
  }
}
