import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fm-table-page',
  templateUrl: './table-page-component.component.html',
  styleUrls: ['./table-page-component.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line:no-host-metadata-property
  host: { class: 'fm-table-page' },
})
export class FmTablePageComponent {}
