import { BooleanValues } from '../../../enums';

const positiveRegex = /^(1|да|yes|true)$/gimu;
const negativeRegex = /^(0|нет|no|false)$/gimu;
const allValuesRegex = /^(0|нет|no|false|1|да|yes|true)$/gimu;

export function getValidBooleanValue(value: string): BooleanValues {
  if (value === null || value === undefined) {
    return null;
  }

  let validValue: BooleanValues;
  switch (true) {
    case !!value.match(positiveRegex):
      validValue = BooleanValues.Yes;
      break;
    case !!value.match(negativeRegex):
      validValue = BooleanValues.No;
      break;
    case value.split('|').every((s) => s.match(allValuesRegex)):
      validValue = BooleanValues.YesOrNo;
      break;
    default:
      validValue = null;
  }

  return validValue;
}
