import { Directive, ElementRef, HostListener } from '@angular/core';
import { FmTabsService } from '@services';
import { TabType } from '@common/interfaces';

@Directive({
  selector: '[appLinkTab]',
  standalone: true,
})
export class LinkTabDirective {
  constructor(private _tabService: FmTabsService, private elementRef: ElementRef) {}

  @HostListener('click', ['$event']) onClick($event) {
    $event.stopPropagation();
    $event.preventDefault();

    if ($event.ctrlKey) {
      const link = this.elementRef.nativeElement.href;
      const data = link?.replace(`${window.location.origin}/`, '');

      const paramsList: string[] = data.split(';');
      const linkWithoutParams = paramsList.shift();
      const params = {};
      paramsList.forEach((f) => {
        const paramsElement = f.split('=');
        params[paramsElement[0]] = paramsElement[1];
      });

      const linkSegments = linkWithoutParams.split('/');
      const id = +linkSegments.pop();
      const tabType = linkSegments.join('/').toLowerCase() as TabType;

      if (Number.isNaN(id)) {
        return;
      }

      this._tabService.openTabById(id, tabType, params);
    }
  }
}
