import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { defer, of, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { InputBoolean, PikAutocompleteSelectedEvent } from '@pik-ui/ng-components';

import { FunctionalTypeDto, IdNameDto } from '@common/dto';
import { FunctionalTypesApiService } from '@services/api';
import { TabType } from '@common/interfaces';
import { SmartSearchFilter } from '@common/models/filters/smart-search/smart-search-filter';
import { FunctionalTypesFilterParams } from '@common/models/filters';

@Component({
  selector: 'app-functional-type-control',
  templateUrl: './functional-type-control.component.html',
  styleUrls: ['./functional-type-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FunctionalTypeControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunctionalTypeControlComponent implements OnDestroy, ControlValueAccessor, OnInit {
  @Input() set standardId(value: number) {
    this._standardId = value;
    this.entities$.next([]);
  }

  @Input() set filter(value: FunctionalTypesFilterParams) {
    this._filter = value;
  }

  _excludedIds = [];
  @Input() set excludedIds(ids: number[]) {
    this._excludedIds = ids;
  }
  get excludedIds(): number[] {
    return this._excludedIds;
  }

  @Input()
  @InputBoolean()
  emptyTrigger = false;

  @Output() getSelectedFunctionalType = new EventEmitter<FunctionalTypeDto>();

  @Input()
  @InputBoolean()
  full: boolean;

  TabType = TabType;

  nameControl = new UntypedFormControl(null);
  selectedEntity: FunctionalTypeDto;
  _selectedEntity: IdNameDto | FunctionalTypeDto;
  entities$ = new Subject();

  isDisable: boolean;

  private _destroy$ = new Subject();
  private _standardId: number;
  private _filter: FunctionalTypesFilterParams;

  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(private _changeDetector: ChangeDetectorRef, private _functionalTypeApi: FunctionalTypesApiService) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this._subscribeToNameControl().subscribe();
  }

  async writeValue(value: number) {
    if (typeof value === 'object' && value && !value?.['value']) return;
    if (value) {
      await this._getEntity(value).toPromise();
    } else {
      this.nameControl.setValue(null);
      this.isDisable = false;
    }
    this._changeDetector.detectChanges();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  async selectEntity(event: PikAutocompleteSelectedEvent) {
    this._selectedEntity = event.value;
    this.getSelectedFunctionalType.emit(this._selectedEntity as FunctionalTypeDto);
    if (this.full) {
      this.selectedEntity = await this._functionalTypeApi.getById(event.value.id).toPromise();
    }
    this.nextChangeValue();
  }

  nextChangeValue(): void {
    if (this._onChange) {
      this.full ? this._onChange(this.selectedEntity) : this._onChange(this._selectedEntity?.id);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisable = isDisabled;
    this._changeDetector.markForCheck();
  }

  private _subscribeToNameControl() {
    return this.nameControl.valueChanges.pipe(
      debounceTime(300),
      filter((name) => name !== this._selectedEntity?.name && typeof name === 'string'),
      tap(() => (this._selectedEntity = null)),
      tap(() => (this.selectedEntity = null)),
      tap(() => this.nextChangeValue()),
      switchMap((value) => {
        return defer(() =>
          Boolean((value === '' || value === null) && !this.emptyTrigger)
            ? of([])
            : defer(() =>
                Boolean(this._filter)
                  ? this._functionalTypeApi
                      .getList(new FunctionalTypesFilterParams({ ...this._filter.toObject(), name: value }))
                      .pipe(map((data) => data.result))
                  : this._functionalTypeApi.smartSearch(
                      new SmartSearchFilter({ searchString: value, standardId: this._standardId }),
                    ),
              ),
        );
      }),
      map((result: IdNameDto[]) =>
        result
          .filter((item) => !this.excludedIds.includes(item.id))
          .map((item) => ({
            label: item.name,
            value: item,
          })),
      ),
      tap((data) => this.entities$.next(data)),
      takeUntil(this._destroy$),
    );
  }

  private _getEntity(id: number) {
    return this._functionalTypeApi.getById(id).pipe(
      take(1),
      tap((entity: FunctionalTypeDto) => {
        this.nameControl.setValue(entity.name, { emitEvent: false });
        this._selectedEntity = { id: entity.id, name: entity.name };
        this.selectedEntity = entity;
        this.getSelectedFunctionalType.emit(entity);
        this.nextChangeValue();
      }),
    );
  }
}
