import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import _ from 'lodash';

import { ROUTES, ThemeColors } from '@common/consts';
import { Router } from '@angular/router';
import { PermissionService } from '@core/account/services';
import { normalizePageParams } from '@common/utils';
import { ShowMoreDirective } from '../show-more.directive';

@Component({
  selector: 'app-disciplines-tags',
  templateUrl: './disciplines-tags.component.html',
  styleUrls: ['./disciplines-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisciplinesTagsComponent extends ShowMoreDirective {
  @Input() set disciplines(resp: any[]) {
    if (!resp) resp = [];

    const data = _.cloneDeep(resp.filter((discipline) => discipline));
    this._disciplines = _.orderBy(data, 'name', 'asc').map((d) => ({
      ...d,
      params: ['', ROUTES.disciplines, normalizePageParams({ id: d.id, searchValue: d.id, searchField: 'id' })],
    }));
  }

  ThemeColors = ThemeColors;
  ROUTES = ROUTES;
  _disciplines: { params: []; id: number; name: any }[] = [];

  constructor(private _router: Router, public permissionService: PermissionService) {
    super();
  }
}
