import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiPaths } from '@common/consts';
import { CrudService, ResultPart } from '@common/interfaces';
import { ProjectDto, ProjectUpdateDtoNormalized } from '@common/dto';
import { ProjectsFilterParams } from '@common/models/filters';

@Injectable({ providedIn: 'root' })
export class ProjectsApiService
  implements CrudService<ProjectDto, ProjectUpdateDtoNormalized, ProjectUpdateDtoNormalized>
{
  constructor(private http: HttpClient) {}

  /**
   * Возвращает заданное количество объектов, по умолчанию возвращает все.
   * @param filter - фильтр
   */
  public getList(filter?: ProjectsFilterParams): Observable<ResultPart<ProjectDto>> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<ResultPart<ProjectDto>>(`${ApiPaths.Projects}/grid`, params);
  }

  /**
   * Возвращает объект по ID.
   * @param projectId - id атрибута
   */
  public getById(projectId: number) {
    return this.http.get<ProjectDto>(`${ApiPaths.Projects}/${projectId}`);
  }

  /**
   * Добавляет новый объект
   * @param project - новый атрибут
   */
  public create(project: ProjectUpdateDtoNormalized) {
    return this.http.post<ProjectDto>(ApiPaths.Projects, project);
  }

  /**
   * Обновляет объект
   * @param project - изменённые данные
   */
  public update(project: ProjectUpdateDtoNormalized) {
    return this.http.put<ProjectDto>(`${ApiPaths.Projects}/${project.id}`, project);
  }

  /**
   * Удаляет объект
   * @param projectId - id проекта
   */
  public remove(projectId: number): Observable<void> {
    return this.http.delete<void>(`${ApiPaths.Projects}/${projectId}`);
  }

  /**
   * Создаёт электронную таблицу проектов
   */
  public createSheet(filter: ProjectsFilterParams): Observable<string> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post(`${ApiPaths.Projects}/sheets`, params, { responseType: 'text' });
  }

  /**
   * Импортирует электронную таблицу проектов
   * @param sheetId - id электронной таблицы
   */
  public importSheet(sheetId: string): Observable<void> {
    return this.http.put<void>(`${ApiPaths.Projects}/sheets/${sheetId}`, null);
  }

  public createExcelSheet(filter: ProjectsFilterParams): Observable<Blob> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post(`${ApiPaths.Projects}/excel`, params, { responseType: 'blob' });
  }

  public importExcelSheet(file: File) {
    const data = new FormData();
    data.append('file', file);
    return this.http.put(`${ApiPaths.Projects}/excel`, data);
  }
}
