<div class="chatMessage" [ngClass]="{ robot: message.isSystem }">
  <div class="chatMessage__header">
    <div class="chatMessage__userPic">
      <ng-container *ngIf="!message.isSystem; else robotPic">
        <a [appOpenTab]="appOpenTab">
          <app-user-photo [value]="message.email"></app-user-photo>
        </a>
      </ng-container>
      <ng-template #robotPic>
        <img src="/assets/logo.svg" />
      </ng-template>
    </div>
    <div class="chatMessage__headerContent">
      <ng-container *ngIf="message.isSystem; else user">
        <div class="chatMessage__name">Family Manager</div>
      </ng-container>
      <ng-template #user>
        <a class="chatMessage__name" [appOpenTab]="appOpenTab">{{ message.fio }}</a>
      </ng-template>

      <div class="chatMessage__date">{{ message.created | dateFormat: true }}</div>
    </div>
  </div>
  <div class="chatMessage__text">
    <markdown-viewer [data]="message.message"></markdown-viewer>
  </div>
</div>
