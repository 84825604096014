<ng-container *ngIf="ellipsis && removeMarkdown; else content">
  <div #viewerRef>
    <pik-ellipsis-text>
      {{ convertedData }}
    </pik-ellipsis-text>
  </div>
</ng-container>
<ng-template #content>
  <div #viewerRef [class.nowrap]="whiteSpace === 'nowrap'" [innerHTML]="convertedData"></div>
</ng-template>
