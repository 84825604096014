import { TablePageFilter } from '../table-page-filter-base';
import { BindingSourceType, RangeDateDto } from '@common/dto';
import { SortOrder } from '@common/interfaces';
import { IBindingGroupsFilterParams } from './binding-groups-filter.interface';
import { formatRangeDate } from '@shared/utils';

const DEFAULT_PARAMS: IBindingGroupsFilterParams = {
  sourceType: BindingSourceType.Internal,
  defaultSortBy: 'created',
  sortOrder: SortOrder.Descending,
};

export class BindingGroupsFilterParams extends TablePageFilter<IBindingGroupsFilterParams> {
  id: number;
  description: string;
  formula: string;
  disciplineIds: number[];
  functionalTypeIds: number[];
  familyIds: number[];
  materialIds: number[];
  authorIds: number[];
  lastEditorIds: number[];
  created: RangeDateDto;
  modified: RangeDateDto;
  sourceType: BindingSourceType;

  constructor(filter?: IBindingGroupsFilterParams) {
    super(filter, DEFAULT_PARAMS);
  }

  toObject(): IBindingGroupsFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      description: this.description,
      formula: this.formula,
      disciplineIds: this.disciplineIds,
      functionalTypeIds: this.functionalTypeIds,
      familyIds: this.familyIds,
      materialIds: this.materialIds,
      authorIds: this.authorIds,
      lastEditorIds: this.lastEditorIds,
      created: formatRangeDate(this.created),
      modified: formatRangeDate(this.modified),
      sourceType: this.sourceType,
    };
  }
}
