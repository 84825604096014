import { Component, Input } from '@angular/core';
import { ThemeColors } from '@common/consts';
import { IFamiliesFilterParams } from '@common/models/filters';

@Component({
  selector: 'app-families-tag',
  templateUrl: './families-tag.component.html',
  styleUrls: ['families-tag.component.scss'],
})
export class FamiliesTagComponent {
  @Input() count: number;
  @Input() filter: IFamiliesFilterParams;

  colors = ThemeColors;
}
