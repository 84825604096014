import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'fm-edit-row-form',
  templateUrl: './fm-edit-row-form.component.html',
  styleUrls: ['./fm-edit-row-form.component.scss'],
})
export class FmEditRowFormComponent implements OnInit {
  @Input() value: string;
  @Input() placeholder: string;
  @Input() uniqueValues: string[] = [];
  @Input() buttonText = 'COMMON.SEND';
  @Output() cancel: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output() submit: EventEmitter<string> = new EventEmitter<string>();
  form: UntypedFormGroup;

  ngOnInit() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.value, [Validators.required, this._uniqueNameValidator()]),
    });

    this.form.controls['name'].setValue(this.value);
  }

  submitFormHandler($event) {
    this.preventClick($event);
    this.submit.emit(this.form.value['name']);
    this.form.reset();
  }

  cancelHandler($event: MouseEvent) {
    this.preventClick($event);
    this.cancel.emit(true);
    this.form.reset();
  }

  preventClick($event: MouseEvent) {
    $event.stopPropagation();
  }

  private _uniqueNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const forbidden = (this.uniqueValues ?? []).some((v) => v === control.value);
      return forbidden ? { forbiddenName: { value: control.value } } : null;
    };
  }
}
