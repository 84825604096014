<ng-container
  *ngFor="
    let value of attributeValues | slice: 0:(isShowAllCount || !maxCount ? attributeValues.length : maxCount);
    trackBy: trackByIndex
  "
>
  <app-condition-link [access]="permissionService.access.VisibilityOfCatalogs" [link]="value.params">
    <pik-tag [color]="ThemeColors.Gray4" [textColor]="ThemeColors.DarkBlue">
      {{ value.name }}
    </pik-tag>
  </app-condition-link>
</ng-container>
<pik-tag
  class="toggle-tag"
  *ngIf="maxCount !== undefined && attributeValues.length > maxCount"
  [color]="'transparent'"
  [textColor]="ThemeColors.DarkBlue"
  (click)="toggle()"
  large
>
  {{
    isShowAllCount
      ? ('COMMON.HIDE' | translate)
      : ('COMMON_PHRASE.AND_MORE' | translate: { number: attributeValues.length - maxCount })
  }}
</pik-tag>
