import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IdNameDto } from '@common/dto';
import { ApiPaths } from '@common/consts';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class FamilySymbolsApiService {
  constructor(private http: HttpClient, private _sanitized: DomSanitizer) {}

  /**
   * Триграмный поиск по имени
   * @param searchString - строка поиска
   */
  smartSearch(searchString: string): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.FamilySymbols}/smartSearch`, {
      params: {
        searchString,
      },
    });
  }

  /**
   * Возвращает параметры по id[]
   * @param ids - айди параметров
   */
  public getIdNames(ids: number[]): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.FamilySymbols}/idnames`, {
      params: {
        ids: ids.map((id) => id.toString()),
      },
    });
  }

  /**
   * Возвращает иконку предпросмотра по ID типоразмера
   * @param symbolId - ID типоразмера
   */
  public getSymbolPreview(symbolId: number) {
    return this.getSymbolImageAsync(symbolId).pipe(
      take(1),
      map((data) =>
        data
          ? {
              file: data,
              styleUrl: this._sanitized.bypassSecurityTrustStyle('url(' + URL.createObjectURL(data) + ')'),
              url: this._sanitized.bypassSecurityTrustUrl(URL.createObjectURL(data)),
              resourceUrl: this._sanitized.bypassSecurityTrustResourceUrl(URL.createObjectURL(data)),
            }
          : null,
      ),
      catchError(() => of(null)),
    );
  }

  getSymbolImageAsync(symbolId: number) {
    return this.http.get(`${ApiPaths.FamilySymbols}/${symbolId}/image`, { responseType: 'blob' });
  }
}
