import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiPaths } from '@common/consts';
import { PermissionGroupDto } from '@common/dto';

@Injectable({ providedIn: 'root' })
export class PermissionsApiService {
  constructor(private http: HttpClient) {}

  /**
   * Возвращает полный перечень доступных разрешений
   */
  public getList(): Observable<PermissionGroupDto[]> {
    return this.http.get<PermissionGroupDto[]>(ApiPaths.Permissions);
  }
}
