import { AbstractControl, ValidationErrors } from '@angular/forms';
import { AttributeType } from '@common/enums';

export function attributesValidator(control: AbstractControl): ValidationErrors | null {
  const value = control.value;
  const isArray = value && Array.isArray(value);

  const hasEmptyValues = (item) => {
    if (item.attributeType === AttributeType.String) {
      return !item.valueStr;
    }

    if (item.attributeType === AttributeType.StringList) {
      return (item.options?.length || 0) === 0;
    }
  };

  const notRemoved = (item) => !item.removed;

  const invalid = isArray ? value.filter(notRemoved).some(hasEmptyValues) : false;
  return invalid ? { hasEmptyAttribute: true } : null;
}
