import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiPaths } from '@common/consts';
import { ResultPart } from '@common/interfaces';
import { DataTypesFilterParams } from '@common/models/filters';
import { DataTypeDto } from '@common/dto';

@Injectable({ providedIn: 'root' })
export class DataTypesApiService {
  constructor(private http: HttpClient) {}
  public getList(filter: DataTypesFilterParams): Observable<ResultPart<DataTypeDto>> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<ResultPart<DataTypeDto>>(`${ApiPaths.DataTypes}/filter`, params);
  }

  public getAll(): Observable<ResultPart<DataTypeDto>> {
    return this.getList(
      new DataTypesFilterParams({
        limit: -1,
      }),
    );
  }
}
