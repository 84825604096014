import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';

import { LoadLogDto, LoadLogExtendedDto } from '@common/dto';
import { LoadLogFilterParams } from '@common/models/filters';
import { ResultPart } from '@common/interfaces';
import { ApiPaths } from '@common/consts';

@Injectable({ providedIn: 'root' })
export class LoadLogApiService {
  constructor(private http: HttpClient, private _sanitized: DomSanitizer) {}

  /**
   * Возвращает журнал загрузок типоразмеров версии семейства
   * @param filter - фильтр
   */
  public getList(filter: LoadLogFilterParams) {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<ResultPart<LoadLogExtendedDto>>(`${ApiPaths.LoadLog}/filter`, params);
  }

  /**
   * Возвращает объект по ID.
   * @param id - id объекта
   */
  public getById(id: number): Observable<LoadLogDto> {
    return this.http.get<LoadLogDto>(`${ApiPaths.LoadLog}/${id}`);
  }
}
