import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiPaths } from '@common/consts';
import { ResultPart } from '@common/interfaces';
import { CategoryDto, IdNameDto } from '@common/dto';
import { CategoriesFilterParams } from '@common/models/filters/categories';

@Injectable({ providedIn: 'root' })
export class CategoriesApiService {
  constructor(private http: HttpClient) {}

  /**
   * Возвращает категории
   * @param filter - фильтры запроса
   */
  public getList(filter: CategoriesFilterParams) {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<ResultPart<CategoryDto>>(`${ApiPaths.Categories}/filter`, params);
  }

  smartSearch(query: string): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.Categories}/smartSearch`, {
      params: {
        searchString: query,
      },
    });
  }
}
