<div class="attr-values">
  <ng-container *ngFor="let item of options | slice: 0:(isShowAllCount || !maxCount ? options.length : maxCount)">
    <pik-tag
      *ngIf="!item?.isDeleted || showDeletedValues"
      [ngClass]="{ deleted: item?.isDeleted }"
      [color]="bgColor"
      [textColor]="color"
      large
    >
      <span appLinkTransform>{{ item.name }}</span>
    </pik-tag>
  </ng-container>

  <pik-tag
    class="toggle-tag"
    *ngIf="maxCount !== undefined && options.length > maxCount"
    [color]="'transparent'"
    [textColor]="ThemeColors.DarkBlue"
    (click)="toggle($event)"
    large
  >
    {{
      isShowAllCount
        ? ('COMMON.HIDE' | translate)
        : ('COMMON_PHRASE.AND_MORE' | translate: { number: options.length - maxCount })
    }}
  </pik-tag>
</div>
