import { AuthConfig, OAuthResourceServerConfig } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';

export const authConfig: AuthConfig = {
  issuer: environment.auth.issuer,
  redirectUri: environment.auth.redirectUri,
  clientId: environment.auth.clientId,
  responseType: environment.auth.responseType,
  scope: environment.auth.scope,
  requireHttps: environment.auth.requireHttps,
  showDebugInformation: environment.auth.showDebugInformation,
  disableAtHashCheck: environment.auth.disableAtHashCheck,
  silentRefreshRedirectUri: environment.auth.silentRefreshRedirectUri,
  timeoutFactor: 0.75,
};

export const resourceServerConfig: OAuthResourceServerConfig = {
  allowedUrls: environment.auth.allowedUrls,
  sendAccessToken: environment.auth.sendAccessToken,
};
