import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppUploadDto } from '@common/dto/AppUploadDto';
import { MsiInfoDto, PatchOperation } from '@common/dto';
import { ApiPaths } from '@common/consts';
import { ResultPart } from '@common/interfaces';
import { AppsFilterParams } from '@common/models/filters';
import { AppType } from '@common/enums';

@Injectable({
  providedIn: 'root',
})
export class UpdateApiService {
  constructor(private http: HttpClient) {}

  public getList(filter?: AppsFilterParams) {
    const params = filter ? filter.toQueryParams() : undefined;
    return this.http.get<ResultPart<MsiInfoDto>>(ApiPaths.Update, { params });
  }

  public getVersionsByType(appType: AppType): Observable<ResultPart<MsiInfoDto>> {
    return this.http.get<ResultPart<MsiInfoDto>>(`${ApiPaths.Update}?appType=${appType}`);
  }

  public getLastMsi(appType: AppType): Observable<MsiInfoDto> {
    return this.http.get<MsiInfoDto>(`${ApiPaths.Update}/last`, {
      params: { appType },
    });
  }

  public getPackage(id: number) {
    return this.http.get(`${ApiPaths.Update}/${id}/package`, { responseType: 'blob' });
  }

  public upload(dto: AppUploadDto): Observable<MsiInfoDto> {
    const formData = new FormData();

    Object.keys(dto).forEach((key) => {
      formData.append(key, dto[key]);
    });

    return this.http.post<MsiInfoDto>(ApiPaths.Update, formData);
  }

  public patch(appVersionId: number, patch: PatchOperation[]) {
    return this.http.patch<MsiInfoDto>(`${ApiPaths.Update}/${appVersionId}`, patch);
  }

  public getPackageLink(id: number): string {
    return `${ApiPaths.Update}/${id}/package`;
  }
}
