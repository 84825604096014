<pik-select
  *ngIf="!isDisable; else statusView"
  inverse
  [formControl]="statusControl"
  placeholder="{{ 'COMMON_PHRASE.NOT_SELECTED' | translate }}"
>
  <pik-option *ngFor="let status of statuses" [value]="status.id">{{ status.name | translate }}</pik-option>
</pik-select>

<ng-template #statusView>
  <fm-status-tag [statusId]="statusControl.value"></fm-status-tag>
</ng-template>
