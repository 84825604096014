<div class="embed-link">
  <input
    #inputRef
    pikInput
    [formControl]="control"
    size="small"
    placeholder="{{ 'COMMON_LABELS.LINK' | translate }}"
    type="text"
    autofocus
  />
  <button pikButton type="button" (click)="save.emit(control.value)">
    {{ (value ? 'COMMON.SAVE' : 'COMMON.ADD') | translate }}
  </button>
  <button appearance="link" type="button" (click)="cancel.emit()">{{ 'COMMON.CANCEL' | translate }}</button>
</div>
