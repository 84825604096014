<fm-table-page>
  <fm-table-page-header>
    <fm-table-page-toolbar>
      <ng-container *ngIf="searchTypes.length; else emptyRow">
        <pik-search-input
          [formControl]="searchControl"
          [inverse]="isPageTable"
          placeholder="{{ 'COMMON.SEARCH' | translate }}"
          [type]="getSearchType(searchTypeControl.value)"
        ></pik-search-input>

        <pik-select [inverse]="!isPageTable" [formControl]="searchTypeControl" class="fmFilter__search-by-select">
          <pik-option *ngFor="let item of searchTypes" [value]="item.key">
            {{ item.label | translate }}
          </pik-option>
        </pik-select>

        <fm-info-tooltip *ngIf="searchSelectTooltip" [text]="searchSelectTooltip"></fm-info-tooltip>
      </ng-container>
      <ng-template #emptyRow><div class="empty-row"></div></ng-template>

      <ng-container *ngIf="permissions?.create && showAddButton">
        <button
          pikButton
          [disabled]="!isAddButtonVisible"
          [class.disable-btn]="!isAddButtonVisible"
          appearance="inverse"
          [ngClass]="{ 'white-inverse-button': isPageTable }"
          (click)="create.emit()"
        >
          <svg-icon pikButtonIcon src="./assets/icons/plus.svg"></svg-icon>
          {{ 'MAIN_TABLE.ADD' | translate }}
        </button>
      </ng-container>
      <button
        pikButton
        appearance="inverse"
        [ngClass]="{ 'white-inverse-button': isPageTable }"
        (click)="tableSettings.open()"
      >
        <svg-icon pikButtonIcon src="./assets/icons/table-settings.svg"></svg-icon>
        {{ 'MAIN_TABLE.SETTINGS' | translate }}
        <span class="circle-indicator" *ngIf="tableFilter?.hasFilters$ | async"></span>
      </button>
    </fm-table-page-toolbar>
  </fm-table-page-header>

  <fm-table
    [dataSource]="pageService.result$ | async"
    [columns]="columns"
    [entityPhrase]="entityPhrase || title"
    [sortBy]="pageService.filter.sortBy"
    [sortOrder]="pageService.filter.sortOrder"
    [pending]="pageService.pending$ | async"
    [hasActiveFilter]="pageService.filter.hasFilter$ | async"
    (sortChange)="onSort($event)"
    (onRowClick)="onRowClick.emit($event)"
    [paginator]="
      isPageTable && {
        fullCount: pageService.fullCount$ | async,
        pageIndex: pageService.filter.tableParams.pageIndex,
        limit: pageService.filter.tableParams.limit,
        limitMin: pageService.filter.tableParams.limitMin,
        pageSizesList: pageService.filter.tableParams.pageSizesList
      }
    "
    (pageChange)="onChangePageNumber($event)"
    (pageSizeChange)="onChangePageSize($event)"
  >
  </fm-table>
</fm-table-page>

<app-table-settings #tableSettings [showFilters]="showFilters">
  <ng-content select="[fmTableFilter]"></ng-content>
</app-table-settings>
