import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { PikAutocompleteSelectedEvent } from '@pik-ui/ng-components';
import { TableFamilyDto } from '@common/dto';
import { FamiliesApiService } from '@services/api';
import { FamiliesFilterParams, IFamiliesFilterParams } from '@common/models/filters';
import { SortOrder, TypedFormControl } from '@common/interfaces';

@Component({
  selector: 'app-family-control',
  templateUrl: './family-control.component.html',
  styleUrls: ['./family-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FamilyControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FamilyControlComponent implements OnDestroy, ControlValueAccessor, OnInit {
  @Input() filter: IFamiliesFilterParams = {};
  @Input() sortOrderFamily: SortOrder = null;
  @Output() selectFull = new EventEmitter<TableFamilyDto>();

  nameControl: TypedFormControl<string> = new FormControl(null);
  selectedFamily: TableFamilyDto;
  families$ = new Subject<PikAutocompleteSelectedEvent[]>();
  isDisable: boolean;

  private _destroy$ = new Subject();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(private _changeDetector: ChangeDetectorRef, private familiesApi: FamiliesApiService) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this._subscribeToNameControl().subscribe();
  }

  async writeValue(value: number) {
    if (value) {
      await this._getFamilies(value).toPromise();
    } else {
      this.nameControl.setValue(null);
    }
    this._changeDetector.detectChanges();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  selectEntity(event: PikAutocompleteSelectedEvent) {
    this.selectedFamily = event.value;
    this.nextChangeValue();
  }

  nextChangeValue(): void {
    if (this._onChange) {
      this._onChange(this.selectedFamily?.id || null);
      this.selectFull.next(this.selectedFamily);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisable = isDisabled;
    this._changeDetector.markForCheck();
  }

  private _subscribeToNameControl() {
    return this.nameControl.valueChanges.pipe(
      debounceTime(300),
      filter((f) => f !== this.selectedFamily?.name),
      tap((name) => {
        this.selectedFamily = null;
        this.nextChangeValue();
      }),
      switchMap((value) =>
        value === '' || value === null
          ? of([])
          : this.familiesApi
              .getList(
                new FamiliesFilterParams({
                  sortBy: 'Name',
                  sortOrder: this.sortOrderFamily ? this.sortOrderFamily : SortOrder.Ascending,
                  ...this.filter,
                  name: value,
                }),
              )
              .pipe(map((data) => data.result)),
      ),
      map((result: TableFamilyDto[]) =>
        result.map((item) => ({
          label: item.name,
          value: item,
        })),
      ),
      tap((data) => this.families$.next(data)),
      takeUntil(this._destroy$),
    );
  }

  private _getFamilies(id: number) {
    const ftFilter = new FamiliesFilterParams({ id });

    return this.familiesApi.getList(ftFilter).pipe(
      take(1),
      map((data) => data.result[0]),
      tap((entity) => {
        this.nameControl.setValue(entity.name, { emitEvent: false });
        this.selectedFamily = entity;
        this.selectFull.emit(entity);
      }),
    );
  }
}
