import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DictionariesService } from '@services';
import { tap } from 'rxjs/operators';
import { TypedFormControl } from '@common/interfaces';
import { DisciplineDto } from '@common/dto';
import { Store } from '@ngxs/store';
import { AppState } from '../../../../store';

@Component({
  selector: 'app-discipline-control',
  templateUrl: './discipline-control.component.html',
  styleUrls: ['./discipline-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DisciplineControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisciplineControlComponent implements ControlValueAccessor, OnInit {
  @Output() selectFull = new EventEmitter<DisciplineDto>();
  control: TypedFormControl<number> = new FormControl(null);

  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(
    private _changeDetector: ChangeDetectorRef,
    public dictionariesService: DictionariesService,
    private store: Store,
  ) {}

  ngOnInit() {
    this.dictionariesService.loadDisciplines();
  }

  writeValue(value: number) {
    this.dictionariesService
      .loadDisciplines()
      .pipe(
        tap(() => {
          this.control.setValue(value, { emitEvent: false });
          this._changeDetector.detectChanges();
        }),
      )
      .subscribe();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  nextChangeValue(): void {
    if (this._onChange) {
      const id = this.control.value;
      const disciplines = this.store.selectSnapshot(AppState.disciplines);
      this.selectFull.emit(disciplines.find((d) => d.id === id));
      this._onChange(id);
    }
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.control.disable({ emitEvent: false }) : this.control.enable({ emitEvent: false });
  }
}
