import { TablePageFilter } from '../table-page-filter-base';
import { ParameterErrorType } from '@common/enums';
import { IFamilyVersionFTParametersFilterParams } from './family-version-ft-parameters.interface';

const DEFAULT_PARAMS: IFamilyVersionFTParametersFilterParams = {
  valueStrFromFilterAsMask: true,
};

export class FamilyVersionFTParametersFilterParams extends TablePageFilter<IFamilyVersionFTParametersFilterParams> {
  id: number;
  parameter: string;
  uniqueParameter: string;
  valueDescription: string;
  stringValue: string;
  dataTypeIds: number[];
  unitTypeIds: number[];
  disciplineIds: number[];
  description: string;
  isType: boolean;
  isRequired: boolean;
  isAddress: boolean;
  isRequiredForRequest: boolean;
  isKey: boolean;
  isSynchronizable: boolean;
  isAdditional: boolean;
  searchString: string;
  valueStrFromFilterAsMask: boolean;
  errorTypes: ParameterErrorType[];

  skipEmptyArrayFields = ['errorTypes'];
  constructor(filter: IFamilyVersionFTParametersFilterParams = {}) {
    super(filter);
  }

  public toObject(): IFamilyVersionFTParametersFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      parameter: this.parameter,
      uniqueParameter: this.uniqueParameter,
      valueDescription: this.valueDescription,
      stringValue: this.stringValue,
      dataTypeIds: this.dataTypeIds,
      unitTypeIds: this.unitTypeIds,
      disciplineIds: this.disciplineIds,
      description: this.description,
      isType: this.isType,
      isRequired: this.isRequired,
      isAddress: this.isAddress,
      isRequiredForRequest: this.isRequiredForRequest,
      isKey: this.isKey,
      isSynchronizable: this.isSynchronizable,
      isAdditional: this.isAdditional,
      searchString: this.searchString,
      valueStrFromFilterAsMask: this.valueStrFromFilterAsMask,
      errorTypes: this.errorTypes,
    };
  }
}
