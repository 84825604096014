import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { STATUSES } from '@common/consts';

@Component({
  selector: 'app-status-control',
  templateUrl: './status-control.component.html',
  styleUrls: ['./status-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StatusControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusControlComponent implements OnDestroy, ControlValueAccessor, OnInit {
  statusControl = new UntypedFormControl(null);
  statuses = STATUSES;

  isDisable: boolean;

  private _destroy$ = new Subject();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(private _changeDetector: ChangeDetectorRef) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this._subscribeToStatusControl().subscribe();
  }

  writeValue(value: number) {
    this.statusControl.setValue(value);
    this._changeDetector.detectChanges();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  nextChangeValue(): void {
    if (this._onChange) {
      this._onChange(this.statusControl.value);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisable = isDisabled;
    this._changeDetector.markForCheck();
  }

  private _subscribeToStatusControl() {
    return this.statusControl.valueChanges.pipe(
      tap(() => this.nextChangeValue()),
      takeUntil(this._destroy$),
    );
  }
}
