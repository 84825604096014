export enum ERquestTypes {
  createFamily = 1,
  updateFamily,
  createMaterial,
  updateMaterial,
}

export enum ERequestTypesMode {
  family,
  material,
}

export enum ERequestCreateUpdate {
  create,
  update,
}
