import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { PikAutocompleteSelectedEvent, PikInputComponent } from '@pik-ui/ng-components';
import { debounceTime, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { IdNameDto, UniqueParameterDto } from '@common/dto';
import { UniqueParametersApiService } from '@services/api';
import { TypedFormControl } from '@common/interfaces';

@Component({
  selector: 'app-unique-parameter-control',
  templateUrl: './unique-parameter-control.component.html',
  styleUrls: ['./unique-parameter-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UniqueParameterControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniqueParameterControlComponent implements OnDestroy, ControlValueAccessor, OnInit {
  @ViewChildren('input') searchInput: QueryList<PikInputComponent>;

  nameControl: TypedFormControl<string> = new FormControl(null);
  selectedEntity$ = new Subject<UniqueParameterDto>();
  _selectedEntity: IdNameDto;
  entities$ = new Subject();

  isDisable: boolean;

  private _destroy$ = new Subject();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(private _changeDetector: ChangeDetectorRef, private uniqueParametersApi: UniqueParametersApiService) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this._subscribeToNameControl().subscribe();
  }

  writeValue(value: number) {
    if (value) {
      this._getEntity(value)
        .pipe(tap((parameter) => this.nameControl.setValue(parameter.name, { emitEvent: false })))
        .subscribe();
    } else {
      this._selectedEntity = null;
      this.selectedEntity$.next(null);
      this.nameControl.setValue(null, { emitEvent: false });
      this._changeDetector.detectChanges();
    }
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  selectEntity(event: PikAutocompleteSelectedEvent) {
    this.nextChangeValue(event.value.id);

    this._getEntity(event.value.id).subscribe();
  }

  nextChangeValue(id: number): void {
    if (this._onChange) {
      this._onChange(id ?? null);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisable = isDisabled;
    this._changeDetector.markForCheck();
  }

  private _subscribeToNameControl() {
    return this.nameControl.valueChanges.pipe(
      debounceTime(300),
      filter((name) => name !== this._selectedEntity?.name),
      tap(() => {
        this._selectedEntity = null;
        this.selectedEntity$.next(null);
        this.nextChangeValue(null);
      }),
      switchMap((value) => (value === '' || value === null ? of([]) : this.uniqueParametersApi.search(value))),
      map((result: IdNameDto[]) =>
        result.map((item) => ({
          label: item.name,
          value: item,
        })),
      ),
      tap((data) => this.entities$.next(data)),
      takeUntil(this._destroy$),
    );
  }

  private _getEntity(id: number) {
    return this.uniqueParametersApi.getById(id).pipe(
      take(1),
      tap((entity: UniqueParameterDto) => {
        this._selectedEntity = { id: entity.id, name: entity.name };
        this.selectedEntity$.next(entity);
        this._changeDetector.detectChanges();
      }),
    );
  }
}
