export enum EXTENSION {
  Dwg = 'dwg',
  Rvt = 'rvt',
  Rfa = 'rfa',
}

export enum TYPE {
  Revit = 'revit',
  Civil = 'civil',
}
