import { PIK_MODAL_DATA, PikModalRef } from '@pik-ui/ng-components';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'fm-modal-dialog',
  templateUrl: './fm-modal-dialog.component.html',
  styleUrls: ['./fm-modal-dialog.component.scss'],
  host: {
    maxWidth: '400px',
  },
})
export class FmModalDialogComponent {
  constructor(
    public dialogRef: PikModalRef<FmModalDialogComponent>,
    @Inject(PIK_MODAL_DATA)
    public data: { title: string; successLabel?: string; failureLabel?: string; html?: boolean },
  ) {
    this.dialogRef.config.center = true;
  }

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
