import { Component, Input } from '@angular/core';
import { ROUTES } from '@common/consts';
import { normalizePageParams } from '@common/utils';
import { IRequestsFilterParams, RequestsFilterParams } from '@common/models/filters';
import { ConditionLinkModule } from '@shared/components/condition-link';

@Component({
  standalone: true,
  selector: 'app-request-link',
  templateUrl: './request-link.component.html',
  imports: [ConditionLinkModule],
})
export class RequestLinkComponent {
  @Input() set params(value: IRequestsFilterParams) {
    if (value) {
      this.link = ['', ROUTES.requestsList, normalizePageParams(new RequestsFilterParams(value).toParams())];
    }
  }
  @Input() name: string;

  link: any[];
}
