/**
 * Преобразовывает файл в Base64 строку
 * @param file - объект файла
 * @return - promise<string>
 */
export function convertToBase64(file: File | Blob): Promise<string | ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
