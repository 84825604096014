import { Pipe } from '@angular/core';
import { AttributeValueDto } from '@common/dto';
import { sortBySortNumber } from '@common/utils';

@Pipe({
  name: 'attributesSort',
  pure: true,
})
export class AttributesSortPipe {
  transform(array: AttributeValueDto[]): AttributeValueDto[] {
    if (!array || !Array.isArray(array)) {
      return [];
    }

    return sortBySortNumber([...array]);
  }
}
