<ng-container *ngIf="!isDisable; else parameterView">
  <input
    pikInput
    [triggerValueOnEmpty]="emptyTrigger"
    [formControl]="nameControl"
    [pikAutocomplete]="autocomplete"
    placeholder="{{ 'COMPONENT_PARAMETER_CONTROL' | translate }}"
    (blur)="_onTouched()"
  />

  <pik-autocomplete
    [standalone]="false"
    #autocomplete="pikAutocomplete"
    (optionSelected)="selectEntity($event)"
    [dataSource]="entities$ | async"
  >
  </pik-autocomplete>
</ng-container>

<ng-template #parameterView>
  <app-parameters-link [params]="{ id: _selectedEntity?.id, searchValue: 'id', searchField: 'id' }">{{
    _selectedEntity?.name || nameControl.value
  }}</app-parameters-link>
</ng-template>
