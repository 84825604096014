import { BindingSourceType } from '@common/dto';

export class LoadAttributes {
  static readonly type = '[App] Load Attributes';

  constructor(public force: boolean = false) {}
}

export class LoadDisciplines {
  static readonly type = '[App] Load disciplines';

  constructor(public force: boolean = false) {}
}

export class LoadUnitTypes {
  static readonly type = '[App] Load unit types';

  constructor(public force: boolean = false, public bindingSourceType: BindingSourceType) {}
}

export class LoadRoles {
  static readonly type = '[App] Load roles';
}

export class LoadDataTypes {
  static readonly type = '[App] Load data types';
}

export class LoadPermissions {
  static readonly type = '[App] Load permissions';
}

export class LoadRequestStatuses {
  static readonly type = '[App] Load request statuses';
}
