<ng-container *ngIf="multi; else singleView">
  <ng-container
    *ngFor="
      let symbol of symbols | slice: 0:(isShowAllCount || !maxCount ? symbols.length : maxCount);
      trackBy: trackByIndex
    "
  >
    <app-condition-link [access]="linked" [link]="['', routesParts.FAMILIES, family?.id, { version: version }]">
      <pik-tag large [color]="colors.Symbol">
        {{ symbol?.name }}
      </pik-tag>
    </app-condition-link>
  </ng-container>

  <pik-tag
    class="toggle-tag"
    *ngIf="maxCount !== undefined && symbols.length > maxCount"
    [color]="'transparent'"
    [textColor]="colors.DarkBlue"
    (click)="toggle()"
    large
  >
    {{
      isShowAllCount
        ? ('COMMON.HIDE' | translate)
        : ('COMMON_PHRASE.AND_MORE' | translate: { number: symbols.length - maxCount })
    }}
  </pik-tag>
</ng-container>

<ng-template #singleView>
  <app-condition-link [access]="linked" [link]="['', routesParts.FUNCTIONAL_TYPES, symbol?.id]">
    <pik-tag large [color]="colors.Symbol">
      {{ symbol?.name }}
    </pik-tag>
  </app-condition-link>
</ng-template>
