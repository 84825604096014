import { ExpandEntityType } from '../types';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ExpandableBlockService {
  setExpandableBlock(toggle: boolean, entity: ExpandEntityType) {
    const expandableBlocks = JSON.parse(localStorage.getItem('expandableBlocks')) || {};

    localStorage.setItem(
      'expandableBlocks',
      JSON.stringify({
        ...expandableBlocks,
        [entity]: toggle,
      }),
    );
  }

  getExpandableBlock(entity: ExpandEntityType) {
    const expandableBlocks = JSON.parse(localStorage.getItem('expandableBlocks')) || {};

    return expandableBlocks[entity] || false;
  }
}
