import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CdkTableModule } from '@angular/cdk/table';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import {
  PikCardModule,
  PikEllipsisTextModule,
  PikEmptyDataModule,
  PikLoaderModule,
  PikSortHeaderModule,
  PikTableModule,
  PikTagModule,
} from '@pik-ui/ng-components';

import { TablePaginatorModule } from '@shared/components/table-paginator';
import { FmInfoTooltipModule } from '@shared/components';
import { FmTableComponent } from './fm-table.component';
import { FmTableCellTemplateDirective, FmTableHeaderTemplateDirective } from './directives';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TableAttributesComponent } from './components';
import { ConditionLinkModule } from '@shared/components/condition-link';

@NgModule({
  declarations: [
    FmTableComponent,
    FmTableCellTemplateDirective,
    FmTableHeaderTemplateDirective,
    TableAttributesComponent,
  ],
  imports: [
    CommonModule,
    CdkTableModule,
    PikTableModule,
    PikSortHeaderModule,
    PerfectScrollbarModule,
    TablePaginatorModule,
    PikCardModule,
    PikEmptyDataModule,
    PikLoaderModule,
    PikEllipsisTextModule,
    FmInfoTooltipModule,
    RouterModule,
    TranslateModule,
    AngularSvgIconModule,
    PikTagModule,
    ConditionLinkModule,
  ],
  exports: [FmTableComponent, FmTableCellTemplateDirective, FmTableHeaderTemplateDirective],
})
export class FmTableModule {}
