import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FmTableCellTemplateDirective, FmTableHeaderTemplateDirective } from '@shared/modules/fm-table/directives';
import { FmTableComponent } from '@shared/modules/fm-table';

import { TABLE_COLUMNS, TABLE_SEARCH_TYPES } from '../tokens';
import { FmTablePageFilterDirective } from '../layout';
import { ColumnType, TableColumnDef, TablePageBase, TablePageServiceBase, TableSettingsFilter } from '../models';
import { TableSettingsComponent } from '../table-settings/table-settings.component';
import { SearchType } from '../types';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { TableBuilderService } from '@shared/services/table-builder.service';

interface TablePagePermissions {
  create?: boolean;
}

const searchTypesFactory = (types: SearchType[]) =>
  types.map((item) => ({
    key: item.key,
    label: item.label,
    default: item.default,
    type: item.type || ColumnType.STRING,
  }));

@Component({
  selector: 'app-table-builder',
  templateUrl: './table-builder.component.html',
  styleUrls: ['./table-builder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableBuilderComponent extends TablePageBase implements AfterViewInit, OnInit {
  isAddButtonVisible = true;
  @ContentChild(FmTableHeaderTemplateDirective) headerTemplate: FmTableHeaderTemplateDirective;
  @ContentChild(FmTableCellTemplateDirective) cellTemplate: FmTableCellTemplateDirective;
  @ContentChild(FmTablePageFilterDirective, { read: TableSettingsFilter }) tableFilter: TableSettingsFilter;

  @ViewChild(FmTableComponent) tableComponent: FmTableComponent;
  @ViewChild(TableSettingsComponent) tableSettings: TableSettingsComponent;

  @Input() title: string;
  @Input() showAddButton = true;
  @Input() hint: string;
  @Input() entityPhrase: string;
  @Input() showFilters = true;
  @Input() permissions: TablePagePermissions;
  @Input() searchSelectTooltip: string | TemplateRef<any>;
  @Input() isPageTable: boolean;

  @Output() create = new EventEmitter<void>();
  @Output() onRowClick = new EventEmitter<any>();
  columns: TableColumnDef[] = [];

  constructor(
    public pageService: TablePageServiceBase,
    protected _route: ActivatedRoute,
    @Inject(TABLE_COLUMNS) columns: TableColumnDef[],
    @Inject(TABLE_SEARCH_TYPES) searchTypes: SearchType[],
    private helper: TableBuilderService,
  ) {
    super(pageService, _route, searchTypesFactory(searchTypes));
    this.subscribeToParametersAction().subscribe();
  }

  ngOnInit(): void {
    this.subscribeToColumns().subscribe();
    this.helper.isCharacteristicsAddButtonVisible.subscribe((value) => {
      this.isAddButtonVisible = value;
    });
  }

  ngAfterViewInit() {
    if (this.headerTemplate) {
      this.tableComponent.headerTemplate = this.headerTemplate;
    }

    if (this.cellTemplate) {
      this.tableComponent.cellTemplate = this.cellTemplate;
    }

    if (this.tableFilter) {
      this.tableSettings.tableFilterRef = this.tableFilter;
    }
  }

  reload() {
    this.tablePageService.reloadAndSetParams();
    this.scrollToTop();
  }

  scrollToTop() {
    if (this.tableComponent._scrollbar.first) {
      this.tableComponent._scrollbar.first.directiveRef.scrollToTop();
    }
  }

  resetFilter() {
    super.resetFilter();

    if (this.searchControl) {
      this.searchControl.setValue(this.tablePageService.filter.searchValue, { emitEvent: false });
    }

    if (this.searchTypeControl) {
      this.searchTypeControl.setValue(this.tablePageService.filter.searchField, { emitEvent: false });
    }
  }

  private subscribeToColumns() {
    return combineLatest([this.pageService.columns$, this.pageService.attributesColumns$]).pipe(
      tap(([columns, attributeColumns]) => {
        const attributeColumn = columns.find((d) => d.name === 'attributes');
        const columnsWithoutAttribute = columns.filter((f) => f.name !== 'attributes');

        this.columns = attributeColumn?.isVisible
          ? [
              ...columnsWithoutAttribute,
              ...attributeColumns.map((a) => ({ ...a, orderPosition: attributeColumn?.orderPosition ?? 0 })),
            ]
          : columnsWithoutAttribute;
      }),

      takeUntil(this.destroy$),
    );
  }

  private subscribeToParametersAction() {
    return this.pageService.setParametersAction$.pipe(
      filter((f) => !!f),
      tap((params: any) => {
        this.pageService.filter = params;
        this.searchTypeControl.setValue(this.pageService.filter.searchField, { emitEvent: false });
        setTimeout(() => this.searchControl.setValue(this.pageService.filter.searchValue, { emitEvent: false }));
      }),
      takeUntil(this.destroy$),
    );
  }
}
