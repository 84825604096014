import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BindingsFilterParams } from '@common/models/filters';
import { ResultPart } from '@common/interfaces';
import { ApiPaths } from '@common/consts';

import { BindingCreateDto, BindingDto, BindingFiltersGroup, BindingUpdateDto, MatchingPropertyDto } from '@common/dto';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BindingsApiService {
  constructor(private http: HttpClient) {}

  public getList(filter?: BindingsFilterParams) {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<ResultPart<BindingDto>>(`${ApiPaths.Bindings}/filter`, params);
  }

  public getFiltersGroups(entityId: number) {
    return this.http.get<BindingFiltersGroup[]>(`${ApiPaths.Bindings}/${entityId}/filtersGroups`);
  }

  public getById(entityId: number) {
    return this.http.get<BindingDto>(`${ApiPaths.Bindings}/${entityId}`);
  }

  public create(entity: BindingCreateDto) {
    return this.http.post<BindingDto>(ApiPaths.Bindings, {
      ...entity,
      name: entity.bindingName,
    });
  }

  public update(entity: BindingUpdateDto) {
    return this.http.put<BindingDto>(`${ApiPaths.Bindings}/${entity.id}`, {
      ...entity,
      name: entity.bindingName,
    });
  }

  public remove(entityId: number) {
    return this.http.delete<void>(`${ApiPaths.Bindings}/${entityId}`);
  }

  public createSnap(entity: BindingCreateDto) {
    return this.http.post<BindingDto>(`${ApiPaths.Bindings}/snap`, {
      ...entity,
      name: entity.bindingName,
    });
  }

  public updateSnap(entity: BindingUpdateDto) {
    return this.http.put<BindingDto>(`${ApiPaths.Bindings}/snap/${entity.id}`, {
      ...entity,
      name: entity.bindingName,
    });
  }

  public removeSnap(entityId: number) {
    return this.http.delete<void>(`${ApiPaths.Bindings}/snap/${entityId}`);
  }

  public getParameters(id) {
    return this.http.get<MatchingPropertyDto[]>(`${ApiPaths.Bindings}/${id}/parameters`);
  }

  public formulaValidate(formula: string) {
    return this.http.post(`${ApiPaths.Bindings}/formula/validate`, { value: formula });
  }

  public createGoogleInternalSheet(filter: BindingsFilterParams): Observable<string> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post(`${ApiPaths.Bindings}/sheets`, params, { responseType: 'text' });
  }

  public createGoogleSnapSheet(filter: BindingsFilterParams): Observable<string> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post(`${ApiPaths.Bindings}/snap/sheets`, params, { responseType: 'text' });
  }

  public importSheet(sheetId: string) {
    return this.http.put(`${ApiPaths.Bindings}/sheets/${sheetId}`, {});
  }

  public importSnapSheet(sheetId: string) {
    return this.http.put(`${ApiPaths.Bindings}/snap/sheets/${sheetId}`, {});
  }

  public createInternalExcelSheet(filter: BindingsFilterParams): Observable<Blob> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post(`${ApiPaths.Bindings}/excel`, params, { responseType: 'blob' });
  }

  public createSnapExcelSheet(filter: BindingsFilterParams): Observable<Blob> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post(`${ApiPaths.Bindings}/snap/excel`, params, { responseType: 'blob' });
  }

  public importExcelSheet(file: File) {
    const data = new FormData();
    data.append('file', file);
    return this.http.put(`${ApiPaths.Bindings}/excel`, data);
  }

  public importSnapExcelSheet(file: File) {
    const data = new FormData();
    data.append('file', file);
    return this.http.put(`${ApiPaths.Bindings}/snap/excel`, data);
  }
}
