import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemeColors } from '@common/consts';
import { IdNameDto } from '@common/dto';
import { PikTagModule } from '@pik-ui/ng-components';
import { NgForOf } from '@angular/common';

@Component({
  selector: 'app-permission-tags',
  templateUrl: './permission-tags.component.html',
  styleUrls: ['./permission-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PikTagModule, NgForOf],
})
export class PermissionTagsComponent {
  @Input() permissions: IdNameDto[];
  ThemeColors = ThemeColors;
}
