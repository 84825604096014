import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IdNameDto } from '@common/dto';
import { ApiPaths } from '@common/consts';

@Injectable({ providedIn: 'root' })
export class RequestStatusApiService {
  constructor(private http: HttpClient) {}

  /**
   * Возвращает список статусов заявки
   */
  public getList(): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.RequestStatues}`);
  }
}
