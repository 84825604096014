import { NgModule } from '@angular/core';
import { ParametersViewComponent } from './parameters-view.component';
import { TranslateModule } from '@ngx-translate/core';
import { PikTagModule } from '@pik-ui/ng-components';
import { CommonModule } from '@angular/common';
import { ParameterViewStringWithSpecialSymbolsPipe } from './pipes';
import { ParametersBooleanViewComponent } from './components/parameters-boolean-view/parameters-boolean-view.component';

@NgModule({
  declarations: [ParametersViewComponent, ParameterViewStringWithSpecialSymbolsPipe, ParametersBooleanViewComponent],
  exports: [ParametersViewComponent, ParameterViewStringWithSpecialSymbolsPipe],
  imports: [TranslateModule, PikTagModule, CommonModule],
})
export class ParametersViewModule {}
