import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AttributeDto } from '@common/dto';
import { CrudService, DictionaryItemModel, ResultPart } from '@common/interfaces';
import { AttributesFilterParams } from '@common/models/filters';
import { ApiPaths } from '@common/consts';
import { DictionaryProvider } from '@common/models';

@Injectable({ providedIn: 'root' })
export class AttributesApiService implements CrudService<AttributeDto>, DictionaryProvider<AttributeDto> {
  constructor(private http: HttpClient) {}

  /**
   * Возвращает заданное количество объектов, по умолчанию возвращает все.
   * @param filter - фильтр
   */
  public getList(filter?: AttributesFilterParams): Observable<ResultPart<AttributeDto>> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<ResultPart<AttributeDto>>(`${ApiPaths.Attributes}/filter`, params);
  }

  public getAll() {
    return this.getList(
      new AttributesFilterParams({
        limit: -1,
        showDeletedAttributes: false,
        showDeletedValues: false,
      }),
    );
  }

  /**
   * Возвращает объект по ID.
   * @param attributeId - id атрибута
   */
  public getById(attributeId: number) {
    return this.http.get<AttributeDto>(`${ApiPaths.Attributes}/${attributeId}`);
  }

  /**
   * Добавляет новый объект
   * @param attribute - новый атрибут
   */
  public create(attribute: AttributeDto) {
    return this.http.post<AttributeDto>(ApiPaths.Attributes, attribute);
  }

  /**
   * Обновляет объект
   * @param attribute - изменённые данные
   */
  public update(attribute: AttributeDto) {
    return this.http.put<AttributeDto>(`${ApiPaths.Attributes}/${attribute.id}`, attribute);
  }

  /**
   * Удаляет объект
   * @param attributeId - id атрибута
   */
  public remove(attributeId: number) {
    return this.http.delete<void>(`${ApiPaths.Attributes}/${attributeId}`);
  }

  /**
   * Поиск объекта.
   * @param query - поисковая строка
   */
  public search(query: string) {
    return this.http.get<AttributeDto[]>(`${ApiPaths.Attributes}/search`, { params: { query } });
  }

  /**
   * Добавляет несколько объектов.
   * @param attributes - атрибуты
   */
  public createBatch(attributes: AttributeDto[]) {
    return this.http.post<AttributeDto[]>(`${ApiPaths.Attributes}/batch`, attributes);
  }

  public dictionarySearch(query: string): Observable<DictionaryItemModel<AttributeDto>[]> {
    return this.search(query).pipe(
      map((result: AttributeDto[]) => {
        return result.map((item: AttributeDto): DictionaryItemModel<AttributeDto> => {
          return {
            name: item.name,
            value: item.id,
            item,
          };
        });
      }),
    );
  }
}
