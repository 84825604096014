<div class="files" *ngIf="attachments?.length">
  <file-item
    *ngFor="let file of attachments"
    [name]="file.fileName"
    [removable]="removable && permissionService.checkDisciplines(disciplines)"
    (remove)="removeHandler(file)"
    (open)="downloadFile(file)"
  >
  </file-item>
</div>

<pik-button-group class="uploader">
  <ng-container *hasDisciplines="disciplines || []">
    <input type="file" class="fileInput" #fileInput (change)="uploadFiles($event.target.files)" multiple />
    <button pikButton appearance="inverse" (click)="addFile()" *ngIf="permissionUpload" [disabled]="pending">
      <svg-icon pikButtonIcon position="before" src="./assets/icons/paperclip.svg"></svg-icon>
      {{ 'COMPONENT_ATTACHMENTS.PICK_FILES' | translate }}
    </button>
  </ng-container>
  <button
    pikButton
    appearance="inverse"
    (click)="downloadAll.emit()"
    *ngIf="permissionDownload && attachments?.length > 1"
    [disabled]="pending"
  >
    <svg-icon
      [svgStyle]="{ 'width.px': '16' }"
      pikButtonIcon
      position="before"
      src="./assets/icons/download-file.svg"
    ></svg-icon>
    {{ 'COMPONENT_ATTACHMENTS.DOWNLOAD_ALL' | translate }}
  </button>
  <pik-loader *ngIf="pending"></pik-loader>
</pik-button-group>
