<div class="fm-wrap">
  <div class="fm-container">
    <div class="page404">
      <h1>404</h1>
      <p>{{ 'PAGE_404.MESSAGE' | translate }}</p>
      <p>
        <a routerLink="/">{{ 'PAGE_404.TO_MAIN' | translate }}</a>
      </p>
    </div>
  </div>
</div>
