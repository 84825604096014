import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PikInputComponent } from '@pik-ui/ng-components';

@Component({
  selector: 'app-embed-link',
  templateUrl: './embed-link.component.html',
  styleUrls: ['./embed-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmbedLinkComponent implements AfterViewInit {
  @Input()
  set value(value: string) {
    if (value !== this._value) {
      this._value = value;
      this.control.setValue(value);
    }
  }
  get value(): string {
    return this._value;
  }

  @Output()
  save = new EventEmitter<string>();

  @Output()
  cancel = new EventEmitter<any>();

  @ViewChild('inputRef', { static: true })
  inputRef: PikInputComponent;

  control = new UntypedFormControl();

  private _value: string;

  ngAfterViewInit() {
    this.inputRef.element.focus();
  }
}
