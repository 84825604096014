<input
  type="file"
  style="display: none"
  #fileInput
  accept="image/png"
  (change)="handleFileInput($event.target.files)"
  onclick="this.value = null"
/>

<button *ngIf="!image && !pending && !isDisabled" type="button" pikButton appearance="inverse" (click)="reloadImage()">
  <svg-icon pikButtonIcon src="./assets/icons/plus.svg"></svg-icon>
  {{ 'COMMON.ADD' | translate }}
</button>

<div class="image-wrap" *ngIf="image">
  <div class="relative">
    <div
      class="image image-wrap__image"
      [style.background-image]="image?.styleUrl"
      [ngClass]="{ 'not-open': !canOpen }"
      (click)="showImage(image)"
    ></div>
    <button type="button" class="image__remove" *ngIf="removable" (click)="onDeleteImage()">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 11L8 8M8 8L5 5M8 8L11 11M8 8L11 5" stroke="white"></path>
      </svg>
    </button>
  </div>

  <div class="image-wrap__descr">
    <span class="image-wrap__message">{{ 'COMMON_PHRASE.IMAGE_UPLOADED' | translate }}</span>

    <button *ngIf="!isDisabled" type="button" pikButton appearance="inverse" (click)="reloadImage()">
      <svg-icon pikButtonIcon src="./assets/icons/swap.svg"></svg-icon>
      {{ 'COMMON.REPLACE' | translate }}
    </button>
  </div>

  <pik-loader class="image-wrap__loader" *ngIf="pending"></pik-loader>
</div>
