<div class="string-list">
  <div class="string-list__content">
    <input pikInput [formControl]="control" />
    <button (click)="addItem()" [attr.disabled]="control.invalid ? true : null">
      <svg-icon src="./assets/icons/plus.svg" pikButtonIcon position="before"></svg-icon>
    </button>
  </div>
</div>

<div class="tags" *ngIf="valueList?.length">
  <pik-tag large *ngFor="let item of valueList" removable (remove)="removeItem(item)" (click)="editItem(item)">
    <ng-container *ngIf="item.valueStr; else emptyValue">
      {{ item.valueStr }}
    </ng-container>
    <ng-template #emptyValue>
      <i><{{ 'COMMON_LABELS.EMPTY_VALUE' | translate }}></i>
    </ng-template>
  </pik-tag>
</div>
