import { Component, Input } from '@angular/core';

import { ThemeColors } from '@common/consts';
import { AttributeType, AttributeTypeValue } from '@common/enums';

@Component({
  selector: 'app-attribute-type-tag',
  templateUrl: './attribute-type-tag.component.html',
  styleUrls: ['./attribute-type-tag.component.scss'],
})
export class AttributeTypeTagComponent {
  colors = ThemeColors;
  AttributeType = AttributeType;
  AttributeTypeValue = AttributeTypeValue;

  @Input()
  attributeType: AttributeType;
}
