export class MentionOptions {
  debounceTime = 150;
  minSearchLength = 1;
  maxDropdownHeight = 256;

  constructor(options?) {
    if (options) {
      Object.assign(this, options);
    }
  }
}
