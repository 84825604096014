import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { finalize, map, take, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import _ from 'lodash';

import {
  AttributeDto,
  DataTypeDto,
  DisciplineDto,
  IdNameDto,
  PermissionDto,
  RoleDto,
  UnitTypeDto,
  UserDto,
} from '@common/dto';
import {
  AttributesApiService,
  DataTypesApiService,
  DisciplinesApiService,
  PermissionsApiService,
  RequestStatusApiService,
  RolesApiService,
  UnitTypesApiService,
  VersionsApiService,
} from '@services/api';
import { RolesFilterParams } from '@common/models/filters';

import {
  LoadAttributes,
  LoadDataTypes,
  LoadDisciplines,
  LoadPermissions,
  LoadRequestStatuses,
  LoadRoles,
  LoadUnitTypes,
} from './app.actions';
import { DATA_TYPE_LABEL } from '@common/enums';
import { TranslateService } from '@ngx-translate/core';

export class AppStateModel {
  public attributes: AttributeDto[];
  public disciplines: DisciplineDto[];
  public roles: RoleDto[];
  public permissions: PermissionDto[];
  public requestStatuses: IdNameDto[];
  public dataTypes: DataTypeDto[];
  public unitTypes: UnitTypeDto[];
  public user: UserDto;

  public requestStatusesPending: boolean;
  public permissionsPending: boolean;
  public disciplinesPending: boolean;
  public dataTypesPending: boolean;
  public attributesPending: boolean;
  public unitTypesPending: boolean;
}

@Injectable()
@State<AppStateModel>({
  name: 'app',
  defaults: {
    attributes: null,
    roles: [],
    dataTypes: [],
    unitTypes: [],
    disciplines: [],
    permissions: [],
    requestStatuses: [],
    user: null,
    disciplinesPending: false,
    attributesPending: false,
    permissionsPending: false,
    dataTypesPending: false,
    requestStatusesPending: false,
    unitTypesPending: false,
  },
})
export class AppState {
  @Selector()
  static attributes(state: AppStateModel) {
    return state.attributes;
  }

  @Selector()
  static disciplines(state: AppStateModel) {
    return state.disciplines;
  }

  @Selector()
  static roles(state: AppStateModel) {
    return state.roles;
  }

  @Selector()
  static permissions(state: AppStateModel) {
    return state.permissions;
  }

  @Selector()
  static dataTypes(state: AppStateModel) {
    return state.dataTypes;
  }

  @Selector()
  static unitTypes(state: AppStateModel) {
    return state.unitTypes;
  }

  @Selector()
  static requestStatuses(state: AppStateModel) {
    return state.requestStatuses;
  }

  constructor(
    private _attributesApi: AttributesApiService,
    private _disciplinesApi: DisciplinesApiService,
    private _unitTypesApi: UnitTypesApiService,
    private _versionsApi: VersionsApiService,
    private _rolesApi: RolesApiService,
    private _permissionsApi: PermissionsApiService,
    private _requestStatusApi: RequestStatusApiService,
    private _dataTypesApi: DataTypesApiService,
    private _translateService: TranslateService,
  ) {}

  @Action(LoadAttributes, { cancelUncompleted: true })
  loadAttributes(ctx: StateContext<AppStateModel>, { force }: LoadAttributes): Observable<AttributeDto[]> {
    const state = ctx.getState();

    if (!!state.attributes && !state.attributesPending && !force) {
      return of(state.attributes);
    }

    ctx.patchState({ attributesPending: true });
    return this._attributesApi.getAll().pipe(
      map((response) => response.result),
      tap((attributes) => ctx.patchState({ attributes })),
      finalize(() => ctx.patchState({ attributesPending: false })),
    );
  }

  @Action(LoadRoles)
  loadRoles(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();

    if (state.roles.length > 0) {
      return of(state.roles);
    }

    return this._rolesApi.getList(new RolesFilterParams()).pipe(
      take(1),
      tap((data) => ctx.patchState({ roles: _.orderBy(data.result, 'name', 'asc') })),
    );
  }

  @Action(LoadPermissions)
  LoadPermissions(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();

    if (state.permissions.length > 0 || state.permissionsPending) {
      return of(state.permissions);
    }
    ctx.patchState({ permissionsPending: true });
    return this._permissionsApi.getList().pipe(
      take(1),
      tap((permissionGroups) => {
        const permissions = _.orderBy(permissionGroups.map((g) => g.permissions).flat(), 'name', 'asc');
        ctx.patchState({ permissions });
      }),
      finalize(() => ctx.patchState({ permissionsPending: false })),
    );
  }

  @Action(LoadRequestStatuses)
  loadRequestStatuses(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();

    if (state.requestStatuses.length > 0 || state.requestStatusesPending) {
      return of(state.requestStatuses);
    }
    ctx.patchState({ requestStatusesPending: true });
    return this._requestStatusApi.getList().pipe(
      take(1),
      tap((requestStatuses) => ctx.patchState({ requestStatuses })),
      finalize(() => ctx.patchState({ requestStatusesPending: true })),
    );
  }

  @Action(LoadDisciplines)
  loadDisciplinesAction(ctx: StateContext<AppStateModel>, { force }: LoadDisciplines): Observable<DisciplineDto[]> {
    const state = ctx.getState();

    if ((!!state.disciplines.length || state.disciplinesPending) && !force) {
      return of(state.disciplines);
    }
    ctx.patchState({ disciplinesPending: true });
    return this._disciplinesApi.getAll().pipe(
      take(1),
      map((data) => data.result),
      tap((data) => ctx.patchState({ disciplines: data })),
      finalize(() => ctx.patchState({ disciplinesPending: false })),
    );
  }

  @Action(LoadUnitTypes)
  loadUnitTypes(ctx: StateContext<AppStateModel>, { force, bindingSourceType }): Observable<UnitTypeDto[]> {
    const state = ctx.getState();

    if ((!!state.unitTypes.length || state.unitTypesPending) && !force) {
      return of(state.unitTypes);
    }
    ctx.patchState({ unitTypesPending: true });
    return this._unitTypesApi.getAll(bindingSourceType).pipe(
      take(1),
      map((data) => data.result),
      tap((data) => ctx.patchState({ unitTypes: data })),
      finalize(() => ctx.patchState({ unitTypesPending: false })),
    );
  }

  @Action(LoadDataTypes, { cancelUncompleted: true })
  loadDataTypes(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();

    if (!!state.dataTypes.length || state.dataTypesPending) {
      return of(state.dataTypes);
    }

    ctx.patchState({ dataTypesPending: true });

    return this._dataTypesApi.getAll().pipe(
      tap((response) =>
        ctx.patchState({
          dataTypes: response.result.map((d) => ({
            ...d,
            name: this._translateService.instant(DATA_TYPE_LABEL[d.code]),
          })),
        }),
      ),
      finalize(() => ctx.patchState({ dataTypesPending: false })),
    );
  }
}
