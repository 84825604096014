<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'entity'">
    <ng-container *ngIf="_entity; else negativeIcon">
      <app-condition-link [access]="permissionService.access.VisibilityOfCatalogs" [link]="link">
        {{ _entity.name }}
      </app-condition-link>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'link'">
    <app-condition-link [access]="permissionService.access.VisibilityOfCatalogs" [link]="link">
      <ng-content></ng-content>
    </app-condition-link>
  </ng-container>
</ng-container>
<ng-template #negativeIcon>—</ng-template>
