<ng-container *ngIf="!isDisable; else defaultControl">
  <ng-container [ngSwitch]="parameterValueControlService.dataType$ | async">
    <ng-container *ngSwitchCase="DataTypeCodeEnum.Boolean">
      <app-parameter-boolean-value-control [formControl]="valueControl"></app-parameter-boolean-value-control>
    </ng-container>

    <ng-container *ngSwitchCase="DataTypeCodeEnum.Integer">
      <app-parameter-range-values-control
        numberType="Z"
        [formControl]="valueControl"
      ></app-parameter-range-values-control>
    </ng-container>

    <ng-container *ngSwitchCase="DataTypeCodeEnum.Double">
      <app-parameter-range-values-control
        numberType="R"
        [formControl]="valueControl"
      ></app-parameter-range-values-control>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <app-parameter-string-value-control
        [formControl]="valueControl"
        [isDisable]="isDisable"
      ></app-parameter-string-value-control>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #defaultControl>
  <app-parameter-string-value-control
    [formControl]="valueControl"
    [isDisable]="isDisable"
  ></app-parameter-string-value-control>
</ng-template>
