import { Component, Input } from '@angular/core';

import { ROUTES_PARTS, ThemeColors } from '@common/consts';
import { BindingSourceType } from '@common/dto';
import { PermissionService } from '@core/account/services';

@Component({
  selector: 'app-nomenclature-type-tag',
  templateUrl: './nomenclature-type-tag.component.html',
  styleUrls: ['./nomenclature-type-tag.component.scss'],
})
export class NomenclatureTypeTagComponent {
  @Input() id: number;
  @Input() sourceType: BindingSourceType;

  public readonly colors = ThemeColors;
  public readonly routesParts = ROUTES_PARTS;
  public readonly BindingSourceType = BindingSourceType;

  constructor(public permissionService: PermissionService) {}
}
