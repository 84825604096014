<button
  type="button"
  [pikTooltip]="tooltip"
  [position]="tooltipPosition"
  [ngStyle]="{
    width: size + 'px',
    height: size + 'px'
  }"
>
  <svg-icon
    [src]="icon"
    [stretch]="true"
    [ngStyle]="{
      width: size + 'px',
      height: size + 'px'
    }"
  ></svg-icon>
</button>
