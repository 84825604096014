import { ColumnType, TableColumnDef } from './models';

export enum TableSettingsTabs {
  Filter,
  Columns,
}

export interface ITablePageDef {
  name: string;
  service?: any;
  columns: TableColumnDef[];
  searchTypes: SearchType[];
}

export interface SearchType {
  key: string;
  label: string;
  default?: boolean;
  type?: ColumnType;
}

export type ColumnStorage = Pick<TableColumnDef, 'name' | 'default' | 'required' | 'isVisible' | 'orderPosition'>;
