<input
  pikInput
  [formControl]="nameControl"
  [pikAutocomplete]="autocomplete"
  placeholder="{{ 'COMPONENT_USER_CONTROL' | translate }}"
/>

<pik-autocomplete
  [standalone]="false"
  #autocomplete="pikAutocomplete"
  (optionSelected)="selectEntity($event)"
  [dataSource]="entities$ | async"
>
</pik-autocomplete>
