import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ROUTES_PARTS, ThemeColors } from '@common/consts';
import { BindingSourceType } from '@common/dto';
import { BindingEntityType } from '@features/bindings/shared/types';
import { PermissionService } from '@core/account/services';

@Component({
  selector: 'app-binding-tag',
  templateUrl: './binding-tag.component.html',
  styleUrls: ['./binding-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BindingTagComponent {
  @Input() bindingType: BindingEntityType;
  @Input() snapCount: number;
  @Input() internalCount: number;
  @Input() entityId: number;

  BindingSourceType = BindingSourceType;
  colors = ThemeColors;
  ROUTES_PARTS = ROUTES_PARTS;

  constructor(public permissionService: PermissionService) {}
}
