<ng-container *ngIf="!isDisabled; else attributesView">
  <ng-container *ngFor="let item of form.controls; let i = index">
    <div class="attr-control" [class.removed]="item.value.removed">
      <ng-container [formGroup]="item">
        <div class="attr-control__main">
          <div class="attr-control__name">
            <pik-ellipsis-text>{{ item.value.attributeName }}</pik-ellipsis-text>
          </div>

          <pik-form-control class="attr-control__values">
            <ng-container [ngSwitch]="item.value.attributeType">
              <ng-container *ngSwitchCase="AttributeType.String">
                <input
                  pikInput
                  size="small"
                  formControlName="valueStr"
                  placeholder="{{ 'COMPONENT_ATTRIBUTE_CONTROL.VALUE_PLACEHOLDER' | translate }}"
                />

                <ng-container *ngIf="item.get('valueStr').touched || parentFormGroup?.touched">
                  <pik-error *ngIf="item.get('valueStr').hasError('required')">{{
                    'COMPONENT_ATTRIBUTE_CONTROL.VALUE_ERROR' | translate
                  }}</pik-error>
                  <pik-error *ngIf="item.get('valueStr').hasError('maxlength')">{{
                    'COMPONENT_ATTRIBUTE_CONTROL.MAX_ERROR' | translate
                  }}</pik-error>
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="AttributeType.StringList">
                <app-multi-select
                  placeholder=""
                  class="attribute-values-select"
                  formControlName="options"
                  [options]="item.value.displayOptions"
                  [canSearch]="true"
                >
                </app-multi-select>

                <ng-container *ngIf="item.get('options').touched || parentFormGroup?.touched">
                  <pik-error *ngIf="item.get('options').hasError('required')">
                    {{ 'COMPONENT_ATTRIBUTE_CONTROL.VALUES_ERROR' | translate }}
                  </pik-error>
                </ng-container>
              </ng-container>
            </ng-container>
          </pik-form-control>

          <icon-button
            icon="./assets/icons/restore.svg"
            tooltip="{{ 'COMMON.RESTORE' | translate }}"
            tooltipPosition="before"
            class="attr-control__action"
            (click)="restoreAttribute(item)"
            *ngIf="item.value.removed"
          ></icon-button>

          <icon-button
            icon="./assets/icons/delete.svg"
            tooltip="{{ 'COMMON.REMOVE' | translate }}"
            tooltipPosition="before"
            class="attr-control__action"
            (click)="removeAttribute(item, i)"
            *ngIf="!item.value.removed && item.value.canRemove"
          ></icon-button>
        </div>
        <div
          class="attr-control__description"
          *ngIf="showDescription && item.value.originalAttribute.attributeDescription"
        >
          <app-expandable-block entityType="description">
            <markdown-viewer [data]="item.value.originalAttribute.attributeDescription || null"></markdown-viewer>
          </app-expandable-block>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-content *ngIf="isSomeAttrRemoved"></ng-content>
  <button
    type="button"
    *ngIf="originalAttributesList?.length && canAdd && originalAttributesList.length !== form.getRawValue().length"
    pikButton
    appearance="inverse"
    class="addButton"
    [pikDropdownTrigger]="dropdown"
  >
    <svg-icon src="./assets/icons/plus.svg" pikButtonIcon position="before"></svg-icon>
    {{ 'COMPONENT_ATTRIBUTE_CONTROL.ADD_ATTRIBUTE' | translate }}
  </button>

  <pik-dropdown #dropdown="pikDropdown">
    <app-attributes-list [attributes]="attributesList" (add)="addEmptyAttribute($event); dropdown.close()">
    </app-attributes-list>
  </pik-dropdown>
</ng-container>

<ng-template #attributesView>
  <app-expandable-block entityType="attributes">
    <app-attributes-view [attributes]="entityAttributes"></app-attributes-view>
  </app-expandable-block>
</ng-template>
