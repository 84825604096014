import { Component, Input } from '@angular/core';
import { ROUTES } from '@common/consts';
import { PermissionService } from '@core/account/services';
import { normalizePageParams } from '@common/utils';
import { IParametersFilterParams, ParametersFilterParams } from '@common/models/filters';

@Component({
  selector: 'app-parameters-link',
  templateUrl: './parameter-link.component.html',
})
export class ParameterLinkComponent {
  @Input() set params(value: IParametersFilterParams) {
    if (value) {
      this.link = ['', ROUTES.parameters, normalizePageParams(new ParametersFilterParams(value).toParams())];
    }
  }
  link: any[];

  constructor(public permissionService: PermissionService) {}
}
