import { TablePageFilter } from '../table-page-filter-base';
import { IFamilyVersionParameterFilterParams } from './family-version-parameter-filter.interface';
import { ParameterErrorType } from '@common/enums';

export class FamilyVersionParameterFilterParams extends TablePageFilter<IFamilyVersionParameterFilterParams> {
  id: number;
  parameterValueSetId: number;
  parameterName: string;
  dataTypeIds: number[];
  unitTypeIds: number[];
  valueStr: string;
  isType: boolean;
  isAdditional: boolean;
  isKey: boolean;
  isSynchronizable: boolean;
  errorTypes: ParameterErrorType[];
  skipEmptyArrayFields = ['errorTypes'];

  constructor(filter: IFamilyVersionParameterFilterParams = {}) {
    super(filter);
  }

  public toObject(): IFamilyVersionParameterFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      parameterValueSetId: this.parameterValueSetId,
      parameterName: this.parameterName,
      dataTypeIds: this.dataTypeIds,
      unitTypeIds: this.unitTypeIds,
      valueStr: this.valueStr,
      isType: this.isType,
      isAdditional: this.isAdditional,
      isKey: this.isKey,
      isSynchronizable: this.isSynchronizable,
      errorTypes: this.errorTypes,
    };
  }
}
