import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiPaths } from '@common/consts';
import { BindingFilterCreateDto, BindingFilterDto, BindingFilterUpdateDto, IdNameDto } from '@common/dto';
import { CrudService } from '@common/interfaces';

@Injectable({ providedIn: 'root' })
export class BindingFilterApiService implements CrudService<BindingFilterDto> {
  constructor(private http: HttpClient) {}

  public getById(entityId: number): Observable<BindingFilterDto> {
    return this.http.get<BindingFilterDto>(`${ApiPaths.BindingFilter}/${entityId}`);
  }

  public create(entity: BindingFilterCreateDto): Observable<BindingFilterDto> {
    return this.http.post<BindingFilterDto>(ApiPaths.BindingFilter, entity);
  }

  public update(entity: BindingFilterUpdateDto): Observable<BindingFilterDto> {
    return this.http.put<BindingFilterDto>(`${ApiPaths.BindingFilter}/${entity.id}`, entity);
  }

  public remove(entityId: number): Observable<void> {
    return this.http.delete<void>(`${ApiPaths.BindingFilter}/${entityId}`);
  }

  /**
   * Удаляет группу условий привязок
   */

  public removeGroup(bindingId: number, groupNumber: number): Observable<void> {
    return this.http.delete<void>(`${ApiPaths.BindingFilter}/group/${groupNumber}/binding/${bindingId}`);
  }

  /**
   * Возвращает операторы для условий для привязки.
   * @returns Список операторов
   */
  public getOperators(): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.BindingFilter}/operators`);
  }

  /**
   * Возвращает типы условий привязок.
   * @returns Список типов
   */
  public getTypes(): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.BindingFilter}/types`);
  }
}
