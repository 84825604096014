import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { OAuthService } from 'angular-oauth2-oidc';
import { fromEvent, Subject } from 'rxjs';
import { delay, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

import { PikSelectComponent } from '@pik-ui/ng-components';
import { ApiPaths, NAVIGATION_ITEMS, NAVIGATION_LIST, PINNED_NAVIGATION_LIST, ROUTES_PARTS } from '@common/consts';
import { AccountService, PermissionService } from '@core/account/services';
import { environment } from '../../../../environments/environment';
import { NavigationCategory, NavigationPage } from '@common/interfaces/Navigation';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
})
export class SideNavigationComponent implements OnInit, OnDestroy {
  activeMenu = false;
  supportLink = environment.supportLink;
  menuLists: NavigationCategory[] = [];
  pinnedMenuPages: NavigationPage[] = [];
  pinnedCategory: NavigationCategory;
  scrollConfig = { suppressScrollX: true, suppressScrollY: true, passive: true };
  ApiPaths = ApiPaths;
  standardControl = new FormControl(null);
  routesParts = ROUTES_PARTS;

  private _destroy$ = new Subject();

  @ViewChild('standardSelect', { static: false }) standardSelect: PikSelectComponent;

  @ViewChild('scroll', { static: false }) scroll: PerfectScrollbarComponent;

  constructor(
    private oauthService: OAuthService,
    public userService: AccountService,
    public permissionService: PermissionService,
  ) {}

  ngOnInit(): void {
    this.userService.user$
      .pipe(
        filter((r) => !!r),
        tap((user) => {
          this.standardControl.setValue(user.currentStandard.id, { emitEvent: false });
          this.setCategories();
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this.standardControl.valueChanges
      .pipe(
        switchMap((value) => this.userService.changeStandard(+value)),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  mouseEnter() {
    this.scroll.config.suppressScrollY = false;
    this.activeMenu = true;

    if (this.standardSelect) {
      this.standardSelect.openedChange
        .pipe(
          filter((value) => !!value),
          delay(null),
          switchMap(() => {
            const elem = document.getElementsByClassName('sidenav-backdrop')[0];
            return fromEvent(elem, 'mouseleave');
          }),
          tap((event) => this.mouseLeave(event)),
          takeUntil(this._destroy$),
        )
        .subscribe();
    }
  }

  mouseLeave(event) {
    if (
      !event?.relatedTarget ||
      (typeof event?.relatedTarget?.className === 'string' &&
        event?.relatedTarget?.className?.indexOf('sidenav-backdrop') === -1 &&
        event?.relatedTarget?.className?.indexOf('pik-option') === -1)
    ) {
      this.standardSelect && this.standardSelect.close();
      this.activeMenu = false;
    }
  }

  logout() {
    this.oauthService.logOut();
  }

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  setCategories() {
    this.getPinnedMenuItems();

    this.menuLists = NAVIGATION_LIST.filter((c) => (c.permission ? this.permissionService.access[c.permission] : true))
      .map((c) => ({
        ...c,
        pages: c.pages
          .filter((p) => (p.permission ? this.permissionService.access[p.permission] : true))
          .map((item) => ({ ...item, pinned: this.checkPinned(item.name) })),
      }))
      .filter((c) => c.pages.length);
  }

  getPinnedMenuItems() {
    const pinnedNames: string[] = JSON.parse(localStorage.getItem('pinnedMenuListPages')) || [];
    this.pinnedMenuPages = NAVIGATION_ITEMS.filter((f) => pinnedNames.some((i) => i === f.name)).map((i) => ({
      ...i,
      pinned: true,
    }));

    this.pinnedCategory = { ...PINNED_NAVIGATION_LIST, pages: this.pinnedMenuPages };
  }

  checkPinned(menuPageName: string): boolean {
    return this.pinnedMenuPages.some((i) => i.name === menuPageName);
  }
}
