import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AttributeOptionDto } from '@common/dto';
import { ThemeColors } from '@common/consts';

@Component({
  selector: 'app-options-list',
  templateUrl: './options-list.component.html',
  styleUrls: ['./options-list.component.scss'],
})
export class OptionsListComponent {
  @Input() options: AttributeOptionDto[];
  @Input() showDeletedValues: boolean;
  @Input() maxCount: number;
  @Input() color: string = ThemeColors.DarkBlue;
  @Input() bgColor: string = ThemeColors.Gray4;
  @Input() externalExpandEvent: boolean;
  @Output() expand = new EventEmitter();

  protected readonly ThemeColors = ThemeColors;
  public isShowAllCount: boolean;

  toggle(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (this.externalExpandEvent) {
      this.expand.emit();
      return;
    }

    this.isShowAllCount = !this.isShowAllCount;
  }
}
