import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  FamilySearchDto,
  FolderFamilyDto,
  FolderFamilyMoveDto,
  TreeItemFolderDto,
  TreeItemFolderExtendedDto,
} from '@common/dto';
import { ApiPaths } from '@common/consts';
import { TreeItemsFilterParams } from '@common/models/filters';
import { ResultPart } from '@common/interfaces';

@Injectable({
  providedIn: 'root',
})
export class TreeItemsApiService {
  constructor(private http: HttpClient) {}

  public getById(id: number, path = ''): Observable<TreeItemFolderExtendedDto> {
    return this.http.get<TreeItemFolderExtendedDto>(`${ApiPaths.TreeItems}/${id}?path=${path}`);
  }

  public getTreeWithAncestors(id: number): Observable<TreeItemFolderExtendedDto> {
    return this.http.get<TreeItemFolderExtendedDto>(`${ApiPaths.TreeItems}/${id}/withAncestors`);
  }

  public getRootFolders(path = ''): Observable<TreeItemFolderDto[]> {
    return this.http.get<TreeItemFolderDto[]>(`${ApiPaths.TreeItems}?path=${path}`);
  }

  public moveFamilies(folderId: number, families: Omit<FolderFamilyDto, 'id'>[]) {
    return this.http.post(`${ApiPaths.TreeItems}/${folderId}/moveFamilies`, families);
  }

  public copyFamilies(folderId: number, families: FolderFamilyDto[]) {
    return this.http.post(`${ApiPaths.TreeItems}/${folderId}/copyFamilies`, families);
  }

  public batchDeleteFromFolders(families: Omit<FolderFamilyDto, 'id'>[]) {
    return this.http.post(`${ApiPaths.TreeItems}/deleteFromFolders`, families);
  }

  public copyFamiliesToAnyFolders(data: FolderFamilyMoveDto) {
    return this.http.post(`${ApiPaths.TreeItems}/copyFamiliesToAnyFolders`, data);
  }

  public search(filter: TreeItemsFilterParams): Observable<ResultPart<FamilySearchDto>> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<ResultPart<FamilySearchDto>>(`${ApiPaths.TreeItems}/search`, params);
  }
}
