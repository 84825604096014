import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';

import {
  PikButtonsModule,
  PikCheckboxModule,
  PikDrawerModule,
  PikSearchInputModule,
  PikSelectModule,
  PikTabsModule,
} from '@pik-ui/ng-components';

import { FmTableModule } from '@shared/modules/fm-table';
import { FmInfoTooltipModule } from '@shared/components/fm-ui/fm-info-tooltip/fm-info-tooltip.module';
import { TablePageServiceBase } from '@shared/modules/table-builder';

import { FmPageHeaderDirective, FmPageTopToolbarDirective } from './layout';
import { FmPageComponent } from './page-component/page-component.component';
import { PageBuilderComponent } from './page-builder/page-builder.component';
import { TranslateModule } from '@ngx-translate/core';

const layout = [FmPageComponent, FmPageHeaderDirective, FmPageTopToolbarDirective];

@NgModule({
  declarations: [...layout, PageBuilderComponent],
  imports: [
    CommonModule,
    PikDrawerModule,
    PikTabsModule,
    PikButtonsModule,
    PikCheckboxModule,
    ReactiveFormsModule,
    FmTableModule,
    AngularSvgIconModule,
    PikSelectModule,
    PikSearchInputModule,
    FmInfoTooltipModule,
    TranslateModule,
  ],
  exports: [...layout, PageBuilderComponent, FmTableModule],
})
export class PageBuilderModule {
  static forFeature(pageDef?: { service?: any }): ModuleWithProviders<PageBuilderModule> {
    return {
      ngModule: PageBuilderModule,
      providers: [{ provide: TablePageServiceBase, useClass: pageDef.service }],
    };
  }
}
