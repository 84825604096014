import { AfterViewInit, Directive, ElementRef } from '@angular/core';

// tslint:disable-next-line:max-line-length
const LINK_REGEXP =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;

@Directive({
  selector: '[appLinkTransform]',
})
export class LinkTransformDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  get element(): HTMLElement {
    return this.el.nativeElement;
  }

  ngAfterViewInit() {
    const innerText: string = this.element.innerText;

    if (LINK_REGEXP.test(innerText)) {
      const link = document.createElement('a');
      link.textContent = innerText;
      link.href = innerText;
      link.target = '_blank';

      link.addEventListener('click', (e) => e.stopPropagation());

      this.element.innerHTML = '';
      this.element.append(link);
    }
  }
}
