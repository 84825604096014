import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { PermissionService } from '@core/account/services';
import { AttachmentDto, IdNameDto } from '@common/dto';
import { transformToIds } from '@common/utils';
import { ModalService, ToastService } from '@services';
import { filter, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsComponent {
  @Input() attachments: AttachmentDto[] = [];
  @Input() set disciplines(data: number[] | IdNameDto[]) {
    this._disciplines = transformToIds(data);
  }

  get disciplines() {
    return this._disciplines;
  }

  @Input() removable = false;
  @Input() permissionUpload = false;
  @Input() permissionDownload = true;
  @Input() pending = false;
  @Output() remove = new EventEmitter<AttachmentDto>();
  @Output() download = new EventEmitter<AttachmentDto>();
  @Output() downloadAll = new EventEmitter();
  @Output() upload = new EventEmitter<File[]>();

  @ViewChild('fileInput')
  fileInput: ElementRef<HTMLInputElement>;

  private _disciplines: number[];

  constructor(
    public permissionService: PermissionService,
    public modalService: ModalService,
    private toastService: ToastService,
  ) {}

  removeHandler(file) {
    this.modalService
      .confirm('COMPONENT_ATTACHMENTS.CONFIRM_QUESTION')
      .pipe(
        take(1),
        filter((f) => f),
        tap(() => this.remove.emit(file)),
      )
      .subscribe();
  }

  public addFile() {
    this.fileInput.nativeElement.click();
  }

  public uploadFiles(fileList: FileList) {
    const files: File[] = Array.from(fileList);

    if (!files.length) {
      return;
    }

    if (files.some((file) => file.size > 104857600)) {
      this.toastService.warning(
        { value: 'COMPONENT_ATTACHMENTS.SIZE_LIMIT_ERROR.MESSAGE' },
        { value: 'COMPONENT_ATTACHMENTS.SIZE_LIMIT_ERROR.TITLE' },
      );
      return;
    }
    this.upload.emit(files);
  }

  downloadFile(file: AttachmentDto) {
    this.download.emit(file);
  }
}
