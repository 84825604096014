<input
  type="text"
  pikInput
  [pikAutocomplete]="autocomplete"
  [formControl]="nameControl"
  placeholder="{{ 'COMPONENT_VIEW_CONTROL' | translate }}"
/>

<pik-autocomplete
  [standalone]="false"
  #autocomplete="pikAutocomplete"
  (optionSelected)="selectEntity($event)"
  [dataSource]="entities$ | async"
></pik-autocomplete>
