<pik-loader *ngIf="pending; else tableActionButton"></pik-loader>
<ng-template #tableActionButton>
  <button
    type="button"
    [pikTooltip]="tooltip"
    position="above"
    (click)="action.emit(_type)"
    class="table-action-button"
  >
    <svg-icon [src]="'./assets/icons/' + icon" [stretch]="true"></svg-icon>
  </button>
</ng-template>
