import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { PikAutocompleteSelectedEvent } from '@pik-ui/ng-components';

import { IdNameDto } from '@common/dto';
import { UsersApiService } from '@services/api';

@Component({
  selector: 'app-user-control',
  templateUrl: './user-control.component.html',
  styleUrls: ['./user-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserControlComponent implements OnDestroy, ControlValueAccessor, OnInit {
  list: IdNameDto[] = [];
  nameControl = new UntypedFormControl(null);
  selectedUser: IdNameDto;

  entities$ = new Subject();

  private _destroy$ = new Subject();

  private _onChange: (value) => void;
  private _onTouched: () => void;

  constructor(private _changeDetector: ChangeDetectorRef, private _usersApi: UsersApiService) {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit() {
    this._subscribeToNameControl().subscribe();
  }

  async writeValue(value: number) {
    if (value) {
      await this._getEntity(value).toPromise();
    } else {
      this.nameControl.setValue(null);
    }
    this._changeDetector.detectChanges();
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  selectEntity(event: PikAutocompleteSelectedEvent) {
    this.selectedUser = event.value;
    this.nextChangeValue();
  }

  nextChangeValue(): void {
    if (this._onChange) {
      this._onChange(this.selectedUser?.id || null);
    }
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.nameControl.disable() : this.nameControl.enable();
  }

  private _subscribeToNameControl() {
    return this.nameControl.valueChanges.pipe(
      filter((name) => name !== this.selectedUser?.name),
      tap(() => {
        this.selectedUser = null;
        this.nextChangeValue();
      }),
      debounceTime(300),
      switchMap((value) => (value === '' ? of([]) : this._usersApi.smartSearch(value))),
      map((result: IdNameDto[]) =>
        result.map((item) => ({
          label: item.name,
          value: item,
        })),
      ),
      tap((data) => this.entities$.next(data)),
      takeUntil(this._destroy$),
    );
  }

  private _getEntity(id: number) {
    return this._usersApi.getUser(id).pipe(
      take(1),
      map((user) => ({ id: user.id, name: user.name })),
      tap((entity: IdNameDto) => this.nameControl.setValue(entity.name, { emitEvent: false })),
      tap((entity: IdNameDto) => (this.selectedUser = entity)),
    );
  }
}
