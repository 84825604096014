<div
  class="list"
  [ngClass]="{
    active: isActiveMenu,
    selected: isListSelected,
    selected_withoutActive: !isActiveMenu && isListSelected,
    noActive: noSelected
  }"
>
  <div class="list__label-container" (click)="toggleExpandableList()">
    <div class="list__label">
      <span class="list__icon {{ menuItemsList.icon }}">
        <svg-icon src="./assets/icons/sections/{{ menuItemsList.icon }}.svg"></svg-icon>
      </span>
      <span class="list__name">
        {{ 'MENU.CATEGORIES.' + menuItemsList.title | translate }}
      </span>
    </div>

    <icon-button
      class="list__expanded-arrow-icon"
      icon="./assets/icons/tree-angle-active.svg"
      [ngClass]="{ expanded: isExpanded }"
      [size]="10"
      *ngIf="menuItemsList.pages?.length"
    ></icon-button>
  </div>

  <div class="items" *ngIf="menuItemsList.pages?.length">
    <ng-container *ngFor="let item of menuItemsList.pages">
      <div class="items__item" [routerLinkActive]="'active'" [ngClass]="{ opened: isExpanded && isActiveMenu }">
        <a class="items__item-link" routerLink="/{{ item.link }}">
          <span class="items__item-label">{{ 'MENU.ITEM.' + item.name | translate }}</span>
          <svg-icon
            class="items__item-icon"
            src="./assets/icons/pin.svg"
            [stretch]="true"
            (click)="togglePinItem(item, $event)"
            [ngClass]="{ active: item.pinned }"
          ></svg-icon>
        </a>
      </div>
    </ng-container>
  </div>
</div>
