<ng-container *ngIf="(functionalTypeList$ | async).length > 1; else singleFunctionalType">
  <div class="control-wrap">
    <pik-select inverse [formControl]="functionalTypesControl" [customTemplate]="selectTemplate" class="{{ size }}">
      <pik-option [value]="functionalType.id" *ngFor="let functionalType of functionalTypeList$ | async">
        <app-functional-type-tag [linked]="false" [entity]="functionalType"></app-functional-type-tag>
      </pik-option>
    </pik-select>

    <a [routerLink]="['', ROUTES_PARTS.FUNCTIONAL_TYPES, activeFunctionalType?.id || '']">
      <icon-button
        icon="./assets/icons/tree-angle.svg"
        tooltip="{{ 'COMMON_LABELS.TO_PROPERTIES' | translate }}"
        [size]="10"
      ></icon-button>
    </a>
    <ng-template #selectTemplate let-option>
      <app-functional-type-tag [linked]="false" [entity]="activeFunctionalType"></app-functional-type-tag>
    </ng-template>
  </div>
</ng-container>

<ng-template #singleFunctionalType>
  <app-functional-type-tag [entity]="activeFunctionalType"></app-functional-type-tag>
</ng-template>
