import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TrackByFunction,
  ViewChildren,
} from '@angular/core';
import { TableColumnDef } from '@shared/modules/table-builder';
import { ColumnType } from '../table-builder/models/table-columns';
import { FmTableCellTemplateDirective, FmTableHeaderTemplateDirective } from './directives';
import { SortOrder } from '@common/interfaces';
import { normalizePageParams } from '@common/utils';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { RowService } from '@services/row.service';

interface TablePaginatorDef {
  fullCount: number;
  pageIndex: number;
  limit: number;
  limitMin: number;
  pageSizesList: number[];
}

@Component({
  selector: 'fm-table',
  templateUrl: './fm-table.component.html',
  styleUrls: ['./fm-table.component.scss'],
})
export class FmTableComponent {
  @Input() paginator: TablePaginatorDef;
  @Input() pending: boolean;
  @Input() hasActiveFilter: boolean;
  @Input() entityPhrase = 'MAIN_TABLE.ENTITY_PHRASE';
  @Input() sortBy: string;
  @Input() sortOrder: SortOrder = SortOrder.Ascending;
  clickedCell = null;
  @Input()
  set columns(cols: TableColumnDef[]) {
    if (!cols) {
      throw new Error('Column description not passed');
    }

    if (!Array.isArray(cols)) {
      throw new Error('Column descriptions must be an array and be of type TableColumnDef[]');
    }

    this._columns = cols.sort((a, b) => a.orderPosition - b.orderPosition);
    this.displayedColumns = this._columns.map((c) => c.name);
  }
  get columns() {
    return this._columns;
  }

  @Input() dataSource: any[] = [];
  @Input() trackBy: TrackByFunction<any>;

  @Output() sortChange = new EventEmitter();
  @Output() pageChange = new EventEmitter<number>();
  @Output() pageSizeChange = new EventEmitter<number>();
  @Output() onRowClick = new EventEmitter<any>();

  @ContentChild(FmTableHeaderTemplateDirective)
  headerTemplate: FmTableHeaderTemplateDirective;

  @ContentChild(FmTableCellTemplateDirective)
  cellTemplate: FmTableCellTemplateDirective;

  @ViewChildren(PerfectScrollbarComponent)
  _scrollbar: QueryList<PerfectScrollbarComponent>;

  SortOrder = SortOrder;
  ColumnType = ColumnType;
  displayedColumns: string[] = [];
  hideScrollingOverlay: boolean;

  normalizePageParams = normalizePageParams;

  private _columns: TableColumnDef[] = [];

  constructor(public rowService: RowService) {}

  trackByIndex(index) {
    return index;
  }
  onRowClickFn(row): void {
    this.onRowClick.emit([row, this.clickedCell]);
  }
}
