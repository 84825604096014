<div class="fm-wrap">
  <div class="fm-container">
    <div *ngIf="navLinks.length > 0" class="fm-menu">
      <fm-hint link="https://fm-help.bimteam.app/{{ 'LOCALIZATION' | translate }}/web/tabs"></fm-hint>
      <nav class="fm-menu__list">
        <a
          class="fm-menu__list-item"
          *ngFor="let link of firstLinks"
          [ngClass]="link.class"
          routerLinkActive="active"
          [routerLink]="[link.path, link.params ?? {}]"
          (auxclick)="onCloseTab($event, link)"
        >
          <span>{{ link.label }}</span>
          <icon-button
            icon="./assets/icons/close.svg"
            class="fm-menu__button"
            tooltip="{{ 'COMMON.CLOSE' | translate }}"
            (click)="onCloseTab($event, link)"
            [size]="10"
          ></icon-button>
        </a>
      </nav>
      <div class="otherTabs" *ngIf="otherLinks.length" [ngClass]="{ active: toggleTabs }" #otherTabs>
        <icon-button
          icon="./assets/icons/tree-angle-active.svg"
          class="fm-menu__button"
          [ngClass]="{ active: toggleTabs }"
          tooltip="{{ 'TABS.OTHER' | translate }}"
          (click)="toggleOtherTabs()"
          *ngIf="otherLinks.length"
        ></icon-button>

        <div class="otherTabs__wrap">
          <div class="otherTabs__content">
            <a
              routerLinkActive="active"
              [ngClass]="link.class"
              [routerLink]="[link.path, link.params ? link.params : {}]"
              class="otherTabs__item"
              *ngFor="let link of otherLinks"
              (click)="disableOtherTabs($event)"
              (auxclick)="onCloseTab($event, link)"
            >
              <span class="otherTabs__label"
                ><pik-ellipsis-text>{{ link.label }}</pik-ellipsis-text></span
              >
              <icon-button
                icon="./assets/icons/close.svg"
                class="fm-menu__button"
                tooltip="{{ 'COMMON.CLOSE' | translate }}"
                (click)="onCloseTab($event, link)"
                [size]="10"
              ></icon-button>
            </a>
          </div>
        </div>
      </div>
      <icon-button
        icon="./assets/icons/close.svg"
        class="fm-menu__button"
        tooltip="{{ 'TABS.CLOSE_ALL' | translate }}"
        (click)="closeAllTabs()"
      ></icon-button>
    </div>
    <div class="fm-content">
      <div class="fm-content__wrap">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
