import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TableBuilderService {
  __isCharacteristicsAddButtonVisible$ = new BehaviorSubject(true);
  get isCharacteristicsAddButtonVisible() {
    return this.__isCharacteristicsAddButtonVisible$.asObservable();
  }
  constructor() {}
}
