import { toParams } from '@common/utils';
import { EntityType, RangeDateDto } from '@common/dto';
import { PeriodType } from '@common/enums';

export interface IDynamicsFilterParams {
  functionalTypeIds?: number[];
  disciplineIds?: number[];
  entityType?: EntityType;
  groupBy?: PeriodType;
  onlyCompletedRequest?: boolean;
  rangeDate?: RangeDateDto;
}

export class DynamicsFilterParams {
  functionalTypeIds: number[] = [];
  disciplineIds: number[] = [];
  entityType: EntityType = null;
  groupBy: PeriodType = null;
  onlyCompletedRequest: boolean;
  rangeDate: RangeDateDto = null;

  constructor(filter?: IDynamicsFilterParams) {
    Object.assign(this, filter);
  }

  public toObject(): IDynamicsFilterParams {
    return {
      functionalTypeIds: this.functionalTypeIds,
      disciplineIds: this.disciplineIds,
      entityType: this.entityType,
      groupBy: this.groupBy,
      onlyCompletedRequest: this.onlyCompletedRequest,
      rangeDate: this.rangeDate,
    };
  }

  public toParams() {
    return toParams(this.toObject());
  }
}
