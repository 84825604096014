import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

import {
  AttachmentDto,
  FunctionalTypeCreateDto,
  FunctionalTypeDto,
  FunctionalTypeListItemDto,
  FunctionalTypeUpdateDto,
  IdNameDto,
  PatchOperation,
} from '@common/dto';
import { FunctionalTypesFilterParams, FunctionalTypeSheetFilterAllParams } from '@common/models/filters';
import { CrudService, ImageDataInterface, ResultPart } from '@common/interfaces';
import { ApiPaths } from '@common/consts';
import { SmartSearchFilter } from '@common/models/filters/smart-search/smart-search-filter';
import { saveAs } from 'file-saver';

@Injectable({ providedIn: 'root' })
export class FunctionalTypesApiService
  implements CrudService<FunctionalTypeDto, FunctionalTypeCreateDto, FunctionalTypeUpdateDto>
{
  constructor(private http: HttpClient, private _sanitized: DomSanitizer) {}

  /**
   * Возвращает заданное количество объектов, по умолчанию возвращает все.
   * @param filter - фильтр
   */
  public getList(filter: FunctionalTypesFilterParams) {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<ResultPart<FunctionalTypeListItemDto>>(`${ApiPaths.FunctionalTypes}/filter`, params);
  }

  public smartSearch(filter: SmartSearchFilter): Observable<IdNameDto[]> {
    const params = filter.toParams();
    return this.http.get<IdNameDto[]>(`${ApiPaths.FunctionalTypes}/smartSearch`, { params });
  }

  /**
   * Возвращает объект по ID.
   * @param id - id функционального типа
   */
  public getById(id: number): Observable<FunctionalTypeDto> {
    return this.http.get<FunctionalTypeDto>(`${ApiPaths.FunctionalTypes}/${id}`);
  }

  /**
   * Добавляет новый объект
   * @param attribute - новый атрибут
   */
  public create(attribute: FunctionalTypeCreateDto) {
    return this.http.post<FunctionalTypeDto>(ApiPaths.FunctionalTypes, attribute);
  }

  /**
   * Обновляет объект
   * @param attribute - изменённые данные
   */
  public update(attribute: FunctionalTypeUpdateDto) {
    return this.http.put<FunctionalTypeDto>(`${ApiPaths.FunctionalTypes}/${attribute.id}`, attribute);
  }

  /**
   * Удаляет объект
   * @param attributeId - id атрибута
   */
  public remove(attributeId: number) {
    return this.http.delete<void>(`${ApiPaths.FunctionalTypes}/${attributeId}`);
  }

  /**
   * Поиск объекта.
   * @param query - поисковая строка
   */
  public search(query: string) {
    return this.http.get<FunctionalTypeDto[]>(`${ApiPaths.FunctionalTypes}/search`, { params: { query } });
  }

  /**
   * Добавляет несколько объектов.
   * @param attributes - атрибуты
   */
  public createBatch(attributes: FunctionalTypeDto[]) {
    return this.http.post<FunctionalTypeDto[]>(`${ApiPaths.FunctionalTypes}/batch`, attributes);
  }

  /**
   * Обновляет отдельные поля ресурса
   * @param functionalTypeId - id
   * @param patchData - поля для обновления
   */
  public patch(functionalTypeId: number, patchData: PatchOperation[]) {
    return this.http.patch<FunctionalTypeDto[]>(`${ApiPaths.FunctionalTypes}/${functionalTypeId}`, patchData);
  }

  /**
   * Возвращает иконку прредпросмотра ФТ
   * @param functionalTypeId - id
   */
  public getImage(functionalTypeId: number): Observable<ImageDataInterface> {
    return this.getImageAsync(functionalTypeId).pipe(
      take(1),
      map((data) =>
        data
          ? {
              file: data,
              styleUrl: this._sanitized.bypassSecurityTrustStyle('url(' + URL.createObjectURL(data) + ')'),
              url: this._sanitized.bypassSecurityTrustUrl(URL.createObjectURL(data)),
              resourceUrl: this._sanitized.bypassSecurityTrustResourceUrl(URL.createObjectURL(data)),
            }
          : null,
      ),
      catchError(() => of(null)),
    );
  }

  /**
   * Возвращает изображение ФТ
   * @param functionalTypeId - id
   */
  getImageAsync(functionalTypeId: number) {
    return this.http.get(`${ApiPaths.FunctionalTypes}/${functionalTypeId}/image`, { responseType: 'blob' });
  }

  public uploadImage(id: number, image: File, forceUpdate = true): Observable<void> {
    const formData = new FormData();
    formData.append('file', image);

    return this.http.post<void>(`${ApiPaths.FunctionalTypes}/${id}/image?forceUpdate=${forceUpdate}`, formData);
  }

  public createGoogleSheetById(id: number, filter: Partial<FunctionalTypeSheetFilterAllParams>): Observable<string> {
    return this.http.post(`${ApiPaths.FunctionalTypes}/${id}/sheets`, filter, { responseType: 'text' });
  }

  public createExcelSheetById(id: number, filter: Partial<FunctionalTypeSheetFilterAllParams>): Observable<Blob> {
    return this.http.post(`${ApiPaths.FunctionalTypes}/${id}/excel`, filter, { responseType: 'blob' });
  }

  public importGoogleSheet(id: number, sheetId: string): Observable<void> {
    return this.http.put<void>(`${ApiPaths.FunctionalTypes}/${id}/sheets/${sheetId}`, null);
  }

  public importExcelSheetById(id: number, file: File) {
    const data = new FormData();
    data.append('file', file);
    return this.http.put(`${ApiPaths.FunctionalTypes}/${id}/excel`, data);
  }

  public importRequestGoogleSheet(sheetId: string): Observable<void> {
    return this.http.put<void>(`${ApiPaths.FunctionalTypes}/requests/${sheetId}`, null);
  }

  public importRequestExcelSheet(file: File): Observable<void> {
    const data = new FormData();
    data.append('file', file);
    return this.http.put<void>(`${ApiPaths.FunctionalTypes}/requests/excel`, data);
  }

  public getAttachments(functionalTypeId: number): Observable<AttachmentDto[]> {
    return this.http.get<AttachmentDto[]>(`${ApiPaths.FunctionalTypes}/${functionalTypeId}/attachments`);
  }

  public async downloadAttachment(functionalTypeId: number, attachmentId: number, fileName: string) {
    const file = await this.getAttachment(functionalTypeId, attachmentId).toPromise();
    saveAs(file, fileName);
  }

  public async downloadAllAttachments(functionalTypeId: number) {
    const file = await this.getAllAttachments(functionalTypeId).toPromise();
    saveAs(file, `Вложения семейства ${functionalTypeId}`);
  }

  public getAttachment(functionalTypeId: number, attachmentId: number) {
    return this.http.get(this.getAttachmentUrl(functionalTypeId, attachmentId), {
      responseType: 'blob',
    });
  }

  public getAttachmentUrl(functionalTypeId: number, attachmentId: number): string {
    return `${ApiPaths.FunctionalTypes}/${functionalTypeId}/attachments/${attachmentId}`;
  }

  public getAllAttachments(functionalTypeId: number) {
    return this.http.get(`${ApiPaths.FunctionalTypes}/${functionalTypeId}/attachments/batch`, {
      responseType: 'blob',
    });
  }

  public removeAttachment(functionalTypeId: number, attachmentId: number) {
    return this.http.delete<{ id: number; name: string }[]>(
      `${ApiPaths.FunctionalTypes}/${functionalTypeId}/attachments/${attachmentId}`,
    );
  }

  public loadAttachments(functionalTypeId: number, files: File[]): Observable<AttachmentDto[]> {
    const formData = new FormData();
    files.forEach((file) => formData.append('files', file));

    return this.http.post<AttachmentDto[]>(`${ApiPaths.FunctionalTypes}/${functionalTypeId}/attachments`, formData);
  }

  public getIdNames(ids: number[]): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.FunctionalTypes}/idnames`, {
      params: {
        ids: ids.map((id) => id.toString()),
      },
    });
  }

  public createGoogleSheet(filter: FunctionalTypesFilterParams): Observable<string> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<string>(`${ApiPaths.FunctionalTypes}/exportGoogleSheetFunctionalTypes`, params);
  }

  public importSheet(sheetId: string) {
    return this.http.put(`${ApiPaths.FunctionalTypes}/importGoogleSheetFunctionalTypes/${sheetId}`, {});
  }

  public createExcelSheet(filter: FunctionalTypesFilterParams): Observable<Blob> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post(`${ApiPaths.FunctionalTypes}/exportExcelSheetFunctionalTypes`, params, {
      responseType: 'blob',
    });
  }

  public importExcelSheet(file: File) {
    const data = new FormData();
    data.append('file', file);
    return this.http.put(`${ApiPaths.FunctionalTypes}/excelImportSheetFunctionalTypes`, data);
  }
}
