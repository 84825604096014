<form [formGroup]="form">
  <pik-form-control *ngFor="let value of values; index as i; trackBy: trackByValue">
    <div class="string-value">
      <input pikInput formControlName="control-{{ i }}" size="small" />
      <icon-button
        icon="./assets/icons/delete.svg"
        tooltip="{{ 'COMMON.REMOVE' | translate }}"
        tooltipPosition="before"
        class="attr-control__action"
        (click)="removeValue(i)"
        *ngIf="!isDisable"
      ></icon-button>
    </div>

    <pik-error *ngIf="form.controls['control-' + i]?.hasError('sameValueError')">{{
      'PARAMETER_VALUES_COMPONENT.ERROR_SAME_VALUE' | translate
    }}</pik-error>
  </pik-form-control>
</form>

<div class="params-footer">
  <app-parameter-valid-values></app-parameter-valid-values>

  <button
    class="add-value"
    pikButton
    appearance="inverse"
    (click)="addValue()"
    *ngIf="!isDisable && values.length < (parameterValueControlService.maxValuesCount$ | async)"
  >
    <svg-icon pikButtonIcon src="./assets/icons/plus.svg"></svg-icon>
    {{ 'PARAMETER_VALUES_COMPONENT.ADD_VALUE' | translate }}
  </button>
</div>
