import { TablePageFilter } from '../table-page-filter-base';
import { ITreeItemsFilterParams } from './tree-items-filter.interface';
import { FilterAttributeData } from '@common/dto';
import { AppType, FamilyType } from '@common/enums';

export class TreeItemsFilterParams extends TablePageFilter<ITreeItemsFilterParams> {
  appType: AppType;
  treeFolderId: number;
  functionalTypeParameters: {
    [key: string]: {
      value: string;
      fullMatch: boolean;
    };
  };
  functionalTypeAttributes: {
    [key: string]: {
      value: string;
      fullMatch: boolean;
    };
  };
  attributeOptionsIds: number[];
  familyIds: number[];
  fmIds: string[];
  isSystem: boolean;
  type: FamilyType;
  folderName: string;
  name: string;
  treeFolderName: string;
  categoryName: string;
  functionalTypeName: string;
  familySymbolName: string;
  includeAncestors: boolean;
  filePath: string;
  attributes: FilterAttributeData[];
  withAttributesOnly: boolean;
  valueStrFromFilterAsMask: boolean;

  constructor(filter?: ITreeItemsFilterParams) {
    super(filter);
  }

  toObject(): ITreeItemsFilterParams {
    return {
      ...super.toObjectBase(),
      appType: this.appType,
      treeFolderId: this.treeFolderId,
      functionalTypeParameters: this.functionalTypeParameters,
      functionalTypeAttributes: this.functionalTypeAttributes,
      attributeOptionsIds: this.attributeOptionsIds,
      familyIds: this.familyIds,
      fmIds: this.fmIds,
      isSystem: this.isSystem,
      type: this.type,
      folderName: this.folderName,
      name: this.name,
      treeFolderName: this.treeFolderName,
      categoryName: this.categoryName,
      functionalTypeName: this.functionalTypeName,
      familySymbolName: this.familySymbolName,
      includeAncestors: this.includeAncestors,
      filePath: this.filePath,
      attributes: this.attributes,
      withAttributesOnly: this.withAttributesOnly,
      valueStrFromFilterAsMask: this.valueStrFromFilterAsMask,
    };
  }
}
